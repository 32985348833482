import styled from "styled-components";
import colors from "../../../../../config/colors";
import { panelSpacing } from "../../../../../common/styles";

export const ProgramMentorsContainer = styled.div``;

export const MentorCard = styled.div`
    border-bottom:solid 1px ${colors.secondary};
    padding:${panelSpacing}px;
    display:flex;
`;
export const MentorImage = styled.img`
    height:150px;
    width:150px;
    object-fit:cover;
    border-radius:10px;
    margin-right:20px;
`;
export const MentorInfo = styled.div``;

export const MentorName = styled.div`
    font-size:24px;
    font-weight:bold;
    margin-bottom:10px;
`;
export const MentorBio = styled.div`

margin-bottom:10px;
`;
export const Actions = styled.div`
    display:flex;
    align-items:center;
    justify-content:flex-end;
`;