import React, { useEffect } from "react";
import {
  ProgramDetailsContainer,
  Basics,
  LeftSection,
  RightSection, 
} from "./styles";
import {
  createInitialValid,
  updateValid,
  validateText,
} from "../../../../../common/utils";
import Labelize from "../../../../../common/Labelize";
import TextInput from "../../../../../common/TextInput";
import { ButtonSpacer } from "../../../../../common/Button/styles";
import Checkbox from "../../../../../common/Checkbox";
import features from "../../../../../config/features";
import ImageUpload from "../../../../../common/ImageUpload";
import Pills from "../../../../../common/Pills";

function ProgramDetails({ programForm, setProgramForm, isValid, setIsValid ,isEditProgram,setProgramType}) {
  function updateField(field) {
    return function (newValue) {
      setProgramForm({
        ...programForm,
        [field]: newValue,
      });
    };
  }

  useEffect(() => {}, []);
  return (
    <ProgramDetailsContainer>
      <Basics>
        <LeftSection>
          <Labelize label="Name">
            <TextInput
              value={programForm.title}
              placeholder="Program Name"
              setValue={updateField("title")}
              isValid={isValid.title}
              validator={validateText}
              setIsValid={(newValid) => {
                setIsValid({ ...isValid, title: true });
                updateValid(isValid, setIsValid, "title")(newValid);
              }}
            />
          </Labelize>
          <Labelize label="Organization">
            <TextInput
              value={programForm.organization}
              placeholder="Program organization"
              setValue={updateField("organization")}
              isValid={isValid.organization}
              validator={validateText}
              setIsValid={updateValid(isValid, setIsValid, "organization")}
            />
          </Labelize>
        </LeftSection>
        <RightSection>
          <ImageUpload
            value={programForm.image}
            setValue={updateField("image")}
            setIsValid={(newValid) => {
              setIsValid({ ...isValid, image: true });
              updateValid(isValid, setIsValid, "image")(newValid);
            }}
          />
        </RightSection>
      </Basics>

      <ButtonSpacer />
      <Labelize label="About">
        <TextInput
          value={programForm.description}
          placeholder="About the Program"
          setValue={updateField("description")}
          isValid={isValid.description}
          isTextArea={true}
        isHeight={true}
          wordLimit={500}
          validator={validateText}
          setIsValid={updateValid(isValid, setIsValid, "description")}
        />
      </Labelize>
      <ButtonSpacer />
      <ButtonSpacer />
    {!isEditProgram ?  <Labelize label="Program">
        {}
        <Pills
          options={[
            {
              label: "1 - 1 Networking",
              value: "1_1_NETWORK",
            },
            {
              label: "Open Networking",
              value: "OPEN_NETWORK",
            },
            {
              label: "Team",
              value: "TEAM",
            },
          ]}
          value={programForm.programType}
          setValue={updateField("programType")}
          setProgramType={updateField("programType")}
        />
      </Labelize>:''}
      <ButtonSpacer />
      <ButtonSpacer />
      {features.selfService && (
        <>
          <Checkbox
            label="Allow mentees to choose their mentors"
            value={programForm.type === "selfservice"}
            setValue={(newValue) => {
              setProgramForm({
                ...programForm,
                type: newValue ? "selfservice" : "fullservice",
              });
            }}
          />
          <ButtonSpacer />
        </>
      )}
    </ProgramDetailsContainer>
  );
}
export default ProgramDetails;
