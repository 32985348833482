import styled from "styled-components";

export const MentorOuterContainer = styled.div`
    position:relative;
`;

export const MentorContainer = styled.div`
    background-color: rgba(77, 77, 159, 0.10);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 100px 51px;
    position: relative;
    z-index: 3;
    @media screen and (max-width: 992px) {
        padding: 40px 20px 51px;
    }
    @media screen and (max-width: 767px) {
        padding: 40px 20px 40px;
    }
`;

export const MentorHeading = styled.h2`
    color: #44449B;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 50px;
    max-width: 660px;
    margin: 0 auto;
    @media screen and (max-width: 1366px) {
        font-size: 38px;
        line-height: 48px;
        max-width: 80%;
        margin: 0 auto;
    }
    @media screen and (max-width: 992px) {
        font-size: 30px;
        line-height: 38px;
    }
    @media screen and (max-width: 767px) {
        max-width: 100%;
    }
`;

export const MentorStepOne = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    margin-top: 30px;
    width: 100%;
    @media screen and (max-width: 1366px) {
        align-items: flex-start;
    }
    @media screen and (max-width: 1024px) {
        flex-direction: column;
        margin-top: 40px;
    }
`;

export const MentorSuggestionImage = styled.img`
    max-width: 45%;
    width: 100%;
    @media screen and (max-width: 1024px) {
        max-width: 80%;
        order: 2;
        margin: 0 auto;
    }
    @media screen and (max-width: 767px) {
        max-width: 100%;
    }
`;

export const MentorStep = styled.div`
    max-width: 45%;
    width: 100%;
    margin-left:${(props) => props.isLeft ? 80 : 0}px;
    @media screen and (max-width: 1366px) {
        margin-left:${(props) => props.isLeft ? 0 : 0}px;
        margin-top:${(props) => props.isLeft ? 50 : 0}px;
    }
    @media screen and (max-width: 1024px) {
        max-width: 80%;
        margin: 0 auto 40px;
    }
    @media screen and (max-width: 767px) {
        max-width: 100%;
        margin: 0 auto 30px;
    }
`;

export const StepHeading = styled.h3`
    color: #44449B;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    margin: 0;
    @media screen and (max-width: 1366px) {
        font-size: 26px;
        line-height: 44px;
    }
    @media screen and (max-width: 992px) {
        font-size: 24px;
        line-height: 36px;
    }
`;

export const StepDescription = styled.p`
    color: #302F2F;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 490px;
    margin: 16px 0 0;
    @media screen and (max-width: 1366px) {
        font-size: 20px;
        margin: 10px 0 0;
    }
    @media screen and (max-width: 1024px) {
        max-width: 100%;
    }
    @media screen and (max-width: 992px) {
        font-size: 18px;
    }
`;

export const MentorAssignImage = styled.img`
    position: absolute;
    top: 75%;
    left: 35%;
    z-index: 2;
    @media screen and (max-width: 1366px) {
        max-width: 300px;
        width: 100%;
        top: 70%;
        left: 29%;
    }
    @media screen and (max-width: 1024px) {
        position: static;
        max-width: 80%;
        width: 100%;
        order: 3;
        margin: 0 auto;
    }
`;

export const MentorStepTwo = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-top: 42px;
    width: 100%;
    align-items: center;
    @media screen and (max-width: 1366px) {
        align-items: flex-start;
    }
    @media screen and (max-width: 1024px) {
        flex-direction: column;
        margin-top: 10px;
    }
`;

export const MentorMatchImage = styled.img`
    max-width: 45%;
    width: 100%;
    @media screen and (max-width: 1024px) {
        max-width: 80%;
        margin: 0 auto;
    }
    @media screen and (max-width: 767px) {
        max-width: 100%;
    }
`;


export const MentorMatchLines = styled.img`
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
`;

export const MentorSuggestionLines = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;