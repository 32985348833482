import styled from "styled-components";
import { Scrollable, headerSize, panelSpacing } from "../../../../../common/styles";
import colors from "../../../../../config/colors";


export const borderRadius=panelSpacing/2;
const scrollbarWidth=borderRadius;

export const RequestListContainer = styled.div`
  width: 95%;
  margin-left: 20px;
  margin-top: 20px;
  overflow: scroll;
`;

export const RequestsKeyHeader = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-align:${props=> props.textAlign && 'justify'};
    padding-right: ${props=>props.padding ? props.padding : '0'}rem;
  @media (max-width: 991px) {
    width: ${props=>props.widthSize ? props.widthSize : '200px'}
  text-overflow: ellipsis;
  }
  margin: 0;
`;
export const RequestsKeyHeaderlocation = styled.span`
  flex-shrink:0;
  overflow-wrap: break-word;
font-weight:bold;
`;
export const RequestsKeyHeaderspan = styled.span`
  ${'' /* overflow-wrap: anywhere; */}
  max-height:60px;
  word-break: break-word;
  overflow-y:auto;
  width:400px;
  text-align:${props=> props.textAlign && 'justify'};
  font-weight:normal !important;
  margin-left:${props=> props.left && '5px'};

${props=>props.height  ? Scrollable :''};
 ::-webkit-scrollbar {
        background-color:${colors.secondary};
        border:solid 1px ${colors.darkBackground};
        width:5px;
        border-radius:0px 0px 10px 0px;

     
      }
       ::-webkit-scrollbar-thumb {
    background: ${colors.primary};
    border-radius: 0 ${scrollbarWidth}px ${scrollbarWidth}px 0;
    box-shadow: inset ${scrollbarWidth/3}px 0 ${scrollbarWidth/2}px #0004;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    }


`;
export const RequestsKeyHeaders = styled.p`
font-style:${props=>props.fontStyles ? props.fontStyles : ''} ;
margin-top:${props=>props.marginSpace ? props.marginSpace : ''}px ;
  white-space: nowrap;
  text-align:${props=> props.textAlign && 'justify'};
  font-weight: ${props=>props.isTeam ? '400': 'bold'};
  text-overflow: ellipsis;
  overflow: hidden;
  @media (max-width: 991px) {
    width:${props=>props.widthSize ? props.widthSize : '200'}px ;
    font-size:${props=>props.size ? props.size : ''}px ;
  }
  margin: 0;
  padding-right: 5px;
`;

export const RequestsDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row !important; 
  text-align:left !important;
  max-width: ${props=>props.width ? props.width :''}rem;
  font-style:${props=>props.fontStyles ?props.fontStyles : ''} ;
  font-weight:${props=>props.isTeam && '400'} ;
  @media (max-width:767px) {

    font-size: 16px ;
  font-weight: 400 ;
  }
  
`;
export const RequestsDetailsWrapperForAdditional = styled.div`
  display: flex;
  flex-direction: column !important; 
  text-align:left !important;
  max-width: ${props=>props.width ? props.width :''}rem;
  font-style:${props=>props.fontStyles ?props.fontStyles : ''} ;
  font-weight:${props=>props.isTeam && '400'} ;
  @media (max-width:767px) {
    font-size: 16px ;
  font-weight: 400 ;
  }
`;

export const RequestsDetailsWrappers = styled.div`
  display: flex;
  flex-direction: column !important;
  max-width: ${props=>props.width ? props.width :''}rem;
  font-style:${props=>props.fontStyles ?props.fontStyles : ''} ;
  font-weight:${props=>props.isTeam && '400'} ;
  @media (max-width:767px) {

    font-size: 16px ;
  font-weight: 400 ;
  }
  
`;
export const TimeSection = styled.div`
  ${'' /* display: flex; */}
  @media (max-width:767px) {
  ${'' /* flex-direction: column; */}

  }
  
`;

export const Spacer = styled.div`
  margin-top: 0.5rem;
`;

export const RequestListWrapper = styled.div`
  width:100%;
  border: 2px solid #efefef;
  border-inline: none;
  padding: 40px 20px;
  margin-bottom: 7px;
  margin-top: 10px;
  display:flex;
  font-weight: 400;
  @media (max-width:1200px) {
        width: -webkit-fill-available;
    padding: 22px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap:5px;
    font-size: 14px;
    text-align: justify;
}
`;
export const ButtonWrapper = styled.div`
display: flex;
justify-content: flex-start;
gap: 15px;
margin-left:10px
`;
export const Clander = styled.div`
  position: relative;
  z-index: 9999; /* Adjust the z-index value as needed */
  margin-top:-7px;

  $ #atcb-btn-1{
    background: red !important;
  }
`;


export const PersonInfo = styled.div`
  justify-content: "flex-start";
  display: flex;
  gap: 15px;
  align-items: center;
 width:${props=>props.size}%;
  max-width:${props => props.isTeam ? '90%' :'500px'}; 
  @media (max-width:767px) {
    flex-direction: column;
    align-items: flex-start;
  }

`;

export const ImagePreview = styled.img`
width:${props=>props.size}px;
height:${props=>props.size}px;
  border-radius: 50%;
  object-fit: cover

  
`;
export const ImageContainer = styled.div`
width:65px;
min-width:65px;
  border-radius: 50%;
 
`;

export const InfoWrapper = styled.div``;
export const StatusWraper = styled.div`
  font-weight: bold;
`;
export const HeadingWraper = styled.div`
  font-weight: normal;
  font-size: 16px;
`;

export const RequestHeading = styled.div`
  margin-left: 200px;
  font-size: 16px;
  font-weight: bold;
  @media (max-width:767px) {
    margin-left: 0;
  }
`;
export const OpenNetworkContainer = styled.div`
`;
export const RequestListsWrapper = styled.div`
    border: 2px solid #efefef;
    padding: 15px 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    border-left: none;
    border-right: none;
    text-align:left;


`;
export const CardsWraper= styled.div`

`
export const NoprogramContainer= styled.div`
    padding-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const ButtonsWraper= styled.div`
display:flex;
padding-top: 20px;
margin-inline: 8rem;
justify-content: flex-start;
`
export const ImagePreviews= styled.img`
width:${props=>props.size}px;
height:${props=>props.size}px;
margin-bottom:${props=>props.panelSpacing ?props.panelSpacing:panelSpacing}px;
display:block;
border-radius:${panelSpacing}px;
object-fit:cover;
padding-top: 40px;


`
export const NameContainer= styled.span``
export const ProgramInfo= styled.div`
display:block;



`
export const ProgramLoaderContainer = styled.div`
    
    background-color:${colors.background};
    border-radius:${panelSpacing}px;
    box-sizing:border-box;
    height:calc(100vh - ${headerSize+2*panelSpacing}px);
`;

export const MeetingWith = styled.span`
      display: block;
    font-size: 14px;
    font-weight: 300;

`;

