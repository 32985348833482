import React from "react";
import { UserInfoContainer,SubHeading } from "./styles";
import Modal from "../../../../../common/Modal";
import Loading from "../../../../../common/Loading";
import callAPI from "../../../../../api/apiCaller";
import { useParams } from "react-router-dom";
import { Context } from "../../../../../Store";
import Labelize from "../../../../../common/Labelize";
import { formatDate } from "../../../../../common/utils";
import { Image, Info } from "../../ProgramContent/ProgramParticipants/ActiveParticipants/styles";

function UserInfo({userId,closeModal,isNewBooking}) {
  const params = useParams();
  const [info,setInfo] = React.useState(null)
  const { state, dispatch } = React.useContext(Context);
  React.useEffect(()=>{
    callAPI(dispatch,"getUserProgramInfo",{rid:userId,id:params.selectedProgram}).then((retrievedInfo)=>{
      if(retrievedInfo.isResponseOk)
      setInfo(retrievedInfo.data)
    })
  },[])
  if(info === null){
    return <Modal
    title={"User Information"}
    onClose={closeModal}
    >
      <UserInfoContainer><Loading loadingItem="User Information"/></UserInfoContainer>
    </Modal>
  }
  return  <Modal
  title={<Info>
    {info.participant.image && <Image src={info.participant.image?info.participant.image:"https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"} />}
    {info.participant.firstName}</Info>}
  onClose={closeModal}
  actions={[
    {
      text:"Back",
      icon:"back",
      onClick:closeModal
    }
  ]}
  >
    <UserInfoContainer>
      <Labelize label={"Active Since"}>
        {formatDate(info.participant.createdAt)}
      </Labelize>
      <Labelize label={"Bio"}>
      {info.participant.bio}
      </Labelize>
      <SubHeading>Application</SubHeading>
     
      {info.application.length === 0 && "User was requested into the program"}
      {
        info.application.map(qa=>{
          return <>
           <Labelize label={qa?.questions?.question}>{qa?.answer}</Labelize>
          </>
        })
      }
      
    </UserInfoContainer>
  </Modal>
  
  ;
}
export default UserInfo;