import styled from "styled-components";
import colors from "../../../../../../config/colors";
import { Scrollable, panelSpacing } from "../../../../../../common/styles";
import { SectionContent } from "../../../../../../common/Accordian/styles";
import { InvitesContainer } from "../Invites/styles";

export const ActiveParticipantsContainer = styled.div`
    display:flex;
    min-width:1150px;
    @media (max-width:991px) {
       flex-direction: column;
       gap: 20px;
       min-width: fit-content;
    }
`;

export const ActiveParticipantsWrapper= styled.div`
overflow-x: auto;
@media (max-width:991px) {
  
        overflow-y: scroll;
    height: calc(100vh - 420px);

}
`
export const ParticipantsActions = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-bottom:20px;
`;

export const MentorHeading = styled.div`
    font-size:20px;
    font-weight:bold;
`;

export const MentorHeader = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    width:calc(100% - 10px);
    @media (max-width:767px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }
`;
export const Info = styled.div`
    display:flex;
    align-items:center;
`;
export const Image = styled.img`
    height:35px;
    width:35px;
    object-fit:cover;
    border-radius:50%;
    margin-right:10px;
`;
export const Name = styled.div`
    font-size:18px;
    word-break: break-word;
`;
export const Actions = styled.div`
    display:flex;
    align-items:center;
    gap:10px;
    justify-content:flex-end;
    @media (max-width:767px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;
export const Meta = styled.div`
    margin-right:20px;
    font-size:12px;
    color:${colors.primary};
`;
export const MenteeHeader = styled(MentorHeader)`
    background-color:${colors.background};
    padding:${panelSpacing/4}px;
    box-sizing:border-box;
    margin-bottom:${panelSpacing/2}px;
    border-radius:${panelSpacing/2}px;
    width:100%;
    :last-of-type{
        margin-bottom:0px;
    }
`;

export const UnassignedActionContainer = styled.div`
display: flex;
`
export const UnassignedList = styled(SectionContent)``;
export const ParticipantsContainer = styled(InvitesContainer)`
    width:calc(100% - 500px);
    min-width:800px;
    padding: 20px 0 0 20px; 
    overflow: ${props=>props.isTeam ? 'hidden scroll' : 'hidden'};
    @media (max-width:1600px) {
        min-width: 700px;
    }
    @media (max-width:1366px) {
        min-width: 620px;
    }
    @media (max-width:991px) {
        height: fit-content;
        width: 100%;
        min-width: 100%;
    } 
`;


export const ParticipantsContainerInner = styled.div`
    height:calc(100vh - 220px - ${panelSpacing*4}px);
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    ${Scrollable}
`;

export const UnassignedContainer =styled(InvitesContainer)`
    width:500px;
    padding: ${panelSpacing}px ${panelSpacing/4}px;
    @media (max-width:991px) {
        height: fit-content;
        width: 100%;
        padding: 20px;
    }

`;

export const ViewChatIconContainer= styled.div`
display: flex;
align-items: center;
`