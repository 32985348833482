import createReducer from "../common/createReducer";

var reducers = {
  SET_PROGRAM_LIST: (_, action) => {
    return {
      list:action.list,
      isLoaded:true
    }
  },
  IS_LOADING_PROGRAM_LIST:()=>{
    return {
      list:[],
      isLoaded:false
    }
  },
  REFRESH_LIST:(state)=>{
    return {
      list:[],
      isLoaded:false,
      refetch:!state.refetch
    }
  }
};

const initialState = {
  list:[],
  isLoaded:false,
  refetch:false
};

export default createReducer(initialState, reducers);