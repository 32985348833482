import React from "react";
import { useParams } from "react-router-dom";
import callAPI from "../../../../../api/apiCaller";
import Loading from "../../../../../common/Loading";
import ConnectionCard from "./ConnectionCard";
import ProgramConnection from "./ProgramConnection";
import {
  ProgramConnectionsContainer,
  ConnectionSection,
  ConnectionsList,
  ConnectionsHeading,
  Span,
} from "./styles";
import { Context } from "../../../../../Store";
//all mentees
function ProgramConnections({ program }) {
  const [programConnections, setProgramConnections] = React.useState(null);
  const [requests, setRequests] = React.useState(null);
  const { state, dispatch } = React.useContext(Context);
  let params = useParams();
  React.useEffect(() => {
    retrieveInformation();
  }, []);
  function retrieveInformation() {
    setProgramConnections(null);
    callAPI(
      dispatch,
      program.role === "mentor" ||  program.role === "mentor_manager" ? "getMyMentees" : "getConnections",
      { id: params.selectedProgram }
    ).then((connections) => {
      if (connections.isResponseOk) {
        setProgramConnections(connections.data);
      } else {
        setProgramConnections([]);
      }
    });
    
    if (program.role === "mentor" || program.role === "mentor_manager") {
      setRequests(null);
      callAPI(dispatch, "getMenteeRequests", {
        programId: params.selectedProgram,
      }).then((response) => {
        setRequests(response);
        // console.log(response,"res");
      });
    } else {
      setRequests([]);
    }
  }
  if (params.connectionId) {
    return <ProgramConnection />;
  }
  return (
    <ProgramConnectionsContainer>
      {
      programConnections === null ? (
        <Loading loadingItem="Connections" />
      ) : (
        <ConnectionSection>
          <ConnectionsList>
            {programConnections && programConnections.map((connection) => (
              <ConnectionCard
                user={connection}
                role={program.role}
                type="connect"
              />
            ))}
          </ConnectionsList>
        </ConnectionSection>
      )}
      {
       programConnections?.length === 0 && <Span>{'You do not have any Connection'}</Span>
      }
    </ProgramConnectionsContainer>
  );
}
export default ProgramConnections;
