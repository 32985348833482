import styled from "styled-components";
import { panelSpacing } from "../styles";
import colors from "../../config/colors";

export const ProgressBarContainer = styled.div`
    display:flex;
    align-items:center;
`;



export const Track = styled.div`
    width: 100%;
    height:${panelSpacing/2}px;
    background-color:${colors.secondary};
    border-radius:${panelSpacing}px;
`;
export const Bar = styled.div`
width:${props=>props.percent * 100}%;
height:${panelSpacing/2}px;
background-color:${props=>
    (props.type==="done") ? 
        colors.success :
        props.type === "loading"  ? 
            colors.warning : 
            colors.primary};
transition:all 0.5s ease-in-out;
border-radius:${panelSpacing}px;
`;