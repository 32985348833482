import ApplicationEditor from "../../forms/ApplicationEditor";
import CreateFlowProgramManager from "../../forms/CreateFlowProgramManager";
import ProgramDetails from "../../forms/ProgramDetails";
import Completion from "./Completion";

export const createSteps=[
    {
        component:ProgramDetails,
        isValidFields:["title", "organization","description","image"],
    },
    {
        component:CreateFlowProgramManager,
        isValidFields:[],
    },
    {
        component:ApplicationEditor,
        isValidFields:[]
    },
    {
        component:Completion,
        isValidFields:[]
    },
];