import React from "react";
import { useParams } from "react-router-dom";
import LoginSecurity from "./LoginSecurity";
import ProfileSections from "./ProfileSections";
import { ProfileContainer, ProfileContent } from "./styles";
import YourAccount from "./YourAccount";
import Feedback from "./Feedback";
import { CrossButtonContainer, CrossButtonContainerSpan } from "../Programs/ProgramsList/styles";
import Billing from "./Billing";
import TermsConditions from "./TermsConditions";

function Profile() {
  const [hamburgerOpen, setHamburgerOpen]= React.useState(false)
  const params=useParams();
  return <ProfileContainer>
    
    <ProfileSections  hamburgerOpen={hamburgerOpen} setHamburgerOpen={setHamburgerOpen}/>
    
    <ProfileContent>
    {
      params.profileSection === "account" && <YourAccount hamburgerOpen={hamburgerOpen} setHamburgerOpen={setHamburgerOpen}/>
    }
    {
      params.profileSection === "security" && <LoginSecurity hamburgerOpen={hamburgerOpen} setHamburgerOpen={setHamburgerOpen}/>
    }
    {
      params.profileSection === "terms-and-services" && <TermsConditions hamburgerOpen={hamburgerOpen} setHamburgerOpen={setHamburgerOpen}/>
    }
    {
      params.profileSection === "feedback" && <Feedback hamburgerOpen={hamburgerOpen} setHamburgerOpen={setHamburgerOpen}/>
    }
    {
      params.profileSection === "billing" && <Billing hamburgerOpen={hamburgerOpen} setHamburgerOpen={setHamburgerOpen}/>
    }
    </ProfileContent>
   
  </ProfileContainer>;
}
export default Profile;