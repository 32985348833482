const colors={
    primary:"#44449B",
    secondary:"#EFEFEF",
    background:"#FFFFFF",
    backgroundBlack:"#313134",
    darkBackground:"#CCCCCC",
    text:"#2c2c2c",
    textBlack:"#302F2F",
    whiteText:"#FFFFFF",
    success:"#00C714",
    warning:"#F6C000",
    failure:"#D70000",
}

export default colors;