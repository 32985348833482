import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Context } from "../../../../../Store";
import { getUserInfo } from "../../../../../Store/User/accessors";
import Button from "../../../../../common/Button";
import { ButtonSpacer } from "../../../../../common/Button/styles";
import Modal from "../../../../../common/Modal";
import { SectionActions } from "../../../Profile/LoginSecurity/styles";
import { Spacer } from "../ProgramOutBounds/style";
import { Dot, PaymentDotContainer, PaymentNote, PaymentSuccess, PaymentSuccessHeading } from "./styles";


const Paypal = ({
  setPaypal,
  coffeeOrLunch = "",
  coffeeQuantity,
  lunchQuantity,
  setProductInfo,
  setIsmodalOpen,
  subTotal,
  isPlan
}) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const coffeePrice = coffeeQuantity * 10;
  const lunchPrice = lunchQuantity * 25;
  const price = +subTotal;
  const params = useParams();
  const paypal = useRef();
  const { state } = React.useContext(Context);
  // console.log(price.toFixed(2),"<sub");
 useEffect(() => {
    window.paypal
      .Buttons({
        createOrder: (data, actions, err) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                amount: {
                  currency_code: "USD",
                  value: price.toFixed(2),
                },
                custom_id: JSON.stringify({
                  userId: getUserInfo(state).id,
                  programId: params.selectedProgram,
                  coffeeOrLunch: coffeeOrLunch,
                  coffee: coffeeQuantity,
                  lunch: lunchQuantity,
                }),
                description: JSON.stringify({
                  coffeePrice: coffeePrice,
                  lunchPrice: lunchPrice,
                }),
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          if (order.status === "COMPLETED") {
            setShowSuccessModal(true);
          }
        },
        onError: (err) => {
          console.log(err);
        },
        commit: true,
      })
      .render(paypal.current);
  }, [price]);
  return (
    <>
      {!showSuccessModal ? (
        <div ref={paypal}></div>
      ) : (
        <Modal onClose={() => setShowSuccessModal(false)}>
          <PaymentSuccessHeading>Success!</PaymentSuccessHeading>
          <PaymentDotContainer>
            <Dot></Dot>
            <Dot></Dot>
            <Dot></Dot>
            </PaymentDotContainer>
            <Spacer/>
          {isPlan ? (<PaymentNote>You are on your way to building the best mentorship program!</PaymentNote>):
          ( <PaymentSuccess>
            <PaymentNote>Nothing builds community more than a nice snack! You’ve just helped
            create a stronger bonds between your mentors and mentees!</PaymentNote>

             Mentors will receive a QR code loaded with funds 30 minutes prior to their
            in-person meetings at a cafe/restaurant of their choice
          </PaymentSuccess>)}
          <SectionActions>
            <ButtonSpacer />
            <Button
              icon="tick"
              variant="primary"
              onClick={() => {
                setShowSuccessModal(false)
                setPaypal(false)
                setProductInfo(false)
                setIsmodalOpen(false)
              }}
              text="Done"
            />
          </SectionActions>
        </Modal>
      )}
    </>
  );
};

export default Paypal;
