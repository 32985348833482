import ReactQuill, {Quill} from "react-quill";
import styled from "styled-components";
import { Scrollable } from "../../../../../common/styles";

const bold = Quill.import('formats/bold');

// Override the tagName property to 'b'
bold.tagName = 'b';

// Register the modified bold format with React Quill
Quill.register('formats/bold', bold);

export const SendAnnouncementContainer = styled.div`
    width:100%;
    max-height: 60vh;
    ${Scrollable};
    
`;

export const StyledReactQuill = styled(ReactQuill)`
/* Custom styles for the ReactQuill editor */
.ql-editor {
  background-color: #EFEFEF; /* Change background color */
  color: #333; /* Change text color */
  font-family:sans-serif; /* Change font family */
  font-size: 14px; /* Change font size */
  line-height: 1.5; /* Change line height */
  
}

.ql-snow .ql-tooltip{
  margin-left: 5px !important;
  left: 1px !important; 

}
.ql-editor:not(.ql-blank)::before,
.ql-editor.ql-blank::before,
.ql-editor::placeholder {
  font-style: normal !important;
  
}

`;