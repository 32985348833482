import React from "react";
import Labelize from "../../../../../common/Labelize";
import Modal from "../../../../../common/Modal";
import TextInput from "../../../../../common/TextInput";
import { validateText } from "../../../../../common/utils";
import { AddFolderContainer } from "./styles";
import callAPI from "../../../../../api/apiCaller";
import { Context } from "../../../../../Store";
import { setProgramList } from "../../../../../Store/ProgramsList/actions";

function AddFolder({ addFolder, closeAddFolder }) {
  const [newFolderName, setNewFolderName] = React.useState("");
  const [isNewFolderActionActive, setIsNewFolderActionActive] = React.useState(false);
  const [isNameValid, setIsNameValid] = React.useState(false);
  const { state, dispatch } = React.useContext(Context);
  return <AddFolderContainer>
    return <Modal title="Add Folder" onClose={closeAddFolder} actions={[
      {
        text: "Cancel",
        variant: "secondary",
        onClick: () => closeAddFolder()
      },
      {
        text: "Add",
        variant: "primary",
        disabled: !isNameValid,
        icon: isNewFolderActionActive ? "loading" : "add",
        onClick: () => {
          setIsNewFolderActionActive(true)
          addFolder(newFolderName).then((res) => {
            
             callAPI(dispatch,"fetchPrograms").then((programs) => {
              dispatch(setProgramList(programs.data))
            })
            setIsNewFolderActionActive(false);
            closeAddFolder();
          })
        }
      }
    ]}>
      <Labelize label="New Folder Name">
        <TextInput
          isValid={isNameValid}
          setIsValid={setIsNameValid}
          validator={validateText}
          value={newFolderName}
          setValue={setNewFolderName}
          placeholder="Name of New Programs Folder" />
      </Labelize>
    </Modal>
  </AddFolderContainer>;
}
export default AddFolder;