import styled from "styled-components";
import { borderRadius, headerSize, panelSpacing, Scrollable } from "../../../../common/styles";
import colors from "../../../../config/colors";

export const ChatWindowContainer = styled.div`
    background-color:${colors.background};
    height:calc(100vh - ${headerSize+panelSpacing*2}px);
    width:calc(100vw - ${349+panelSpacing*3}px);
    border-radius:${borderRadius}px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    @media(max-width:991px) {
        width: 100%;
        position: relative;
        overflow-y:scroll;
        justify-content:${props=>props.isHeading ? 'center' :'flex-start'};
    }
`;

export const NoChatCOntentContainer= styled.div`
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 1;`
export const ChatHeader = styled.div`
    padding:${panelSpacing}px;
    border-bottom:1px solid ${colors.secondary};
    width:100%;
    box-sizing:border-box;
    @media (max-width:991px) {
    padding: 9px 20px;
    }
`;
export const ChatUser = styled.div`
    display:flex;
    align-items:center;
    @media (max-width:991px) {
        gap: 20px;
        align-items: flex-start;
        margin-top: 12px;
    }
`;
export const UserImage = styled.img`
    height:50px;
    width:50px;
    object-fit:cover;
    border-radius:50%;
    margin-right:10px;
`;
export const ChatUserInfo = styled.div``;
export const UserName = styled.div`
    font-size:18px;
    font-weight:bold;
`;
export const UserStatus = styled.div`
    display: flex;
    align-items:center;
    margin-top:5px;
`;
export const LoadingContainer = styled.div`
display: flex;
width: 100%;
height:'10%';
align-items: center;
justify-content: center;
box-sizing: border-box;
padding:'5px 0px';
`;
export const UserStatusDot = styled.div`
    background-color:${props=>props.status==="Online"?"green":"grey"};
    height:10px;
    width:10px;
    border-radius:50%;
    margin-right:${props=> props.group ? '-21px' :'5px'};
    margin-top:${props => props.group && '-9px'};
    z-index:${props => props.group && '0'};
`;
export const UserStatusText = styled.div`
    font-size:12px;
    color:${props=>props.status==="Online"?"green":"grey"};
`;
export const ChatBody = styled.div`
    padding:${panelSpacing}px;
    width:100%;
    box-sizing:border-box;
    
`;
export const ChatContainerOverall = styled.div`
  padding:${panelSpacing}px;
    width:100%;
    box-sizing:border-box;
    overflow-y:scroll;
    ::-webkit-scrollbar {
    width: 5px;
  }

@media(max-width:991px) {
    overflow-y:scroll;
    padding: 0;
    height: calc(100vh - 300px);
    height: ${props => props.isPersonal ? `calc(100vh - 260px)` : `calc(100vh - 300px)`};


}
`;
export const MemberDetails = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
`;
export const ChatContent = styled.div`
    background-color:${colors.secondary};
    border-radius:10px;
    padding-top:20px;
    height:calc(100vh - ${300 + panelSpacing * 2}px);
    ${Scrollable}
    box-sizing:border-box;
    display:flex;
    flex-direction:column;
    gap:5px;
    @media(max-width:991px) {
    ${'' /* height:calc(90vh - ${300 + panelSpacing * 2}px); */}
    height: ${props => props.isPersonal ? `calc(95vh - ${300 + panelSpacing * 2}px)` : `calc(90vh - ${300 + panelSpacing * 2}px)`};
    overflow-y:scroll;
    overflow-x:hidden;
    padding: 0;

}

`;
export const Header = styled.div`
   display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 40px 0px;
    
`;


export const NewMessage = styled.div`
    display:flex;
    padding:${panelSpacing}px;
    border-top:1px solid ${colors.secondary};
    width:100%;
    box-sizing:border-box;
`;
export const PastLoadingContainer = styled.div`

`;
export const Members = styled.div`
display:flex;
width: 100%;
justify-content: space-between;
align-items: center;
@media(max-width:767px) {
    flex-direction: column;
    align-items: flex-end;
    gap:10px;
}
`;
export const MemberInformations = styled.div`
display:flex;
width: 100%;
gap:10px;
align-item:center;
@media(max-width:991px) {
    margin-right: auto;
}
@media(max-width:480px) {
    width: calc(100% - 44px);
}
`;
export const ButtonAction = styled.div`
display:flex;
gap:10px;
justify-content: flex-end;
max-width: 250px;
    width: 100%;
    @media(max-width:991px) {
    margin-right: auto;
}
`;
export const Text = styled.div`
padding:20% 0;

`;
export const Container = styled.div`
  position: relative;
  display: inline-block;
`;

export const RemainingImages=styled.div` 
      overflow: hidden;
    color: ${colors.primary};
    width: 103px;
    height: 45px;
    margin-left:${props=>props.Team && '-15px'};
    /* border-radius: 50%; */
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 15px;
    font-weight: 900;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-item: center;
    -webkit-box-align-item: center;
    -ms-flex-align-item: center;
    align-item: center;
    cursor:pointer;

  `;

export const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
  color: ${colors.primary};
  border: none;
  border-radius: 50%;
  width: ${props=>props.disabled ? '40px':'20px'};
  height:${props=>props.disabled ? '40px':'20px'};
  font-size: ${props=>props.fontSize ? props.fontSize: '12px'};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
cursor:${props=>props.disabled?"not-allowed":"pointer"};
 opacity:${props=>props.disabled && '0.5'};

`;