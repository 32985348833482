import createReducer from "../common/createReducer";

var reducers = {

    Term_And_Conditions:(state, action) => {
    return {
        ...state,
        agreeTermCond : action.agreeTermCond
    };
  },
};


const initialState = {
    agreeTermCond : false,
};

export default createReducer(initialState, reducers);