import styled from "styled-components";
import { Scrollable, panelSpacing } from "../../../../../../../../common/styles";
import colors from "../../../../../../../../config/colors";

export const UpdatesContainer = styled.div`
    padding:${panelSpacing}px;
    ${Scrollable}
    height:calc(100vh - 340px - ${panelSpacing*2}px);
    box-sizing:border-box;
`;


export const UpdateContainer = styled.div`
    padding-bottom:20px;
    margin-bottom:20px;
    border-bottom: 1px solid ${colors.secondary};
`;
export const UpdateHeader = styled.div`
    display:flex;
    align-items:center;
`;
export const UpdaterImage = styled.img`
    height:50px;
    width:50px;
    object-fit:cover;
    border-radius:50%;
    margin-right:10px;
`;
export const Updater = styled.div``;
export const UpdaterName = styled.div`
    font-size:16px;
    font-weight:bold;
`;
export const UpdateTime = styled.div`
    font-size:12px;
`;
export const UpdateContent = styled.div`
    padding:${panelSpacing}px 0px;
`;
export const UpdateActions = styled.div``;
