import React from "react";
import {
  ProgramParticipantsContainer,
  PillContainer,
  SearchBoxContainer,
  MyTeamContainers,
} from "./styles";
import Pills from "../../../../../common/Pills";
import ActiveParticipants from "./ActiveParticipants";
import TextInput from "../../../../../common/TextInput";
import Button from "../../../../../common/Button";
import AddParticipants from "../../popups/AddParticipants";
import Invites from "./Invites";
import PendingRequests from "./PendingRequests";
import MyTeams from "../MyTeams";


function ProgramParticipants({program}) {
  const [currentPage, setCurrentPage] = React.useState("active");
  const [searchText, setSearchText] = React.useState("");
  const [isAddParticipantsOpen, setIsAddParticipantsOpen] = React.useState(false);
  const [refreshInvites, setRefreshInvites] = React.useState(false);
  const [specificTeamProgram, setSpecificTeamProgram] = React.useState(false);

  return (
     <ProgramParticipantsContainer>
     {specificTeamProgram ?<MyTeamContainers>
     <MyTeams specificTeamProgram={true}/>
     </MyTeamContainers>: <>
    <PillContainer>
      <SearchBoxContainer>
        <TextInput placeholder="Search for Participant" value={searchText} setValue={setSearchText} />
      </SearchBoxContainer>
      <Pills
        value={currentPage}
        setValue={setCurrentPage}
        options={[
          {
            label: "Active",
            value: "active"
          },
          {
            label: "Invites",
            value: "invites"
          },
          {
            label: "Applications",
            value: "requests"
          }
        ]}
      />

      <Button icon="add" text="Add Participants" onClick={() => {
        setIsAddParticipantsOpen(true)
      }} />
    </PillContainer>
    {
      currentPage === "active" && <ActiveParticipants searchText={searchText} specificTeamProgram={specificTeamProgram} setSpecificTeamProgram={setSpecificTeamProgram} />
    }
    {
      currentPage === "invites" && <Invites searchText={searchText} refreshInvites={refreshInvites} setRefreshInvites={setRefreshInvites}/>
    }
    {
      currentPage === "requests" && <PendingRequests program={program} searchText={searchText}/>
    }
    {
      isAddParticipantsOpen && <AddParticipants program={program} closeModal={(shouldRedirect, page) => {
        if (shouldRedirect) {
          setCurrentPage(page)
          setRefreshInvites(!refreshInvites)
        }
        setIsAddParticipantsOpen(false);
      }} />
    }
    </>}
  </ProgramParticipantsContainer>
  )
}
export default ProgramParticipants;