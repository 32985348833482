import styled from "styled-components";


export const ActivityContainer= styled.div`
display:flex;
justify-content:center;
align-items:center;
margin-top: 1rem;
` 
export const ButtonWraper= styled.div`
visibility: hidden;
` 