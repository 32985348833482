/**
 * This is the feature toggle configuration.
 * If any feature needs to be switched off/on this can be done here.
 * This only ensure conditional rendering of the components and not the API calls (which may fail in the background)
 */
export default {
    selfService:false,
    updates:false,
    notes:false,
    timesheet:true,
    activity:false,
    explore:false,
    announcements:true,
    collaborate:true,
    meetings:true
}