import styled from "styled-components";
import { InvitesContainer } from "../Invites/styles";
import { Scrollable, panelSpacing } from "../../../../../../common/styles";
import colors from "../../../../../../config/colors";

export const PendingRequestsContainer = styled(InvitesContainer)`
box-sizing: border-box;
padding:${panelSpacing}px;
overflow-x:hidden;
height:calc(100vh - 140px - ${panelSpacing*4}px);
border-top:solid 1px ${colors.secondary};
${Scrollable}
@media (max-width:767px) {
height:calc(100vh - 395px);
}

`;
export const FilterContainer =styled.div`
    padding-bottom:${panelSpacing}px;
    display:flex;
    align-items:center;
    justify-content:center;
`;