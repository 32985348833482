import styled from "styled-components";
import colors from "../../config/colors";
export const TabsContainer = styled.div`
    display:flex;
    border-bottom: 1px solid ${colors.secondary};
    min-width:${props=>props.isWidth && '530px'};

`;
export const Tab = styled.div`
    padding:15px 20px;
    cursor:pointer;
    transition: all 0.1s ease-in-out;
    font-size: 14px;
    user-select: none;
    display: flex;
    align-items: center;
    gap: 10px;
    ${props=>props.active && `
        padding-bottom:10px;
        border-bottom:5px solid ${colors.primary};
        font-weight:bold;
    `}
    :hover{
        color: ${colors.primary};
    }
    :active{
        transform: scale(0.9);
    }

    @media (max-width:640px) {
    padding:15px 11px;
    width:${props=>props.isWidth ? '170px':'100px'};
   justify-content:${props=>props.isWidth && 'center'};
    }
    
`;
export const ItemNumber = styled.span`
    color: white;
    font-size: 12px;
    background: #44449B;
    width: 21px;
    height: 21px;
    display: grid;
    place-items: center;
    flex-shrink: 0;
    border-radius: 100%;
    
   
 
`;