import styled from "styled-components";
import { borderRadius, panelSpacing } from "../../../../common/styles";
import colors from "../../../../config/colors";

export const ProfileSectionsContainer = styled.div`
    width:350px;
    background-color:${colors.background};
    border-radius:${borderRadius}px;
    height:100%;
    border-right:1px solid ${colors.secondary};
    @media(max-width:991px) {
        position: fixed;
        transition: 0.4s;
        left: ${props => props.hamburgerOpen===true ? '0%' : '-100%'};
        top: 65px;
        z-index: 1;
     }
`;
export const ProfileSectionHeader=styled.div`
    font-size: 24px;
    font-weight: bold;
    padding:${panelSpacing}px;
`;

export const ControlPanelHeader= styled.div`
display: flex;
    justify-content: space-between;
    align-items: center;
`
export const ProfileSectionOption =styled.div`
    padding:10px 20px;
    border-top:solid 1px ${colors.secondary};
    cursor:pointer;
    display:flex;
    align-items:center;
    justify-content:flex-start;
    font-size: 16px;
    transition: all 0.1s ease-in-out;
    ${props=>props.isActive && `
        background-color:${colors.primary};
        color:${colors.background};
    `}
    ${props=>props.disable &&
    `
    pointer-events: none;
    background-color:${colors.background};
    color:${colors.secondary};
    `}
`;