import createReducer from "../common/createReducer";

var reducers = {
  UPDATE_WITH_NEW_NOTIFICATIONS: (notifications, action) => {
    let uniqueNotifications = {};
    [...notifications,...action.notifications].forEach(notification => {
      if(notification.hasRead && notifications.time > new Date().getTime()-(24*60*60*1000)){
        return ;
      }
      else{
        
        uniqueNotifications[notification.time]=notification;
      }
    });
    return Object.values(uniqueNotifications);
  },

  MARK_AS_READ:(notifications,{time})=>{
    return notifications.map((notification)=>{
      if(notification.time===time){
        return {
          ...notification,
          hasRead: true
        }
      }
      else{
        return notification;
      }
    })
  },
};

const initialState = [
 
];

export default createReducer(initialState, reducers);