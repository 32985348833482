import React from "react";
import styled from "styled-components";
import Button from "../../../../../common/Button";
import colors from "../../../../../config/colors";
import { HorezentalLine } from "./styles";

const TransactionContainer = styled.div`
  margin-top: -17px;
`;
const Receipt = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: "20px";
`;
const Heading = styled.h1`

font-size:20px;
text-transform: capitalize;
color:${(props) => (props.align ? "#89898f" : colors.primary)};
text-align:${(props) => (props.align ? props.align : "center")};
font-weight: ${(props) => (props.align ? 400 : "")};
}
`;
const Product = styled.span`
  display: ${(props) => (props.display ? "flex" : "block")};
  justify-content: space-between;
  align-item: center;
`;
const ProductName = styled.span`
  font-weight: bold;
  color: ${(props) =>
    props.padding
      ? colors.darkBackground
      : props.color
      ? props.color
      : colors.primary};
  text-transform: capitalize;
  padding-top: ${(props) => (props.padding ? props.padding : "")};
`;

const HorizontalLine = styled.hr`
  background: #b7b3b3;
`;

export const ButtonWraper = styled.div`
  width: ${(props) => props.width}%;
  margin: 10px 0;
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : "column")};
  justify-content: ${(props) => (props.gap ? props.gap : "")};
  gap: 10px;
  padding-top: 10px;
`;
const TransactionInfo = ({
  coffeeOrLunch = "",
  coffeeQuantity,
  lunchQuantity,
  setIsmodalOpen,
  setPaypal,
  title,
  setSubtotal,
}) => {
  const coffeePrice = coffeeQuantity * 10;
  const lunchPrice = lunchQuantity * 25;
  const price = coffeePrice + lunchPrice;
  const totalItems = parseInt(coffeeQuantity) + parseInt(lunchQuantity);

  function calculatePercentage(totalItems) {
    const number = totalItems * 8 + price;
    const percentage = 7.25 / 100;
    const result = number * percentage;
    return result;
  }
  const Subtotal=calculatePercentage(totalItems) + totalItems * 8 + price;
  
  return (
    <TransactionContainer>
      <Heading align={"left"} color="">
        Summary
      </Heading>
      <Receipt>
        <Product>
          <ProductName>{totalItems} items </ProductName>
        </Product>
        {coffeeQuantity > 0 && (
          <Product display={true}>
            <ProductName>{coffeeQuantity}x Coffee on Us ($10) </ProductName>
            <ProductName>${coffeePrice} </ProductName>{" "}
          </Product>
        )}
        {lunchQuantity > 0 && (
          <Product display={true}>
            <ProductName>{lunchQuantity}x Lunch on Us ($25) </ProductName>
            <ProductName>${lunchPrice} </ProductName>{" "}
          </Product>
        )}
        <Product display={true}>
          <ProductName padding={"30px"}>
            {totalItems}x Processing Fee ($8){" "}
          </ProductName>
          <ProductName padding={"30px"}>${totalItems * 8} </ProductName>{" "}
        </Product>
        <Product display={true}>
          <ProductName padding={true}>Tax (7.25%) </ProductName>{" "}
          <ProductName padding={true}>
            ${calculatePercentage(totalItems).toFixed(2)}{" "}
          </ProductName>{" "}
        </Product>
      </Receipt>
      <HorizontalLine></HorizontalLine>
      <Product display={true}>
        <ProductName color={"#000"}>Total </ProductName>{" "}
        <ProductName>${Subtotal.toFixed(2)} </ProductName>{" "}
      </Product>
      <ButtonWraper gap={"flex-end"} direction={"row"} width={100}>
        <Button
          text={"Cancel"}
          icon={"cross"}
          variant="primary"
          isNotRound={true}
          onClick={() => setIsmodalOpen(false)}
        />
        <Button
          text={"Next"}
          icon={"tick"}
          variant="primary"
          isNotRound={true}
          onClick={() =>{
            setSubtotal(Subtotal)
             setPaypal(true)
            }}
          disabled={lunchQuantity <= 0 && coffeeQuantity <= 0 ? true : false}
        />
      </ButtonWraper>
    </TransactionContainer>
  );
};

export default TransactionInfo;
