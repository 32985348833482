import React from 'react'
import {ErrorContainer, ErrorContainerWrapper, ErrorText, ErrorTextWrapper, HeadingText, ImagePreview, ImageWrapper} from "./style"
import ErrorImage from "./errorImage.jpg"
const ErrorMessageForCalendar = () => {
  return (
    <ErrorContainer>
      <ErrorContainerWrapper>
        <ErrorTextWrapper>
          <HeadingText>Oops!</HeadingText>
          <ErrorText top={20}>This user has not added calendar link to our platform yet.</ErrorText>
          <ErrorText>Please contact them directly to schedule time to meet</ErrorText>
        </ErrorTextWrapper>
        <ImageWrapper>
        <ImagePreview size={100} src={ErrorImage}/>
        </ImageWrapper>
      </ErrorContainerWrapper>
    </ErrorContainer>
  )
}

export default ErrorMessageForCalendar
