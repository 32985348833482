/**
 * 
 * Notification System:
 * 
 * All new notifications would be marked "new".
 * A notification would be marked as "read" if it was clicked on. Read notifications are cleared after a day.
 */

export const updateWithNewNotifications=(notifications)=>{
  return {
    type:"UPDATE_WITH_NEW_NOTIFICATIONS",
    notifications,
    segmentName: "notifications",
  }
}
export const markAsRead=(time)=>{
  return {
    type:"MARK_AS_READ",
    time,
    segmentName: "notifications",
  }
}