import React from "react";
import {
    DemoPanelOuterContainer,
    DemoPanelContainer,
    DemoHeading,
    DemoDescription,
    DemoButton,
    DemoRightLines,
    DemoLeftLines
} from './styles';
import DemoRightLinesImage from './RightLinesDemo.svg';
import DemoLeftLinesImage from './LeftLinesDemo.svg';

const DemoPanel = () => {
    const handleClick = () => {
        window.location.href = 'https://forms.gle/1GMziDdvqRp5mHvM9';
      };
  return (
    <DemoPanelOuterContainer>
      <DemoPanelContainer>
        <DemoHeading>Do you want to explore more?</DemoHeading>
        <DemoDescription>
          Click the button below to schedule a demo with us!
        </DemoDescription>
        <DemoButton onClick={handleClick}>Schedule a Demo</DemoButton>
      </DemoPanelContainer>
      <DemoRightLines src={DemoRightLinesImage} alt="Demo Right Lines" />
      <DemoLeftLines src={DemoLeftLinesImage} alt="Demo Left Lines"/>
    </DemoPanelOuterContainer>
  );
};

export default DemoPanel;
