import styled from "styled-components";
import colors from "../../../../../config/colors";
import { Scrollable } from "../../../../../common/styles";

export const EditProgramManagersContainer = styled.div`
width:60vw;
@media (max-width:767px) {
width:100% ;
  ${Scrollable}
  max-height: 280px;
    }
`;



export const ProgramManagerLine =styled.div`
    display:flex;
    padding:10px;
    border-bottom:1px solid ${colors.secondary};
    align-items:center;
    justify-content:space-between;
`;
export const ProgramManagerInfo =styled.div`
    width:60%;
    display:flex;
    align-items:center;
`;
export const ManagerName =styled.div`
    margin-left:10px;
`;
export const ManagerImage =styled.img`
    width:40px;
    height:40px;
    object-fit:cover;
    border-radius:50%;
`;