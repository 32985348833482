import React from "react";
import { InviteEmpty, InvitesContainer } from "./styles";
import Loading from "../../../../../../common/Loading";
import { Actions, Image, Info, MenteeHeader, Meta, Name, UnassignedList } from "../ActiveParticipants/styles";
import callAPI from "../../../../../../api/apiCaller";
import { Context } from "../../../../../../Store";
import { useParams } from "react-router-dom";
import { ButtonSpacer } from "../../../../../../common/Button/styles";
import Button from "../../../../../../common/Button";
import { formatDate } from "../../../../../../common/utils";

function Invites({ searchText ,refreshInvites,setRefreshInvites}) {
  const params = useParams();
  const [invites, setInvites] = React.useState(null);
  const { dispatch } = React.useContext(Context);
  const [isResendingInvite,setIsResendingInvite] = React.useState({});
  const [isCancellingInvite,setIsCancellingInvite] = React.useState({});
  React.useEffect(() => {
    setInvites(null);
    callAPI(dispatch, "getProgramInvites", { id: params.selectedProgram }).then(retrievedInvites => {
      if(retrievedInvites.isResponseOk){
        setInvites(retrievedInvites.data.invited)
      }
    })
  }, [refreshInvites])
  function resendInvite(id){
    if(isResendingInvite[id]){
      return
    }
    let newResendState = {...isResendingInvite,[id]:"loading"}
    setIsResendingInvite(newResendState)
    callAPI(dispatch, "resendInvite", { id:id }).then(() => {
      let newResendState = {...isResendingInvite,[id]:"complete"}
      setIsResendingInvite(newResendState)
    });
  }
  function cancelInvite(email){
    if(isCancellingInvite[email]){
      return
    }
    let newCancelState = {...isCancellingInvite,[email]:"loading"}
    setIsCancellingInvite(newCancelState)
    callAPI(dispatch, "cancelInvite", { id:email }).then(() => {
      setRefreshInvites(!refreshInvites);
    });
  }
  if (invites === null) {
    return <InvitesContainer>
      <Loading loadingItem="Invites" />
    </InvitesContainer>
  }
  if (!invites.length) {
    return <InvitesContainer>
           <InviteEmpty>There are no outstanding invitations.</InviteEmpty>
         </InvitesContainer>
  }
  return <InvitesContainer>
    <UnassignedList>
      {
        invites.filter(user => {
          if (user.email.toLowerCase().includes(searchText.toLowerCase())) {
            return true;
          }
          return false;
        }).map(user => {
          return <MenteeHeader>
            <Info>
              <Name>{user.email}
                <Meta>{user.joinAs.toUpperCase()}</Meta>
                </Name>
            </Info>
            <Actions>
              <Meta>Invite Time: {formatDate(user.createdAt)}</Meta>
              <ButtonSpacer />
              <Button 
              icon={!isResendingInvite[user.id] ? "send" : isResendingInvite[user.id]  === "loading" ? "loading" : "tick"} 
              variant={"primary"}
              disabled={isResendingInvite[user.id] === "complete"}
              text={isResendingInvite[user.id] === "complete" ? "Invite Sent":"Resend Invite"} 
              onClick={() => {
                resendInvite(user.id)
              }} />
              <ButtonSpacer />
              <Button 
              icon={!isCancellingInvite[user.id] ? "cross" : "loading"} 
              variant={"danger"}
              text={"Cancel"} 
              onClick={() => {
                cancelInvite(user.id)
              }} />
            </Actions>

          </MenteeHeader>
        })}
    </UnassignedList>
  </InvitesContainer>;
}
export default Invites;