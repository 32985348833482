import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../../../../../../../common/Button";
import Loading from "../../../../../../../../../common/Loading";
import { formatDate } from "../../../../../../../../../common/utils";
import { NotesListContainer,NoteItem,NoteTitle,NoteTime,NoteAction } from "./styles";

function NotesList({notes,addNote,isAddingNote}) {
  const params = useParams();
  const navigate = useNavigate();
  if(notes===null){
    return <NotesListContainer>
      <Loading loadingItem="Notes"/>
    </NotesListContainer>;
  }
  
  return <NotesListContainer>
    <NoteAction>
      <Button icon={isAddingNote ? "loading":"add"} text="Add New Note" onClick={()=>addNote()}>Edit</Button>
    </NoteAction>
    {
      notes.map((note) => {
        return <NoteItem key={note.id} active={params.currentNote===""+note.id} onClick={()=>{
          navigate(`/programs/${params.selectedProgram}/${params.tab}/${params.connectionId}/${params.connectionTab}/${note.id}`)
        }}>
          <NoteTitle active={params.currentNote===""+note.id}>{note.title}</NoteTitle>
          <NoteTime>{formatDate(note.time)}</NoteTime>
        </NoteItem>
      })
    }
  </NotesListContainer>;
}
export default NotesList;