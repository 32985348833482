import styled from "styled-components";
import { panelSpacing } from "../../../../../../../../common/styles";

export const TimesheetContainer = styled.div`
    padding:${panelSpacing}px;
    
`;
export const StatusCell = styled.div`
    color: ${props => props.value==="Approved"? "green" : "gray"};
`;
export const AddTimesheetButtonContainer = styled.div`
    margin-bottom:20px;
    display:flex;
    align-items:center;
    justify-content:flex-end;
`;
export const TableWrapper= styled.div`
overflow-x:auto;
`