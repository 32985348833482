import React from "react";
import { ProgressBarContainer,Track,Bar } from "./styles";

function ProgressBar({percent , type = "normal"}) { //Percent out of 1
  return <ProgressBarContainer>
    <Track>
      <Bar percent={percent} type={type}/>
    </Track>
  </ProgressBarContainer>;
}
export default ProgressBar;