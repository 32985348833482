import React from "react";
import { useParams } from "react-router-dom";
import callAPI from "../../../../../api/apiCaller";
import Accordian from "../../../../../common/Accordian";
import Button from "../../../../../common/Button";
import { ButtonSpacer } from "../../../../../common/Button/styles";
import Loading from "../../../../../common/Loading";
import { DisplayDate, formatDate, formatDateForTask, formatOnlyDate, formatsDateForTask, unixTImeStampToIsoDate } from "../../../../../common/utils";
import AddEditTask from "../../popups/AddEditTask";
import TaskMembers from "../../popups/TaskMembers";
import { CreateTaskDate, TaskActions, TaskContent, TaskDate, TaskDescription, TaskName, TaskStatus, TaskTitle } from "../ProgramConnections/ProgramConnection/ConnectionProfile/Tasks/styles";
import { ProgramTasksContainer, TasksActions } from "./styles";
import { Context } from "../../../../../Store";
import { ContentActions, ContentCard } from "../../../../../common/Accordian/styles";


function ProgramTasks() {
  const params = useParams();
  const [tasks, setTasks] = React.useState(null);
  const [showAddEditTask, setShowAddEditTask] = React.useState(false);
  const [showTaskMembers, setShowTaskMembers] = React.useState(false);
  const {state,dispatch} = React.useContext(Context);
  const [refresh,setRefresh]  = React.useState(false);
  const [program, setProgram] = React.useState(null)
  const programInformation =() => {
    callAPI(dispatch, "getProgramInformation", {
      id: params.selectedProgram,
    }).then((info) => {
      setProgram(info.data);
    });
  }

  function getCurrentProgramTask(){
    setTasks(null)
    callAPI(dispatch,"getProgramTasks", { id: params.selectedProgram }).then((res) => {

      if(res.isResponseOk){
      setTasks(res.data);
      }
    });
  }

  React.useEffect(() => {
    getCurrentProgramTask()
    programInformation()
  }, [refresh]);
  return <ProgramTasksContainer>
    <TasksActions>
      <Button
        text="Add New Task"
        icon="add"
        onClick={() => {
          setShowAddEditTask(true)
        }} />
    </TasksActions>
    {
      tasks === null && <Loading loadingItem="Tasks" />
    }
    {
      tasks != null && <Accordian
      isTask={true}
        sections={tasks.map(task => {
          console.log(task,"<<<<<Task")
          return {
            title: <TaskTitle>
              <TaskName>{task.title}</TaskName>

              <CreateTaskDate >Task Created at: {DisplayDate(task.createdAt)} 
              </CreateTaskDate>
              <TaskDate isDue={new Date().getTime() > new Date(task.dueDate+"t00:00:00")}>Due on: {formatOnlyDate(task.dueDate)}
              </TaskDate>
            </TaskTitle>,

            
                
            content: <TaskContent>
              <ContentCard>{task.description}</ContentCard>
              <ButtonSpacer/>
            {/* {program?.programType === 'TEAM' &&<TaskStatus isCompleted={task.teamTask[0].status === "completed"}>Status: <b>{task.teamTask[0].status && task.teamTask[0].status }</b></TaskStatus>} */}
              <ContentActions>
                <Button
                  text="Edit Task"
                  icon={"edit"}
                  variant="primary"
                  onClick={() => {
                    setShowAddEditTask(task.id)
                   }} />
                <ButtonSpacer />
                
                 <Button
                  text="View Pending"
                  icon={"view"}
                  variant="dark"
                  onClick={() => {
                    setShowTaskMembers({
                      id:task.id,
                      mode:"pending"
                    })
                  }} />
                <ButtonSpacer />

                <Button
                  text="View Completed"
                  icon={"view"}
                  variant="dark"
                  onClick={() => {
                    setShowTaskMembers({
                      id:task.id,
                      mode:"complete"
                    })
                  }} />
                 

              </ContentActions>
            </TaskContent>
          }
        })
        }
      />
    }
    {
      program && showAddEditTask && <AddEditTask programType={program.programType} getCurrentProgramTask={getCurrentProgramTask} editTask={showAddEditTask} setShowAddEditTask={setShowAddEditTask}/>
    }
    {
      showTaskMembers && <TaskMembers programType={program.programType} taskId={showTaskMembers.id} mode={showTaskMembers.mode} setShowTaskMembers={(refresh)=>{
        if(refresh){
          setRefresh(!refresh)
        }
        setShowTaskMembers(false)}
      }/>
    }
  </ProgramTasksContainer>;
}
export default ProgramTasks;