import styled from "styled-components";
import { Scrollable, panelSpacing } from "../../../../../common/styles";
import colors from "../../../../../config/colors";

export const ProgramHomeContainer = styled.div`
    ${Scrollable}
    height:calc(100vh - 110px - ${panelSpacing*2}px);
    
    @media (max-width: 767px) {
        height:calc(100vh - 170px - ${panelSpacing*2}px);
    }
`;



export const ProgramHomeHeader = styled.div`
    padding:${panelSpacing}px;
    border-bottom: 1px solid ${colors.secondary};
    display:flex;
    @media (max-width: 640px) {
        flex-direction: column;
        gap: 20px;
    }

`;
export const ProgramImage = styled.img`
    width:250px;
    height:250px;
    margin-right:20px;
    object-fit:cover;
    border-radius:10px;
    @media (max-width:1200px) {
        width:160px;
        height:160px;
    }
`;
export const ProgramInfo = styled.div`
    display:flex;
    align-items:flex-start;
    justify-content:center;
    flex-direction:column;
`;
export const ProgramName = styled.div`
    font-size:32px;
    font-weight:bold;
    @media (max-width:1200px) {
       font-size: 24px;
    }
`;
export const ProgramOrganization = styled.div`
    font-size:12px;
`;
export const ProgramManagers = styled.div`
    margin:15px 0px;
`;
export const ProgramManagersLabel = styled.div`
    font-size:14px;
    font-weight:bold;
    margin-bottom:5px;
`;
export const ProgramManagersList = styled.div`
    display:flex;
`;
export const ProgramManagerImage = styled.img`
    width:30px;
    height:30px;
    border-radius:50%;
    object-fit: cover;
    cursor:pointer;
    :hover{
        opacity:0.7;
    }
    @media (max-width:1200px) {
        width:24px;
        height:24px;
    }
`;
export const ProgramTrackedHours = styled.div`
    font-size:14px;
`;

export const AboutContent = styled.div`
    padding:${panelSpacing}px;
    border-bottom: 1px solid ${colors.secondary};
`;
export const AboutTitle = styled.div`
   margin-bottom:20px;
   font-size:24px;
   font-weight:bold;
`;
export const AboutDescription = styled.div``;


export const AnnouncementsContent=styled(AboutContent)`
    border-bottom:none;
`;
export const AnnouncementsTitle=styled(AboutTitle)`
margin-bottom:0px;
`;
export const EditModeOptions = styled.div`
    display:flex;
    align-items:center;
    padding:20px;
    border-bottom: 1px solid ${colors.secondary};
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
`;

export const LinkLine = styled.div`
    display:flex;
    align-items:center;
    @media(max-width:1024px) {
        flex-direction:column;
        gap: 10px;
        align-items: flex-start;
    }
`;

export const Link = styled.a`
    color:${colors.primary};
    margin-right:15px;
    @media (max-width:1200px) {
        font-size: 14px;
        line-break: anywhere;
    }
`;
export const PaymentContainer = styled.div`
    max-height: 500px;
        overflow-y: auto;
     ::-webkit-scrollbar {
    width: 10px; 
  }
  
`;
export const Paymentintro = styled.div`
  
`;
export const GuideOrderList = styled.ol`
  display:block
`;
export const GuideList = styled.li`
  display:block;
  max-width:950px;

`;
export const SunHeading = styled.h3`
  font-weight:bold;
  font-size:16px;
  margin-bottom:0;

`;
export const ItemInfo = styled.div`
 margin-top:10px;
`;
export const ButtonWraper = styled.div`
width:${props=>props.width}%;
margin:10px 0;
display:flex;
flex-direction:${props=>props.direction ? props.direction :'column'};
justify-content:${props=>props.gap ? props.gap : ''};
gap:10px;


`;
export const HorezentalLine = styled.hr`
color :red
`;
export const PaypalContaner = styled.div`
    max-height: 500px;
    overflow-y: auto;
     ::-webkit-scrollbar {
    width: 10px; 
  }

`;
export const PaymentNote = styled.span`display:block; 
font-weight: 700;
margin-bottom:10px;
`
export const PaymentDotContainer = styled.div`
display:flex;
gap:50px

`
export const Dot = styled.span`
display: block;
    min-width: 20px;
    max-width: 20px;
    border-radius: 50px;
    border: 1px solid green;
    min-height: 20px;
    background: green;

`
export const PaymentSuccess = styled.p`
  text-align: left;
  font-family: Inter;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0em;
  max-width:55rem;
`;
export const PaymentSuccessHeading = styled.h2`
  text-align: left;
  font-family: Inter;
  font-size: 20px;
  font-weight: 800;
  line-height: 19px;
  letter-spacing: 0em;
  color: ${colors.primary};
  text-transform:capitalize;
`;
export const PremiumContainer = styled.div`
 display:flex;
 justify-content:space-between;
 width:100%;
 height:45vh;
 background:${colors.secondary};
padding:5px;
gap:5px;

`;
export const PersonalContainer = styled.div`
width:100%;
 display:flex;
 flex-direction:column;
gap:20px;
padding:10px;
background:${colors.background};
border-radius:10px;
align-items: center;
justify-content:center;


 
`;
export const TeamContainer = styled.div`
width:100%;
 display:flex;
 flex-direction:column;
gap:20px;
padding:10px;
background:${colors.background};
border-radius:10px;
align-items: center;
justify-content:center;
 
`;
export const PlatinumContainer = styled.div`
width:100%;
 display:flex;
 flex-direction:column;
gap:20px;
padding:10px;
background:${colors.background};
border-radius:10px;
align-items: center;
justify-content:center;
 
`;
export const Heading = styled.h1`
text-align:center;
 text-transform:capitalize;
 font-size:20px;
`;
export const ImagePreview = styled.img`
width:150px;
border-radius:10px;
height:150px;
`;
export const PremiumInfo = styled.div`
display:flex;
flex-direction:column;
`;
export const SubHeading = styled.h4`
text-align:center;
 text-transform:capitalize;
 font-size:16px;
`;
