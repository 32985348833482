import React from "react";
import {
  TermsAndConditionContainer,
  Heading,
  Description,
  Category,
  SubHeading,
  SubDescription,
  SubDescriptionPoint,
  SubDescriptionDetail,
  SubDescriptionDetailPoint,
} from "../styles";

const TermsAndConditionsContent = ({isModal, isProfile}) => {
  return (
    <>
      <TermsAndConditionContainer isModal={isModal} isProfile={isProfile}>
        {!isProfile && <Heading isModal={isModal}>TERMS OF SERVICE</Heading>}
        <Description isModal={isModal} isProfile={isProfile}>
          Welcome to One Mauve (the “Website”). This Terms of Service Agreement
          (the “Agreement”) is made and entered into by and between you and One
          Mauve, Inc. (the “Company”, “us”, “we”, or “our”). This Agreement sets
          forth the terms and conditions that govern your use of and access to
          the Website and any products, materials, and services provided by or
          on the Website (collectively, the “Services”).
        </Description>
        <Category>
          <SubHeading isModal={isModal} isProfile={isProfile}>1. Acceptance of this Agreement.</SubHeading>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>1.1</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Acceptance Through Using or Accessing the Services. Please review
              the following terms carefully. By accessing or using the Services
              (or by clicking on “accept” or “agree” to this Agreement when
              prompted), you agree to be bound by the terms and conditions of
              this Agreement on behalf of yourself or the entity or organization
              that you represent. If you do not agree to the terms and
              conditions of this Agreement, you may not use or access the
              Services and must discontinue use immediately.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>1.2</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Eligibility Requirements to Use or Access the Services. To use the
              Website or any other Services, you must be (i) at least 18 years
              old, and (ii) be physically residing in the United States.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              By accessing or using the Services, you represent and warrant that
              you meet all the foregoing eligibility requirements. You also
              represent and warrant that you have the right, authority, and
              capacity to enter into this Agreement on your behalf or the entity
              or organization that you represent. If you do not meet all these
              requirements, you may not use or access the Services.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              The Services are not intended for use by any person or entity in
              any jurisdiction or country where such use would be contrary to
              applicable law or regulation, or which subject us to any
              registration requirement in such jurisdiction or country. Those
              persons who chose to access or use the Services outside the United
              States do so on their own initiative and are solely responsible
              for compliance with local laws and we will not be responsible for
              nor obligated to comply with any such laws or regulations.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              The Services do not comply the Health Insurance Portability and
              Accountability Act (HIPPA) and you should not use the Services for
              any purpose that would require compliance with HIPPA. If your
              interactions would be subjected to HIPPA, you may not use the
              Services.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>1.3</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Changes to this Agreement. The Company reserves the right to
              change this Agreement from time to time in its sole discretion. We
              will alert you about any changes by updating the “Last Updated”
              date of this Agreement, and you waive any right to receive
              specific notice of each such change. All changes will apply to
              your use of and access to the Services from the date the changes
              become effective and onwards. For new users, the changes will be
              effective immediately.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Your continued use of or access to the Services following any
              changes to this Agreement shall constitute your acknowledgment of
              such changes and agreement to be bound by the terms and conditions
              of such changes. You should check this page frequently so that you
              are aware of any changes since they are binding on you.
            </SubDescriptionDetail>
          </SubDescription>
        </Category>
        <Category>
          <SubHeading isModal={isModal} isProfile={isProfile}>2. Access to the Services.</SubHeading>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>2.1</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Changes to Your Access and the Services. The Services may change
              from time to time as the Company evolves, refines, or adds more
              features to the Services. The Company reserves the right to
              modify, withdraw, or discontinue the Services, in whole or in
              part, at any time without notice to you. You agree that the
              Company shall have no liability to you or any third party for any
              losses or damages caused by the Services not being available, in
              whole or in part, at any time or for any period.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>2.2</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Creating an Account. You may be required to register for an
              account and provide certain information about yourself to access
              the Services or certain features of the Services. You promise to
              provide us with accurate, complete, and updated information about
              yourself. The Company may have different types of accounts for
              different users. All information that you provide will be governed
              by this Agreement, including Section 8 and, if applicable, Section
              16. You consent to all actions that we may take with respect to
              your information consistent with this Agreement.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>2.3</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Account Responsibilities. You are entirely responsible for
              maintaining the confidentiality of your password and account. You
              are also entirely responsible for any and all activities
              associated with your account. Your account is personal to you and
              you agree not to provide any other person with access to the
              Services or any portions of it using your username, password, or
              other security information. You should ensure that you exit from
              your account at the end of each session. You should use extra
              caution when accessing your account from a public or shared
              computer so that others are not able to view or record your
              password or other personal information. You may not transfer your
              account to anyone else. You agree to notify the Company
              immediately of any actual or suspected unauthorized use of your
              account or any other breach of security. The Company will not be
              liable for any losses or damages arising from your failure to
              comply with the above requirements. You will be held liable for
              losses or damages incurred by the Company or any third party due
              to someone else using your account or password.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>2.4</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Termination or Deletion of an Account. The Company may suspend or
              terminate your account at any time in our sole discretion for any
              or no reason, including if we determine that you have violated any
              terms or conditions of this Agreement. You may delete your account
              at any time, for any reason, by choosing “Delete Account” in the
              Settings menu or by emailing us at info@onemauve.com.
            </SubDescriptionDetail>
          </SubDescription>
        </Category>
        <Category>
          <SubHeading isModal={isModal} isProfile={isProfile}>3. Policy for Using the Services.</SubHeading>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>3.1</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Prohibited Uses. You may use the Services for lawful purposes only
              and in accordance with this Agreement. You agree not to use the
              Services in any way that could damage the Services or general
              business of the Company. You may use the Services solely for your
              own personal use or, if using the Services on behalf of an entity
              or organization, solely for the internal purposes of such entity
              or organization.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>3.2</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Prohibited Activities. You further agree not to engage in any of
              the following prohibited activities in connection with using the
              Services:
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(a)</SubDescriptionDetailPoint>
              No Violation of Laws or Obligations. Violate any applicable laws
              or regulations (including intellectual property laws and right of
              privacy or publicity laws) or any contractual obligations.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(b)</SubDescriptionDetailPoint>
              No Unsolicited Communications. Send any unsolicited or
              unauthorized advertising, promotional materials, spam, junk mail,
              chain letters, or any other form of unsolicited communications,
              whether commercial or otherwise.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(c)</SubDescriptionDetailPoint>
              No Impersonation. Impersonate others or otherwise misrepresent
              your affiliation with a person or entity in an attempt to mislead,
              confuse, or deceive others.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(d)</SubDescriptionDetailPoint>
              No Harming of Minors. Exploit or harm minors in any way, including
              exposing inappropriate content or obtaining personally
              identifiable information.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(e)</SubDescriptionDetailPoint>
              Compliance with Content Standards. Upload, display, distribute, or
              transmit any material that does not comply with the Content
              Standards set out below in this Agreement.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(f)</SubDescriptionDetailPoint>
              No Interference with Others’ Enjoyment. Harass or interfere with
              anyone’s use or enjoyment of the Services, or expose the Company
              or other users to liability or other harm.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(g)</SubDescriptionDetailPoint>
              No Interference or Disabling of the Services. Use any device,
              software, or routine that interferes with the proper working of
              the Services, or take any action that may interfere with, disrupt,
              disable, impair, or create an undue burden on the infrastructure
              of the Services, including servers or networks connected to the
              Website.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(h)</SubDescriptionDetailPoint>
              No Monitoring or Copying Material. Copy, monitor, distribute, or
              disclose any part of the Services by automated or manual
              processes, devices, or means. This includes, without limitation,
              using automatic devices such as robots, spiders, offline readers,
              crawlers, or scrapers to strip, scrape, or mine data from the
              Website; provided, however, that the Company conditionally grants
              to the operators of public search engines revocable permission to
              use spiders to copy materials from the Website for the sole
              purpose of and solely to the extent necessary for creating
              publicly available searchable indices of the materials, but not
              caches or archives of such materials.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(i)</SubDescriptionDetailPoint>
              No Viruses, Worms, or Other Damaging Software. Upload, transmit,
              or distribute to or through the Services any viruses, Trojan
              horses, worms, logic bombs, or other materials intended to damage
              or alter the property of others, including attacking the Services
              via a denial-of-service or distributed denial-of-service attack.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(j)</SubDescriptionDetailPoint>
              No Unauthorized Access or Violation of Security. Violate the
              security of the Services through (i) any attempt to gain
              unauthorized access to the Services or to other systems or
              networks connected to the Services, (ii) the breach or
              circumvention of encryption or other security codes or tools, or
              (iii) data mining or interference to any server, computer,
              database, host, user, or network connected to the Services.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(k)</SubDescriptionDetailPoint>
              No Reverse Engineering. Reverse engineer, decompile, or otherwise
              attempt to obtain the source code or underlying information of or
              relating to the Services.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(l)</SubDescriptionDetailPoint>
              No Collecting User Data. Collect, harvest, or assemble any data or
              information regarding any other user without their consent. This
              includes, without limitation, their names, emails, usernames,
              passwords, or affiliations.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(m)</SubDescriptionDetailPoint>
              No Other Interference. Otherwise attempt to interfere with the
              proper working of the Services.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(n)</SubDescriptionDetailPoint>
              Attempt or Assist Others in Attempting. Attempt any of the
              foregoing or assist, permit, or encourage others to do or attempt
              any of the foregoing.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>3.3</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Geographic Restrictions. The Company is based in the United
              States. The Services are for use by persons located in the United
              States only. By choosing to access the Services from any location
              other than the United States, you accept full responsibility for
              compliance with all local laws. The Company makes no
              representations that the Services or any of its content are
              accessible or appropriate outside of the United States.
            </SubDescriptionDetail>
          </SubDescription>
        </Category>
        <Category>
          <SubHeading isModal={isModal} isProfile={isProfile}>4. Intellectual Property Rights.</SubHeading>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>4.1</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Ownership of Intellectual Property. You acknowledge that all
              intellectual property rights, including copyrights, trademarks,
              service marks, logos trade secrets, and patents, in the Services
              and its contents, features, and functionality, and any necessary
              software, source code, or used in connection with any Service
              (collectively, the “Content”), are owned by the Company, its
              licensors, or other providers of such material. The Content is
              protected by U.S. and international intellectual property or
              proprietary rights laws. Neither this Agreement nor your access to
              the Services transfers to you any right, title, or interest in or
              to such intellectual property rights. Any rights not expressly
              granted in this Agreement are reserved by the Company and its
              licensors.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>4.2</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              License to Use the Services. Subject to your compliance with this
              Agreement, during the Term, the Company grants you a limited,
              non-exclusive, non-transferable, non-sublicensable, and revocable
              license to use and access the Content for your own personal use
              or, if using the Services on behalf of an entity or organization,
              solely for the internal purposes of such entity or organization.
              The Content may not be used for any other purpose. Any breach of
              this Section 4 will constitute a material breach of this Agreement
              and your right to use our Services will terminate immediately.
              This license will terminate upon your cessation of use of the
              Services or at the termination of this Agreement.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>4.3</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Certain Restrictions. The rights granted to you in this Agreement
              are subject to the following restrictions:
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(a)</SubDescriptionDetailPoint>
              No Copying or Distribution. You shall not copy, reproduce,
              publish, display, perform, post, transmit, or distribute any part
              of the Content in any form or by any means except as expressly
              permitted herein or as enabled by a feature, product, or the
              Services when provided to you.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(b)</SubDescriptionDetailPoint>
              No Modifications. You shall not modify, create derivative works
              from, translate, adapt, disassemble, reverse compile, or reverse
              engineer any part of the Content.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(c)</SubDescriptionDetailPoint>
              No Exploitation. You shall not sell, license, sublicense,
              transfer, assign, rent, lease, loan, host, or otherwise exploit
              the Content or the Services in any way, whether in whole or in
              part.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(d)</SubDescriptionDetailPoint>
              No Altering of Notices. You shall not delete or alter any
              copyright, trademark, or other proprietary rights notices from
              copies of the Content.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(e)</SubDescriptionDetailPoint>
              No Competition. You shall not access or use the Content in order
              to build a similar or competitive website, product, or service.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>4.4</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Trademark Notice. All trademarks, logos, and service marks
              displayed on the Services are either the Company’s property or the
              property of third parties. You may not use such trademarks, logos,
              or service marks without the prior written consent of their
              respective owners.
            </SubDescriptionDetail>
          </SubDescription>
        </Category>
        <Category>
          <SubHeading isModal={isModal} isProfile={isProfile}>5. User Content.</SubHeading>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>5.1</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              User Generated Content. The Services may contain message boards,
              chatrooms, profiles, forums, and other interactive features that
              allow users to post, upload, submit, publish, display, or transmit
              to other users or other persons content or materials
              (collectively, “User Content”) on or through the Services.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              You are solely responsible for your User Content. Please consider
              carefully what you choose to share. You understand that User
              Content may be viewable by other users of the Services and
              possibly through third-party websites. All User Content must
              comply with the Content Standards set forth below. Any User
              Content you post on or through the Services will be considered
              non- confidential and non-proprietary. You assume all risks
              associated with the use of your User Content. This includes any
              reliance on its accuracy, completeness, reliability, or
              appropriateness by other users and third parties, or any
              disclosure of your User Content that personally identifies you or
              any third party. You agree that the Company shall not be
              responsible or liable to any third party for any User Content
              posted by you or any other user of the Services.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              You further agree that the Company shall not be responsible for
              any loss or damage incurred as the result of any interactions
              between you and other users. Your interactions with other users
              are solely between you and such users. If there is a dispute
              between you and any other user, we are under no obligation to
              become involved.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>5.2</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              License. You hereby grant to the Company an irrevocable,
              non-exclusive, royalty-free and fully paid, transferable,
              perpetual, and worldwide license to reproduce, distribute,
              publicly display and perform, prepare derivative works of,
              incorporate into other works, and otherwise use and exploit your
              User Content, and to grant sublicenses of the foregoing rights, in
              connection with the Services and the Company’s business including,
              for promoting and redistributing part or all of the Services in
              any media formats and through any media channels. This license
              includes our use of your name, company name, and franchise name,
              as applicable, and any of the trademarks, service marks, trade
              names, logos, and personal and commercial images you provide.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              You represent and warrant that you have all the rights, power, and
              authority necessary to grant the rights granted herein to any User
              Content that you submit. You hereby irrevocably waive all claims,
              including to the extent permissible by applicable law, all moral
              rights, and have no recourse against us for any alleged or actual
              infringement or misappropriation of any proprietary rights in any
              communication, content, or material submitted to us. Please note
              that all of the following licenses are subject to this Agreement
              to the extent they relate to any User Content that contains any
              personally identifiable information.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>5.3</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Content Standards. You agree not to send, knowingly receive,
              upload, transmit, display, or distribute any User Content that
              does not comply with the following standards (“Content
              Standards”). User Content must not:
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(a)</SubDescriptionDetailPoint>
              Violate Laws or Obligations. Violate any applicable laws or
              regulations (including intellectual property laws and right of
              privacy or publicity laws), or any contractual or fiduciary
              obligations.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(b)</SubDescriptionDetailPoint>
              Promote Illegal Activity or Harm to Others. Promote any illegal
              activity; advocate, promote, or assist any unlawful act; or create
              any risk of any harm, loss, or damage to any person or property.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(c)</SubDescriptionDetailPoint>
              Infringe Intellectual Property Rights. Infringe any copyright,
              trademark, patent, trade secret, moral right, or other
              intellectual property rights of any other person.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(d)</SubDescriptionDetailPoint>
              Defamatory, Abusive, or Otherwise Objectionable Material. Contain
              any information or material that we deem to be unlawful,
              defamatory, trade libelous, invasive of another’s privacy or
              publicity rights, abusive, threatening, harassing, harmful,
              violent, hateful, obscene, vulgar, profane, indecent, offensive,
              inflammatory, humiliating to other people (publicly or otherwise),
              or otherwise objectionable. This includes any information or
              material that we deem to cause annoyance, inconvenience, or
              needless anxiety, or be likely to upset, embarrass, alarm, or
              annoy another person.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(e)</SubDescriptionDetailPoint>
              Promotion of Sexually Explicit Material or Discrimination. Promote
              sexually explicit or pornographic material, violence, or
              discrimination based on race, sex, religion, nationality,
              disability, sexual orientation, or age.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(f)</SubDescriptionDetailPoint>
              Fraudulent Information or Impersonation. Contain any information
              or material that is false, intentionally misleading, or otherwise
              likely to deceive any person including, without limitation,
              impersonating any person, or misrepresenting your identity or
              affiliation with any person or organization.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(g)</SubDescriptionDetailPoint>
              Commercial Activity. Involve commercial activities or sales, such
              as contests, sweepstakes, and other sales promotions, barter, or
              advertising.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(h)</SubDescriptionDetailPoint>
              Endorsement by the Company. Represent or imply to others that it
              is in any way provided, sponsored, or endorsed by the Company.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>5.4</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Monitoring and Enforcement. We reserve the right at all times, but
              are not obligated, to:
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(a)</SubDescriptionDetailPoint>
              take any action with respect to any User Content that we deem
              necessary or appropriate in our sole discretion, including if we
              believe that such User Content violates the Content Standards or
              any other provision in this Agreement, or creates liability for
              the Company or any other person. Such action may include reporting
              you to law enforcement authorities.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(b)</SubDescriptionDetailPoint>
              remove or reject any User Content for any or no reason in our sole
              discretion.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(c)</SubDescriptionDetailPoint>
              disclose any User Content, your identity, or electronic
              communication of any kind to satisfy any law, regulation, or
              government request, or to protect the rights or property of the
              Company or any other person.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(d)</SubDescriptionDetailPoint>
              Terminate or suspend your access to all or part of the Services
              for any or no reason, including without limitation, any violation
              of this Agreement.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              We do not review User Content before it is posted on or through
              the Services, and therefore cannot ensure prompt removal of
              questionable User Content. Accordingly, the Company and its
              affiliates, and their respective officers, directors, employees or
              agents, assume no liability for any action or inaction regarding
              transmissions, communications, or content provided by any user or
              third party. The Company shall have no liability or responsibility
              to anyone for performance or non-performance of the activities
              described in this Section.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>5.5</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Copyright Infringement (Digital Millennium Copyright Act Policy).
              The Company respects the intellectual property of others and
              expects users of the Services to do the same. It is the Company’s
              policy to terminate the users of our Services who are repeat
              infringers of intellectual property rights, including copyrights.
              If you believe that your work has been copied in a way that
              constitutes copyright infringement and wish to have the allegedly
              infringing material removed, please provide the following
              information in accordance with the Digital Millennium Copyright
              Act to our designated copyright agent:
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(a)</SubDescriptionDetailPoint>a
              physical or electronic signature of the copyright owner or a
              person authorized to act on their behalf;
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(b)</SubDescriptionDetailPoint>a
              description of the copyrighted work that you allege has been
              infringed;
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(c)</SubDescriptionDetailPoint>a
              description of the material that is claimed to be infringing or to
              be the subject of infringing activity and that is to be removed or
              access to which is to be disabled;
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(d)</SubDescriptionDetailPoint>a
              description of where the material that you claim is infringing is
              located;
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(e)</SubDescriptionDetailPoint>
              your contact information, including your address, telephone
              number, and email address;
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(f)</SubDescriptionDetailPoint>a
              statement that you have a good faith belief that use of the
              objectionable material is not authorized by the copyright owner,
              its agent, or under the law; and
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(g)</SubDescriptionDetailPoint>a
              statement by you, made under penalty of perjury, that the above
              information in your notice is accurate and that you are the
              copyright owner or authorized to act on the copyright owner’s
              behalf.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Please note that pursuant to 17 U.S.C. § 512(f), any
              misrepresentation of material fact in a written notification
              automatically subjects the complaining party to liability for any
              damages, costs, and attorneys’ fees incurred by us in connection
              with the written notification and allegation of copyright
              infringement.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>5.6</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Feedback to the Company. If you provide the Company with any
              feedback, suggestions, requests for features, or idea regarding
              the Services (“Feedback”), you hereby assign to the Company all
              rights in such Feedback and agree that the Company shall have the
              right to use and fully exploit such Feedback and related
              information in any manner it deems appropriate without any
              acknowledgement or compensation to you. The Company will treat any
              Feedback that you provide to the Company as non-confidential and
              non-proprietary. You agree that you will not submit to the Company
              any information or ideas that you consider to be confidential or
              proprietary.
            </SubDescriptionDetail>
          </SubDescription>
        </Category>
        <Category>
          <SubHeading isModal={isModal} isProfile={isProfile}>6. Subscriptions; Purchases and Payment.</SubHeading>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>6.1</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Billing and Renewal. Your subscription will continue and
              automatically renew unless canceled. You consent to our charging
              your payment method on a recurring basis without requiring your
              prior approval for each recurring charge, until such time as you
              cancel the applicable order. The length of your billing cycle is
              one month.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>6.2</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Cancellation. All purchases are non-refundable. You can cancel
              your subscription at any time by contacting us using the contact
              information provided below. Your cancellation will take effect at
              the end of the current paid term. If you have any questions or are
              unsatisfied with our Services, please email us at
              info@onemauve.com.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>6.3</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Fee Changes. We may, from time to time, make changes to the
              subscription fee and will communicate any price changes to you in
              accordance with applicable law.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>6.4</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Purchases and Payment. We accept payment via credit card or
              PayPal. You agree to provide current, complete, and accurate
              purchase and account information for all purchases made via the
              Services. You further agree to promptly update account and payment
              information, including email address, payment method, and payment
              card expiration date, so that we can complete your transactions
              and contact you as needed. Sales tax will be added to the price of
              purchases as deemed required by us. We may change prices at any
              time. All payments shall be in US dollars.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              You agree to pay all charges at the prices then in effect for your
              purchases and any applicable shipping fees, and you authorize us
              to charge your chosen payment provider for any such amounts upon
              placing your order. We reserve the right to correct any errors or
              mistakes in pricing, even if we have already requested or received
              payment.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              We reserve the right to refuse any order placed through the
              Services. We may, in our sole discretion, limit or cancel Services
              purchased per person, per entity, or per order. These restrictions
              may include orders placed by or under the same customer account,
              the same payment method, and/or orders that use the same billing
              or shipping address.
            </SubDescriptionDetail>
          </SubDescription>
        </Category>
        <Category>
          <SubHeading isModal={isModal} isProfile={isProfile}>7. Assumption of Risk.</SubHeading>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              The information presented on or through the Services is made
              available for general information purposes only. The Company does
              not warrant the accuracy, completeness, suitability, or quality of
              any such information. Any reliance on such information is strictly
              at your own risk. There may be information on the Services that
              contains typographical errors, inaccuracies, or omissions,
              including descriptions, pricing, availability, and various other
              information. We reserve the right to correct any errors,
              inaccuracies, or omissions and to change or update the information
              on the Services at any time, without prior notice. The Company
              disclaims all liability and responsibility arising from any
              reliance placed on such information by you or any other user to
              the Services, or by anyone who may be informed of any of its
              contents.
            </SubDescriptionDetail>
          </SubDescription>
        </Category>
        <Category>
          <SubHeading isModal={isModal} isProfile={isProfile}>8. Privacy.</SubHeading>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              We care about data privacy and security. Please be advised the
              Services are hosted in the United States. If you access the
              Services from any other region of the world with laws or other
              requirements governing personal data collection, use, or
              disclosure that differ from applicable laws in the United States,
              then through your continued use of the Services, you are
              transferring your data to the United States, and you expressly
              consent to have your data transferred to and processed in the
              United States. We do NOT sell your personal information.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>8.1</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Information We Collect. We collect information:
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(a)</SubDescriptionDetailPoint>
              you provide to us when you create an account, contact us, or
              interact with the Services;
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(b)</SubDescriptionDetailPoint>
              automatically when you visit the Website and use the Services,
              including your internet protocol (IP) address, browser and device
              characteristics, usage information, operating system, language
              preferences, referring URLs, country, location, how and when you
              use the Services, and other technical information for our internal
              analytics and reporting purposes and to ensure the security and
              operation of the Services;
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(c)</SubDescriptionDetailPoint>
              rom third parties that you have permitted to share your
              information, e.g. your university or membership organization.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>8.2</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              How We Process Your Information. We process your information to
              provide, improve and administer the Services, communicate with
              you, for security and fraud prevention, and to comply with
              applicable law. We may also process your information for other
              purposes with your consent.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>8.3</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              When and With Whom Do We Share Your Information. We may need to
              share your information as follows:
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(a)</SubDescriptionDetailPoint>
              Business transfers. In connection with any merger, sale,
              acquisition, or financing of all or a portion of our business;
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(b)</SubDescriptionDetailPoint>
              Vendors. With service providers, professional advisors,
              contractors, and other third parties that provide us with support
              services, such as payment processing, website hosting, information
              technology, sales, email and postal delivery, location mapping,
              product and service delivery, or analytics services, and who are
              bound by contractual obligations to keep personal information
              confidential and use it only for the purposes for which we
              disclose it to them (e.g. Miro and PayPal);
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(c)</SubDescriptionDetailPoint>
              When required by law. To comply with any court order, law, or
              legal process, including to respond to any government or
              regulatory request;
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(d)</SubDescriptionDetailPoint>
              To help protect lawful interests. If we believe disclosure will
              help us protect the rights, property, or safety of the Company,
              our users, partners, agents, and others, including exchanging
              information with other companies and organizations for fraud
              protection, and spam and malware prevention;
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              <SubDescriptionDetailPoint isModal={isModal}>(e)</SubDescriptionDetailPoint>
              To fulfill the purpose for that information or with your consent.
              To fulfill the purpose for which you provide the information, for
              any other purpose disclosed by us when you provide the
              information, or with your consent.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>8.4</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              User Content. Information that you post on or through the public
              areas of the Services (e.g., in chat rooms, bulletin boards, and
              discussion groups) is generally accessible to, and may be
              collected and used by, others and may result in unsolicited
              messages or other forms of contact from others. Users of the
              Services are encouraged to exercise caution when providing
              personal information about themselves in public or interactive
              areas.
            </SubDescriptionDetail>
          </SubDescription>
        </Category>
        <Category>
          <SubHeading isModal={isModal} isProfile={isProfile}>9. Third-Party Links.</SubHeading>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              The Services may contain links to third-party websites, resources,
              and services (collectively, “Third-Party Links”). Third-Party
              Links are provided for your convenience only. The Company does not
              review, approve, monitor, endorse, warrant, or make any
              representations with respect to Third-Party Links. The Company has
              no control over the contents, products, or services of any
              Third-Party Link and accepts no responsibility for them or for any
              loss or damage that may arise from your use of them. If you decide
              to access any Third-Party Link, you do so entirely at your own
              risk and subject to the terms and conditions of use for such
              Third-Party Link. You should make whatever investigation you feel
              necessary or appropriate before proceeding with any transaction in
              connection with any Third-Party Link.
            </SubDescriptionDetail>
          </SubDescription>
        </Category>
        <Category>
          <SubHeading isModal={isModal} isProfile={isProfile}>10. Termination.</SubHeading>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>10.1</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Termination. The Company may suspend or terminate your access or
              rights to use the Services at any time, for any reason, in our
              sole discretion, and without prior notice, including for any
              breach of the terms of this Agreement. Upon termination of your
              access or rights to use the Services, your right to access and use
              the Services will immediately cease. The Company will not have any
              liability whatsoever to you for any suspension or termination of
              your rights under this Agreement, including for termination of
              your account or deletion of your User Content. If you have
              registered for an account, you may terminate this Agreement at any
              time by contacting the Company and requesting termination.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>10.2</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Effect of Termination. Upon termination of this Agreement, any
              provisions that by their nature should survive termination shall
              remain in full force and effect. This includes, ownership or
              intellectual property provisions, warranty disclaimers, and
              limitations of liability. Termination of your access to and use of
              the Services shall not relieve you of any obligations arising or
              accruing prior to termination or limit any liability that you
              otherwise may have to the Company or any third party. You
              understand that any termination of your access to and use of the
              Services may involve deletion of your User Content associated with
              your account from our databases.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              If we terminate or suspend your account for any reason, you are
              prohibited from registering and creating a new account under your
              name, a fake or borrowed name, or the name of any third party,
              even if you may be acting on behalf of the third party. In
              addition to terminating or suspending your account, we reserve the
              right to take appropriate legal action, including without
              limitation pursuing civil, criminal, and injunctive redress.
            </SubDescriptionDetail>
          </SubDescription>
        </Category>
        <Category>
          <SubHeading isModal={isModal} isProfile={isProfile}>11. No Warranty.</SubHeading>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              THE SERVICES ARE PROVIDED ON AN “AS-IS” AND “AS AVAILABLE” BASIS.
              USE OF THE SERVICES IS AT YOUR OWN RISK. TO THE MAXIMUM EXTENT
              PERMITTED BY APPLICABLE LAW, THE SERVICES ARE PROVIDED WITHOUT
              WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, OR
              OTHERWISE, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET
              ENJOYMENT, ACCURACY, OR NON- INFRINGEMENT.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              WITHOUT LIMITING THE FOREGOING, THE COMPANY AND ITS LICENSORS DO
              NOT WARRANT THAT THE CONTENT IS ACCURATE, RELIABLE, COMPLETE, OR
              CORRECT; THAT THE SERVICES WILL MEET YOUR REQUIREMENTS; THAT THE
              SERVICES WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION,
              UNINTERRUPTED, ERROR- FREE, OR SECURE; THAT ANY DEFECTS OR ERRORS
              WILL BE CORRECTED; THAT THE SERVICES ARE FREE OF VIRUSES OR OTHER
              HARMFUL COMPONENTS; OR THAT THE SERVICES OR ITEMS OBTAINED THROUGH
              THE SERVICES WILL OTHERWISE MEET YOUR REQUIREMENTS OR
              EXPECTATIONS. TO THE FULLEST EXTENT PROVIDED BY LAW. WE WILL NOT
              BE LIABLE FOR ANY LOSS OR DAMAGE TO YOUR COMPUTER SYSTEM, MOBILE
              DEVICE, DATA, OR OTHER PROPRIETARY MATERIAL THAT MAY RESULT FROM
              YOUR USE OF THE SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES OR
              YOUR DOWNLOADING OF ANY MATERIAL POSTED ON THE SERVICES. WE DO NOT
              WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
              PRODUCT OR SERVICES ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH
              THE SERVICES OR THIRD-PARTY LINKS, AND WE WILL NOT BE A PARTY TO
              OR IN ANY WAY MONITOR ANY TRANSACTION BETWEEN YOU AND ANY THIRD-
              PARTY PROVIDERS OF PRODUCTS OR SERVICES OR ANY OTHER USER.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              THE SERVICES WOULD NOT BE PROVIDED WITHOUT THESE LIMITATIONS. NO
              ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
              FROM US THROUGH THE SERVICES SHALL CREATE ANY WARRANTY,
              REPRESENTATION, OR GUARANTEE NOT EXPRESSLY STATED IN THIS
              AGREEMENT. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF
              IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.
              IF APPLICABLE LAW REQUIRES ANY WARRANTIES WITH RESPECT TO THE
              SERVICES, ALL SUCH WARRANTIES ARE LIMITED IN DURATION TO 90 DAYS
              FROM THE DATE OF FIRST USE.
            </SubDescriptionDetail>
          </SubDescription>
        </Category>
        <Category>
          <SubHeading isModal={isModal} isProfile={isProfile}>12. Limitation of Liability.</SubHeading>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, IN NO EVENT SHALL
              THE COMPANY OR ITS AFFILIATES, OR THEIR RESPECTIVE LICENSORS,
              SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE
              LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOSS OF USE, REVENUE, OR
              PROFIT, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF DATA,
              LOSS OF GOODWILL, OR DIMINUTION IN VALUE, OR FOR ANY
              CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, OR
              PUNITIVE DAMAGES WHETHER ARISING OUT OF BREACH OF CONTRACT, TORT
              (INCLUDING NEGLIGENCE), OR OTHERWISE, REGARDLESS OF WHETHER SUCH
              DAMAGE WAS FORESEEABLE AND WHETHER OR NOT THE COMPANY HAS BEEN
              ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND NOTWITHSTANDING
              THE FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL
              PURPOSE. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
              CERTAIN DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT
              APPLY TO YOU.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, NOTWITHSTANDING
              ANYTHING TO THE CONTRARY CONTAINED HEREIN, THE COLLECTIVE
              LIABILITY OF THE COMPANY AND ITS AFFILIATES, AND THEIR RESPECTIVE
              LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, AND
              DIRECTORS, TO YOU (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE
              FORM OF THE ACTION) WILL AT ALL TIMES BE LIMITED TO A MAXIMUM
              OF$100 DOLLARS. SOME STATES DO NOT ALLOW THE EXCLUSION OR
              LIMITATION OF CERTAIN DAMAGES, SO THE ABOVE LIMITATION AND
              EXCLUSIONS MAY NOT APPLY TO YOU.
            </SubDescriptionDetail>
          </SubDescription>
        </Category>
        <Category>
          <SubHeading isModal={isModal} isProfile={isProfile}>13. Indemnification.</SubHeading>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              You agree to indemnify, defend, and hold harmless the Company and
              its affiliates and their respective officers, directors,
              employees, agents, affiliates, successors, and permitted assigns
              (collectively, “Indemnified Party”) from and against any and all
              losses, claims, actions, suits, complaints, damages, liabilities,
              penalties, interest, judgments, settlements, deficiencies,
              disbursements, awards, fines, costs, fees, or expenses of whatever
              kind, including reasonable attorneys’ fees, fees and other costs
              of enforcing any right to indemnification under this Agreement,
              and the cost of pursuing any insurance providers, arising out of
              or relating to your breach of this Agreement or your use or misuse
              of the Services, including your User Content or any actions taken
              by a third party using your account. The Company reserves the
              right, at your expense, to assume the exclusive defense and
              control of any matter for which you are required to indemnify us,
              and you agree to assist and cooperate with our defense or
              settlement of these claims. We will use reasonable efforts to
              notify you of any such claim, action, or proceeding which is
              subject to this indemnification upon becoming aware of it.
            </SubDescriptionDetail>
          </SubDescription>
        </Category>
        <Category>
          <SubHeading isModal={isModal} isProfile={isProfile}>14. Disputes.</SubHeading>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>14.1</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Governing Law. All matters relating to this Agreement, and all
              matters arising out of or relating to this Agreement, whether
              sounding in contract, tort, or statute are governed by, and
              construed in accordance with, the laws of the State of California,
              without giving effect to any conflict of law principles.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>14.2</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Dispute Resolution. Please read this Section carefully. It
              contains procedures for MANDATORY BINDING ARBITRATION AND A CLASS
              ACTION WAIVER.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Any dispute, claim or controversy arising out of or relating to
              this Agreement, or the breach, termination, enforcement,
              interpretation or validity thereof, shall be submitted to and
              decided by a single arbitrator by binding arbitration under the
              rules of the American Arbitration Association in the County of Los
              Angeles, California. The decision of the arbitrator shall be final
              and binding on the parties and may be entered and enforced in any
              court of competent jurisdiction by either party. The prevailing
              party in the arbitration proceedings shall be awarded reasonable
              attorneys’ fees, expert witness costs and expenses, and all other
              costs and expenses incurred directly or indirectly in connection
              with the proceedings, unless the arbitrator shall for good cause
              determine otherwise.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              All arbitrations shall proceed on an individual basis. You agree
              that you may bring claims against the Company in arbitration only
              in your individual capacity and in so doing you hereby waive the
              right to a trial by jury, to assert or participate in a class
              action lawsuit or class action arbitration (either as a
              named-plaintiff or class member), and to assert or participate in
              any joint or consolidated lawsuit or joint or consolidated
              arbitration of any kind. Notwithstanding anything to the contrary
              under the rules of the American Arbitration Association, the
              arbitrator may not consolidate more than one person's claims, and
              may not otherwise preside over any form of a representative or
              class proceeding. If a court decides that applicable law precludes
              enforcement of any of this paragraph’s limitations as to a
              particular claim for relief, then that claim (and only that claim)
              must be severed from the arbitration and may be brought in court.
            </SubDescriptionDetail>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              YOU UNDERSTAND AND AGREE THAT BY ENTERING INTO THESE TERMS, YOU
              ARE WAIVING THE RIGHT TO TRIAL BY JURY OR TO PARTICIPATE IN A
              CLASS ACTION.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>14.3</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Limitation to Time to File Claims. ANY CAUSE OF ACTION OR CLAIM
              YOU MAY HAVE ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE
              SERVICES MUST BE COMMENCED WITHIN ONE YEAR AFTER THE CAUSE OF
              ACTION AROSE; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS
              PERMANENTLY WAIVED AND BARRED.
            </SubDescriptionDetail>
          </SubDescription>
        </Category>
        <Category>
          <SubHeading isModal={isModal} isProfile={isProfile}>15. Miscellaneous.</SubHeading>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>15.1</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Waiver. Except as otherwise set forth in this Agreement, no
              failure of the Company to exercise, or delay by the Company in
              exercising, any right, remedy, power, or privilege arising from
              this Agreement shall operate or be construed as a waiver thereof,
              nor shall any single or partial exercise of any right, remedy,
              power, or privilege hereunder preclude any other or further
              exercise thereof or the exercise of any other right, remedy,
              power, or privilege.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>15.2</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Severability. If any term or provision of this Agreement is found
              by a court of competent jurisdiction to be invalid, illegal, or
              unenforceable, such invalidity, illegality, or unenforceability
              shall not affect any other term or provision of this Agreement or
              invalidate or render unenforceable such term or provision in any
              other jurisdiction.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>15.3</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Entire Agreement. This Agreement, together with all documents
              referenced herein, constitutes the entire agreement between you
              and the Company with respect to the subject matter contained
              herein. This Agreement supersedes all prior and contemporaneous
              understandings, agreements, representations, and warranties, both
              written and oral, with respect to the subject matter hereof.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>15.4</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Headings. Headings and titles of sections, clauses, and parts in
              this Agreement are for convenience only. Such headings and titles
              shall not affect the meaning of any provisions of the Agreement.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>15.5</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              No Agency, Partnership or Joint Venture. No agency, partnership,
              or joint venture has been created between you and the Company as a
              result of this Agreement. You do not have any authority of any
              kind to bind the Company in any respect whatsoever.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>15.6</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Assignment. You shall not assign or delegate any of your rights or
              obligations under this Agreement without the prior written consent
              of the Company. Any purported assignment or delegation in
              violation of this Section shall be deemed null and void. No
              assignment or delegation shall relieve you of any of your
              obligations hereunder. The Company may freely assign or delegate
              its rights and obligations under this Agreement at any time.
              Subject to the limits on assignment stated above, this Agreement
              will inure to the benefit of, be binding on, and be enforceable
              against each of the parties hereto and their respective successors
              and assigns.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>15.7</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Export Laws. The Services may be subject to U.S. export control
              laws and regulations. You agree to abide by these laws and their
              regulations (including, without limitation, the Export
              Administration Act and the Arms Export Control Act) and not to
              transfer, by electronic transmission or otherwise, any materials
              from the Services to either a foreign national or a foreign
              destination in violation of such laws or regulations.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>15.8</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              Electronic Communications, Transactions, and Signatures. Visiting
              the Website, using the Services, sending us emails, and completing
              online forms constitute electronic communications. You consent to
              receive electronic communications, and you agree that all
              agreements, notices, disclosures, and other communications we
              provide to you electronically, via email and on the Services,
              satisfy any legal requirement that such communication be in
              writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
              CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY
              OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR
              COMPLETED BY US OR VIA THE SERVICES. You hereby waive any rights
              or requirements under any statutes, regulations, rules,
              ordinances, or other laws in any jurisdiction which require an
              original signature or delivery or retention of non-electronic
              records, or to payments or the granting of credits by any means
              other than electronic means.
            </SubDescriptionDetail>
          </SubDescription>
        </Category>
        <Category>
          <SubHeading isModal={isModal} isProfile={isProfile}>16. California Users and Residents. </SubHeading>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>16.1</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              If any complaint with us is not satisfactorily resolved, you can
              contact the Complaint Assistance Unit of the Division of Consumer
              Services of the California Department of Consumer Affairs in
              writing at 1625 North Market Blvd., Suite N 112, Sacramento,
              California 95834 or by telephone at (800) 952-5210 or (916)
              445-1254.
            </SubDescriptionDetail>
          </SubDescription>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionPoint isModal={isModal} isProfile={isProfile}>16.2</SubDescriptionPoint>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              The CCPA provides California residents specific rights regarding
              their personal information. You have the right to request that the
              Company disclose certain information to you about our collection
              and use of your personal information over the past 12 months (the
              “Right to Know”). You have the right to request that we delete any
              of your personal information that we collected from you and
              retained, subject to certain exceptions (the “Right to Delete”).
              Once we receive your request and confirm your identity, we will
              review your request to see if an exception allowing us to retain
              the information applies. We may deny your deletion request if
              retaining the information is necessary for us or our service
              provider(s). We will delete or deidentify personal information not
              subject to an exception from our records and will direct our
              service providers to take similar action. Note that your
              communications with other users and User Content that you’ve
              posted on the Website will be deidentified but may not be deleted.
              In addition, other users (for example administrators of
              organizations that use the Services to manage mentorship or other
              programs that you are a member of) may have downloaded or
              otherwise saved personal information and User Content that you
              made available to them, and we have no ability to delete such
              personal information or User Content. To exercise your Right to
              Know or Right to Delete, please submit a request by email to
              info@onemauve.com. We cannot respond to your request or provide
              you with personal information if we cannot verify your identity or
              authority to make the request and confirm that the personal
              information relates to you. We will not discriminate against you
              for exercising any of your CCPA rights.
            </SubDescriptionDetail>
          </SubDescription>
        </Category>
        <Category>
          <SubHeading isModal={isModal} isProfile={isProfile}>17. Contact Information.</SubHeading>
          <SubDescription isModal={isModal} isProfile={isProfile}>
            <SubDescriptionDetail isModal={isModal} isProfile={isProfile}>
              All feedback, comments, requests for technical support, and other
              communications relating to the Services or your privacy rights
              should be directed to info@onemauve.com.
            </SubDescriptionDetail>
          </SubDescription>
        </Category>
      </TermsAndConditionContainer>
    </>
  );
};
export default TermsAndConditionsContent;
