import createReducer from "../common/createReducer";

const reducers = {
  MARK_AS_READ_GROUP_MESSAGE:(state, action) => {
        return {
            messages : action.messages
        };
    }
}

const initialState = {};

export default createReducer(initialState, reducers);