import styled from "styled-components";
import { borderRadius, headerSize, panelSpacing, Scrollable } from "../../../../common/styles";
import colors from "../../../../config/colors";

export const MessageListContainer = styled.div`
    width:350px;
    background-color: ${colors.background};
    box-sizing: border-box;
    border-radius: ${borderRadius}px;
    margin-right:${panelSpacing}px;
    height:fit-content;
    border-right: 1px solid ${colors.secondary};
    min-width:350px;
    & .fixed-size-inner{
        scrollbar-width: none;
    }
    @media(max-width:1500px){
        & .fixed-size-inner{
            height: 500px !important;
        }
    }
    @media(max-width:1254px){
        & .fixed-size-inner{
            height: 410px !important;
        }
    }
    @media(max-width:991px) {
        position: fixed;
        left: ${props => props.hamburgerOpen===true ? '0%' : '-100%'};
        
        transition: 0.5s;
        border: 2px solid ${colors.secondary};
        border-left: none;
     }
     z-index: 2;
`;

export const PersonalChats = styled.div`
    height: calc(100vh - 282px);
    overflow: auto;
    scrollbar-width: none;
`

export const ConversationData = styled.div`
    display: flex;
    gap: 10px;
`

export const MessageListHeader = styled.div`
    padding:${panelSpacing}px;
    border-bottom: 1px solid ${colors.secondary};
`;
export const MessageListTitle = styled.div`
    font-size: 24px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
`;
export const MessageListButton = styled.div`
    font-size: 24px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
`;
export const Personal = styled.div`
    font-size: 24px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
   padding-inline: 5px;
   & .chat-button{
    &:hover{
        opacity: 1;
    }
   }
`;
export const Group = styled.div`
    font-size: 24px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    & .chat-button{
    &:hover{
        opacity: 1;
    }
   }
`;

export const ItemNumber = styled.span`
    margin-left: -16px;
    border-radius: 40px;
    padding: 3px;
    min-width: 0px;
    text-align: center;
    background: #44449b;
    ${props=>props.active && `
    background: ${colors.secondary};
    `}
`;
export const Messages =styled.div`
    height:calc(100vh - 290px);
    ${Scrollable}
    @media(max-width:991px) {
    height:calc(100vh - ${305+headerSize+panelSpacing*2}px);

    }
`;
export const MessageContact =styled.div`
    display: flex;
    align-items: center;
    min-height:65px;
    padding: 10px;
    justify-content: space-between;
        gap: 10px;
    border-bottom:solid 1px ${colors.secondary};
    cursor: pointer;
    ${props=>props.active && `
        background-color: ${colors.primary};
        color: ${colors.background};
        pointer-events: none;
    `}
    :last-of-type{
        border-radius:0px 0px 0px 10px;
    }
`;
export const ImageContainer =styled.div`
    min-width:60px;
    width:60px;
    border-radius:50%;
    display: flex;
    flex-wrap: wrap;
  justify-content: space-between; /* Aligns the first two images to the left and right edges */
  align-items: center; 
`;

export const ContactMemberImages =styled.img`
    height:${props=>props.width ? '45px':'25px'};
    width:${props=>props.width ? '45px':'23px'};
    border-radius:40%;
    object-fit:cover;
    position: ${props=>props.lastImage && 'relative'};
    left:${props=>props.lastImage &&  '50%'}; /* Moves the image to the center horizontally */
    transform: ${props=>props.lastImage && 'translateX(-50%)'};
`;

export const ContactImage =styled.img`
    height:45px;
    width:45px;
    border-radius:50%;
    object-fit:cover;
  
`;

export const MessageInfo =styled.div`
    padding: 0px 10px;
    min-width: 130px;
`;
export const ContactName =styled.div`
    font-size:16px;
    font-weight:bold;
    margin-bottom:5px;
`;
export const MessageSummary =styled.div`
    font-size: 14px;
`;
export const MessageTime =styled.div`
    font-size:12px;
    color: ${colors.darkBackground};

`;
export const TimeContainer =styled.div`
    display:flex;
    gap:10px;
    align-items:center;

`;
export const Indicator =styled.div`
    background-color:${props=>props.top ? '#fff' : '#44449B'};
    height:10px;
    width:10px;
    border-radius:50%;

`;

export const UserNotPartOfGroupMesssage =styled.div`
    font-size:12px;
    color: ${colors.darkBackground};
    max-width:${props=>props.isList && '126px'};
`;
export const GroupMesssageWraper =styled.div`
    height: calc(100vh - 282px);
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
`;