import React from "react";
import { redirect, useParams } from "react-router-dom";
import callAPI from "../../../../../api/apiCaller";
import Button from "../../../../../common/Button";
import { ButtonSpacer } from "../../../../../common/Button/styles";
import Modal from "../../../../../common/Modal";
import Pills from "../../../../../common/Pills";
import TextInput from "../../../../../common/TextInput";
import { updateValid, validateEmail } from "../../../../../common/utils";
import { AddParticipantsContainer, NewParticipantLine, NewParticipantActions, NewParticipantEmailContainer, AddLine, NewParticipantEmail, NewParticipantRole, AddedLines, NewParticipantRow } from "./styles";
import { Context } from "../../../../../Store";
import { getUserInfo } from "../../../../../Store/User/accessors";
import { ErrorMessage } from "../../forms/CreateFlowProgramManager/styles";
import { participantContent } from "../../../../../common/constants/constants_content";

function AddParticipants({ closeModal,program }) {
  const params=useParams();
  const [newParticipants, setNewParticipants] = React.useState([]);
  const [newParticipantLine, setNewParticipantLine] = React.useState({
    email: "",
    joinAs: program?.programType==='TEAM' ? 'member':"mentee"
  });
  const [editParticipant, setEditParticipant] = React.useState(null);
  const [editLine, setEditLine] = React.useState(null);
  const [isAdding, setIsAdding] = React.useState(false);
  const [isValidAdd, setIsValidAdd] = React.useState(false);
  const [error, setError] = React.useState('');
  const [isValidEdit, setIsValidEdit] = React.useState(false);
  const {state,dispatch} = React.useContext(Context);

  function deleteParticipant(email){
    setNewParticipants(newParticipants.filter(participant => participant.email !== email))
  }
  function addNewParticipant(newParticipant){

    if(newParticipants.find((participant)=>participant.email===newParticipant.email)){
      setError(participantContent.EXISTING_PARTICIPANT_ERROR)
      return
    }
    // newParticipants.find((participant))
    setNewParticipants([...newParticipants, newParticipantLine])

  }
  function addParticipants(){
    setIsAdding(true)
    callAPI(dispatch,"addParticipants",{programId:+params.selectedProgram, participants:newParticipants}).then(res=>{
      window.location.reload();
      if(res.data>0){
      closeModal(true,'active')
      }
      else{
      closeModal(true,'invites')

      }
      setIsAdding(false)
    });
  }

  const Options=[
    {
      label: "Mentor",
      value: "mentor"
    },
    {
      label:"Mentee",
      value:"mentee"
    },
  ]

  return <Modal title="Add Participants" onClose={() => { closeModal(false) }} actions={[
    {
      text: "Cancel",
      icon: "cross",
      onClick: () => { closeModal(false) }
    },
    {
      text:"Add Participants",
      icon:isAdding? "loading" : "add",
      onClick:addParticipants
    }
  ]}>
    <AddParticipantsContainer>
      <AddedLines>
        {
          newParticipants.map(participant => {
            if (editParticipant === participant.email) {
               <NewParticipantRow key={participant.email}>               
                <NewParticipantEmailContainer>
                  <TextInput 
                  placeholder="Enter email" 
                  value={editLine.email} 
                  setValue={(newEmail) => {
                    setEditLine({
                      ...editLine,
                      email: newEmail
                    })
                  }} 
                  isValid={isValidEdit}
                  setIsValid={setIsValidEdit}
                  validator={validateEmail}
                  />
                </NewParticipantEmailContainer>
                <NewParticipantActions>
                  <Pills
                    options={program?.programType==="TEAM" ?[{
                      label: "Member",
                      value: "member"
                    }]:[
                      {
                        label: "Mentor",
                        value: "mentor"
                      },
                      {
                        label:"Mentee",
                        value:"mentee"
                      },
                    ]}
                    value={editLine.joinAs}
                    setValue={(newRole) => {
                      setEditLine({
                        ...editLine,
                        joinAs: newRole
                      })
                    }}
                  />
                  <ButtonSpacer />
                  <Button icon="tick" variant="primary" onClick={() => {
                    setNewParticipants(newParticipants.map(participant => {
                      if (participant.email === editParticipant) {
                        return editLine
                      }
                      else {
                        return participant
                      }
                    }))
                    setEditLine(null)
                    setEditParticipant(null);
                  }}
                  
              disabled={isValidEdit!==true} />
                  <ButtonSpacer />
                  <Button icon="cross" onClick={() => {
                    setEditLine(null)
                    setEditParticipant(null);
                  }}/>
                </NewParticipantActions>
              </NewParticipantRow>
            }
            return <NewParticipantRow>
              <NewParticipantEmail>{participant.email}</NewParticipantEmail>
              <NewParticipantActions>
                <NewParticipantRole>{participant.joinAs}</NewParticipantRole>
                <ButtonSpacer />
                <Button icon="edit" variant="primary" onClick={() => {
                  setEditParticipant(participant.email)
                  setEditLine(participant)
                }} />
                <ButtonSpacer />
               
                <Button icon="trash" onClick={() =>deleteParticipant(participant.email) } />
              </NewParticipantActions>
            </NewParticipantRow>
          })
        }
      </AddedLines>
    
      <AddLine>
      {(error) &&
                <ErrorMessage>
               {error}
               {/* {newParticipantLine.joinAs} */}
             </ErrorMessage> 
             
             }
        <NewParticipantLine isColumn={true}>
          <NewParticipantEmailContainer>
        
            <TextInput placeholder="Enter email" value={newParticipantLine.email} setValue={(newEmail) => {
              
              setNewParticipantLine({
                ...newParticipantLine,
                email: newEmail
              })
            }} 
            isValid={isValidAdd}
            setIsValid={setIsValidAdd}
            validator={validateEmail}
            />
          </NewParticipantEmailContainer>
          <NewParticipantActions>
         {  <Pills
              options={program?.programType==="TEAM" ? [{    
                label: "Member",
                value: "member"
              },]:Options}
              value={newParticipantLine.joinAs}
              setValue={(newRole) => {
                setNewParticipantLine({
                  ...newParticipantLine,
                  joinAs: newRole
                })
              }}
            />}
            <ButtonSpacer />
            <Button icon="add"
            disabled={isValidAdd!==true}
            onClick={() => {
          
              if(newParticipantLine.email==getUserInfo(state).email && newParticipantLine.joinAs==='mentee'){
                console.log("SELF_USER_ERROR ", participantContent.SELF_USER_ERROR)
                setError(participantContent.SELF_USER_ERROR)
                return
              }
              
              addNewParticipant(newParticipantLine)
              setNewParticipantLine({
                email: "",
                joinAs:program?.programType==="TEAM"? 'member': "mentee"
              });
            
            }} />
          </NewParticipantActions>
        </NewParticipantLine>
      </AddLine>
    </AddParticipantsContainer>
  </Modal>;
}
export default AddParticipants;