export function getProgramsList(state){
    return state.programsList.list;
}
export function getFlatProgramsList(state){
    let a=  Object.keys(state.programsList.list).reduce((list,key)=>{
        return [...list,...state.programsList.list[key]];
    },[])
    return a;
}
export function isProgramsListLoaded(state){
    return state.programsList.isLoaded;
}
export function getProgramListRefreshToggle(state){
    return state.programsList.refetch;
}