import React from "react";
import HomeImage from './Banner.png';
import {
  HomePanelOuterContainer,
  BannerHeading,
  BannerDescription,
  BannerImage,
  HomePanelImage,
  BannerButton,
} from "./styles";
import colors from "../../../config/colors";
import Icon from "../../../common/Icon";

function HomePanel() {
  return (
    <HomePanelOuterContainer>
      <BannerHeading>
        We help organizations build robust mentorship programs that last
      </BannerHeading>
      <BannerDescription>
        We make it easy to build, manage, and track the success of your
        mentorship programs
      </BannerDescription>
      <BannerButton onClick={()=>{
        window.location.href="/app";
      }}>
        Create an account
        <Icon type="right-arrow" color={colors.background} size={20}/>
      </BannerButton>
      <BannerImage>
        <HomePanelImage src={HomeImage} alt="Banner Image" />
      </BannerImage>
    </HomePanelOuterContainer>
  );
}
export default HomePanel;
