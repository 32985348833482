import styled from "styled-components";
import colors from "../../../../../config/colors";
import { Scrollable } from "../../../../../common/styles";

export const ReassignMenteeContainer = styled.div`
    width:${props=>props.width ? props.width :'40vw'};
    overflow-y: scroll;
    ${Scrollable};
    height: ${props=>props.height ? props.height :'80vh'};
`;
export const MentorRow = styled.div`
padding:10px;
display:flex;
align-items:center;
justify-content:space-between;
border-bottom: 1px solid ${colors.secondary};

@media (max-width:640px) {
   flex-direction:column;
   gap:5px;
   align-items: flex-start;
}
`;

export const MentorInfo = styled.div`
    display: flex;
    align-items: center;
`;
export const MentorImage = styled.img`
    height:40px;
    width:40px;
    object-fit:cover;
    border-radius:50%;
`;
export const MentorName = styled.div`
    margin-left:10px;
`;