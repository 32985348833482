import styled from "styled-components";
import { borderRadius, panelSpacing } from "../../../../common/styles";
import colors from "../../../../config/colors";

export const ProgramContentContainer = styled.div`
    background-color: ${colors.background};
    border-radius: ${borderRadius}px;
    width: calc(100vw - 350px - ${panelSpacing*3}px);
    @media(max-width:991px) {
        width: 100%;
    }
`;
export const SelectContainer = styled.div`
    width:100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const JoinOrApplyButtonContainer = styled.div`
    width:100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column
`;

export const LabelProgramDashboard = styled.p`
    justify-content: center;
    align-items: center;
    font-weight: 600;
    margin-top:30px;
`;

export const LabelButton = styled.button`
    justify-content: center;
    align-items: center;
    font-weight: 600;
`;

export const JoinPorgramContainer= styled.div`
width: 80vh;
@media (max-width:767px){
width: 100%;
}

`;

export const TabsContainer= styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    flex-direction: row-reverse;
    @media (max-width:991px) {
        flex-direction: row;
    }
`

export const HamburgerIconContainer=styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    @media (max-width:991px) {
    margin-top: 12px;
    }
    
`

export const NoProgramContainer=styled.div`
   padding: 20px;
   position: absolute;
    top: 55px;
    right: 11px;
    
`

export const HamburgerIconContainerSpan=styled.span`
  width: 30px;
  height: 4px;
  background-color: #44449B;
  display: none;
  @media (max-width:991px) {
    display: block;
  }
`
export const ProgramTabContainer=styled.div`
    overflow-x: auto;
    margin-left: 20px;
  @media (max-width:991px) {

  }
`