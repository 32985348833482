import { validateText } from "../../../../../common/utils";

export function getIsValid(application){
    let newIsValid={};
    ["mentor","mentee"].forEach(key => {
        let isValid = false;
        if(!application.accepts[key]){
            isValid = true
        }
        else if (application[key].every(question=>validateText(question.question))){
            isValid = true
        }
        newIsValid[key] = isValid
    })
    return newIsValid
}