import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Context } from "../../../../Store";
import { refreshProgramList } from "../../../../Store/ProgramsList/actions";
import callAPI from "../../../../api/apiCaller";
import Button from "../../../../common/Button";
import { ButtonSpacer } from "../../../../common/Button/styles";
import { ImagePreview } from "../../../../common/ImageUpload/styles";
import Labelize from "../../../../common/Labelize";
import Loading from "../../../../common/Loading";
import Modal from "../../../../common/Modal";
import Tabs from "../../../../common/Tabs";
import TextInput from "../../../../common/TextInput";
import { validateUrl } from "../../../../common/utils";
import { ButtonContainer } from "../../Header/Notifications/styles";
import { SectionActions } from "../../Profile/LoginSecurity/styles";
import AddProgram from "../popups/AddProgram";
import ProgramHome from "./ProgramHome";
import { programContentConfig } from "./config";
import {
  JoinOrApplyButtonContainer,
  JoinPorgramContainer,
  LabelProgramDashboard,
  ProgramContentContainer,
  HamburgerDiv,
  TabsContainer,
  HamburgerIconContianer,
  HamburgerIconContainerSpan,
  HamburgerIconContainer,
  NoProgramContainer,
  ProgramTabContainer,
} from "./styles";
import homeImage from "./home-program.svg";
import Requests from "./ProgramRequests";

function ProgramContent(props) {
  let params = useParams();
  let navigate = useNavigate();
  const [program, setProgram] = React.useState(null);
  const { state, dispatch } = React.useContext(Context);
  const [showAddProgram, setShowAddProgram] = React.useState(false);
  const [urlValid, setIsUrlValid] = React.useState(false);
  const [programUrl, setProgramUrl] = React.useState("");
  const [joinProgram, setJoinProgram] = React.useState(false);
  const [hamburgerOpen, setHamburgerOpen] = React.useState(false);
  React.useEffect(() => {
    retrieveProgramInformation();
  }, [params.selectedProgram]);
  function retrieveProgramInformation() {
    setProgram(null);
    params.selectedProgram &&
      callAPI(dispatch, "getProgramInformation", {
        id: params.selectedProgram,
      }).then((info) => {
        setProgram(info.data);
      });
  }

  if (params.selectedProgram === undefined) {
    return (
      <ProgramContentContainer>
        <Requests />
        {/* <ButtonContainer>
        <Button>Join Program</Button>
      </ButtonContainer> */}
        <NoProgramContainer>
          <HamburgerIconContainer
            onClick={() => props.setHamburgerOpen(!props.hamburgerOpen)}
          >
            <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
            <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
            <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
          </HamburgerIconContainer>
        </NoProgramContainer>

        {/* <ImagePreview  src={homeImage} panelSpacing={'0'}></ImagePreview>
          <LabelProgramDashboard>Lets Get Started</LabelProgramDashboard> */}
        <ButtonContainer>
          {/* <Button
             onClick={() => {setShowAddProgram(true)}}
             text="Create program" variant="secondary"
            />
              <ButtonSpacer />
              <ButtonSpacer />
            <Button
              onClick={() => {setJoinProgram(true)}}
              text="Join program" variant="primary"
            /> */}
          {showAddProgram && (
            <AddProgram
              closeModal={(shouldRefresh) => {
                if (shouldRefresh) {
                  dispatch(refreshProgramList());
                }
                setShowAddProgram(false);
              }}
            />
          )}

          {/* {
    joinProgram && 
    <Modal
        title={"Join a program"}
        onClose={() => {
          setJoinProgram(false)
        }}
      >
        <JoinPorgramContainer>
          <Labelize label="Paste the link for the program you would like to join space below">
          <TextInput 
            placeholder="Paste your link here"
            value={programUrl} 
            type="text"
            isValid={validateUrl["text"]}
            validator={validateUrl}
            setValue={(newValue) => {
              setIsUrlValid(validateUrl(newValue))
              setProgramUrl(newValue)
          }} />
        </Labelize>
        <SectionActions>
        <Button icon={"cross"} variant="secondary" onClick={()=>setJoinProgram(false)}  text="Cancel" />
        <ButtonSpacer />
        <Button icon="tick" variant="primary"  disabled={urlValid ?false:true}  onClick={()=>window.location.href=programUrl} text="Save" />

      </SectionActions>
      </JoinPorgramContainer>
        </Modal>
    
  } */}
        </ButtonContainer>
      </ProgramContentContainer>
    );
  } else if (program === null) {
    return <Loading loadingItem="Program" />;
  } else if (program.enrollment && program.enrollment != "active") {
    return (
      <ProgramContentContainer>
        <ProgramHome program={program} />
      </ProgramContentContainer>
    );
  }
  // Self service has all Mentors tab.
  // Program manager has different tabs
  else {
    // let options = programContentConfig[program.type][program.role];
    // console.log("program.role ", program.role)
    let options = programContentConfig[program.programType][program.role];
  //  console.log(options,"options");
    let CurrentComponent =options && options.find(
      (option) => option.value === params.tab
    ).component;


    return (
      <ProgramContentContainer>
        <TabsContainer>
          <HamburgerIconContainer
            onClick={() => props.setHamburgerOpen(!props.hamburgerOpen)}
          >
            <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
            <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
            <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
          </HamburgerIconContainer>
          <ProgramTabContainer>
          <Tabs
            value={params.tab}
            setValue={(newTab) => {
              navigate("/programs/" + params.selectedProgram + "/" + newTab);
            }}
            options={options}
            isWidth={true}
          />
          </ProgramTabContainer>
        </TabsContainer>
        <CurrentComponent
          program={program}
          refreshProgram={retrieveProgramInformation}
        />
      </ProgramContentContainer>
    );
  }
}
export default ProgramContent;
