import styled from "styled-components";

export const LoadingContainer = styled.div`
    display: flex;
    width: 100%;
    height:${props=>props.isLoading ? '10%' :'100%'};
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding:${props=>props.isLoading ?'0px' :'40px 0px'};
`;
export const LoadingText=styled.div`
    margin-left:10px;
`;