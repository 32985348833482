import React, { useEffect, useState } from "react";
import {
  PaymentInfo,
  BillingTypo,
  ButtonWraper,
  CardInfo,
  PaymentLabel,
  ProgramItem,
  LabelWraper,
  Invoices,
  ProgramLoaderContainer,
  NoBillingHistoryContainer,
  Subtotal,
} from "./styles";
import Button from "../../../../common/Button";
import callAPI from "../../../../api/apiCaller";
import { Context } from "../../../../Store";
import { formatDate } from "../../../../common/utils";
import Loading from "../../../../common/Loading";

const Billing = () => {
  const { state, dispatch } = React.useContext(Context);
  const [billingHistory, setBillingHistory] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [disableButtonId, setDisableButtonId] = useState("");
  const token = localStorage.getItem("accessToken");
  function getBillingHistory() {
    callAPI(dispatch, "billingHistory", {}).then((res) => {
      setBillingHistory(res.data);
    });
  }
  const handleReceipt = (id) => {
    setDisableButtonId(id);
    setDataLoading(true);
    fetch(`https://onemauve.com/api/v1/paypal/receipt/${id}`, {
      headers: {
        "content-type": "application/pdf",
        Authorization: token && `Bearer ${token}`,
      },
    })
      .then((response) => response.blob())
      .then((blobData) => {
        setDataLoading(false);
        const dataUri = URL.createObjectURL(blobData);
        const anchor = document.createElement("a");
        anchor.href = dataUri;
        anchor.target = "_blank";
        anchor.click();

        URL.revokeObjectURL(dataUri);
      })
      .catch((error) => {
        console.error("Error fetching PDF:", error);
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getBillingHistory();
  }, []);

  if (billingHistory === null) {
    return (
      <ProgramLoaderContainer>
        <Loading loadingItem="Billing History" />
      </ProgramLoaderContainer>
    );
  }
  return (
    <PaymentInfo>
      {!billingHistory.length ? '': <BillingTypo>Billing Information</BillingTypo>}
      <Invoices>
        {!billingHistory.length && (
          <NoBillingHistoryContainer>
            You have not made any purchases yet
          </NoBillingHistoryContainer>
        )}
        {billingHistory?.map((item) => {
          return (
            <CardInfo>
              <PaymentLabel size={14}>
                Order#:{item?.transactionId} <br /> Date:
                {formatDate(item?.paymentBody?.create_time).toLocaleString()}
              </PaymentLabel>
              <ProgramItem>{item?.perk}</ProgramItem>
              <ButtonWraper space="space-between">
                <LabelWraper>
                  <Subtotal left={5} >Total:</Subtotal>
                  <Subtotal left={5}>{item.paymentBody.resource.purchase_units[0].amount.value + "$"}</Subtotal>
                </LabelWraper>
                <Button
                  text="View Receipt"
                  variant="primary"
                  width={30}
                  onClick={() => handleReceipt(item.id)}
                  disabled={item.id === disableButtonId && dataLoading}
                  icon={
                    item.id === disableButtonId && dataLoading ? "loading" : ""
                  }
                />
              </ButtonWraper>
            </CardInfo>
          );
        })}
      </Invoices>
    </PaymentInfo>
  );
};

export default Billing;
