import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import callAPI from "../../../../api/apiCaller";
import Button from "../../../../common/Button";
import { ButtonSpacer } from "../../../../common/Button/styles";
import Loading from "../../../../common/Loading";
import TextInput from "../../../../common/TextInput";
import { isResponsive, validateText } from "../../../../common/utils";
import { Context } from "../../../../Store";
import ChatBubble from "./ChatBubble";
import DateLine from "./DateLine";
import {
  ChatWindowContainer,
  ChatHeader,
  ChatUser,
  UserImage,
  ChatUserInfo,
  UserName,
  UserStatus,
  UserStatusDot,
  UserStatusText,
  ChatBody,
  ChatContent,
  NewMessage,
  PastLoadingContainer,
  NoChatCOntentContainer,
  LoadingContainer,
  ChatContainerOverall,
} from "./styles";
import { amIOnBottomOfScroll, isActiveChat, scrollToBottom, scrollToPosition, updateMessages } from "./utils";
import { socket } from "../../../../common/chat-socket";
import {
  HamburgerIconContainer,
  HamburgerIconContainerSpan,
} from "../../Programs/ProgramContent/styles";
import { getUserInfo } from "../../../../Store/User/accessors";
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px); /* Adjust the initial position */
  }

  to {
    opacity: 1;
    transform: translateY(0); /* Adjust the final position */
  }
`;

const ChatBubbleContainer = styled.div`
  animation: ${fadeIn} 0.5s ease; /* Adjust the animation duration */
`;


function ChatWindow(props) {
  const [user, setUser] = React.useState(null);
  const [currentReceiverId, setCurrentReceiverId]=useState(null)
  const [messages, setMessages] = React.useState(null);
  const params = useParams();
  const [newMessage, setNewMessage] = React.useState("");
  const [conversationId, setConversationId] = React.useState(null);
  const [isNewMessageValid, setIsNewMessageValid] = React.useState(null);
  const [rePollTick, setRePollTick] = React.useState(false);
  const [shouldScrollToBottom, setShouldScrollToBottom] = React.useState(true);
  const polling = React.useRef(null);
  const chatWindow = React.useRef(null);
  const allWindow = React.useRef(null);
  const [isLoadingInitial, setIsLoadingInitial] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { state, dispatch } = React.useContext(Context);
  const [page, setPage] = React.useState(2);
  const [limit ,setLimit]=React.useState(30);
  const [totalPage ,setTotalPage]=React.useState(null);
  const [scroll ,setScroll]=React.useState(false);


  const storeUser = (id) => {
    socket.emit("store_user", id);
  };
  function handleSubmit() {
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        user: getUserInfo(state),
        message: newMessage,
        createdAt: new Date(),
      },
    ]);
    socket.emit("privateMessage", {
      
      user: getUserInfo(state),
      recipientId: currentReceiverId,
      message: newMessage,
      conversationId: conversationId,
    }, 
    );
    setNewMessage("");
    props.setRefresh(!props.refresh);
    scrollToBottom(chatWindow);
    // scrollToPosition(chatWindow.current, "bottom");
  }

  const EnterPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const messageWithTimeProperty = (message) => {
    const notificationPayload = [];
    message.forEach((message) => {
      const dateObject = new Date(message.createdAt);
      const unixTimestamp = Math.floor(dateObject.getTime() / 1000); // Convert milliseconds to seconds
      message["time"] = unixTimestamp;
      notificationPayload.push(message);
    });
    return notificationPayload;
  };

  const handleScroll = async (e) => {
    const { scrollTop } = e.target;
  if (scrollTop === 0 && !loading) {
    if(totalPage < page) return;
    const prevScrollHeight = chatWindow.current.scrollHeight;
    setShouldScrollToBottom(false)
      setPage(page => page+1); // Increment page
        setLoading(true);

        try {
            // Fetch more messages with pagination parameters
            const res = await callAPI(dispatch, "getChat", { id: params.user, page: page , take: limit });
            // Append new messages to the existing message list
            setMessages(prevMessages => [...messageWithTimeProperty(res.data.messages), ...prevMessages]);
            // const newScrollHeight =
            // chatWindow.current.scrollHeight - prevScrollHeight;
        } catch (error) {
            // Handle errors
            console.error('Error fetching messages:', error);
        } finally {
          requestAnimationFrame(() => {
            const newScrollHeight = chatWindow.current.scrollHeight;
            chatWindow.current.scrollTop = newScrollHeight - prevScrollHeight;
          });
            setLoading(false);
        }
    }
};

  async function getConversation() {
    if (!params.user) return;

    try {
        const userData = await callAPI(dispatch, "getUser", { id: params.user });
        if (!userData.isResponseOk || Object.keys(userData.data).length === 0) {
            setConversationId(null);
            setMessages([]);
            setIsLoadingInitial(false);
            return;
        }

        setUser(userData.data);
        setCurrentReceiverId(userData?.data?.id)

        const conversationPayload = await callAPI(dispatch, "createNewConversation", {
            message: newMessage,
            email: userData.data.email,
        });
        setConversationId(conversationPayload.data?.conversationId);

        const chatData = await callAPI(dispatch, "getChat", { id: params.user, page: 1, take: limit });
        setMessages(messageWithTimeProperty(chatData.data.messages));
        setTotalPage(chatData.data.totalPages)
        setIsLoadingInitial(false);
    } catch (error) {
        console.error("Error fetching conversation:", error);
    }
}

  React.useLayoutEffect(() => {
    if(shouldScrollToBottom && chatWindow.current)
    {
      scrollToBottom(chatWindow);
      // setIsRender(true)
    }
    if (chatWindow.current) {
      const bottomMessage = chatWindow.current.lastChild;
      if (bottomMessage) {
        // bottomMessage.scrollIntoView({ behavior: "smooth", block: "end"  });
        scrollToBottom(chatWindow)
      }
    }
   

}, [ params.user, messages]);


useEffect(()=>{
  if(newMessage?.length > 30  ){
    scrollToBottom(allWindow)

  }
},[newMessage])

  React.useEffect(() => {
    // Listen for incoming messages from the server
    socket.on("privateMessage", (msg) => {
      msg.createdAt = new Date();
      const selectUser = window.location.pathname.split("/").pop();
      //if current message is for selected user then add it to his/her message list
      if (msg.user.uuid === selectUser) {
        msg["createdAt"] = new Date();
        props.setRecieverData(msg)
        setMessages((prevMessages) => [...prevMessages, msg]);
        // scrollToPosition(chatWindow.current, "bottom");
      // scrollToBottom(chatWindow);

      }
    });


  }, []);

  React.useEffect(() => {
    setUser(null);
    setMessages([]);
    setIsLoadingInitial(true);
    socket.on("disconnect", () => {
      console.log("Disconnected from server");
    });
    clearInterval(polling.current);
    setUser(null);
    setMessages([]);
    getConversation();
    // params.user/
    setNewMessage("");
    return () => {
      setMessages([]);
      setConversationId(null);
      setPage(2)
    };
  }, [params.user]);

  // React.useEffect(() => {
  //   if (rePollTick) {
  //     let shouldScroll = isActiveChat(chatWindow.current);

  //     callAPI(dispatch, "getChat", { id: params.user, page:page ,take:limit}).then((res) => {
  //       setRePollTick(false);
  //       // if (res.user.id !== user.id || isLoadingInitial) {
  //       //   setMessages([])
  //       //   return;
  //       // }
  //       // else {
  //       setUser({
  //         ...user,
  //         ...res.user,
  //       });
  //       setMessages(
  //         messages != undefined &&
  //           messages?.length &&
  //           updateMessages(
  //             messages,
  //             messageWithTimeProperty(res.data.messages)
  //           )
  //       );
  //       if (shouldScroll && isActiveChat(chatWindow.current)) {
  //   // scrollToBottom(chatWindow);

  //       }
  //       // }
  //     });
  //   }
  //   return () => {};
  // }, [rePollTick]);
  // function handleScroll(e) {
  //   // if (!user.loadedAll && (e.target.scrollTop < 100) && !isLoadingPast) {
  //   //   setIsLoadingPast(true);
  //   //   callAPI(dispatch,"getPastChat").then(res => {
  //   //     setIsLoadingPast(false);
  //   //     setUser({
  //   //       ...user,
  //   //       ...res.user
  //   //     });
  //   //     setMessages(messages.length && updateMessages(messages));
  //   //     scrollToPosition(chatWindow.current, "top");
  //   //   })
  //   // }
  // }
  if (!params.user) {
    return (
      <>
        <ChatWindowContainer isHeading={true}>
          <NoChatCOntentContainer>
            <HamburgerIconContainer
              onClick={() => props.setHamburgerOpen(!props.hamburgerOpen)}
            >
              <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
              <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
              <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
            </HamburgerIconContainer>
          </NoChatCOntentContainer>
          Select contact to view chat
        </ChatWindowContainer>
      </>
    );
  }
  if (isLoadingInitial || user === null) {
    return (
      <ChatWindowContainer>
        <Loading loadingItem="Messages" />
      </ChatWindowContainer>
    );
  }
  return (
    <>
      { Object.keys(user).length>0 ?
        
        <ChatWindowContainer>
          <ChatHeader>
            <ChatUser>
              <HamburgerIconContainer
                onClick={() => props.setHamburgerOpen(!props.hamburgerOpen)}
              >
                <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
                <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
                <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
              </HamburgerIconContainer>
              <UserImage
                src={
                  user?.image
                    ? user?.image
                    : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                }
              />
              <ChatUserInfo>
                <UserName>
                  {user?.firstName + " "}
                  {user?.lastName}
                </UserName>
                <UserStatus>
                  <UserStatusDot status={user?.status} />
                  <UserStatusText>{user?.status}</UserStatusText>
                </UserStatus>
              </ChatUserInfo>
            </ChatUser>
          </ChatHeader>
          <ChatContainerOverall ref={allWindow} isPersonal={true}>
          <ChatBody>
            <ChatContent isPersonal={true} onScroll={(e)=>handleScroll(e)} ref={chatWindow}>
              {loading && <Loading loadingItem="Chats" isLoading={true}/>}
              {messages?.length   ?
                 messages.map((message) => {
                //   if ( loading) {
                //     return    <Loading loadingItem="Messages"  />; // Show loader at the end
                // } else {
                    // if (message.type === "date") {
                    //   return <DateLine dateString={message.sender.createdAt} />
                    // }
                    // else {
                    return (
                      <ChatBubbleContainer>
                      <ChatBubble message={message} userImage={user.image} />
                      </ChatBubbleContainer>
                    );
                  // }
                    
                    // }
                  })
                : ""}
            </ChatContent>
          </ChatBody>
          <NewMessage>
         {isResponsive() ?   <TextInput
              placeholder="Type a message"
              value={newMessage}
              setValue={setNewMessage}
              isValid={isNewMessageValid}
              setIsValid={setIsNewMessageValid}
              validator={validateText}
              onKeyDown={EnterPress}
            isTextArea={true}
            // isTextArea={newMessage.length > 30 ? true: false}
            isHeight={newMessage?.length > 30 ? true : false}

            />
            :
            <TextInput
              placeholder="Type a message"
              value={newMessage}
              setValue={setNewMessage}
              isValid={isNewMessageValid}
              setIsValid={setIsNewMessageValid}
              validator={validateText}
              onKeyDown={EnterPress}
              isTextArea={true}
            isHeight={newMessage?.length > 180 ? true : false}
            />
            }
            <ButtonSpacer />
            <Button
              icon="send"
              variant="primary"
              disabled={!isNewMessageValid}
              onClick={handleSubmit}
            />
          </NewMessage>
          </ChatContainerOverall>
        </ChatWindowContainer>
        : <>
        <ChatWindowContainer>
          <NoChatCOntentContainer>
            <HamburgerIconContainer
              onClick={() => props.setHamburgerOpen(!props.hamburgerOpen)}
            >
              <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
              <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
              <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
            </HamburgerIconContainer>
          </NoChatCOntentContainer>
          user is not accessible
        </ChatWindowContainer>
      </>
      }
    </>
  );
}
export default ChatWindow;
