import React, { useEffect } from "react";
import { CreateFlowProgramManagerContainer, ErrorMessage } from "./styles";
import { ManagerName, ProgramManagerInfo, ProgramManagerLine } from "../../popups/EditProgramManagers/styles";
import { validateEmail } from "../../../../../common/utils";
import Button from "../../../../../common/Button";
import TextInput from "../../../../../common/TextInput";
import Labelize from "../../../../../common/Labelize";
import { getUserInfo } from "../../../../../Store/User/accessors";
import { Context } from "../../../../../Store";

function CreateFlowProgramManager({programForm,setProgramForm}) {
  const {state,dispatch} = React.useContext(Context);
  const [newProgramManagerEmail,setNewProgramManagerEmail] = React.useState("");
  const [isValid,setIsValid] = React.useState(null);
  return <CreateFlowProgramManagerContainer>
    <Labelize label={"Additional Program Managers"}/>
    {
        programForm.programManagers.map((programManager) => {
          return <ProgramManagerLine>
            <ProgramManagerInfo>
              <ManagerName>{programManager}</ManagerName>
            </ProgramManagerInfo>
            <Button 
              icon={"cross"} 
              onClick={() => {
              setProgramForm({
                ...programForm,
                programManagers:programForm.programManagers.filter(pm=>pm!=programManager)
              })
            }} />
          </ProgramManagerLine>
        })
      }

{newProgramManagerEmail === getUserInfo(state).email && (
            <ErrorMessage>
              Cannot add your own email as a program manager.
            </ErrorMessage>
          )}
      <ProgramManagerLine>
        <ProgramManagerInfo>
          <TextInput
            value={newProgramManagerEmail}
            setValue={setNewProgramManagerEmail}
            placeholder="Enter New Program Manager Email"
            isValid={isValid}
            validator={validateEmail}
            setIsValid={setIsValid}
          />
         
        </ProgramManagerInfo>
        
        <Button 
        icon={"add"} 
        onClick={()=>{
          
          if(newProgramManagerEmail=== getUserInfo(state).email){
            return
          }
          setProgramForm({
            ...programForm,
            programManagers:[...programForm.programManagers,newProgramManagerEmail]
          });
          setNewProgramManagerEmail("");
        }}
        disabled={!isValid}
         />
      </ProgramManagerLine>
    
  </CreateFlowProgramManagerContainer>;
}
export default CreateFlowProgramManager;