import React from "react";
import ProgramsListItem from "./ProgramsListItem";
import { ProgramsListSectionContainer, FolderName, FolderPrograms } from "./styles";

function ProgramsListSection({ folder, programs, moveProgram,searchText,setHamburgerOpen,hamburgerOpen }) {
 
  return <ProgramsListSectionContainer
  onDrop={(e) => {
    e.preventDefault();
    moveProgram(e.dataTransfer.getData("text"),folder);
  }}
  onDragOver={(e)=>{
    e.preventDefault();
  }}>
    {folder != "" && <FolderName>{folder}</FolderName>}
    <FolderPrograms>
      {programs
        .filter(program=>
            searchText==="" || program.title.toLowerCase().includes(searchText.toLowerCase())
        )
        .map(program =>
        <ProgramsListItem
          key={program.id}
          program={program}
          setHamburgerOpen={setHamburgerOpen}
            hamburgerOpen={hamburgerOpen}
        />)}
    </FolderPrograms>
  </ProgramsListSectionContainer>;
}
export default ProgramsListSection;