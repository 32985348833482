import styled from "styled-components";
import colors from "../../../../../config/colors";
import { Scrollable } from "../../../../../common/styles";

export const UserInfoContainer = styled.div`
    max-height:60vh;
    width:50vw;
    ${Scrollable}
`;

export const SubHeading = styled.div`
    font-weight:bold;
    color:${colors.primary};
    margin:10px 0px;
`;