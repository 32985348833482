import React from "react";
import { UserBrowserContainer } from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import Browser from "../Browser";
import { formatDate } from "../../../common/utils";
import User from "./User";

function UserBrowser() {
  const params=useParams();
  const navigate=useNavigate();
  if(!params.userId){
    return <Browser
    config={{
      item:"Users",
      api:"getAdminUsers",
      filters:[
        {
          key:"name",
          label:"Name",
        },
        {
          key:"email",
          label:"Email",
        },
      ],
      columns:[
        {
          title: "Name",
          key: "name",
        },
        {
          title: "Email",
          key: "email",
        },
        {
          title: "Status",
          key: "status",
        },
        {
          title: "Number Of Programs",
          key: "numberOfPrograms",
        },
        {
          title: "Total Tracked Hours",
          key: "totalTrackedHours",
        },
        {
          title: "Created On",
          key: "createdOn",
          render: ({ value}) => {
            return formatDate(value)
          }
        },
      ],
      rowActions:[
        {
          icon:"next",
          variant:"primary",
          onClick:(user)=>()=>{
            navigate(`/userbrowser/${user.id}`)
          }
        },
      ],
      getRows:(data)=>{
        return data
      }
    }}
    />
  }
  return <UserBrowserContainer>
    <User userId={params.userId}/>
    {/* <Program id={params.programId}/> */}
  </UserBrowserContainer>;
}
export default UserBrowser;