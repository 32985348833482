import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { formatDate } from "../../../common/utils";
import Browser from "../Browser";
import { ProgramBrowserContainer,Actions } from "./styles";
import Program from "./Program";

function ProgramBrowser() {
  const params=useParams();
  const navigate=useNavigate();
  if(!params.programId){
    return <Browser
    config={{
      item:"Programs",
      api:"getAdminPrograms",
      filters:[
        {
          key:"name",
          label:"Program Name",
        },
        {
          key:"organization",
          label:"Organization",
        },
        {
          key:"createdBy",
          label:"Created By",
        },
      ],
      columns:[
        {
          title: "Organization",
          key: "organization",
        },
        {
          title: "Program Name",
          key: "name",
        },
        {
          title: "Created By",
          key: "createdBy",
          render: ({ value}) => {
            return value.name
          }
        },
        {
          title: "Created On",
          key: "createdOn",
          render: ({ value}) => {
            return formatDate(value)
          }
        },
        {
          title: "Number of Participants",
          key: "noOfParticipants",
        },
        {
          title: "Total Tracked Hours",
          key: "totalTrackedHours",
        },
      ],
      rowActions:[
        {
          icon:"next",
          variant:"secondary",
          onClick:(program)=>()=>{
            navigate(`/programbrowser/${program.id}`)
          }
        },
      ],
      getRows:(data)=>{
        return data
      }
    }}
    />
  }
  return <ProgramBrowserContainer>
    <Program id={params.programId}/>
  </ProgramBrowserContainer>;
}
export default ProgramBrowser;