import styled from "styled-components";
import colors from "../../../../config/colors";
import { panelSpacing } from "../../../../common/styles";

export const LoginSecurityContainer = styled.div``;



export const SecuritySection = styled.div`
    padding:${panelSpacing}px;
    border-bottom:1px solid ${colors.secondary};
`;
export const SectionHeader = styled.div`
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 6px;
`;
export const SectionText = styled.div`
    font-size: 16px;
`;
export const SectionActions = styled.div`
    display:flex;
    align-items: center;
    justify-content: flex-end;
    margin-top:10px;
`;
export const SecuritySectionHeader=styled.div`
@media(max-width:991px){
    display: flex;
    gap: 5px;
}
`