import styled from "styled-components";
import { Scrollable } from "../../../../../common/styles";
import colors from "../../../../../config/colors";

export const TaskMembersContainer = styled.div`
    width: 100%;
    max-height:70vh;
    ${Scrollable}
`;

export const MemberRow = styled.div`
    padding:10px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    border-bottom: 1px solid ${colors.secondary};
    gap:25px;
`;
export const MemberInfo = styled.div`
    display: flex;
    align-items: center;
`;
export const MemberImage = styled.img`
    height:40px;
    width:40px;
    object-fit:cover;
    border-radius:50%;
    opacity: ${props=>props.disabled && '0.5'}; 
`;
export const MemberName = styled.div`
    color:${props=>props.color ? colors.primary :''};
    text-align:center;
`;
export const Span = styled.span`
display:block
`;
export const MemberActions = styled.div`
    display:flex;
    align-items:center;
`;
export const Container = styled.div`
  position: relative;
  display: inline-block;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
  color: red;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;