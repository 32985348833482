import styled from "styled-components";
import { Scrollable } from "../../../../../common/styles";

export const EditProgramContainer = styled.div`
width:60vw;
    max-height:70vh;
    ${Scrollable}
    padding-right:30px;
    @media(max-width:767px){
        width:${props=>props.isEditModal ? "100%":'98%'};
        padding-right:${props=>props.isEditModal && '20px'};
        height: calc(100% - 100px);
        
    }
    
    `;