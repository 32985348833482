import React, { useEffect, useState } from "react";
import ChatWindow from "./ChatWindow";
import MessageList from "./MessageList";
import { MessagesContainer } from "./styles";
import { Context } from "../../../Store";
import callAPI from "../../../api/apiCaller";
import { markAsReadGroupMessage } from "../../../Store/GroupMessageCount/actions";
import { getPersonalUnreadMsg } from "../../../Store/PersonalMessageCount/accessors";

function Messages({chats, setChats}) {
  const [hamburgerOpen, setHamburgerOpen]= React.useState(false)
  const [refresh, setRefresh] = React.useState(false)
  const { state, dispatch } = React.useContext(Context);
  const [isMessageGet , setIsMessageGet] = useState(false)
  const [receiverData , setRecieverData] = useState(null);


  const personalMessageIndicator = getPersonalUnreadMsg(state);


  useEffect(()=>{

    callAPI(dispatch, "getGroupMessageList").then((res) => {
      dispatch(markAsReadGroupMessage(res.data))
    });

  },[])

  return <MessagesContainer>
    <MessageList receiverData={receiverData} isMessageGet={isMessageGet} setHamburgerOpen={setHamburgerOpen} hamburgerOpen={hamburgerOpen} refresh={refresh} personalMessageIndicator={personalMessageIndicator}/>
    <ChatWindow setRecieverData={setRecieverData} setIsMessageGet={setIsMessageGet}  setHamburgerOpen={setHamburgerOpen} hamburgerOpen={hamburgerOpen}  setRefresh={setRefresh} refresh={refresh}/>
  </MessagesContainer>;
}
export default Messages;