import React, { useContext, useEffect } from "react";
import { ToastsContainer,ToastContainer,ToastMessage,IconContainer } from "./styles";
import { Context } from "../../Store";
import { deleteToast, refreshToasts } from "../../Store/Toasts/actions";
import Icon from "../Icon";
import colors from "../../config/colors";


function Toast() {
  const {state,dispatch} = useContext(Context);
  let toasts=state.toast;
  return <ToastsContainer>
    {
      toasts.map(toast=>{
        let escape = toast.expiry- 100 < Date.now() ;
        return <ToastContainer key={toast.id} type={toast.type} escape={escape}>
          <IconContainer>
          <Icon 
          size="20px" 
          type={toast.type === "inProgress" ? "loading" : toast.type == "success" ? "tick" : "cross"} 
          color={colors.background}
          />

          </IconContainer>
          <ToastMessage>{toast.message}</ToastMessage>

          {toast.type!== "inProgress" && <IconContainer onClick={() => dispatch(deleteToast(toast.id))}>
            <Icon 
          size="10px" 
          type={"cross"} 
          color={colors.background}
          />
         
          </IconContainer>
      }
          </ToastContainer>
      })
    }
  </ToastsContainer>;
}
export default Toast;