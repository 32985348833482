import styled from "styled-components";
import colors from "../../config/colors";

export const LabelizeContainer = styled.div`
    margin-bottom:5px;
    ${props=>props.horizontal && `
        display:flex;
        align-items:center;
        margin:0px;
        justify-content:space-between;
        
    `}
`;
export const Label=styled.div`
    font-size:12px;
    font-weight:bold;
    margin-bottom:5px;
    color:${colors.primary};
    ${props=>props.horizontal && `
        font-size:14px;
        margin-left:5px;
    `}
`;

export const RedText = styled.span`
  color: red;
  font-weight:500
`;