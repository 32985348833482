import styled from "styled-components";
import { borderRadius, headerSize, panelSpacing } from "../../../common/styles";
import colors from "../../../config/colors";

export const ProfileContainer = styled.div`
    padding:${panelSpacing}px;
    display: flex;
    align-items: center;
    justify-content:space-between;
    background-color: ${colors.secondary};
    height:calc(100vh - ${headerSize}px);
    width:100vw;
    box-sizing: border-box;
`;
export const ProfileContent=styled.div`
    background-color: ${colors.background};
    width:calc(100vw - 350px - ${panelSpacing*3}px);
    border-radius: ${borderRadius}px;
    height:100%;
    overflow: hidden;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        width: 10px;
    }
    box-sizing: border-box;
    @media (max-width: 991px) {
        width: 100%;
    }
 `;