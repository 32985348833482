import React from "react";
import { CompletionContainer,MainText,SubText,LinkLine,Link } from "./styles";
import Button from "../../../../../../common/Button";

function Completion({newProgramId}) {
  let url = window.location.protocol + "//" + window.location.host + "/app/programs/"+newProgramId+"/home";
  const [hasCopied,setHasCopied] = React.useState(false)
  return <CompletionContainer>
    <MainText>Congratulations! Your program has successfully been created!</MainText>
    <SubText>Share the link below and/or manually add members to your community</SubText>
    <LinkLine>
    <Link href={url}>{url}</Link>
    <Button icon={hasCopied ? "tick" : "copy"} text="Copy" onClick={()=>{
        navigator.clipboard.writeText(url)
        setHasCopied(true)
    }}/>
    </LinkLine>
  </CompletionContainer>;
}
export default Completion;