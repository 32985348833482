import React, { useState } from "react";
import {
  Heading,
  PersonalContainer,
  PremiumContainer,
  PremiumInfo,
  SubHeading,
  TeamContainer,
} from "./styles";
import Button from "../../../../../common/Button";
import Icon from "../../../../../common/Icon";
import colors from "../../../../../config/colors";
import Modal from "../../../../../common/Modal";
import Paypal from "./Paypal";

const PremiumSubscription = () => {
  const [isMonthly,setIsMonthly]=useState(false)
  const [isYearly,setIsYearly]=useState(false)
  const [paypal, setPaypal] = useState(false);

  return (
    <PremiumContainer>
      <PersonalContainer>
        <Heading>{"6 months Access"}</Heading>
        <Icon type={"badge"} color={colors.primary } size={'70px'} />
        
        <PremiumInfo>
          <SubHeading>{"$2,199"}</SubHeading>
          <Button text={'Go Premium'} variant="primary" onClick={()=>setIsMonthly(true)}/>
        </PremiumInfo>
      </PersonalContainer>
      <TeamContainer>
        <Heading>{"1 Year Access"}</Heading>
        <Icon type={"premium"} color={colors.primary } size={'70px'} />
        <PremiumInfo>
          <SubHeading>{"$4,000"}</SubHeading>
          <Button text={'Go Premium'}  onClick={()=>setIsYearly(true)}/>
        </PremiumInfo>
      </TeamContainer>
     {isMonthly && <Modal
      onClose={()=>setIsMonthly(false)}
      >
         <Paypal 
          coffeeOrLunch={ "Subscription"}
          coffeeQuantity={1}
          lunchQuantity={1}
        setPaypal={setPaypal}
          setProductInfo={'Subscription'}
          // setIsmodalOpen={setIsmodalOpen}
          subTotal={1}
         />

      </Modal>}
     {isYearly && <Modal
      onClose={()=>setIsYearly(false)}
      >
         <Paypal 
        coffeeOrLunch={ "Subscription"}
        coffeeQuantity={1}
        lunchQuantity={1}
        setPaypal={setPaypal}
        setProductInfo={'Subscription'}
        // setIsmodalOpen={setIsmodalOpen}
        subTotal={1}
         />

      </Modal>}
    </PremiumContainer>
  );
};

export default PremiumSubscription;
