export default {
    "fetchPrograms": () => {
        return {
            // "Self Serve Programs": [{
            //     "id": 1,
            //     "title": "Self Serve Program as Mentor",
            //     "image": "https://media.istockphoto.com/id/1177159086/photo/graph-paper-textured-background-gray-straight-lines-on-white-background-illustration-business.jpg?s=1024x1024&w=is&k=20&c=-gPzU-90gnEwC_GHJHLnFBuV5h77FB6SL1q6Fkj5Ueg="
            // }, {
            //     "id": 2,
            //     "title": "Self Serve Program as Mentee",
            //     "image": "https://media.istockphoto.com/id/1177159086/photo/graph-paper-textured-background-gray-straight-lines-on-white-background-illustration-business.jpg?s=1024x1024&w=is&k=20&c=-gPzU-90gnEwC_GHJHLnFBuV5h77FB6SL1q6Fkj5Ueg="
            // }, {
            //     "id": 3,
            //     "title": "Self Serve Program as Program Manager",
            //     "image": "https://media.istockphoto.com/id/1177159086/photo/graph-paper-textured-background-gray-straight-lines-on-white-background-illustration-business.jpg?s=1024x1024&w=is&k=20&c=-gPzU-90gnEwC_GHJHLnFBuV5h77FB6SL1q6Fkj5Ueg="
            // },],
            // "Full Service Programs": [{
            //     "id": 4,
            //     "title": "Full Service Program as Mentor",
            //     "image": "https://media.istockphoto.com/id/1177159086/photo/graph-paper-textured-background-gray-straight-lines-on-white-background-illustration-business.jpg?s=1024x1024&w=is&k=20&c=-gPzU-90gnEwC_GHJHLnFBuV5h77FB6SL1q6Fkj5Ueg="
            // }, {
            //     "id": 5,
            //     "title": "Full Service Program as Mentee",
            //     "image": "https://media.istockphoto.com/id/1177159086/photo/graph-paper-textured-background-gray-straight-lines-on-white-background-illustration-business.jpg?s=1024x1024&w=is&k=20&c=-gPzU-90gnEwC_GHJHLnFBuV5h77FB6SL1q6Fkj5Ueg="
            // }, {
            //     "id": 6,
            //     "title": "Full Service Program as Program Manager",
            //     "image": "https://media.istockphoto.com/id/1177159086/photo/graph-paper-textured-background-gray-straight-lines-on-white-background-illustration-business.jpg?s=1024x1024&w=is&k=20&c=-gPzU-90gnEwC_GHJHLnFBuV5h77FB6SL1q6Fkj5Ueg="
            // },],
            "": [{
                "id": 7,
                "title": "Mad Mentors",
                "image": "https://media.istockphoto.com/id/1177159086/photo/graph-paper-textured-background-gray-straight-lines-on-white-background-illustration-business.jpg?s=1024x1024&w=is&k=20&c=-gPzU-90gnEwC_GHJHLnFBuV5h77FB6SL1q6Fkj5Ueg="
            }, {
                "id": 8,
                "title": "Dermatology",
                "image": "https://media.istockphoto.com/id/1177159086/photo/graph-paper-textured-background-gray-straight-lines-on-white-background-illustration-business.jpg?s=1024x1024&w=is&k=20&c=-gPzU-90gnEwC_GHJHLnFBuV5h77FB6SL1q6Fkj5Ueg="
            }, {
                "id": 9,
                "title": "A totally new Program",
                "image": "https://media.istockphoto.com/id/1177159086/photo/graph-paper-textured-background-gray-straight-lines-on-white-background-illustration-business.jpg?s=1024x1024&w=is&k=20&c=-gPzU-90gnEwC_GHJHLnFBuV5h77FB6SL1q6Fkj5Ueg="
            }, {
                "id": 10,
                "title": "Requested Access",
                "image": "https://media.istockphoto.com/id/1177159086/photo/graph-paper-textured-background-gray-straight-lines-on-white-background-illustration-business.jpg?s=1024x1024&w=is&k=20&c=-gPzU-90gnEwC_GHJHLnFBuV5h77FB6SL1q6Fkj5Ueg="
            }]
        }
    },
    "moveProgram": ({ program, organization }) => {
        return {
            "Self Serve Programs": [{
                "id": 1,
                "name": "Self Serve Program as Mentor",
                "image": "https://media.istockphoto.com/id/1177159086/photo/graph-paper-textured-background-gray-straight-lines-on-white-background-illustration-business.jpg?s=1024x1024&w=is&k=20&c=-gPzU-90gnEwC_GHJHLnFBuV5h77FB6SL1q6Fkj5Ueg="
            }, {
                "id": 2,
                "name": "Self Serve Program as Mentee",
                "image": "https://media.istockphoto.com/id/1177159086/photo/graph-paper-textured-background-gray-straight-lines-on-white-background-illustration-business.jpg?s=1024x1024&w=is&k=20&c=-gPzU-90gnEwC_GHJHLnFBuV5h77FB6SL1q6Fkj5Ueg="
            }, {
                "id": 3,
                "name": "Self Serve Program as Program Manager",
                "image": "https://media.istockphoto.com/id/1177159086/photo/graph-paper-textured-background-gray-straight-lines-on-white-background-illustration-business.jpg?s=1024x1024&w=is&k=20&c=-gPzU-90gnEwC_GHJHLnFBuV5h77FB6SL1q6Fkj5Ueg="
            },],
            "Full Service Programs": [{
                "id": 4,
                "name": "Full Service Program as Mentor",
                "image": "https://media.istockphoto.com/id/1177159086/photo/graph-paper-textured-background-gray-straight-lines-on-white-background-illustration-business.jpg?s=1024x1024&w=is&k=20&c=-gPzU-90gnEwC_GHJHLnFBuV5h77FB6SL1q6Fkj5Ueg="
            }, {
                "id": 5,
                "name": "Full Service Program as Mentee",
                "image": "https://media.istockphoto.com/id/1177159086/photo/graph-paper-textured-background-gray-straight-lines-on-white-background-illustration-business.jpg?s=1024x1024&w=is&k=20&c=-gPzU-90gnEwC_GHJHLnFBuV5h77FB6SL1q6Fkj5Ueg="
            }, {
                "id": 6,
                "name": "Full Service Program as Program Manager",
                "image": "https://media.istockphoto.com/id/1177159086/photo/graph-paper-textured-background-gray-straight-lines-on-white-background-illustration-business.jpg?s=1024x1024&w=is&k=20&c=-gPzU-90gnEwC_GHJHLnFBuV5h77FB6SL1q6Fkj5Ueg="
            },],
            "": [{
                "id": 8,
                "name": "Dermatology",
                "image": "https://media.istockphoto.com/id/1177159086/photo/graph-paper-textured-background-gray-straight-lines-on-white-background-illustration-business.jpg?s=1024x1024&w=is&k=20&c=-gPzU-90gnEwC_GHJHLnFBuV5h77FB6SL1q6Fkj5Ueg="
            }, {
                "id": 7,
                "name": "Mad Mentors",
                "image": "https://media.istockphoto.com/id/1177159086/photo/graph-paper-textured-background-gray-straight-lines-on-white-background-illustration-business.jpg?s=1024x1024&w=is&k=20&c=-gPzU-90gnEwC_GHJHLnFBuV5h77FB6SL1q6Fkj5Ueg="
            }, {
                "id": 9,
                "name": "A totally new Program",
                "image": "https://media.istockphoto.com/id/1177159086/photo/graph-paper-textured-background-gray-straight-lines-on-white-background-illustration-business.jpg?s=1024x1024&w=is&k=20&c=-gPzU-90gnEwC_GHJHLnFBuV5h77FB6SL1q6Fkj5Ueg="
            }, {
                "id": 10,
                "name": "Requested Access",
                "image": "https://media.istockphoto.com/id/1177159086/photo/graph-paper-textured-background-gray-straight-lines-on-white-background-illustration-business.jpg?s=1024x1024&w=is&k=20&c=-gPzU-90gnEwC_GHJHLnFBuV5h77FB6SL1q6Fkj5Ueg="
            }]
        }
    },
    "addFolder": ({ title: folderName }) => {
        return {
            "Self Serve Programs": [{
                "id": 1,
                "name": "Self Serve Program as Mentor",
                "image": "https://media.istockphoto.com/id/1177159086/photo/graph-paper-textured-background-gray-straight-lines-on-white-background-illustration-business.jpg?s=1024x1024&w=is&k=20&c=-gPzU-90gnEwC_GHJHLnFBuV5h77FB6SL1q6Fkj5Ueg="
            }, {
                "id": 2,
                "name": "Self Serve Program as Mentee",
                "image": "https://media.istockphoto.com/id/1177159086/photo/graph-paper-textured-background-gray-straight-lines-on-white-background-illustration-business.jpg?s=1024x1024&w=is&k=20&c=-gPzU-90gnEwC_GHJHLnFBuV5h77FB6SL1q6Fkj5Ueg="
            }, {
                "id": 3,
                "name": "Self Serve Program as Program Manager",
                "image": "https://media.istockphoto.com/id/1177159086/photo/graph-paper-textured-background-gray-straight-lines-on-white-background-illustration-business.jpg?s=1024x1024&w=is&k=20&c=-gPzU-90gnEwC_GHJHLnFBuV5h77FB6SL1q6Fkj5Ueg="
            },],
            "Full Service Programs": [{
                "id": 4,
                "name": "Full Service Program as Mentor",
                "image": "https://media.istockphoto.com/id/1177159086/photo/graph-paper-textured-background-gray-straight-lines-on-white-background-illustration-business.jpg?s=1024x1024&w=is&k=20&c=-gPzU-90gnEwC_GHJHLnFBuV5h77FB6SL1q6Fkj5Ueg="
            }, {
                "id": 5,
                "name": "Full Service Program as Mentee",
                "image": "https://media.istockphoto.com/id/1177159086/photo/graph-paper-textured-background-gray-straight-lines-on-white-background-illustration-business.jpg?s=1024x1024&w=is&k=20&c=-gPzU-90gnEwC_GHJHLnFBuV5h77FB6SL1q6Fkj5Ueg="
            }, {
                "id": 6,
                "name": "Full Service Program as Program Manager",
                "image": "https://media.istockphoto.com/id/1177159086/photo/graph-paper-textured-background-gray-straight-lines-on-white-background-illustration-business.jpg?s=1024x1024&w=is&k=20&c=-gPzU-90gnEwC_GHJHLnFBuV5h77FB6SL1q6Fkj5Ueg="
            },],
            [folderName]: [],
            "": [{
                "id": 7,
                "name": "Mad Mentors",
                "image": "https://media.istockphoto.com/id/1177159086/photo/graph-paper-textured-background-gray-straight-lines-on-white-background-illustration-business.jpg?s=1024x1024&w=is&k=20&c=-gPzU-90gnEwC_GHJHLnFBuV5h77FB6SL1q6Fkj5Ueg="
            }, {
                "id": 8,
                "name": "Dermatology",
                "image": "https://media.istockphoto.com/id/1177159086/photo/graph-paper-textured-background-gray-straight-lines-on-white-background-illustration-business.jpg?s=1024x1024&w=is&k=20&c=-gPzU-90gnEwC_GHJHLnFBuV5h77FB6SL1q6Fkj5Ueg="
            }, {
                "id": 9,
                "name": "A totally new Program",
                "image": "https://media.istockphoto.com/id/1177159086/photo/graph-paper-textured-background-gray-straight-lines-on-white-background-illustration-business.jpg?s=1024x1024&w=is&k=20&c=-gPzU-90gnEwC_GHJHLnFBuV5h77FB6SL1q6Fkj5Ueg="
            }, {
                "id": 10,
                "name": "Requested Access",
                "image": "https://media.istockphoto.com/id/1177159086/photo/graph-paper-textured-background-gray-straight-lines-on-white-background-illustration-business.jpg?s=1024x1024&w=is&k=20&c=-gPzU-90gnEwC_GHJHLnFBuV5h77FB6SL1q6Fkj5Ueg="
            }]
        }
    },
    "getProgramInformation": ({ id }) => {
        let response = {
            id: id,
            title: "Mad Mentors",
            image: "https://viterbivoices.usc.edu/wp-content/uploads/2014/01/DSC_1552-scaled.jpg",
            organization: "USC",
            role: "mentor",
            type: "fullservice",
            noOfParticipant: "1-10",
            totalTrackedHours: 5,
            enrollment:"member",
            managers: [{
                id: 1,
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
                email: "amaljose@usc.edu",
                name: "Amal Jose"
            }, {
                id: 2,
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
                email: "nicole@usc.edu",
                name: "Nicole Zara"
            }],
            about: `Welcome to Mad Mentors, an innovative mentorship program that is designed to unleash the potential within individuals, guiding them on a transformative journey towards success. Our program brings together passionate mentors and driven mentees, creating a powerful synergy of knowledge, experience, and inspiration. At Mad Mentors, we believe that mentorship is the key to unlocking one's true potential and fostering personal growth and professional development. Join us on this exciting adventure as we empower, inspire, and mentor the next generation of leaders.
            
            Mad Mentors is a dynamic mentorship program that connects individuals with experienced and accomplished mentors who are experts in their respective fields. The program is open to students, young professionals, and individuals seeking guidance in various aspects of their lives, such as academics, career choices, entrepreneurship, personal development, and more.`,
        }

        switch (id) {
            // Self Serve Mentor
            case "1":
            case "2":
            case "3": response = {
                ...response,
                name: "Self Service",
                type: "selfservice"
            };
                break;
            case "4":
            case "5":
            case "6": response = {
                ...response,
                name: "Full Service",
                type: "fullservice"
            };
        }
        switch (id) {
            case "1":
            case "4": response = {
                ...response,
                role: "mentor"
            }
                break;
            case "2":
            case "5": response = {
                ...response,
                role: "mentee"
            }
                break;
            case "3":
            case "6": response = {
                ...response,
                role: "manager"
            }
                break;
        }
        switch(id){
            case "9":response={
                ...response,
                enrollment:"not-enrolled"
            }
            break;
            case "10":response={
                ...response,
                enrollment:"requested"
            }
        }

        return response;
    },
    "getProgramAnnouncements": ({ program }) => {
        return [
            {
                id:1,
                announcer: {
                    name: "Amal Jose",
                    image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"
                },
                time: 1676436377371,
                content: "Congratulations to all mentees on being successfully matched with dedicated mentors who share your interests and goals. This marks the beginning of an enriching journey towards personal and professional growth. Stay tuned for further instructions on how to connect with your mentor and start your mentorship experience!",
            },
            {
                id:2,
                announcer: {
                    name: "Amal Jose",
                    image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"
                },
                time: 1676436357371,
                content: "Kickoff Meetings Scheduled. Get ready for your kickoff meetings, mentees and mentors! This is your chance to connect, set goals, and establish a strong foundation for your mentoring relationship. Mentees, come prepared to discuss your aspirations and areas of focus. Mentors, bring your expertise and guidance. Get ready for an inspiring and productive kickoff meeting!",
            },
            {
                id:3,
                announcer: {
                    name: "Amal Jose",
                    image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"
                },
                time: 1676426377371,
                content: "Mid-Program Check-in Sessions. Mark your calendars for the mid-program check-in sessions, mentees and mentors! This milestone allows us to evaluate progress, address challenges, and ensure alignment with goals. Mentees, be prepared to discuss your achievements and seek guidance. Mentors, bring your valuable insights and support. Let's make the most of this check-in session!",
            }
        ]
    },
    "getAnnouncement":({announcementId})=>{
        return {
            id:announcementId,
            subject:"Testing Announcement",
            audience:["mentees"],
            time: 1676426377371,
            body: "Congratulations to all mentees on being successfully matched with dedicated mentors who share your interests and goals. This marks the beginning of an enriching journey towards personal and professional growth. Stay tuned for further instructions on how to connect with your mentor and start your mentorship experience!",
        };
    },
    "saveAnnouncement":({announcementId,announcement})=>{
        return {};
    },
    "getProgramMentors": ({ programId }) => {
        return [
            {
                name: "Amal Jose",
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
                id: 1,
                bio: `Amal Jose is a seasoned technology professional with over 15 years of experience in software development and a passion for mentoring aspiring technologists. He possesses a deep understanding of various domains, including web and mobile app development, cloud computing, and artificial intelligence.

                Throughout his career, Amal has worked with startups and established companies, leading teams and driving the development of cutting-edge technology solutions. He is well-versed in programming languages such as Python, Java, and JavaScript, and has expertise in frameworks and tools like Django, React, and AWS. Amal is experienced in agile development methodologies, software architecture design, and creating scalable and robust software solutions.`,
                status: "connected",
            },
            {
                name: "Sarah Johnson",
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
                id: 2,
                bio: `Sarah Johnson is an accomplished entrepreneur and business strategist with a passion for helping others unleash their entrepreneurial spirit. With over 10 years of experience in starting and scaling successful businesses, Sarah brings a wealth of knowledge and expertise to her mentorship role.

                Throughout her career, Sarah has successfully launched multiple ventures, navigating the challenges of building a business from the ground up. Her strategic mindset and innovative approach have played a key role in her success, allowing her to identify market opportunities, develop effective business models, and drive growth.`,
                status: "not-connected",
            },
        ]
    },
    "requestMentorship": ({ programId }) => {
        return [
            {
                name: "Amal Jose",
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
                id: 1,
                bio: `Amal Jose is a seasoned technology professional with over 15 years of experience in software development and a passion for mentoring aspiring technologists. He possesses a deep understanding of various domains, including web and mobile app development, cloud computing, and artificial intelligence.

                Throughout his career, Amal has worked with startups and established companies, leading teams and driving the development of cutting-edge technology solutions. He is well-versed in programming languages such as Python, Java, and JavaScript, and has expertise in frameworks and tools like Django, React, and AWS. Amal is experienced in agile development methodologies, software architecture design, and creating scalable and robust software solutions.`,
                status: "connected",
            },
            {
                name: "Sarah Johnson",
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
                id: 2,
                bio: `Sarah Johnson is an accomplished entrepreneur and business strategist with a passion for helping others unleash their entrepreneurial spirit. With over 10 years of experience in starting and scaling successful businesses, Sarah brings a wealth of knowledge and expertise to her mentorship role.

                Throughout her career, Sarah has successfully launched multiple ventures, navigating the challenges of building a business from the ground up. Her strategic mindset and innovative approach have played a key role in her success, allowing her to identify market opportunities, develop effective business models, and drive growth.`,
                status: "requested",
            },
        ]
    },
    "getConnections": ({programId}) => {
       
        return [
            {
                name: "Amal Jose",
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
                id: 1,
                pendingTasks: 1,
            },{
                name: "Nicole Zara",
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
                id: 2,
                pendingTasks: 0,
            },
        ]
        
    },
    "getMenteeRequests":({programId})=>{
        return [
            {
                name: "Amal Jose",
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
                id: 1,
            },{
                name: "Nicole Zara",
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
                id: 2,
            },
        ]
    },
    "approveRequest":({programId,connectionId})=>{
        return {}
    },
    "denyRequest":({programId,connectionId})=>{
        return {}
    },
    "getConnectionProfile": ({ id }) => {
        return {
            name: "Amal Jose",
            description: "MD/MBA Candidate",
            image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
            id: 1,
            calendarLink: "https://calendar.google.com/calendar/u/0/r/AmalJ"
        }
    },

    "sendUpdate": ({ update }) => {
        return [{
            user: {
                name: "Amal Jose",
                id: 1,
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"
            },
            id: 1,
            time: 1676436377371,
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipisc",
            isLiked: true
        }, {
            user: {
                name: "Amal Jose",
                id: 1,
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"
            },
            id: 2,
            time: 1676436347371,
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipisc",
            isLiked: false
        }]
    },
    "getUpdates": ({ userId, programId }) => {
        return [{
            user: {
                name: "Amal Jose",
                id: 1,
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"
            },
            id: 1,
            time: 1676436377371,
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipisc",
            isLiked: true
        }, {
            user: {
                name: "Amal Jose",
                id: 1,
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"
            },
            id: 2,
            time: 1676436347371,
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipisc",
            isLiked: false
        }]
    },
    "updateLike": ({ id }) => {
        return [{
            user: {
                name: "Amal Jose",
                id: 1,
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"
            },
            id: 1,
            time: 1676436377371,
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipisc",
            isLiked: false
        }, {
            user: {
                name: "Amal Jose",
                id: 1,
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"
            },
            id: 2,
            time: 1676436347371,
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipisc",
            isLiked: false
        }]
    },
    "getNotes": ({ programId, connection }) => {
        return [
            {
                id: 1,
                title: "Meeting on 02/16",
                time: 1676436377371
            },
            {
                id: 2,
                title: "Meeting on 02/20",
                time: 1636439377371
            },
        ];
    },
    "addNewNote": ({ programId, connection }) => {
        return {
            newNoteId: 3,
            notes: [
                {
                    id: 1,
                    title: "Meeting on 02/16",
                    time: 1676436377371
                },
                {
                    id: 2,
                    title: "Meeting on 02/20",
                    time: 1636439377371
                },
                {
                    id: 3,
                    title: "New Note",
                    time: new Date().getTime()
                },
            ]
        };
    },
    "deleteNote": ({ programId, connection, noteId }) => {
        return [
            {
                id: 1,
                title: "Meeting on 02/16",
                time: 1676436377371
            },
            {
                id: 2,
                title: "Meeting on 02/20",
                time: 1636439377371
            },
        ];
    },
    "getNote": ({ noteId }) => {
        return {
            id: noteId,
            title: "Meeting on 02/16",
            content: {
                "entityMap": {},
                "blocks": [
                    {
                        "key": "637gr",
                        "text": "Initialized from content state.",
                        "type": "unstyled",
                        "depth": 0,
                        "inlineStyleRanges": [],
                        "entityRanges": [],
                        "data": {}
                    }
                ]
            }
        }
    },
    "saveNote": ({ note }) => {
        return note;
    },
    "getProgramConnectionTimesheet": ({ programId, connection }) => {
        return [
            {
                id: 1,
                date: "02/10/2023",
                hours: 5,
                timeRequested: 1676436377371,
                timeApproved: 1676436977371,
                status: "Approved"
            },
            {
                id: 2,
                date: "02/12/2023",
                hours: 6,
                timeRequested: 1676436377371,
                status: "Pending"
            },
        ]
    },
    "deleteTimesheetRecord": ({ id }) => {
        return [
            {
                id: 1,
                date: "02/10/2023",
                hours: 5,
                timeRequested: 1676436377371,
                timeApproved: 1676436977371,
                status: "Approved"
            },
            {
                id: 2,
                date: "02/12/2023",
                hours: 6,
                timeRequested: 1676436377371,
                status: "Pending"
            },
        ]
    },
    "addTimesheetRecord": ({ id }) => {
        return [
            {
                id: 1,
                date: "02/10/2023",
                hours: 5,
                timeRequested: 1676436377371,
                timeApproved: 1676436977371,
                status: "Approved"
            },
            {
                id: 2,
                date: "02/12/2023",
                hours: 6,
                timeRequested: 1676436377371,
                status: "Pending"
            },
        ]
    },
    "approveTimesheetRecord": ({ id }) => {
        return [
            {
                id: 1,
                date: "02/10/2023",
                hours: 5,
                timeRequested: 1676436377371,
                timeApproved: 1676436977371,
                status: "Approved"
            },
            {
                id: 2,
                date: "02/12/2023",
                hours: 6,
                timeRequested: 1676436377371,
                timeApproved: Date.now(),
                status: "Approved"
            },
        ]
    },
    "getProgramTasks": ({ programId, connection }) => {
        return [
            {
                id: 1,
                title: "Goal Setting and Action Planning",
                dueDate: 1676436377371,
                description: "In this task, mentors will guide mentees in setting clear and achievable goals aligned with their aspirations. Mentors will help mentees define specific objectives and develop action plans to reach those goals. Through this process, mentees will gain clarity, focus, and a roadmap for their personal and professional development.",
                status: "Pending",
            },
            {
                id: 2,
                title: "Resume Review and Career Guidance",
                dueDate: 1676436377371,
                description: "In this task, mentors will provide valuable feedback and guidance on mentees' resumes, helping them highlight their strengths, skills, and experiences effectively. Mentors will also offer insights on industry-specific requirements and share strategies for career advancement. Mentees will receive personalized advice to enhance their resumes and gain valuable career insights.",
                status: "Complete",
            },
        ]
    },
    "getTask": ({ taskId }) => {
        return {
            id: taskId,
            title: "Goal Setting and Action Planning",
            dueDate: 1676436377371,
            description: "In this task, mentors will guide mentees in setting clear and achievable goals aligned with their aspirations. Mentors will help mentees define specific objectives and develop action plans to reach those goals. Through this process, mentees will gain clarity, focus, and a roadmap for their personal and professional development.",
            assignees: ["mentees"],
            reminder:[],
        }
    },
    "saveTask": ({ programId, task }) => {
        return {}
    },
    "getTaskMembers": ({ taskId, mode }) => {
        return [
            {
                id: 1,
                name: "Amal Jose",
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"
            },
            {
                id: 2,
                name: "Nicole Zara",
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"
            }
        ]
    },
    "remindUser": ({ taskId, userId }) => {
        return [
            {
                id: 1,
                name: "Amal Jose",
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"
            }
        ];
    },
    "markTaskAsCompleteForUser": ({ taskId, userId }) => {
        return [
            {
                id: 1,
                name: "Amal Jose",
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"
            }
        ];
    },
    "markTaskAsComplete": ({ programId, connection, taskId }) => {
        return [
            {
                id: 1,
                title: "Goal Setting and Action Planning",
                dueDate: 1676436377371,
                description: "In this task, mentors will guide mentees in setting clear and achievable goals aligned with their aspirations. Mentors will help mentees define specific objectives and develop action plans to reach those goals. Through this process, mentees will gain clarity, focus, and a roadmap for their personal and professional development.",
                status: "Complete"
            },
            {
                id: 2,
                title: "Resume Review and Career Guidance",
                dueDate: 1676436377371,
                description: "In this task, mentors will provide valuable feedback and guidance on mentees' resumes, helping them highlight their strengths, skills, and experiences effectively. Mentors will also offer insights on industry-specific requirements and share strategies for career advancement. Mentees will receive personalized advice to enhance their resumes and gain valuable career insights.",
                status: "Complete",
            },
        ]
    },
    "removeProgramManager": ({ programId, email }) => {
        return [{
            id: 1,
            image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
            email: "amaljose@usc.edu",
            name: "Amal Jose"
        }]
    },
    "addProgramManager": ({ programId, email }) => {
        return [{
            id: 1,
            image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
            email: "amaljose@usc.edu",
            name: "Amal Jose"
        }, {
            id: 2,
            image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
            email: "amaljose@usc.edu",
            name: "Amal Jose"
        }, {
            id: 3,
            image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
            email: "amaljose@usc.edu",
            name: "Amal Jose"
        }]
    },
    "saveProgram": ({ programId, program }) => {
        return {
            programId:3,
        };
    },
    "deleteProgram": ({ programId }) => {
        return {};
    },
    "getApplicationForm": ({ programId }) => {
        return {
            "accepts":{
                "mentor":true,
                "mentee":true
            },
            "mentor":[
                {
                    id:1,
                    type:"shortString",
                    text:"Enter a short bio"
                }
            ],
            "mentee":[
                {
                    id:1,
                    type:"longString",
                    text:"Why should we accept you to this program"
                },
                {
                    id:2,
                    type:"shortString",
                    text:"When will you graduate"
                },
            ]
        }
    },
    "saveApplicationForm":({form})=>{
        return true;
    },
    "applyToProgram":({programId,role,application})=>{
        return true
    },
    "getApplication":({programId,userId})=>{
        return {
            role:"mentee",
            answers:{
                1:"I'd like to learn more about this program.",
                2:"2024"
            }
        }
    },
    "sendAnnouncement": ({ announcement }) => {
        return {};
    },
    "getProgramParticipants": ({ programId }) => {
        return [
            {
                id: 1,
                name: "Amal Jose",
                totalTrackedHours: 5,
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
                mentees: [
                    {
                        id: 2,
                        name: "Nicole Zara",
                        trackedHours: 5,
                        image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"
                    },
                    {
                        id: 3,
                        name: "Uche",
                        trackedHours: 5,
                        image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"
                    },
                ]
            },
            {
                id: 4,
                name: "John Appleseed",
                totalTrackedHours: 5,
                mentees: [ {
                    id: 3,
                    name: "Uche",
                    trackedHours: 5,
                    image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"
                },]
            },
            {
                name: "Unassigned Mentees",
                mentees: [
                    {
                        id: 1,
                        name: "Amal Jose",
                        image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"
                    },
                    {
                        id: 3,
                        name: "Uche",
                        image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"
                    },
                ]
            }
        ]
    },
    "getProgramInvites":({programId})=>{
        return [
            {
                email: "amaljose96@gmail.com",
                role:"mentor",
                requestTime:10022301223312,
            },
            {
                email: "xyz@xyz.com",
                role:"mentor",
                requestTime:10032301223312,
            },
            {
                email: "abc@amc.com",
                role:"mentee",
                requestTime:10032301223312,
            },
        ]
    },
    "resendInvite":({email,programId})=>{
        return {}
    },
    "cancelInvite":({email,programId})=>{
        return {}
    },
    "getProgramRequests":({programId})=>{
        return [
            {
                id: 1,
                name: "Amal Jose",
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
                role:"mentor",
                application:[
                    {
                        question:"Are you a student at USC?",
                        answer:"Yes"
                    }
                ],
                requestTime:10032301223312,
            },
            {
                id: 2,
                name: "Nicole Zara",
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
                role:"mentee",
                application:[
                    {
                        question:"Are you a student at USC?",
                        answer:"No"
                    }
                ],
                requestTime:10022301223312,
            },
        ]
    },
    "acceptRequest":({userId,programId})=>{
        return {}
    },
    "denyRequest":({userId,programId})=>{
        return {}
    },
    "getAvailableMentors": ({ programId }) => {
        return [
            {
                id: 2,
                name: "Nicole Zara",
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"
            },
            {
                id: 3,
                name: "Uche",
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"
            },
        ]
    },
    "reassignMentee": ({ menteeId, programId, mentorId, }) => {
        return {};
    },
    "addParticipants": ({ programId, participants }) => {
        return {};
    },
    "login": ({ email, password }) => {
        localStorage.setItem("mockExpiry", new Date().getTime() + 1000 * 60 * 30)
        let mockAccessToken = (Math.random() * 100000).toString()
        localStorage.setItem("mockAccessToken", mockAccessToken)
        localStorage.setItem("role",email.includes("admin") ? "admin":"user")
        
        return {
            firstName: "Amal",
            lastName: "Jose",
            id: 1,
            role: email.includes("admin") ? "admin":"user",
            image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
            email: email,
            accessToken: mockAccessToken
        }
    },
    "createUser":()=>{
        return ""
    },
    "forgotPassword":()=>{
        return ""
    },
    "checkAccessToken": ({ accessToken }) => {
        let mockAccessToken = localStorage.getItem("mockAccessToken")
        if (accessToken != mockAccessToken) {
            throw {
                status: "invalid"
            }
        }
        if (localStorage.getItem("mockExpiry") < new Date().getTime()) {
            throw {
                status: "invalid"
            }
        }
        return {
            firstName: "Amal",
            lastName: "Jose",
            id: 1,
            role: localStorage.getItem("role"),
            image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
            email: "amaljose@gmail.com",
            accessToken: accessToken
        }
    },
    "getFeed": ({ filters }) => {
        return [{
            user: {
                name: "Amal Jose",
                id: 1,
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"
            },
            id: 1,
            time: 1676436377371,
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipisc",
            isLiked: true,
            likes: 10,
        }, {
            user: {
                name: "Amal Jose",
                id: 1,
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"
            },
            id: 2,
            time: 1676436347371,
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipisc",
            isLiked: false,
            likes: 2,
        }]
    },
    "updateFeedLike": ({ id }) => {
        return [{
            user: {
                name: "Amal Jose",
                id: 1,
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"
            },
            id: 1,
            time: 1676436377371,
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipisc",
            isLiked: true,
            likes: 10,
        }, {
            user: {
                name: "Amal Jose",
                id: 1,
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"
            },
            id: 2,
            time: 1676436347371,
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid, Ipsum dolor sit amet, consectetur adipisc",
            isLiked: true,
            likes: 3,
        }]
    },

    "getPeople": ({ filters }) => {
        return [{
            id: 1,
            name: "Amal Jose",
            role: "Mentor",
            program: "Mad Mentors",
            programId: 1,
            trackedHours: 5,
            image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
            calendarLink: "https://calendar.google.com/calendar/u/0/r/AmalJ"
        }, {
            id: 1,
            name: "Nicole Zara",
            role: "Mentee",
            program: "Mad Mentors",
            programId: 1,
            trackedHours: 7,
            image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
            calendarLink: "https://calendar.google.com/calendar/u/0/r/AmalJ"
        }, {
            id: 1,
            name: "Amogha Bharadwaj",
            role: "Mentee",
            program: "Mad Mentors",
            programId: 1,
            trackedHours: 10,
            image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
            calendarLink: "https://calendar.google.com/calendar/u/0/r/AmalJ"
        }, {
            id: 1,
            name: "John Appleseed",
            role: "Mentee",
            program: "Mad Mentors",
            programId: 1,
            trackedHours: 9,
            image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
            calendarLink: "https://calendar.google.com/calendar/u/0/r/AmalJ"
        }, {
            id: 1,
            name: "Melody Pereira",
            role: "Mentee",
            program: "Dermatology",
            programId: 2,
            trackedHours: 3,
            image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
            calendarLink: "https://calendar.google.com/calendar/u/0/r/AmalJ"
        },]
    },
    "getMessageList": () => {
        return [
            {
                sender: {
                    id: 1,
                    name: "Amal Jose",
                    image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
                },
                summary: "Hey!",
                time: 1376436377371,
            }, {
                sender: {
                    id: 2,
                    name: "Nicole Zara",
                    image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
                },
                summary: "Hey!",
                time: 1672436277371,
            },
        ]
    },
    "getUserFromEmail": ({ email }) => {
        return {
            id: 3
        }
    },
    "getChat": ({ userId }) => {
        return {
            user: {
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
                id: userId,
                name: "Nicole Zara",
                status: "Online",
                loadedAll: true
            },
            messages: [
                {
                    time: 1676436377371,
                    content: "Hi Amal! I am sending a really huge text!! I am sending a really huge text!! I am sending a really huge text!! I am sending a really huge text!! I am sending a really huge text!! I am sending a really huge text!! I am sending a really huge text!! I am sending a really huge text!! I am sending a really huge text!! I am sending a really huge text!! I am sending a really huge text!! I am sending a really huge text!! I am sending a really huge text!! I am sending a really huge text!! I am sending a really huge text!!",
                    sender: 2,
                },
                {
                    time: 1676436397371,
                    content: "Hi Nicole!",
                    sender: 1,
                },
            ]
        }
    },
    "getPastChat": ({ userId }) => {
        return {
            messages: [
                {
                    time: 1576436377371,
                    content: "Hi Amal!",
                    sender: 2,
                },
                {
                    time: 1476436397371,
                    content: "Hi Nicole!",
                    sender: 1,
                },
            ],
            user: {
                loadedAll: true
            }
        }
    },
    "getNewChat": ({ userId }) => {
        return {
            user: {
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
                id: userId,
                name: "Nicole Zara",
                status: "Offline"
            },
            messages: [
                {
                    time: Date.now(),
                    content: "Hi, I am " + userId,
                    sender: userId,
                },
            ]
        }
    },
    "getNotifications": ({ userId }) => {
        return {data:[
            {
                id: 1,
                type: "New Message",
                message: "Amal has messaged you.",
                time: 10132301223312,
                isRead: false,
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
                url: "/messages/2"
            },
            {
                id: 2,
                type: "Nicole's Update",
                message: "Nicole posted a new update.",
                time: 10132303223312,
                isRead: true,
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
                url: "/programs/1/connections/1/updates"
            },
        ]
    }
    },
    "markNotificationAsRead": ({ id }) => {
    },

    "getUserProgramInfo":({userId,programId})=>{
        let info={
            1:{
                name:"Amal Jose",
                bio:"Ipsum Lorem",
                application:[],
                enrollTime:10032301223312,
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"
            },
            2:{
                name:"Nicole Zara",
                bio:"Ipsum Lorem",
                application:[
                    {
                        question:"Are you a student at USC?",
                        answer:"Yes"
                    }
                ],
                enrollTime:10032301223312,
                image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"
            },
        }
        if(!info[userId]){
            return info[1]
        }
        return info[userId];
    },

    "getAdminPrograms": ({ filters }) => {
        return [
            {
                id: 1,
                name: "Mad Mentors",
                organization: "USC",
                totalTrackedHours: 5,
                createdBy: {
                    id: 1,
                    email: "amaljose@usc.edu",
                    name: "Amal Jose"
                },
                isUserParticipant: true,
                createdOn: 10132301223312,
                noOfParticipants: 14
            },
            {
                id: 2,
                name: "Dermatology",
                organization: "UCLA",
                totalTrackedHours: 12,
                createdBy: {
                    id: 1,
                    email: "amaljose@usc.edu",
                    name: "Amal Jose"
                },
                isUserParticipant: true,
                createdOn: 10032301223312,
                noOfParticipants: 4
            },
        ]
    },
    "getAdminProgram":({programId})=>{
        let response = {
            id: programId,
            name: "Mad Mentors",
            image: "https://viterbivoices.usc.edu/wp-content/uploads/2014/01/DSC_1552-scaled.jpg",
            organization: "USC",
            type: "fullservice",
            numberOfParticipants: 4, 
            tier: "1-10",
            createdOn:1322313223,
            programParticipants:[
                {
                    id:1,
                    role:"programManager",
                    image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
                    email: "amaljose@usc.edu",
                    name: "Amal Jose",
                    createDate:1322313223,
                    status:"active",
                    programJoinDate:155234113233,
                },
                {
                    id:3,
                    role:"mentor",
                    image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
                    email: "johndoe@usc.edu",
                    name: "John Doe",
                    createDate:1322313223,
                    status:"requestPending",
                    programJoinDate:155234113233,
                },
                {
                    id:4,
                    role:"mentee",
                    image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
                    email: "mvp05@gmail.com",
                    name: "Melody Pereira",
                    status:"invited",
                    createDate:1322313223,
                    programJoinDate:155234113233,
                    mentor:3
                },
            ],
            about: `Welcome to Mad Mentors, an innovative mentorship program that is designed to unleash the potential within individuals, guiding them on a transformative journey towards success. Our program brings together passionate mentors and driven mentees, creating a powerful synergy of knowledge, experience, and inspiration. At Mad Mentors, we believe that mentorship is the key to unlocking one's true potential and fostering personal growth and professional development. Join us on this exciting adventure as we empower, inspire, and mentor the next generation of leaders.
            
            Mad Mentors is a dynamic mentorship program that connects individuals with experienced and accomplished mentors who are experts in their respective fields. The program is open to students, young professionals, and individuals seeking guidance in various aspects of their lives, such as academics, career choices, entrepreneurship, personal development, and more.`,
            tasks:[
                {
                    id: 1,
                    title: "Goal Setting and Action Planning",
                    createDate:1676436377371,
                    dueDate: 1676436377371,
                    description: "In this task, mentors will guide mentees in setting clear and achievable goals aligned with their aspirations. Mentors will help mentees define specific objectives and develop action plans to reach those goals. Through this process, mentees will gain clarity, focus, and a roadmap for their personal and professional development.",
                    completeCount:4,
                    dueCount:10,
                },
                {
                    id: 2,
                    title: "Resume Review and Career Guidance",
                    dueDate: 1676436377371,
                    createDate:1676436377371,
                    description: "In this task, mentors will provide valuable feedback and guidance on mentees' resumes, helping them highlight their strengths, skills, and experiences effectively. Mentors will also offer insights on industry-specific requirements and share strategies for career advancement. Mentees will receive personalized advice to enhance their resumes and gain valuable career insights.",
                    status: "Complete",
                    completeCount:4,
                    dueCount:10,
                },
            ]
        }
        return response;
    },
    'paymentInfo':()=>{
        return[
            {
            id:1,
            heading: 'How it works:',
            listFirstheading: '1. Make a selection below.',
            listSecondheading: '2. Mentors will receive a QR code to use at the cafe or restaurant of  their choice 30 minutes before their scheduled in-person meeting with their mentee.',
            Balance:"Your Balance:",
            product1:'Coffee on Us!',
            product2:'Lunch on Us!',
            coffeePrice:10,
            lunchPrice:25,
        },
    ]
    },
    "adminSaveProgram":({program})=>{
        return {};
    },
    "getAdminUsers": ({ filters }) => {
        return [
            {
                id: 1,
                name: "Amal Jose",
                email:"amaljose96@gmail.com",
                status:"Active",
                numberOfPrograms:4,
                totalTrackedHours: 5,
                createdOn: 10132301223312,
            },
            {
                id: 1,
                name: "Nicole Zara",
                email:"nicole@gmail.com",
                status:"Active",
                numberOfPrograms:2,
                totalTrackedHours: 12,
                createdOn: 10132301423312,
            },
        ]
    },
    "getAdminUser":({userId})=>{
        return {
            id: 1,
            name: "Amal Jose",
            email:"amaljose96@gmail.com",
            status:"Active",
            createdOn: 10132301223312,
            image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
            bio:`Amal Jose is a seasoned technology professional with over 15 years of experience in software development and a passion for mentoring aspiring technologists. He possesses a deep understanding of various domains, including web and mobile app development, cloud computing, and artificial intelligence.

            Throughout his career, Amal has worked with startups and established companies, leading teams and driving the development of cutting-edge technology solutions. He is well-versed in programming languages such as Python, Java, and JavaScript, and has expertise in frameworks and tools like Django, React, and AWS. Amal is experienced in agile development methodologies, software architecture design, and creating scalable and robust software solutions.`,
            lastActiveTime:new Date(),
            programs:[
                {
                    id:1,
                    name: "Mad Mentors",
                    organization:"USC",
                    image: "https://viterbivoices.usc.edu/wp-content/uploads/2014/01/DSC_1552-scaled.jpg",
                    programJoinDate:1676436357371,
                    role: "mentor",
                    connections: 3,
                    totalTrackedHours: 4, 
                }
            ],
            tasks:[
                {
                    id: 1,
                    program:"Mad Mentors",
                    title: "Goal Setting and Action Planning",
                    description: "In this task, mentors will guide mentees in setting clear and achievable goals aligned with their aspirations. Mentors will help mentees define specific objectives and develop action plans to reach those goals. Through this process, mentees will gain clarity, focus, and a roadmap for their personal and professional development.",
                    createDate:1676436357371,
                    dueDate: 1676436377371,
                    completeDate:1676436367371,
                    status:"Completed",
                },
                {
                    id: 2,
                    program:"Mad Mentors",
                    title: "Resume Review and Career Guidance",
                    description: "In this task, mentors will provide valuable feedback and guidance on mentees' resumes, helping them highlight their strengths, skills, and experiences effectively. Mentors will also offer insights on industry-specific requirements and share strategies for career advancement. Mentees will receive personalized advice to enhance their resumes and gain valuable career insights.",
                    createDate:1676436357371,
                    dueDate: 1676436377371,
                    status:"Incomplete",
                },
            ]
        }
    },
}