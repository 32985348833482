
  export const markAsReadMessage=(count,isRead) => {
    return {
      type:"MARK_AS_READ_MESSAGE",
      count,
      isRead,
      segmentName: "messageCount",
    }
  }
//   export const markAsReadGroupMessage=(isRead) => {
//     return {
//       type:"MARK_AS_READ_GROUP_MESSAGE",
//       isRead,
//       segmentName: "messageCount",
//     }
//   }