import React from "react";
import { ProgramContainer, Header, Section, Title, Body, Image, Form } from "./styles";
import callAPI from "../../../../api/apiCaller";
import { Context } from "../../../../Store";
import Button from "../../../../common/Button";
import { useNavigate } from "react-router-dom";
import Loading from "../../../../common/Loading";
import { ButtonSpacer } from "../../../../common/Button/styles";
import Labelize from "../../../../common/Labelize";
import { formatDate, formatDescription } from "../../../../common/utils";
import Table from "../../../../common/Table";
import { TableActions } from "../../../../common/Table/styles";

function Program({ programId }) {
  const [program, setProgram] = React.useState(null);
  const { dispatch } = React.useContext(Context);
  const navigate = useNavigate();
  const [editMode, setEditMode] = React.useState(false);
  React.useEffect(() => {
    callAPI(dispatch, "getAdminProgram", { programId }).then(retrievedProgram => {
      setProgram(retrievedProgram);
    })
  }, []);
  if (program === null) {
    return <ProgramContainer>
      <Loading loadingItem="Program Details" />
    </ProgramContainer>
  }
  return <ProgramContainer>
    <Header>
      <Section>
        <Button icon={"back"} onClick={() => {
          navigate(-1)
        }} />
        <Title>{program.name}</Title>
      </Section>
      <Section>
        <Button icon={"edit"} text={"Edit"} variant={"primary"} onClick={() => setEditMode(!editMode)} />
        <ButtonSpacer />
        <Button icon="trash" text={"Delete"} variant="danger" />
      </Section>
    </Header>
    <Body>
      <Section>
        <Form>
          <Labelize label={"Organization"}>
            {program.organization}
          </Labelize>
          <ButtonSpacer />
          <Labelize label={"Type of Program"}>
            {program.type}
          </Labelize>
          <ButtonSpacer />
          <Labelize label={"Total Number of Participants"}>
            {program.numberOfParticipants}
          </Labelize>
          <ButtonSpacer />
          <Labelize label={"About"}>
            {formatDescription(program.about)}
          </Labelize>
          <ButtonSpacer />
          <Labelize label={"Created On"}>
            {formatDate(program.createdOn)}
          </Labelize>
        </Form>
        <Section>
          <Image src={program.image} />
        </Section>
      </Section>
      <ButtonSpacer /><ButtonSpacer />
      <Labelize label={"Program Participants"}>

        <Table
          columns={[
            {
              key: "name",
              title: "Name",
              sortable: true,
            },
            {
              key: "email",
              title: "Email",
              sortable: true,
            },

            {
              key: "programJoinDate",
              title: "Joined On",
              sortable: true,
              render: ({ value }) => {
                return formatDate(value)
              }
            },

            {
              key: "status",
              title: "Status",
              sortable: true,
              render: ({ value }) => {
                return value[0].toUpperCase() + value.slice(1)
              }
            },

            {
              key: "role",
              title: "Role",
              sortable: true,
            },

            {
              key: "createDate",
              title: "Created On",
              sortable: true,
              render: ({ value }) => {
                return formatDate(value)
              }
            },
            {
              key: "",
              title: "Actions",
              sortable: true,
              render: ({ row }) => {
                return <TableActions>
                <Button icon="view" onClick={()=>{
                  navigate("/userbrowser/"+row.id)
                }}/>
                <ButtonSpacer/>
                  <Button icon="edit"  variant="primary"/>
                  <ButtonSpacer/>
                  <Button icon="cross" variant="danger"/>
                </TableActions>
              }
            },
          ]}
          rows={program.programParticipants}
        />

      </Labelize>
      <ButtonSpacer /><ButtonSpacer />
      <Labelize label={"Program Tasks"}>

        <Table
          columns={[
            {
              key: "title",
              title: "Title",
              sortable: true,
            },
            {
              key: "description",
              title: "Description",
              sortable: true,
            },

            {
              key: "createDate",
              title: "Created On",
              sortable: true,
              render: ({ value }) => {
                return formatDate(value)
              }
            },
            {
              key: "dueDate",
              title: "Due By",
              sortable: true,
              render: ({ value }) => {
                return formatDate(value)
              }
            },

            {
              key: "completeCount",
              title: "Completed Count",
              sortable: true,
            },

            {
              key: "dueCount",
              title: "Pending Count",
              sortable: true,
            },
            {
              key: "",
              title: "Actions",
              sortable: true,
              render: ({ row }) => {
                return <TableActions>
                <Button icon="view"/>
                <ButtonSpacer/>
                  <Button icon="edit"  variant="primary"/>
                  <ButtonSpacer/>
                  <Button icon="cross" variant="danger"/>
                </TableActions>
              }
            },
          ]}
          rows={program.tasks}
        />

      </Labelize>
    </Body>
  </ProgramContainer>;
}
export default Program;