import styled from "styled-components";
import colors from "../../config/colors";
import { panelSpacing } from "../styles";

export const ImageUploadContainer = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:100%;
    height:${props=>props.height && props.height};
    aspect-ratio:${props=>props.aspectRatio &&  '1 / 1'};
`;
export const ImagePreview = styled.img`
    width:${props=>props.size}px;
    height:${props=>props.size}px;
    margin-bottom:${props=>props.panelSpacing ?props.panelSpacing:panelSpacing}px;
    display:block;
    border-radius:${panelSpacing}px;
    object-fit:cover;
`;
export const UploadButton = styled.label`
    background-color: ${colors.primary};
    border:solid 1px ${colors.primary};
    color: ${colors.background};
    padding:10px;
    font-size:14px;
    width:fit-content;
    height:fit-content;
    border-radius:5px;
    transition: all 0.1s ease-in-out;
    opacity:1;
    cursor:${props => props.disabled ? "not-allowed" : "pointer"};
    :hover{
        
        opacity:0.95;
        border:solid 1px ${colors.darkBackground};
    }
    :active{
        opacity:0.9;
        transform:scale(1.05);
    }
    display:flex;
    align-items:center;
    user-select:none;

`;