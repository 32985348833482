import React from "react";
import { UserContainer } from "./styles";
import { Context } from "../../../../Store";
import { useNavigate } from "react-router-dom";
import Loading from "../../../../common/Loading";
import { Body, Form, Header, Image, Section, Title } from "../../ProgramBrowser/Program/styles";
import Button from "../../../../common/Button";
import Labelize from "../../../../common/Labelize";
import { formatDate, formatDescription } from "../../../../common/utils";
import callAPI from "../../../../api/apiCaller";
import { ButtonSpacer } from "../../../../common/Button/styles";
import Table from "../../../../common/Table";
import { TableActions } from "../../../../common/Table/styles";

function User({userId}) {
  const [user, setUser] = React.useState(null);
  const { dispatch } = React.useContext(Context);
  const navigate = useNavigate();
  const [editMode, setEditMode] = React.useState(false);
  React.useEffect(() => {
    callAPI(dispatch, "getAdminUser", { userId }).then(retrievedUser => {
      setUser(retrievedUser);
    })
  }, []);
  if (user === null) {
    return <UserContainer>
      <Loading loadingItem="User Details" />
    </UserContainer>
  }
  return <UserContainer>
    <Header>
      <Section>
        <Button icon={"back"} onClick={() => {
          navigate(-1)
        }} />
        <Title>{user.name}</Title>
      </Section>
      <Section>
        <Button icon={"edit"} text={"Edit"} variant={"primary"} onClick={() => setEditMode(!editMode)} />
        <ButtonSpacer />
        <Button icon="trash" text={"Delete"} variant="danger" />
      </Section>
    </Header>
    <Body>
      <Section>
        <Form>
          <Labelize label={"Name"}>
            {user.name}
          </Labelize>
          <ButtonSpacer />
          <Labelize label={"Email"}>
            {user.email}
          </Labelize>
          <ButtonSpacer />
          <Labelize label={"Created On"}>
            {formatDate(user.createdOn)}
          </Labelize>
          <ButtonSpacer />
          <Labelize label={"Bio"}>
            {formatDescription(user.bio)}
          </Labelize>
          <ButtonSpacer />
          <Labelize label={"Status"}>
            {user.status}
          </Labelize>
        </Form>
        <Section>
          <Image src={user.image} />
        </Section>
      </Section>
      <ButtonSpacer /><ButtonSpacer />
      <Labelize label={"Programs Participating"}>
        <Table
          columns={[
            {
              key: "name",
              title: "Name",
              sortable: true,
            },
            {
              key: "organization",
              title: "Organization",
              sortable: true,
            },

            {
              key: "programJoinDate",
              title: "Joined On",
              sortable: true,
              render: ({ value }) => {
                return formatDate(value)
              }
            },
            {
              key: "role",
              title: "Role",
              sortable: true,
            },
            {
              key: "connections",
              title: "Connections",
              sortable: true,
            },
            {
              key: "totalTrackedHours",
              title: "Tracked Hours",
              sortable: true,
            },

            {
              key: "",
              title: "Actions",
              sortable: true,
              render: ({ row }) => {
                return <TableActions>
                <Button icon="view" onClick={()=>{
                  navigate("/programBrowser/"+row.id)
                }}/>
                <ButtonSpacer/>
                  <Button icon="edit"  variant="primary"/>
                  <ButtonSpacer/>
                  <Button icon="cross" variant="danger"/>
                </TableActions>
              }
            },
          ]}
          rows={user.programs}
        />

      </Labelize>
      <ButtonSpacer /><ButtonSpacer />
      <Labelize label={"Tasks"}>

        <Table
          columns={[
            {
              key: "program",
              title: "Program",
              sortable: true,
            },
            {
              key: "title",
              title: "Title",
              sortable: true,
            },
            {
              key: "description",
              title: "Description",
              sortable: true,
            },

            {
              key: "createDate",
              title: "Created On",
              sortable: true,
              render: ({ value }) => {
                return formatDate(value)
              }
            },
            {
              key: "dueDate",
              title: "Due By",
              sortable: true,
              render: ({ value }) => {
                return formatDate(value)
              }
            },

            {
              key: "status",
              title: "Status",
              sortable: true,
              render:({value,row})=>{
                if(value === "Incomplete"){
                  return "Incomplete"
                }
                else{
                  return "Completed on "+formatDate(row.completeDate)
                }
              }
            },

            {
              key: "",
              title: "Actions",
              sortable: true,
              render: ({ row }) => {
                return <TableActions>
                <Button icon="view"/>
                <ButtonSpacer/>
                  <Button icon="edit"  variant="primary"/>
                  <ButtonSpacer/>
                  <Button icon="cross" variant="danger"/>
                </TableActions>
              }
            },
          ]}
          rows={user.tasks}
        />

      </Labelize>
    </Body>
  </UserContainer>;
}
export default User;