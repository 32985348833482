import styled from "styled-components";
import { panelSpacing } from "../../../../../common/styles";

export const RequestListContainer = styled.div`
width:95%;
margin-left:20px;
margin-top:20px;
overflow:scroll;
`;

export const RequestsKeyHeader = styled.p`
white-space: nowrap;
    text-overflow:ellipsis;
    overflow: hidden;
    font-weight:bold;
    @media (max-width:991px){
        ${'' /* width: 200px; */}
    }
    margin:0;
    padding-right: 5px
`;
export const RequestContent = styled.p`
    overflow: hidden;
    font-weight:normal !important;
    @media (max-width:991px){
        ${'' /* width: 200px; */}
    }
    margin:0;
    padding-right: 5px
`;

export const RequestsDetailsWrapper = styled.div`
display: flex;
@media (max-width: 991px) {

flex-direction: column; 
}
`;
export const RequestsDetailsComment = styled.div`
display: flex;
flex-direction: column; 
text-align:left !important;
`;

export const Spacer = styled.div`  
margin-top:2rem; 
`;

export const RequestListWrapper  = styled.div`
width:${props => props.isMeetingTab ?'60%':'90%'};
border: 2px solid #efefef;
border-radius: 10px;
padding:10px;
margin-bottom:1rem;
margin: 10px auto;
@media (max-width: 991px) {
  width:85%;

  }


`
export const ButtonWrapper= styled.div`
justify-content:'flex-start';
display:flex;
align-items: center;

  @media (max-width: 767px) {
    flex-direction:column;
  }

`



export const NoprogramContainer= styled.div`
    padding-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;

`
export const ButtonsWraper= styled.div`
display:flex;
padding-top: 20px;
margin-inline: 8rem;
justify-content: flex-start;
`
export const ImagePreviews= styled.img`
width:${props=>props.size}px;
height:${props=>props.size}px;
margin-bottom:${props=>props.panelSpacing ?props.panelSpacing:panelSpacing}px;
display:block;
border-radius:${panelSpacing}px;
object-fit:cover;
padding-top: 40px;


`
export const NameContainer= styled.span``
export const ProgramInfo= styled.div`
display:block
`

export const RequestsKeyHeaders = styled.p`
font-style:${props=>props.fontStyles ? props.fontStyles : ''} ;
margin-top:${props=>props.marginSpace ? props.marginSpace : ''}px ;
  white-space: nowrap;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  @media (max-width: 991px) {
    width:${props=>props.widthSize ? props.widthSize : '200'}px ;
    font-size:${props=>props.size ? props.size : ''}px ;
  }
  margin: 0;
  padding-right: 5px;
`;

