import styled from "styled-components";
import { headerSize, Scrollable, panelSpacing } from "../../../../../common/styles";
import colors from "../../../../../config/colors";


export const ProgramsContainertest = styled.div`
    background-color: ${colors.secondary};
    height:calc(100vh - ${headerSize}px);
    padding:${panelSpacing}px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    // @media (max-width:991px) {
    //     flex-direction: column;
    //     gap: 20px;
    // }
`;
export const ProgramHomeContainer = styled.div`
    ${Scrollable}
    height:calc(100vh - 95px - ${panelSpacing*2}px);
`;



export const BackButton = styled.div`
padding-bottom:20px;
`
export const ProgramHomeHeader = styled.div`
    padding:${panelSpacing}px;
    border-bottom: 1px solid ${colors.secondary};
    display:flex;
    @media (max-width: 640px) {
        flex-direction: column;
        gap: 20px;
    }

`;
export const ProgramImage = styled.img`
    width:250px;
    height:250px;
    margin-right:20px;
    object-fit:cover;
    border-radius:50%;

    @media (max-width:1200px) {
        width:160px;
        height:160px;
    }
`;
export const ProgramInfo = styled.div`
    display:flex;
    align-items:flex-start;
    justify-content:center;
    flex-direction:column;
`;
export const MemberNotFound = styled.div`
   text-align:center;
   display:flex;
    justify-content:center;

   font-weight: 600;
`;
export const ProgramName = styled.div`
    font-size:32px;
    font-weight:bold;
    @media (max-width:1200px) {
       font-size: 24px;
    }
`;
export const ProgramOrganization = styled.div`
    font-size:12px;
`;
export const ProgramManagers = styled.div`
    margin:15px 0px;
`;
export const ProgramManagersLabel = styled.div`
    font-size:14px;
    font-weight:bold;
    margin-bottom:5px;
`;
export const ProgramManagersList = styled.div`
    display:flex;
    align-items: center;
`;
export const ProgramManagerImage = styled.img`
    width:30px;
    height:30px;
    border-radius:50%;
    object-fit: cover;
    cursor:pointer;
    :hover{
        opacity:0.7;
    }
    @media (max-width:1200px) {
        width:24px;
        height:24px;
    }
`;
export const ProgramTrackedHours = styled.div`
    font-size:14px;
`;

export const AboutContent = styled.div`
    padding:${panelSpacing}px;
    border-bottom: 1px solid ${colors.secondary};
`;
export const AboutTitle = styled.div`
   margin-bottom:20px;
   font-size:24px;
   font-weight:bold;
`;
export const AboutDescription = styled.div``;


export const AnnouncementsContent=styled(AboutContent)`
    border-bottom:none;
`;
export const AnnouncementsTitle=styled(AboutTitle)`
margin-bottom:0px;
`;
export const EditModeOptions = styled.div`
    display:flex;
    align-items:center;
    padding:10px;
    border-bottom: 1px solid ${colors.secondary};
    justify-content:center;
    flex-wrap: wrap;
    gap: 10px;
`;

export const LinkLine = styled.div`
    display:flex;
    align-items:center;
    @media(max-width:1024px) {
        flex-direction:column;
        gap: 10px;
        align-items: flex-start;
    }
`;

export const Link = styled.a`
    color:${colors.primary};
    margin-right:15px;
    @media (max-width:1200px) {
        font-size: 14px;
        line-break: anywhere;
    }
`;
export const PaymentContainer = styled.div`
    max-height: 500px;
        overflow-y: auto;
     ::-webkit-scrollbar {
    width: 10px; 
  }
  
`;
export const Paymentintro = styled.div`
  
`;
export const GuideOrderList = styled.ol`
  display:block
`;
export const GuideList = styled.li`
  display:block;
  max-width:950px;

`;
export const SunHeading = styled.h3`
  font-weight:bold;
  font-size:16px;
  margin-bottom:0;

`;
export const ItemInfo = styled.div`
 margin-top:10px;
`;
export const ButtonWraper = styled.div`
width:${props=>props.width}%;
margin:10px 0;
display:flex;
flex-direction:${props=>props.direction ? props.direction :'column'};
justify-content:${props=>props.gap ? props.gap : ''};
gap:10px;


`;
export const HorezentalLine = styled.hr`
color :red
`;
export const PaypalContaner = styled.div`
    max-height: 500px;
    overflow-y: auto;
     ::-webkit-scrollbar {
    width: 10px; 
  }

`;
export const PaymentNote = styled.span`display:block; 
font-weight: 700;
margin-bottom:10px;
`
export const PaymentDotContainer = styled.div`
display:flex;
gap:50px

`
export const Dot = styled.span`
display: block;
    min-width: 20px;
    max-width: 20px;
    border-radius: 50px;
    border: 1px solid green;
    min-height: 20px;
    background: green;

`
export const PaymentSuccess = styled.p`
  text-align: left;
  font-family: Inter;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0em;
  max-width:55rem;
`;
export const PaymentSuccessHeading = styled.h2`
  text-align: left;
  font-family: Inter;
  font-size: 20px;
  font-weight: 800;
  line-height: 19px;
  letter-spacing: 0em;
  color: ${colors.primary};
  text-transform:capitalize;
`;

export const PillsContainer =styled.div`
    margin-top:10px;
    display: flex;
    justify-content: center;
    align-items:center;
    padding-bottom:10px;
    width:100%;
    border-bottom: 1px solid ${colors.secondary};
    width:${props=>props.width && 'calc(100% - 20px);'};
    margin:${props=>props.margin && ' 10px'};
`;


export const ProgramImagePreview = styled.div`
position: relative;
width: 250px;
height: 250px;
margin-right: 20px;
object-fit: cover;
border-radius: 50%;
overflow-y:hidden;
@media (max-width: 1200px) {
  width: 160px;
  height: 160px;
}

img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
 .icon-overlay {
    position: absolute;
    top: 91%;
    left: 70%;
    transform: translate(-50%, -50%);
    color: white; /* Adjust the color as needed */
    font-size: 24px; /* Adjust the font size as needed */
    cursor: pointer; /* Add pointer cursor for interaction */
  height: 50px;
  margin-right: 20px;
  }
.icon-overlays {
  position: absolute;
  top: 91%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white; /* Adjust the color as needed */
  font-size: 24px; /* Adjust the font size as needed */
  cursor: pointer; /* Add pointer cursor for interaction */
height: 50px;
margin-right: 20px;
}
`;


export const ProgramImages = styled.div`
  position: relative;
  width: 250px;
  height: 250px;
  margin-right: 20px;
  object-fit: cover;
  border-radius: 50%;
overflow-y:hidden;
  @media (max-width: 1200px) {
    width: 160px;
    height: 160px;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  .icon-overlay {
    position: absolute;
    top: 91%;
    left: 70%;
    transform: translate(-50%, -50%);
    color: white; /* Adjust the color as needed */
    font-size: 24px; /* Adjust the font size as needed */
    cursor: pointer; /* Add pointer cursor for interaction */
  height: 50px;
  margin-right: 20px;
  }
`;


export const TeamContainers = styled.div`
background-color: rgb(239, 239, 239);
height: calc(100vh - 45px);
padding: 20px;
box-sizing: border-box;
display: flex;
-webkit-box-pack: justify;
justify-content: space-between;
`;

export const ProgramLists = styled.div`
width:${props=>props.width && props.width};
height:${props=>props.height && props.height};
`;
export const ProgramTeamList = styled.div`
background-color: rgb(255, 255, 255);
border-radius: 10px;
width: calc(100vw - 410px);
`;
export const ActionButton = styled.div`
display:flex;
justify-content:flex-end;
gap:5px;
margin-top:${props => props.isGroup && '20px'};
`;
export const ModalContaier = styled.div`
display:flex;
gap:5px;
align-items: center;
}
`;
export const MessageMember = styled.div`
  padding-top:${props=>props.top && props.top };
  overflow-y: auto;
  height: ${props=> props.height && props.height};
  max-height: ${props=> props.maxHeight && props.maxHeight};
  scrollbar-width: none;
`;
export const MessageMemberInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap:25px 20px;
`;
export const MemberInformation = styled.div`
  display:flex;
   flex-direction:column;
    justify-content: center;
    align-items: center;
    width:100%;
    gap:5px;
`;
export const ChatMemberContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
align-self: center;
  flex-wrap: wrap;

`;
export const Content = styled.div`
padding-top:${props=>props.top ? '1px':'10px'};
padding-bottom:10px;
`;
export const SearchHeader = styled.div`
display:flex;
margin-Top:-20px;
gap:5px;
`;
