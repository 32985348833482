import styled from "styled-components";
import { Scrollable, panelSpacing } from "../../../../../../../../common/styles";

export const TasksContainer = styled.div`
    padding:${panelSpacing}px;
    display:flex;
    width:100%;
    height:calc(100vh - 340px - ${panelSpacing*2}px);
    ${Scrollable}
    box-sizing:border-box;
`;
export const TaskTitle = styled.div``;
export const TaskName = styled.div`
    font-size:16px;
    font-weight:bold;
`;
export const TaskDate = styled.div`
    font-size:12px;
    color:${props=>props.isDue ? "red" : "green"};
    margin-top:5px;
`;
export const CreateTaskDate = styled.div`
    font-size:12px;
    color:#000;
    margin-top:5px;
`;
export const TaskContent = styled.div``;
export const TaskDescription = styled.div``;
export const TaskActions = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin-top:20px;
`;
export const TaskStatus = styled.div`
    color:${props=>props.isCompleted? "green" : "grey"};
`;