import { formatMessageDate } from "../../../../common/utils";


// export function amIOnBottomOfScroll(window) {
//     if (Window) {
//         // Handle the case where the chat window reference is null or undefined
//         return;
//     }else{
//     const scrollTop = window.current.scrollTop;
//     const scrollHeight = window.current.scrollHeight;
//     const clientHeight = window.current.clientHeight;
//     return scrollTop - clientHeight === scrollHeight;
// }}

export function amIOnBottomOfScroll(window) {
    if (!window || !window.current) {
        // Handle the case where the window reference or current property is null or undefined
        return false;
    }

    const scrollTop = window.current.scrollTop;
    const scrollHeight = window.current.scrollHeight;
    const clientHeight = window.current.clientHeight;

    return scrollTop === 0;
}


export function updateMessages(messages, newMessages) {
    if(!newMessages){
        return
    }
    let processedMessages = [];
    let groupedMessages = {};
    let newStream = [...messages, ...newMessages].filter(message => message.type !== "date");
    let dates = []
    newStream.forEach(message => {
        console.log("message.time ", message.time)
        let key = formatMessageDate(message.time);
        console.log("key    ", key)
        if (key.includes(":")) {
            key = "Today"
        }
        if (!groupedMessages[key]) {
            groupedMessages[key] = {}
            dates.push({
                key,
                date: message.time
            })
        }
        groupedMessages[key][message.time] = message;
    })

    console.log("groupedMessages ", groupedMessages)

    dates.sort((a, b) => a.date - b.date).forEach(dateInfo => {
        processedMessages.push({
            type: "date",
            dateString: dateInfo.key,
        });
        Object.keys(groupedMessages[dateInfo.key]).sort((timeA, timeB) => timeA - timeB).forEach(time => {
            processedMessages.push(groupedMessages[dateInfo.key][time]);
        })
    })
    console.log("processedMessages ",processedMessages.length)
    return processedMessages;
}
export function isActiveChat(chatWindow) {
    if (!chatWindow) {
        return false;
    }
    return (chatWindow.scrollHeight - 500) < chatWindow.clientHeight + chatWindow.scrollTop;
}

export function scrollToPosition(chatWindow, type = "bottom") {
    if(!chatWindow) {
        return
    }
    if (type == "bottom") {
        setTimeout(()=>{
            chatWindow.scrollTo({
                top: chatWindow.scrollHeight + 1000,
                behavior: 'smooth',
            });
        },10);
        
    }
    else {
        setTimeout(()=>{
        chatWindow.scrollTo({
            top: 200,
            behavior: 'smooth',
        });
    },10);
    }

}

export const scrollToBottom = (chatWindow) => {
    if (chatWindow.current) {
      chatWindow.current.scrollTop = chatWindow.current.scrollHeight;
      chatWindow.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
  };

