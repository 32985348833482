import styled from "styled-components";
import { panelSpacing } from "../../../common/styles";
import colors from "../../../config/colors";

export const BrowserContainer = styled.div`
    background-color:${colors.secondary};
    height:calc(100vh - 40px);
    width:100vw;
    display:flex;
    align-items:flex-start;
    justify-content:space-between;
    padding:${panelSpacing}px;
    box-sizing:border-box;
`;


export const BrowserTable = styled.div`
    background-color:${colors.background};
    width:calc(100vw - 350px - ${panelSpacing * 3}px);
    border-radius:10px;
    padding:${panelSpacing}px;
    box-sizing:border-box;

`;
export const BrowserFilters = styled.div`
    background-color:${colors.background};
    width:350px;
    height:calc(100vh - 40px - ${panelSpacing * 2}px);
    border-radius:10px;
    padding:${panelSpacing}px;
    box-sizing: border-box;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
`;

export const FiltersHeading = styled.div`
font-size: 24px;
font-weight:bold;
margin-bottom:20px;
`;
export const FilterActions = styled.div`
display:flex;
align-items:center;
justify-content:center;
justify-self:flex-end;
`;
export const FilterTop = styled.div``;
export const Actions=styled.div`
padding-right:10px;
display:flex;
align-items:center;
justify-content:flex-end;
`;