import styled from "styled-components";
import { Scrollable, borderRadius, panelSpacing } from "../../../../../common/styles";
import colors from "../../../../../config/colors";

export const ApplicationEditorContainer = styled.div`
`;
export const FormSection = styled.div`
    
    border-radius:${borderRadius}px;
`;
export const QuestionSection =styled.div`
border-bottom:solid 1px ${colors.secondary};
padding:10px 0px;
`;
export const QuestionNumber =styled.div`
    font-size: 16px;
    font-weight: bold;
    display:flex;
    align-items:center;
    justify-content:space-between;
`;
export const QuestionType =styled.div`
    display:flex;
    align-items: center;
    justify-content: space-between;
`;
export const QuestionTypeLabel =styled.div`
    font-size:14px;
`;
export const AddNewQuestionContainer =styled.div`
    padding:${panelSpacing}px;
    display:flex;
    align-items: center;
    justify-content: center;
`;