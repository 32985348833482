import styled from "styled-components";
import { isMobile } from "../../../common/utils";
import colors from "../../../config/colors";
import { borderRadius } from "../../../common/styles";
export const HeaderContainer = styled.div`
    height: 120px;
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding:0px 50px;
    box-sizing: border-box;
    top:0px;
    position:sticky;
    z-index: 10;
    backdrop-filter:blur(10px);
    background-color:${colors.background}55;
    ${
      isMobile() &&
      `
        padding:0px;
        justify-content: center;
        height:80px;
    `
    }
`;
export const AppLogo = styled.img`
    height:50px;
`;
export const HeaderOptions = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
    font-size:16px;
    z-index:5;
    left: ${(props) => (props.isOpen ? 0 : -100)}vw;
    ${
      isMobile() &&
      `
        position:fixed;
        flex-direction: column;
        height:100vh;
        width:calc(100vw - ${borderRadius}px);
        box-sizing: border-box;
        top:0px;
        background-color:${colors.background};
    `
    }
`;
export const HeaderOption = styled.a`
    margin:20px;
    display:flex;
    align-items: center;
    justify-content: center;
    cursor:pointer;
    transition:all 0.2s;
    text-decoration:none;
    color:${colors.text};
    :hover{
        color:${colors.primary};
    }
`;

// New Landing Page
export const HeaderOuterContainer = styled.div`
    background: #44449B;
    padding: 14px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    box-sizing: border-box;
    z-index: 10;
    @media screen and (max-width: 991px) {
        padding: 14px 20px;
    }
`;
export const AppLogoNew = styled.img`
    height: 24px;
    vertical-align: bottom;
`;

export const Menu = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 767px) {
        position: absolute;
        top: 50px;
        left: 0;
        flex-direction: column;
        gap: 40px;
        background: #44449B;
        padding: 0 20px;
        height: 30vh;
        width: 100%;
        transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(100%)')};
        transition: transform 0.3s ease-in-out;
    }
`;

export const MenuItem = styled.a`
    cursor:pointer;
    transition:all 0.2s;
    text-decoration:none;
    color:${colors.whiteText};
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    margin-right: 24px;
    &:last-child {
        margin-right: 0px;
        padding: 7px 8px;
        border-radius: 5px;
        border: 1px solid #FFF;
    }
    @media screen and (max-width: 767px) {
        margin-right: 0px;
    }
`;

export const Hamburger = styled.div`
    display: none;
    width: 24px;
    height: 20px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    z-index: 100;
    @media screen and (max-width: 767px) {
        display: flex;
    }
`;

export const Line = styled.span`
    width: 24px;
    height: 2px;
    background-color: ${({ isOpen }) => (isOpen ? "transparent" : "#FFF")};
    border-radius: 5px;
    transition: all 0.3s ease;

    &:nth-child(1) {
      transform: ${({ isOpen }) =>
        isOpen ? "rotate(45deg) translateY(10px)" : "none"};
    }

    &:nth-child(2) {
      transform: ${({ isOpen }) => (isOpen ? "opacity(0)" : "none")};
    }

    &:nth-child(3) {
      transform: ${({ isOpen }) =>
        isOpen ? "rotate(-45deg) translateY(-10px)" : "none"};
    }
`;
