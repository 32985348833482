import createReducer from "../common/createReducer";

var reducers = {

    Group_Conversation_List:(state, action) => {
        return {
            messages : action.messages
        };
    },

};


const initialState = {
};

export default createReducer(initialState, reducers);