import { React, useState } from "react";
import {
  HeaderOuterContainer,
  AppLogoNew,
  MenuItem,
  Menu,
  Hamburger,
  Line,
} from "./styles";
import LogoImage from "./mause.svg";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };
  return (
    <HeaderOuterContainer>
      <AppLogoNew
        src={LogoImage}
        onClick={() => {
          document.getElementById("home").scrollIntoView({
            behavior: "smooth",
          });
        }}
      />
      <Hamburger isOpen={isMenuOpen} onClick={toggleMenu} >
        <Line></Line>
        <Line></Line>
        <Line></Line>
      </Hamburger>
      <Menu isOpen={isMenuOpen}>
        <MenuItem
          onClick={() => {
            window.location.href = "/app";
          }}
        >
          Sign In
        </MenuItem>
        <MenuItem href="https://forms.gle/1GMziDdvqRp5mHvM9">
          Book a Demo
        </MenuItem>
      </Menu>
    </HeaderOuterContainer>
  );
};

export default Header;
