import React, { useEffect, useState } from "react";
import {
  PaymentContainer,
  GuideOrderList,
  GuideList,
  SunHeading,
  ItemInfo,
  Paymentintro,
  ButtonWraper,
  HorezentalLine,
  PaypalContaner,
} from "./styles";
import Labelize from "../../../../../common/Labelize";
import Button from "../../../../../common/Button";
import TextInput from "../../../../../common/TextInput";
import callAPI from "../../../../../api/apiCaller";
import { Context } from "../../../../../Store";
import Paypal from "./Paypal";
import TransictionInfo from "./TransictionInfo";
import { useParams } from "react-router-dom";

const Payment = ({ setIsmodalOpen, ismodalOpen,program ,title}) => {
  const [coffeeQuantity, setCoffeeQuantity] = useState(0);
  const [lunchQuantity, setLunchQuantity] = useState(0);
  const [isCoffeeClicked, setIsCoffeeClicked] = useState(false);
  const [subTotal, setSubtotal] = useState();
  const [isLunchClicked, setIsLunchClicked] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState([]);
  const [paypal, setPaypal] = useState(false);
  const [productInfo, setProductInfo] = useState(false);
  const { dispatch } = React.useContext(Context);
   const param =useParams()


  const PaymentInfo = () => {
    callAPI(dispatch, "paymentInfo", {}).then((info) => {
      setPaymentInfo(info);
    });
  };

  useEffect(() => {
    PaymentInfo();
  }, []);

  
  if (paypal) {
    return <PaypalContaner >
    <Paypal coffeeOrLunch={isLunchClicked && isCoffeeClicked ?'Lunch and Coffee on Us' :isLunchClicked ? "Lunch on Us" :  "Coffee on Us"}
     coffeeQuantity={coffeeQuantity}
     lunchQuantity={lunchQuantity}
     setPaypal={setPaypal}
     setProductInfo={setProductInfo}
     setIsmodalOpen={setIsmodalOpen}
     subTotal={subTotal}
     
 
     />
    </PaypalContaner> 
  }
  if (productInfo) {
    return <PaypalContaner >
    <TransictionInfo setPaypal={setPaypal}
    coffeeOrLunch={isLunchClicked && isCoffeeClicked ?'Lunch and Coffee on Us' :isLunchClicked ? "Lunch on Us" :  "Coffee on Us"}
     coffeeQuantity={coffeeQuantity}
     lunchQuantity={lunchQuantity}
     setIsmodalOpen={setIsmodalOpen}
     title={title}
     setSubtotal={setSubtotal}
    />
    </PaypalContaner> 
  }

  return (
    <PaymentContainer>
      <Paymentintro>{paymentInfo[0]?.heading}</Paymentintro>
      <GuideOrderList>
        <GuideList>1. Make a selection below.</GuideList>
        <GuideList>2. Mentors will receive a QR code to use at the cafe or restaurant of their choice 30 minutes before their scheduled in-person meeting with their mentee.</GuideList>  
        <SunHeading>Your Balance: </SunHeading>
          <GuideList>Coffee:{program.programBalance?.availableCoffees ? program.programBalance?.availableCoffees : 0}</GuideList>
          <GuideList>Lunch:{program?.programBalance?.availableLunch ? program?.programBalance?.availableLunch : 0}</GuideList>
      </GuideOrderList>
      <ItemInfo>
        <SunHeading>{paymentInfo[0]?.product1} (${paymentInfo[0]?.coffeePrice} each)</SunHeading>
        <Labelize
          label={
            "Cover the cost of coffee for each in-person  meeting that gets scheduled on the platform"
          }
        ></Labelize>
        <ButtonWraper width={12}>
          <Button
            text={"Select"}
            variant="primary"
            isNotRound={true}
            onClick={() => {
           setIsCoffeeClicked(true)
            }}
            disabled={isCoffeeClicked}
          />
         {isCoffeeClicked && <TextInput
            type="Number"
            isNotRound={true}
            value={coffeeQuantity}
            setValue={setCoffeeQuantity}
            min={0}
          />}
        </ButtonWraper>
        <HorezentalLine />
        <SunHeading>{paymentInfo[0]?.product2} (${paymentInfo[0]?.lunchPrice} each)</SunHeading>
        <Labelize
          label={
            "Cover the cost of lunch for each in-person meeting that gets scheduled on the platform"
          }
        ></Labelize>
        <ButtonWraper width={12}>
          <Button
           text={ "Select"}
            variant={"primary"}
            disabled={isLunchClicked}
            isNotRound={true}
            onClick={() => {setIsLunchClicked(true)}}
          />
           {isLunchClicked && <TextInput
            type="Number"
            isNotRound={true}
            value={lunchQuantity}
            setValue={setLunchQuantity}
            min={0}
          />}
        </ButtonWraper>
      </ItemInfo>
      <ButtonWraper gap={"flex-end"} direction={"row"} width={100}>
        <Button
          text={"Cancel"}
          icon={"cross"}
          variant="primary"
          isNotRound={true}
          onClick={() => setIsmodalOpen(false)}
        />
        <Button
          text={"Next"}
          icon={"tick"}
          variant="primary"
          isNotRound={true}
          onClick={() => setProductInfo(true)}
          disabled={lunchQuantity <= 0  && coffeeQuantity <=0 ? true : false}
        />
      </ButtonWraper>
    </PaymentContainer>
  )
}

export default Payment;
