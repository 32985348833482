import styled from "styled-components";
import colors from "../../../../config/colors";
import { headerSize, panelSpacing } from "../../../../common/styles";

export const BillingContainer = styled.div`
   width:100%;
    padding:20px;
    display:flex;
    justify-content:center
    gap:30px;
    @media(max-width:991px) {
       flex-direction:column;
     }
`;
export const BillingInfo = styled.div`
   width:100%;
   padding:10px;
   border-right:1px solid #e8e9e9;
   
`;
export const AboutTitle = styled.div`
   margin-bottom:20px;
   margin-top:10px;
   font-size:24px;
   font-weight:bold;
   text-align:center;
`;
export const PaymentInfo = styled.div`
   width:98%;
   padding:10px;  
  height: 100%;



`;
export const BillingTypo = styled.h4`
   text-align:center;
   font-size:25px;
   margin-top:10px
   margin-bottom:10px

`;
export const InputWraper = styled.div`
   padding-top:10px;
   display:flex;
   justify-content:center;
   gap:10px;

`;
export const AdressInfo = styled.div`
   padding-top:10px;
   display:flex;
   justify-content:center;

`;
export const ButtonWraper = styled.div`
    margin-top: 30px;
    text-align: center;
    display: flex;
    margin-inline:auto;
 justify-content:${(props) => (props.space ? props.space : "center")};
 align-items:${(props) => (props.space ? "end" : "")};

`;
export const CardInfo = styled.div` 
padding: 10px;
width: 100%;
max-width:28.938rem;
background-color: #e8e9e9;
   border-radius:10px;
`;
export const PaymentLabel = styled.p`
 margin-top:${(props) =>props.size ? 0 : 5}px;
 font-size:${(props) => (props.size ? props.size : 12)}px;
 color:${props=>props.color ? props.color : '#848484'};
 margin-left:${props=>props.color && '5px'};
margin-bottom: ${(props) => (props.bottom ? props.bottom : 1)}px;
`;

export const Subtotal = styled.span`
 margin-top:5px;
 font-size: 14px;
 color:#000;
 margin-left:${props=>props.left ? props.left :''}px;
 margin-top:-5px;
 
`;

export const ProgramItem = styled.h1`
font-family: Inter;
margin-top:10px;
font-size: 16px;
font-weight: 700;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
 overflow: ${props=>props.overflow ? props.overflow:''}
`;
export const LabelWraper = styled.div`
display:block;
margin-bottom:5px;
`;

export const Invoices = styled.div`
width: 100%;
display: flex;
flex-wrap: wrap; 
justify-content:center;
gap: 10px;
overflow: hidden;
`
export const TestFLow= styled.div`width: 100%;
display: flex;
flex-wrap: wrap; 
justify-content:center;
gap: 10px;
max-height: 800px; 
overflow-y: scroll;
::-webkit-scrollbar {
    width: 10px;
}`
//   ReceiptCss

export const ReceiptContainer = styled.div`
display: block;
width: 100%;
background: #fff;
max-width: 350px;
padding: 25px;
margin: 50px auto 0;
`
export const ReceiptHeader = styled.div`
padding-bottom: 40px;
border-bottom: 1px dashed #000;
text-align: center;
`
export const ReceiptHeading = styled.h1`
font-size: 20px;
margin-bottom: 5px;
text-transform: uppercase;
`

export const ReceiptSpan = styled.span`
display: block;
font-size: ${props=>props.font ? props.font : ''};
`
export const ReceiptSubHead = styled.h2`
font-size: 14px;
color: #727070;
font-weight: 300;

`
export const ReceiptBody = styled.div`
margin-top: ${props=>props.top ? props.top :''}px;

`
export const ReceiptTable = styled.table`
width: 100%;

`
export const ReceiptThead = styled.thead`
position: relative;
&::after {
    content: '';
    width: 100%;
    border-bottom: 1px dashed #000;
    display: block;
    position: absolute;
    left:0;
  }

`
export const ReceiptFoot = styled.tfoot`
position: relative;
border-top: 1px dashed #000;
&:last-child {
    text-align: right;
        
      }
`
export const Rth = styled.th`
text-align: left;
border-top: 1px dashed #000

  &:last-child {
text-align: right;
    
  }

`
export const ReceiptSubheading = styled.div`
padding-top: 10px;
margin-top: 25px;
text-align: center;
text-transform: uppercase;

`
export const ReceiptButton = styled.div`
margin-top: 5px;
text-align: center;
text-transform: uppercase;
background-color: ${colors.primary};
border-radius: 49px;
padding: 5px;
color:white;

`
export const ReceiptItem = styled.div`
margin-top: 25px;


`
export const ReceiptDateTime = styled.div`
display: flex;
justify-content: center;
column-gap: 25px;


`

export const ReceiptTBody = styled.tbody`
text-align: left;

&:last-child {
    text-align: right;
        
      }
`
export const ReceiptRow = styled.tr`
text-align: left;
border-top: 1px dashed #000;

&:last-child {
    text-align: right;
        
      }
`
export const ProgramLoaderContainer = styled.div`
    
    background-color:${colors.background};
    border-radius:${panelSpacing}px;
    box-sizing:border-box;
    height:calc(100vh - ${headerSize+2*panelSpacing}px);
`;


export const NoBillingHistoryContainer = styled.div`
    background-color:${colors.background};
    border-radius:${panelSpacing}px;
    box-sizing:border-box;
    height:calc(100vh - ${headerSize+2*panelSpacing}px);
    display:flex;
    align-items:center;
`;

