import React, { useEffect } from 'react';
import styled from 'styled-components';
import { validatePhoneNumber } from '../utils';
import countries from "../../../countries.json"

const PhoneNumberInputWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #efefef;
  border-radius: 50px;
  padding: 8px;
  background-color: #efefef;
`;

const CountryCode = styled.div`
  margin-right: 8px;
  padding: 2px;
  border: 1px solid #efefef;
  border-radius: 37px;
  background: #efefef;
  color: #757589;
`;

const PhoneNumberInput = styled.input`
  border: none;
  flex: 1;
  outline: none;
  background-color: #efefef;
  color: #757589;
`;
const SelectStyle= styled.select`
background-color: #efefef;
border: 1px solid #efefef;
`

const PhoneNumberField = ({ setValue,countryCodes,phoneNumber, onChange,setIsValid = () => { } }) => {

  useEffect(()=>{

  let fullPhoneNumber=''
  if(phoneNumber.contryCode && phoneNumber.phoneNumber)
  {
    fullPhoneNumber=phoneNumber.contryCode+phoneNumber.phoneNumber
    if(phoneNumber.phoneNumber.length===10){
    setValue(fullPhoneNumber)
    setIsValid(validatePhoneNumber(fullPhoneNumber))
    }
    else{
      setIsValid(false)
    }
  }
  fullPhoneNumber=phoneNumber.contryCode+phoneNumber.phoneNumber
  },[setIsValid,onChange, phoneNumber])
  return (
    <PhoneNumberInputWrapper>
      <CountryCode>
        <SelectStyle value={phoneNumber.contryCode} name='contryCode' onChange={onChange}>
          {countries.map((country) => (
            <option key={country.dial_code} value={country.dial_code}>
              {country.dial_code}
            </option>
          ))}
        </SelectStyle>
      </CountryCode>
      <PhoneNumberInput
        type="tel"
        name='phoneNumber'
        value={phoneNumber.phoneNumber}
        onChange={onChange}
        placeholder="Phone Number"
      />
    </PhoneNumberInputWrapper>
  );
};

export default PhoneNumberField;
