import styled from "styled-components";
import colors from "../../../../../config/colors";

export const ErrorContainer = styled.div`
  width: 90%;
  margin:auto;
  padding: 100px;
  height:100vh;
  @media (max-width:1440px) {
    padding: 30px;
    }
`;
export const ErrorContainerWrapper = styled.div`
  display: flex;
  align-item: center;
  justify-content: "space-between";
  text-align: center;
`;
export const ErrorTextWrapper = styled.div`
  max-width: 60%;
  min-width: 60%;
  padding-top:50px;
`;
export const ErrorText = styled.p`
  font-size: 36px;
  min-width: 55%;
  max-width: 54px;
  text-transform: inherit;
  margin:auto;
  padding-top:${props=>props.top}px;
  @media (max-width:1440px) {
    padding-top: 10px;
    }
`;
export const HeadingText = styled.h1`
  color: ${colors.primary};
    font-size: 100px;
    font-weight: bold;
    margin-top: 70px;
    display: flex;
    justify-content: center;
    align-self: center;
    margin-bottom: auto;
}
`;
export const ImagePreview = styled.img`
  width: ${(props) => props.size}%;
  height: ${(props) => props.size}%;
  border-radius: 50%;
  object-fit: cover;
`;
export const ImageWrapper = styled.div`

`;
