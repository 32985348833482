import React from "react";
import { useParams } from "react-router-dom";
import callAPI from "../../../../../api/apiCaller";
import Button from "../../../../../common/Button";
import Loading from "../../../../../common/Loading";
import Modal from "../../../../../common/Modal";
import { Actions } from "../../ProgramContent/ProgramParticipants/ActiveParticipants/styles";
import {
  ReassignMenteeContainer,
  MentorRow,
  MentorName,
  MentorInfo,
  MentorImage,
} from "./styles";
import { Context } from "../../../../../Store";

function ReassignMentee({ menteeId, closeModal, program,team,getMyTeam ,programInformation}) {
  const params = useParams();
  const programId = params.selectedProgram;
  const [mentorList, setMentorList] = React.useState(null);
  const [isReassigning, setIsReassigning] = React.useState(false);
  const [action, setAction] = React.useState(null)
  const [teamRole, setTeamRole] = React.useState(null);
  const { state, dispatch } = React.useContext(Context);

 
const getAvailableMentor=()=>{
  callAPI(dispatch, "getAvailableMentors", {
    id: params.selectedProgram,
  }).then((res) => {
    if (res.isResponseOk) {
      setMentorList(res.data);
    }
  });
}

  React.useEffect(() => {
    getAvailableMentor()
  }, []);
  
  function reassignMentee(mentorId) {
    setIsReassigning(mentorId);
    callAPI(dispatch, "reassignMentee", {
      id: params.selectedProgram,
      mentorId,
      menteeId,
    }).then((res) => {
      closeModal(true);
      setIsReassigning(false);
      
    });
  }

  function assignMember(TeamId,Role) {
    setIsReassigning(TeamId);
    callAPI(dispatch, "AssignMemberToTeam", {
      // id: params.selectedProgram,
      teamId:TeamId,
      userId:+menteeId,
      role:Role
    }).then((res) => {
      closeModal(true);
      setIsReassigning(false);
      getAvailableMentor()
      getMyTeam()
      programInformation()
      
    });
  }
  if (program?.programType !== "TEAM" && mentorList  === null) {
    return (
      <Modal
        onClose={() => {
          closeModal(false);
        }}
        title="Reassign Mentee"
      >
        <Loading loadingItem="Available Mentors" />
      </Modal>
    );
  }
  if (program?.programType === "TEAM" && !team) {
    return (
      <Modal
        onClose={() => {
          closeModal(false);
        }}
        title="Reassign Member"
      >
        <Loading loadingItem="Team Members" />
      </Modal>
    );
  }
  return (
    <Modal
      onClose={() => {
        closeModal(false);
      }}
      title={program?.programType === "TEAM" ? "Assign Member":"Assign Mentee"}
      actions={[
        {
          text: "Cancel",
          onClick: () => {
            closeModal(false);
          },
        },
      ]}
    >
      {program?.programType === "TEAM" ? (
          <ReassignMenteeContainer>
            {team && team.map((member) => {
              return (
                <MentorRow>
                  <MentorInfo>
                    <MentorImage
                      src={
                        member?.media
                          ? member?.media
                          : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                      }
                    />
                    <MentorName>{member?.name}</MentorName>
                  </MentorInfo>
                  <Actions>
                    <Button
                      onClick={() => {
                        setAction('mentorAssigned')
                        assignMember(member.id,'mentor');
                      }}
                      variant="secondary"
                      text="Assign As Mentor"
                      icon={isReassigning === member.id && action==='mentorAssigned' ? "loading" : "tick"}
                    />
                    <Button
                      onClick={() => {
                        setAction('memberAssigned')
                        assignMember(member.id,'member');
                      }}
                      variant="primary"
                      text="Assign"
                      icon={isReassigning === member.id && action==='memberAssigned' ? "loading" : "tick"}
                    />
                  </Actions>
                </MentorRow>
              );
            })}
          </ReassignMenteeContainer>
      ) : (
        <ReassignMenteeContainer>
          {mentorList.map((mentor) => {
            return (
              <MentorRow>
                <MentorInfo>
                  <MentorImage
                    src={
                      mentor.participant.image
                        ? mentor.participant.image
                        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                    }
                  />
                  <MentorName>
                    {mentor.participant.firstName +
                      " " +
                      mentor.participant.lastName}
                  </MentorName>
                </MentorInfo>
                <Actions>
                  <Button
                    onClick={() => {
                      reassignMentee(mentor.id);
                    }}
                    variant="primary"
                    text="Assign"
                    icon={isReassigning === mentor.id ? "loading" : "tick"}
                  />
                </Actions>
              </MentorRow>
            );
          })}
        </ReassignMenteeContainer>
      )}
    </Modal>
  );
}
export default ReassignMentee;
