import styled from "styled-components";
import { Scrollable, panelSpacing } from "../../../../../common/styles";

export const ProgramConnectionsContainer = styled.div`
    ${Scrollable}
    height:calc(100vh - 180px - ${panelSpacing*2}px);
`;
export const ConnectionSection = styled.div`
    padding:0px 20px;
`;
export const ConnectionsHeading = styled.div`
    font-size: 24px;
`;
export const ConnectionsList = styled.div`
    padding:${panelSpacing}px 0px;
    display: flex;
    flex-wrap: wrap;
`;
export const Span = styled.span`
    font-size 18px;
    font-weight:bold;
    text-align:center;
    padding-inline:20px  
`;