import React from "react";
import { useParams } from "react-router-dom";
import callAPI from "../../../../../../../../api/apiCaller";
import Button from "../../../../../../../../common/Button";
import { ButtonSpacer } from "../../../../../../../../common/Button/styles";
import Loading from "../../../../../../../../common/Loading";
import { formatDate } from "../../../../../../../../common/utils";
import { UpdatesContainer,UpdateContainer,UpdateHeader,UpdaterImage,Updater,UpdaterName,UpdateTime,UpdateContent,UpdateActions } from "./styles";
import { Context } from "../../../../../../../../Store";

function Updates() {
  let params = useParams();
  const [updates, setUpdates] = React.useState(null);
  const [likeBeingUpdated,setLikeBeingUpdated] = React.useState(null);
  const {state,dispatch} = React.useContext(Context);
  React.useEffect(() => {
    callAPI(dispatch,"getUpdates",{programId:params.selectedProgram,userId:params.connectionId})
    .then((data) => setUpdates(data));
  }, []);

  function likeUpdate(id) {
    setLikeBeingUpdated(id);
    callAPI(dispatch,"updateLike",{updateId:id})
    .then((data) => {
      setLikeBeingUpdated(null);
      setUpdates(data)
    });
  }

  if(updates === null) return <Loading loadingItem="Updates" />;

  return <UpdatesContainer>
    {
      updates.map((update) => {
        return <UpdateContainer key={update}>
          <UpdateHeader>
              <UpdaterImage src={update.user.image?update.user.image:"https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"}/>
              <Updater>
                <UpdaterName>{update.user.name}</UpdaterName>
                <UpdateTime>{formatDate(update.time)}</UpdateTime>
              </Updater>
          </UpdateHeader>
          <UpdateContent>
            {update.content}
          </UpdateContent>
          <UpdateActions>
            <Button 
              icon={likeBeingUpdated === update.id ? "loading":"like" }
              text={update.isLiked ? "Liked" : "Like"} 
              onClick={() => likeUpdate(update.id)}
              variant={update.isLiked? "primary" : "secondary"}
            />
          </UpdateActions>
        </UpdateContainer>
      })
    }
  </UpdatesContainer>;
}
export default Updates;