import React from "react";
import {
    MentorOuterContainer,
    MentorContainer,
    MentorHeading,
    MentorStepOne,
    MentorSuggestionImage,
    MentorStep,
    StepHeading,
    StepDescription,
    MentorAssignImage,
    MentorStepTwo,
    MentorMatchImage,
    MentorSuggestionLines,
    MentorMatchLines
} from './styles';
import MentorSuggestion from './mentorView.svg';
import MentorAssign from './editApplictnFormModal.svg';
import MentorMatch from './MentorMatch.svg';
import MentorSuggestedLines from './MentorSuggestionLines.svg';
import MentorMatchedLines from './MentorMatchLines.svg';


const MentorView = () => {
  return (
    <MentorOuterContainer>
    <MentorContainer>
      <MentorHeading>Mentor-to-Mentee matching made easy in 2 steps.</MentorHeading>
      <MentorStepOne>
        <MentorSuggestionImage src={MentorSuggestion} alt="Mentor Suggestions"/>
        <MentorAssignImage src={MentorAssign} alt="Mentor Assign"/>
        <MentorStep>
          <StepHeading>Step 1:</StepHeading>
          <StepDescription>Coordinators can quickly match mentors with mentees based on experiences and shared interests</StepDescription>
        </MentorStep>
      </MentorStepOne>
      <MentorStepTwo>
        <MentorStep isLeft={true}>
          <StepHeading>Step 2:</StepHeading>
          <StepDescription>Voila! That's all it takes to make a powerful mentor-mentee match</StepDescription>
        </MentorStep>
        <MentorMatchImage src={MentorMatch} alt="Mentor Match" />
      </MentorStepTwo>

    </MentorContainer>
    <MentorSuggestionLines src={MentorSuggestedLines} alt="Mentor Suggestion Lines" />
    <MentorMatchLines src={MentorMatchedLines} alt="Mentor Match Lines" />
    </MentorOuterContainer>
  );
};

export default MentorView;
