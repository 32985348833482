import React from "react";
import {
  ServicesPanelOuterContainer,
  ServiceTitle,
  ServiceSubText,
  ServicesCards,
  ServicesCard,
  ServicesImage,
  ServicesCardTitle,
  ServicesCardText,
} from "./styles";
import SmartMatch from './smartMatch.svg';
import MentorRetention from './mentorRentention.svg';
import ProvideInsight from "./Insight.svg";

function ServicesPanel() {
  return (
    <ServicesPanelOuterContainer>
      <ServiceTitle>How can we help you?</ServiceTitle>
      <ServiceSubText>
        We help you build opportunities that are more effective, accessible, and
        sustainable
      </ServiceSubText>
      <ServicesCards>
        <ServicesCard>
          <ServicesImage src={SmartMatch} alt="Smart Match"/>
          <ServicesCardTitle>Smart Match</ServicesCardTitle>
          <ServicesCardText>
            Quickly match your mentors and mentees, hassle free
          </ServicesCardText>
        </ServicesCard>
        <ServicesCard>
          <ServicesImage src={MentorRetention} alt="Mentor Retention"/>
          <ServicesCardTitle>Mentor Retention</ServicesCardTitle>
          <ServicesCardText>
            One Mauve provides the tools you need to keep your mentors motivated
            and active
          </ServicesCardText>
        </ServicesCard>
        <ServicesCard>
          <ServicesImage src={ProvideInsight} alt="Provide Insight" />
          <ServicesCardTitle>Provide Insight</ServicesCardTitle>
          <ServicesCardText>
            Built-in program analytics provides real-time insight into
            mentorship performance and efficacy.
          </ServicesCardText>
        </ServicesCard>
      </ServicesCards>
    </ServicesPanelOuterContainer>
  );
}
export default ServicesPanel;
