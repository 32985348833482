import styled from "styled-components";
import colors from "../../../../../../config/colors";

export const CompletionContainer = styled.div`
`;




export const MainText = styled.div`
    font-weight:bold;
    font-size:18px;
    margin-bottom:10px;
`;
export const SubText = styled.div`
font-size:14px;
margin-bottom:10px;
`;
export const LinkLine = styled.div`
    display:flex;
    align-items:center;
`;
export const Link = styled.a`
    color:${colors.primary};
    margin-right:15px;
`;