import styled from "styled-components";
import { panelSpacing } from "../../../../common/styles";

export const YourAccountContainer = styled.div`
    padding:${panelSpacing}px;
`;
export const YourAccountTitle = styled.div`
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
`
export const FormActions = styled.div`
    margin:20px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
export const ShortInfoSection = styled.div`
    display:flex;
    align-items: center;
    justify-content:space-between;
`;
export const AccountHeader=styled.div`
@media(max-width:991px){
    display: flex;
    gap: 5px;
}
`


export const InfoSection = styled.div`
    flex-grow:1;
    margin-right:${panelSpacing}px;
`;
export const ImageContainer = styled.div`
    width:150px;
`;
export const Popup = styled.div`
    position: absolute;
    top: 555px;
    right: 50px;
    z-index: 9;

`;