import styled from "styled-components";
import colors from "../../../../../../config/colors";

export const ProgramsListItemContainer = styled.div`
    display:flex;
    align-items: center;
    height:40px;
    padding:0 20px;
    justify-content:space-between;
    color: ${props => props.isActive ? colors.background : colors.text};
    background-color: ${props => props.isActive ? colors.primary : colors.background};
    transition: all 0.1s ease-in-out;
    :hover{
        color: ${props => props.isActive ? colors.secondary : colors.primary};
    }
    :active{
        transform: scale(0.99);
    }
    cursor: pointer;
    font-size:14px;
`;

export const ItemSection = styled.div`
    display:flex;
    align-items: center;
`;
export const ItemNumber = styled.span`
margin-left: -30px;
    border-radius: 40px;
    padding: 3px;
    min-width: 15px;
    text-align: center;
    color: '#fff';
    color: white;
    font-size:12px;
background: ${props => props.isActive ? colors.secondary : colors.primary};

 
`;
export const GrabHandle = styled(ItemSection)`
    cursor: grab;
`;
export const ProgramImage = styled.img`
    width:25px;
    height:25px;
    border-radius:5px;
    margin-right:10px;
    
`;
export const ProgramName = styled.div`
    white-space: nowrap;
    text-overflow:ellipsis;
    width:265px;
    overflow: hidden;
    @media (max-width:991px){
        width: 200px;
    }
`;