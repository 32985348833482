import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import callAPI from "../../../../api/apiCaller";
import Button from "../../../../common/Button";
import { ButtonSpacer } from "../../../../common/Button/styles";
import Loading from "../../../../common/Loading";
import TextInput from "../../../../common/TextInput";
import { isMobile, isResponsive, validateText } from "../../../../common/utils";
import { Context } from "../../../../Store";
import ChatBubble from "./ChatBubble";
import DateLine from "./DateLine";
import {
  ChatWindowContainer,
  ChatHeader,
  ChatUser,
  UserStatusDot,
  ChatBody,
  ChatContent,
  NewMessage,
  NoChatCOntentContainer,
  Members,
  MemberInformations,
  MemberDetails,
  Container,
  CloseButton,
  RemainingImages,
  ButtonAction,
  Header,
  ChatContainerOverall,
} from "./styles";
import {
  HamburgerIconContainer,
  HamburgerIconContainerSpan,
} from "../../Programs/ProgramContent/styles";
import { io } from "socket.io-client";
import { getUserInfo } from "../../../../Store/User/accessors";
import {
  MemberImage,
  MemberName,
  Span,
} from "../../Programs/popups/TaskMembers/styles";
import { scrollToBottom, scrollToPosition } from "./utils";
import { socket } from "../../../../common/chat-socket";
import Modal from "../../../../common/Modal";
import {
  ChatMemberContainer,
  MemberInformation,
  MessageMember,
} from "../../Programs/ProgramContent/MyTeams/styles";
import { MemberContainer, SubHeading } from "../../Programs/ProgramContent/ProgramConnections/ProgramConnection/ConnectionProfile/Collaborate/style";
import { UserNotPartOfGroupMesssage } from "../MessageList/styles";
import { ChatBubbleContainer } from "./ChatBubble/styles";

function ChatGroupWindow(props) {
  const params = useParams();
  let { state, dispatch } = React.useContext(Context);
  const navigate=useNavigate()
  const [messages, setMessages] = useState([]);
  const [loadMessages, setLoadMessages] = useState([]);
  const [members, setMembers] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [message, setMessage] = useState("");
  const chatWindow = React.useRef(null);
  const allWindow = React.useRef(null);
  const [showMembers, setShowMembers] = useState(false);
  const [showAllMembers, setShowAllMembers] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [selectedHovered, setSelectedHovered] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [isUserLeftGroup, setIsUserLeftGroup] = useState(null)
  const [page, setPage] = React.useState(2);
  const [loading, setLoading] = React.useState(false);
  const [shouldScrollToBottom, setShouldScrollToBottom] = React.useState(true);
  const [limit ,setLimit]=React.useState(30);
  const [totalPage ,setTotalPage]=React.useState(null);
  const handleJoinRoom = () => {
    socket.emit("joinRoom",{ roomName: params.user, user: getUserInfo(state)});
  };
  const handleLeaveRoom = () => {
    socket.emit("leaveRoom", params.user, getUserInfo(state));
  };

  function groupMessages() {
    callAPI(dispatch, "getGroupMessages", { id: params.user, page:1, take:limit}).then((res) => {
      const userLeft = res.data.messages?.userLeft;
      let messages = res.data.messages.messages ? res.data.messages.messages : [];
    
      const findUserLeftIndex = (messages, userId) => {

        const getleftMessageLastIndex = messages.findLastIndex((message)=>message.messageType==="leftconversation" && message.userId === userId)
         return getleftMessageLastIndex;
        // return messages.findIndex((message) => message.messageType === "leftconversation" && message.userId === userId);
      };
      
      // If user left the conversation
      if (userLeft) {
        const leftIndex = findUserLeftIndex(messages, getUserInfo(state).id);
        if (leftIndex !== -1) {
          messages = messages.slice(0, leftIndex + 1);
        }
        setMessages(messages);
  
      }

      setMessages(messages);
      setTotalPage(res.data.totalPages)
      setIsUserLeftGroup(res.data.messages?.userLeft)
      setMembers(res.data.messages.members?res.data.messages.members:[]);
      setTeamMembers(res.data.messages.team.teamMembers.concat(res.data.messages.managers));
    });
  }

  const ids = selectedUser.map((member) => member.user.id);
  function addNewMember() {
    callAPI(dispatch, "createNewUserInConversation", {
      id: params.user,
      userIds: ids,
    }).then((res) => {
      setShowAllMembers(false);
      setSelectedUser([]);
      groupMessages();
      selectedUser([])

    });
  }

  const leaveGroup = () => {
    callAPI(dispatch, "leaveGroupMessages", { id: params.user }).then((res) => {
      handleLeaveRoom()
    // navigate("/messages/group")
    window.location.reload()


    });
  };


  
  const selectedUsers = (member) => {
    // Check if the member already exists in the array
    if (!selectedUser.includes(member)) {
      setSelectedUser((prevSelectedUsers) => [...prevSelectedUsers, member]);
    }
  };
  const removeUser = (memberToRemove) => {
    setSelectedUser((prevSelectedUsers) =>
      prevSelectedUsers.filter((member) => member !== memberToRemove)
    );
  };
  useEffect(()=>{
    if(message?.length > 30  ){
      scrollToBottom(allWindow)
  
    }
  },[message])

  const EnterPress=(e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }
  useEffect(() => {
    if (params.user) {
      groupMessages();
      handleJoinRoom();

    }
    return(()=>{setIsUserLeftGroup(false)
      setMessages(null)
      setPage(2)
    })

  }, [params.user]);
  function handleSubmit() {
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        user: getUserInfo(state),
        message: message,
        createdAt: new Date()
      },
    ]);

    socket.emit("message", {
      user: getUserInfo(state),
      room: params.user,
      message,
    });
    scrollToBottom(chatWindow);
    setMessage("");
    props.setRefresh(!props.refresh);
    
  }

  const handleScroll = async (e) => {
    const { scrollTop } = e.target;
  if (scrollTop === 0 ) {
    if(totalPage < page) return;
    const prevScrollHeight = chatWindow.current.scrollHeight;
    setShouldScrollToBottom(false)
    setPage(page => page+1); // Increment page
    setLoading(true);
  
        try {
           
          const res = await callAPI(dispatch, "getGroupMessages", { id: params.user, page:page, take:limit})
          setMessages(prevMessages => [...res.data.messages.messages, ...prevMessages]);  
             console.log(res.data.messages,"<<<<<res.data.messages");

            setTotalPage(res.data.totalPages)

           setLoading(false);

          


        } catch (error) {
            // Handle errors
            console.error('Error fetching messages:', error);
        } finally {
          requestAnimationFrame(() => {
            const newScrollHeight = chatWindow.current.scrollHeight;
            chatWindow.current.scrollTop = newScrollHeight - prevScrollHeight;
          });
          // setLoading(false);
        }
    }
};



// this scroll to bottom use for to stay scroll at bottom
React.useLayoutEffect(() => {
  if(shouldScrollToBottom && chatWindow.current)
  {
    scrollToBottom(chatWindow);
    // setIsRender(true)
  }

  if (chatWindow.current) {
    const bottomMessage = chatWindow.current.lastChild;
    if (bottomMessage) {
      // bottomMessage.scrollIntoView({ behavior: "smooth", block: "end"  });
      scrollToBottom(chatWindow)
    }
  }
}, [ params.user, messages]);
 
  //
  useEffect(() => {
    // Listen for incoming messages from the server
    socket.on("message", (msg) => {
      msg.createdAt= new Date()

      if (msg.user.id !== getUserInfo(state).id && params.user==msg.room  ){
         scrollToBottom(chatWindow);
        setMessages((prevMessages) => [...prevMessages, msg]);
        // scrollToPosition(chatWindow.current, "bottom");
      }
    });
    return(()=>{setIsUserLeftGroup(false)
      setMessages(null)
    })

  }, []);

  useEffect(() => {
    return(()=>{
      setMessages(null)
    })
  },[params.user]);

  if(params.user && messages===null){
    return (
      <ChatWindowContainer>
      <Loading loadingItem="Group Messages" />
    </ChatWindowContainer>
      )

  }

  if (!params.user) {
    return (
      <>
        <ChatWindowContainer isHeading={true}>
          <NoChatCOntentContainer>
            <HamburgerIconContainer
              onClick={() => props.setHamburgerOpen(!props.hamburgerOpen)}
            >
              <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
              <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
              <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
            </HamburgerIconContainer>
          </NoChatCOntentContainer>
          Select Contact to view chat
        </ChatWindowContainer>
      </>
    );
  }
  if (!members) {
    return (
      <ChatWindowContainer>
        <Loading loadingItem="Group Messages" />
      </ChatWindowContainer>
    );
  }

  return (
    <>
      <ChatWindowContainer>
        <ChatHeader>
          <ChatUser>
            <HamburgerIconContainer
              onClick={() => props.setHamburgerOpen(!props.hamburgerOpen)}
            >
              <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
              <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
              <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
            </HamburgerIconContainer>
            <Members>
              <MemberInformations>
                {members.slice(0, 3).map((member) => {
                  return (
                    <MemberDetails>
                      <MemberImage
                        src={
                          member?.user?.image
                            ? member.user.image
                            : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                        }
                      />
                      {/* <UserStatusDot group={true} status={"Online"} /> */}
                      <MemberName color={true}>
                        {member?.user?.firstName?.length > 5
                          ? member?.user?.firstName.slice(0, 5) + ".."
                          : member?.user?.firstName}
                      </MemberName>
                    </MemberDetails>
                  );
                })}
                {members.length > 3 && (
                  <RemainingImages onClick={() => setShowMembers(true)}>
                    +
                    <span style={{ textDecoration: "underline" }}>
                      {members.length - 3}
                      {"  "} More
                    </span>
                  </RemainingImages>
                )}
              </MemberInformations>
              {!isUserLeftGroup &&
              <ButtonAction> 
                <Button
                  text={"Add User"}
                  width={50}
                  isNotRound={true}
                  onClick={() => {
                    setShowAllMembers(true)
                    setSelectedUser([])
                    
                  }}
                />
                <Button
                  text={"Leave Chat"}
                  width={50}
                  onClick={() => leaveGroup()}
                  isNotRound={true}
                />
              </ButtonAction>
              }
            </Members>
          </ChatUser>
        </ChatHeader>
        <ChatContainerOverall ref={allWindow}> 
        <ChatBody>
          <ChatContent  onScroll={(e)=>handleScroll(e)} ref={chatWindow}>
          {loading && <Loading loadingItem="Chats" isLoading={true}/>}
            {messages && messages.map((message) => {
                  if (message?.messageType && message?.messageType === "leftconversation") {
                    return <DateLine dateString={message?.message} />;
                  } else {
                    return (
                      <ChatBubble
                        message={message}
                        userImage={message?.user?.image}
                        variant={true}
                      />

                    );
                  }
                })
              }
          </ChatContent>
        </ChatBody>
        <NewMessage>
        {

          isUserLeftGroup? 
          <UserNotPartOfGroupMesssage >
          You can't send messages to this group because you're no longer a member
          </UserNotPartOfGroupMesssage>
          :<>
         {isResponsive() ? <TextInput
            placeholder="Type a message"
            value={message}
            setValue={setMessage}
            //   isValid={isNewMessageValid}
            //   setIsValid={setIsNewMessageValid}
            validator={validateText}
            onKeyDown={(e)=>EnterPress(e)}
            isTextArea={true}
            isHeight={message?.length > 30 ?true: false}
          />:<TextInput
            placeholder="Type a message"
            value={message}
            setValue={setMessage}
            //   isValid={isNewMessageValid}
            //   setIsValid={setIsNewMessageValid}
            validator={validateText}
            onKeyDown={(e)=>EnterPress(e)}
            isTextArea={true}
            isHeight={message?.length > 180 ?true: false}
          />}
           <ButtonSpacer />
          <Button
            icon="send"
            variant="primary"
            //   disabled={!isNewMessageValid}
            onClick={handleSubmit}
          />
          </>
        }
         
        </NewMessage>
        </ChatContainerOverall>
        {showMembers && (
          <Modal
            onClose={() => {
              setShowMembers(false);
            }}
            title={"Group Members"}
            width={""}
            isWidth={true}
          >
            <MessageMember top={"20px"}>
              {members.map((member) => {
                return (
                  <ChatMemberContainer>
                    <MemberInformation>
                      <MemberImage
                        src={
                          member?.user?.image
                            ? member?.user?.image
                            : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                        }
                      />
                      <MemberName color={true}>
                       <Span> {member?.user.firstName}</Span>
                       <Span> {member?.user.lastName}</Span>
                      </MemberName>
                    </MemberInformation>
                  </ChatMemberContainer>
                );
              })}
            </MessageMember>
          </Modal>
        )}
        {showAllMembers  && (
          <Modal
            onClose={() => {
              setShowAllMembers(false);
            }}
            title={teamMembers.length ? "Add New Members":' '}
            // width={"30%"}
            actions={[
              {
                text: "Add User",
                onClick: addNewMember,
                disabled: !selectedUser.length,
              },
            ]}
          >
           {teamMembers.length ?
            <MemberContainer>
            <SubHeading>{'Selected Users'}</SubHeading>
            <MessageMember top={"20px"}>
              {selectedUser.map((member) => {
                return (
                  <ChatMemberContainer>
                    <MemberInformation>
                      <Container
                        onMouseEnter={() =>
                          setSelectedHovered(member?.user?.id)
                        }
                        onMouseLeave={() => setSelectedHovered(false)}
                      >
                        <MemberImage
                          src={
                            member?.user?.image
                              ? member?.user?.image
                              : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                          }
                        />
                        {selectedHovered === member?.user?.id && (
                          <CloseButton
                            onClick={() => removeUser(member)}
                            fontSize={"18px"}
                          >
                            x
                          </CloseButton>
                        )}
                      </Container>
                      <MemberName color={true}>
                        {member?.user.firstName.length > 9
                          ? member?.user.firstName.slice(0, 8) + ".."
                          : member?.user.firstName}
                      </MemberName>
                    </MemberInformation>
                  </ChatMemberContainer>
                );
              })}
            </MessageMember>
            <hr />
            <SubHeading>{'Select Users'}</SubHeading>
            <MessageMember top={"20px"}>
              {teamMembers.map((member) => {
                 const isSelected = selectedUser.includes(member);

                return (
                  <ChatMemberContainer>
                    <MemberInformation>
                      <Container
                        onMouseEnter={() => setHovered(member?.user?.id)}
                        onMouseLeave={() => setHovered(false)}
                      >
                        <MemberImage
                             disabled={isSelected}

                          src={
                            member?.user?.image
                              ? member?.user?.image
                              : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                          }
                        />
                        {hovered === member?.user?.id && (
                          <CloseButton
                            onClick={() => selectedUsers(member)}
                            fontSize={"26px"}
                            disabled={isSelected}
                            
                           >
                            +
                          </CloseButton>
                        )}
                      </Container>
                      <MemberName color={true}>
                        {member?.user.firstName.length > 9
                          ? member?.user.firstName.slice(0, 8) + ".."
                          : member?.user.firstName}
                      </MemberName>
                    </MemberInformation>
                  </ChatMemberContainer>
                );
              })}
            </MessageMember>
            </MemberContainer>
            :<SubHeading>{'Everyone is in the Chat'}</SubHeading>
}
          </Modal>
        )}
      </ChatWindowContainer>
    </>
  );
}
export default ChatGroupWindow;
