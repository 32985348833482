import styled from "styled-components";
import colors from "../../../../../config/colors";

export const DateLineContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const DateLineLine = styled.div`
    border-bottom: 1px solid ${colors.darkBackground};
    width:50%;
`;
export const DateLineContent = styled.div`
    padding:10px;
    white-space: nowrap;
    font-size: 12px;
`;