import styled from "styled-components";
import colors from "../../../../../../../config/colors";
import { panelSpacing } from "../../../../../../../common/styles";

export const ConnectionProfileContainer = styled.div`
    overflow-y: auto;
    height: calc(100vh - 150px);

    @media (max-width:767px) {
    height: calc(100vh - 230px);
    }
`;
export const ConnectionProfileHeader = styled.div`
    padding:${panelSpacing}px;
    display:flex;
    align-items:center;
    @media (max-width:767px) {

    flex-direction:column;
    }
`;
export const BackButton = styled.div`
    
    @media (max-width:767px) {
   margin-right:auto;
    }
`;
export const ProfileImage = styled.img`
    margin:0px 40px;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    object-fit: cover;
`;
export const ProfileInfo = styled.div``;
export const ProfileHeader = styled.div``;
export const ProfileName = styled.div`
    font-size: 24px;
    font-weight: bold;
`;
export const ProfileDescription = styled.div`
    font-size: 12px;
`;
export const ProfileActions = styled.div`
    margin-top: 20px;
    display: flex;
`;
export const PillsContainer =styled.div`
    display: flex;
    justify-content: center;
    align-items:center;
    padding-bottom:10px;
    width:100%;
    border-bottom: 1px solid ${colors.secondary};
  

    
`;
