import React, { useEffect, useRef, useState } from "react";
import {
  ProgramHomeHeader,
  ProgramInfo,
  ProgramManagerImage,
  ProgramManagers,
  ProgramManagersLabel,
  ProgramManagersList,
  ProgramName,
  LinkLine,
  PillsContainer,
  ProgramImages,
  ProgramImagePreview,
  ActionButton,
  BackButton,
  MemberNotFound,
  MessageMember,
  MemberInformation,
  ChatMemberContainer,
  MessageMemberInner,
  Content,
  HorezentalLine,
  SearchHeader,
} from "./styles";
import { ButtonSpacer } from "../../../../../common/Button/styles";
import Button from "../../../../../common/Button";
import features from "../../../../../config/features";
import Pills from "../../../../../common/Pills";
import { Link, useNavigate, useParams } from "react-router-dom";
import Updates from "../ProgramConnections/ProgramConnection/ConnectionProfile/Updates";
import Notes from "../ProgramConnections/ProgramConnection/ConnectionProfile/Notes";
import Timesheet from "../ProgramConnections/ProgramConnection/ConnectionProfile/Timesheet";
import Tasks from "../ProgramConnections/ProgramConnection/ConnectionProfile/Tasks";
import Boards from "../ProgramConnections/ProgramConnection/ConnectionProfile/Collaborate";
import MeetingsTab from "../ProgramConnections/ProgramConnection/ConnectionProfile/MeetingsTab";
import callAPI from "../../../../../api/apiCaller";
import { Context } from "../../../../../Store";
import Loading from "../../../../../common/Loading";
import {
  NoprogramContainer,
  RequestsKeyHeaders,
} from "../ProgramInBounds/styles";
import ImageUpload from "../../../../../common/ImageUpload";
import { isImageUrl, settings } from "../../../../../common/utils";
import Modal from "../../../../../common/Modal";
import {
  MentorRow,
  ReassignMenteeContainer,
  MentorInfo,
  MentorImage,
  MentorName,
} from "../../popups/ReassignMentee/styles";
import { Actions } from "../ProgramParticipants/ActiveParticipants/styles";
import Labelize from "../../../../../common/Labelize";
import { MemberImage, MemberName } from "../../popups/TaskMembers/styles";
import { getUserInfo } from "../../../../../Store/User/accessors";
import TextInput from "../../../../../common/TextInput";
import { CloseButton, Container, RemainingImages } from "../../../Messages/ChatWindow/styles";
import Checkbox from "../../../../../common/Checkbox";
import { SubHeading } from "../ProgramConnections/ProgramConnection/ConnectionProfile/Collaborate/style";
import { ConnectionProfileContainer } from "../ProgramConnections/ProgramConnection/ConnectionProfile/styles";
const MyTeams = ({ specificTeamProgram }) => {
  let params = useParams();
  let navigate = useNavigate();
  const [team, setTeam] = useState(null);
  const [teams, setTeams] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const [isMemberDelete, setIsMemberDelete] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSelectMember, setIsSelectMember] = useState(false);
  const [bgActive, setBgActive] = useState(false);
  const [editBotton, setEditBotton] = useState(false);
  const [program, setProgram] = useState(null);
  const [chat, setChat] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [seletedUserHovered, setSeletedUserHovered] = useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [groupTitle,setGroupTitle]= React.useState({
    title:''
  });
  const [teamToEdit, setTeamToEdit] = useState({
    id: "",
    name: "",
    media: "",
    programId: +params.selectedProgram,
  });


  const { state, dispatch } = React.useContext(Context);

  const handleInputChange = (key, value) => {
    console.log(value,"<<key");
    setGroupTitle({
      ...groupTitle,
      [key]: value,
    });
  };

  
  function getMyTeam() {
    specificTeamProgram
      ? callAPI(dispatch, "getSpecificTeamData", {
          id: params.selectedProgram,
          tid: params.connectionId,
        }).then((res) => {
          // console.log("my team is ", res.data[0]);
          setTeam(res?.data[0]);
          setTeams(res.data);
          setTeamMembers([...res?.data[0]?.mentors, ...res?.data[0]?.members,...res?.data[0]?.managers]);
        })
      : callAPI(dispatch, "getMyteams", {
          id: params.selectedProgram,
        }).then((res) => {
          console.log("my team is ", res.data[0]);
          setTeam(res?.data[0]);
          setTeams(res.data);
          setTeamMembers([...res?.data[0]?.mentors, ...res?.data[0]?.members, ...res?.data[0]?.managers]);
        });
  }

  const filteredUser = teamMembers?.filter((member) => {
    return (
      (member?.user.firstName &&
        member?.user.firstName
          .toLowerCase()
          .includes(searchText.toLowerCase())) ||
      (member?.user.lastName &&
        member?.user.lastName.toLowerCase().includes(searchText.toLowerCase()))
    );
  });

  const selectedUsers = (member) => {
    if (allSelected) {
      // Add all members to the array
      setSelectedUser([...teamMembers]);
    } else {
      // Check if the member already exists in the array
      if (!selectedUser.includes(member)) {
        setSelectedUser((prevSelectedUsers) => [...prevSelectedUsers, member]);

      }
    }
  };

  const removeUser = (memberToRemove, ) => {
      setSelectedUser((prevSelectedUsers) =>
        prevSelectedUsers.filter((member) => member !== memberToRemove)
      );
    
  };

  function RemoveMemberFromGroup(memberId) {
    setIsMemberDelete(memberId);
    callAPI(dispatch, "RemoveFromGroup", {
      id: memberId,
    }).then((res) => {
      setIsMemberDelete(false);
      getMyTeam();
      setIsMember(false);
    });
  }

  function retrieveProgramInformation() {
    setProgram(null);
    params.selectedProgram &&
      callAPI(dispatch, "getProgramInformation", {
        id: params.selectedProgram,
      }).then((info) => {
        setProgram(info.data);
      });
  }

  const currentUser = getUserInfo(state).id;
  const ids = selectedUser.map((member) =>member.user.id );
  const idsWithCurrentUser = ids.concat(currentUser);
  const createGroup = (groupType) => {
    setBgActive(true);
    callAPI(dispatch, "createGroup", {
      userIds:idsWithCurrentUser,
      programId: +params.selectedProgram,
      teamId: params.connectionId,
      groupType: groupType,
      // title:groupTitle.title,
    }).then((res) => {
      navigate(`/messages/group/${res.data && res.data?.id}`);
      setBgActive(false);
      setGroupTitle({
        title:"",
      })
    });
  };

  function UnassignedMentor(memberId, role) {
    callAPI(dispatch, "AssignMemberGroup", {
      id: memberId,
      role: role,
    }).then((res) => {
      setIsMemberDelete(false);
      getMyTeam();
      setIsMember(false);
    });
  }

  const renderUrl = () => {
    if (!params.connectionTab) {
      navigate(
        `/programs/${params.selectedProgram}/${params.tab}/${team.id}/meetings`
      );
    }
  };

  // Call renderUrl function after 5m seconds
  setTimeout(renderUrl, 500);

  function UpdateTeam() {
    const updatedTeamToEdit = {
      ...teamToEdit,
      media: teamToEdit.media,
      id: teamToEdit.id,
      name: team.name,
    };
    //if issue faced for about image update double url from bucket
    if (isImageUrl(updatedTeamToEdit.media)) {
      delete updatedTeamToEdit.media;
      delete updatedTeamToEdit.teamToEdit;
    }

    callAPI(dispatch, "UpdateTeamMember", {
      ...updatedTeamToEdit,
      id: team.id,
    })
      .then((res) => {
        setIsEdit(false);
        getMyTeam();
      })
      .catch((error) => {
        setIsEdit(false);
        console.error("Error updating board:", error);
      });
  }

  function updateField(field) {
    return function (newValue) {
      setTeamToEdit({
        ...teamToEdit,
        [field]: newValue,
      });
    };
  }

  const handleEditTeamClick = (SelectedTeam) => {
    setIsEdit(true); // Assuming you want to set isEdit to true when editing
    setTeamToEdit({
      id: team.id,
      name: team.name,
      media: team.media,
      programId: team.programId,
    });
  };

  const fileInputRef = useRef(null);
  const openFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    retrieveProgramInformation();
    getMyTeam();
  }, []);

  useEffect(() => {
    if (allSelected) {
      selectedUsers();
    }else{

      setSelectedUser([]);
    }

  }, [allSelected]);

  if (team === null) {
    return <Loading loadingItem="Team" />;
  }
  if (!team) {
    return (
      <NoprogramContainer>
        <RequestsKeyHeaders size={13} widthSize={300} marginSpace={30}>
          You have not been assigned to a team yet. Please wait for the
          administrator to add you to a team.
        </RequestsKeyHeaders>
      </NoprogramContainer>
    );
  }

  return (
    <>
    <ConnectionProfileContainer>
      <ProgramHomeHeader>
        {
          // isEdit ?
          <ProgramImagePreview>
            <ImageUpload
              value={isEdit ? teamToEdit.media : team?.media}
              setValue={(value) => updateField("media")(value)}
              size={120}
              teamVariant={true}
              // height={"17rem"}
              aspectRatio={true}
              setIsDisabled={setIsDisabled}
              isEdit={true}
              handleEditTeamClick={() => handleEditTeamClick(team)}
              editBotton={editBotton}
              fileInputRef={fileInputRef}
            />
            {isEdit ? (
              <div className="icon-overlays">
                <Button
                  // disabled={isDisabled}
                  icon={"add"}
                  onClick={() => {
                    UpdateTeam(team.id);
                  }}
                />
              </div>
            ) : (
              <div className="icon-overlays">
                <Button
                  // disabled={isDisabled}
                  icon={"edit"}
                  onClick={() => {
                    openFileInput();
                    handleEditTeamClick();
                  }}
                />
              </div>
            )}
          </ProgramImagePreview>
        }
        <ProgramInfo>
          <ProgramName>{team && team?.name}</ProgramName>
          <ProgramManagers>
            <ProgramManagersLabel>Members</ProgramManagersLabel>
            <ProgramManagersList>
              {teamMembers.slice(0,4).map((member) => {
                return (
                  <>
                    <ProgramManagerImage
                      key={member.id}
                      src={
                        member?.user?.image
                          ? member?.user?.image
                          : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                      }
                      onClick={() => navigate(`/messages/${member?.user?.uuid}`)}
                    />
                   
                    <ButtonSpacer />
                  </>
                );
              })}
                {teamMembers.length > 4 ? (
                  <RemainingImages Team={true} onClick={() => setIsMember(true)}>
                    +
                    <span style={{ textDecoration: "underline" }}>
                      {teamMembers.length - 4}
                      {"  "} More
                    </span>
                  </RemainingImages>
                ): <RemainingImages Team={true} onClick={() => setIsMember(true)}>
                <span style={{ textDecoration: "underline",marginLeft:"20px",width:'100%' }}>
                   More..
                </span>
              </RemainingImages>}
            </ProgramManagersList>
          </ProgramManagers>
          <LinkLine></LinkLine>
          <ButtonSpacer />
          <ActionButton>
            <Button
              text={"Chat"}
              icon={"chat"}
              variant="primary"
              isNotRound={true}
              onClick={() => setChat(true)}
            />
            {/* <Button
              text={"Members"}
              variant="secondary"
              isNotRound={true}
              onClick={() => setIsMember(true)}
            /> */}
          </ActionButton>
        </ProgramInfo>
      </ProgramHomeHeader>
      <>
        <PillsContainer width={true} margin={true}>
          <Pills
      isWidth={true}
            options={[
              ...(features.updates
                ? [
                    {
                      label: "Updates",
                      value: "updates",
                    },
                  ]
                : []),

              ...(features.meetings
                ? [
                    {
                      label: "Meetings",
                      value: "meetings",
                    },
                  ]
                : []),
              ...(features.collaborate
                ? [
                    {
                      label: "Collaborate",
                      value: "collaborate",
                    },
                  ]
                : []),
              ...(features.notes
                ? [
                    {
                      label: "Personal Notes",
                      value: "notes",
                    },
                  ]
                : []),
              ...(features.timesheet
                ? [
                    {
                      label: "Timesheet",
                      value: "timesheet",
                    },
                  ]
                : []),
              {
                label: "Tasks",
                value: "tasks",
              },
            ]}
            value={params.connectionTab}
            setValue={(newValue) => {
              navigate(
                `/programs/${params.selectedProgram}/${params.tab}/${team.id}/${newValue}`
              );
            }}
          />
        </PillsContainer>
        {features.updates && params.connectionTab === "updates" && <Updates />}

        {features.notes && params.connectionTab === "notes" && <Notes />}

        {features.timesheet && params.connectionTab === "timesheet" && (
          <Timesheet isTeam={program?.programType === "TEAM"} />
        )}
        {params.connectionTab === "tasks" && <Tasks isTeam={true} />}

        {features.collaborate && params.connectionTab === "collaborate" && (
          <Boards isTeam={program?.programType === "TEAM"} specificTeamProgram={specificTeamProgram} />
        )}
        {features.meetings && params.connectionTab === "meetings" && (
          <MeetingsTab isTeam={program?.programType === "TEAM"} />
        )}
      </>
      </ConnectionProfileContainer>
      {isMember && (
        <Modal
          onClose={() => {
            setIsMember(false);
          }}
          title={"Members"}
          actions={[
            {
              text: "Cancel",
              onClick: () => {
                setIsMember(false);
              },
            },
          ]}
        >
          {team?.mentors.length === 0 && team?.members.length === 0 ? (
            <MemberNotFound>
              {"Currently, there are no members in this group/team."}
            </MemberNotFound>
          ) : (
            <ReassignMenteeContainer width={"70vw"} height={"70vh"}>
              {teams &&
                teams.map((member) => {
                  return (
                    <>
                     {member?.managers.length !== 0 && (
                        <Labelize label={"Team Managers"}>
                          {member?.managers.map((manager) => {
                            return (
                              <MentorRow isMobile={true}> 
                                <MentorInfo>
                                  <MentorImage
                                    src={
                                      manager?.user?.image
                                        ? manager?.user?.image
                                        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                    }
                                  />
                                  <MentorName>
                                    {manager?.user?.firstName +
                                      " " +
                                      manager?.user?.lastName}
                                  </MentorName>
                                </MentorInfo>
                              </MentorRow>
                            );
                          })}
                        </Labelize>
                      )}
                      {member?.mentors.length !== 0 && (
                        <Labelize label={"Team Leader(s)"}>
                          {member?.mentors.map((mentor) => {
                            return (
                              <MentorRow>
                                <MentorInfo>
                                  <MentorImage
                                    src={
                                      mentor?.user?.image
                                        ? mentor?.user?.image
                                        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                    }
                                  />
                                  <MentorName>
                                    {mentor?.user?.firstName +
                                      " " +
                                      mentor?.user?.lastName}
                                  </MentorName>
                                </MentorInfo>
                                <Actions isMobile={true}>
                                  <Button
                                    onClick={() => {
                                      // setAction('mentorAssigned')
                                      setIsSelectMember(mentor.id);
                                      UnassignedMentor(mentor?.id, "member");
                                    }}
                                    variant="primary"
                                    text="Unassign Mentor"
                                    icon={
                                      // mentor.id===isSelectMember ?'loading':
                                      "edit"
                                    }
                                  />
                                  <Button
                                    onClick={() => {
                                      // setAction('memberAssigned')
                                      // assignMember(member.id,'member');
                                      setIsMemberDelete(mentor?.id);
                                      RemoveMemberFromGroup(mentor?.id);
                                    }}
                                    variant="secondary"
                                    text="Remove from group"
                                    icon={
                                      mentor.id === isMemberDelete
                                        ? "loading"
                                        : "cross"
                                    }
                                  />
                                </Actions>
                              </MentorRow>
                            );
                          })}
                        </Labelize>
                      )}
                      
                      {member?.members.length !== 0 && (
                        <Labelize label={"Members"}>
                          {member?.members.map((memberdata) => {
                            return (
                              <MentorRow>
                                <MentorInfo>
                                  <MentorImage
                                    src={
                                      memberdata?.user?.image
                                        ? memberdata?.user?.image
                                        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                    }
                                  />
                                  <MentorName>
                                    {memberdata?.user?.firstName +
                                      " " +
                                      memberdata?.user?.lastName}
                                  </MentorName>
                                </MentorInfo>
                                <Actions>
                                  <Button
                                    onClick={() => {
                                      // setAction('mentorAssigned')
                                      // assignMember(member.id,'mentor');
                                      // setIsSelectMember(memberdata.id)
                                      UnassignedMentor(
                                        memberdata?.id,
                                        "mentor"
                                      );
                                    }}
                                    variant="primary"
                                    text="Assign Mentor"
                                    icon={
                                      // memberdata.id===isSelectMember ?'loading':
                                      "edit"
                                    }
                                  />
                                  <Button
                                    onClick={() => {
                                      // setAction('memberAssigned')
                                      // assignMember(member.id,'member');
                                      setIsMemberDelete(memberdata?.id);
                                      RemoveMemberFromGroup(memberdata?.id);
                                    }}
                                    variant="secondary"
                                    text="Remove from group"
                                    icon={
                                      memberdata.id === isMemberDelete
                                        ? "loading"
                                        : "cross"
                                    }
                                  />
                                </Actions>
                              </MentorRow>
                            );
                          })}
                        </Labelize>
                      )}
                    </>
                  );
                })}
            </ReassignMenteeContainer>
          )}
        </Modal>
      )}
      {chat && (
        <Modal
          onClose={() => {
            setChat(false)
          }}
          fixedWidth={"30vw"}
        >
          <SearchHeader>
            <TextInput
              value={searchText}
              setValue={setSearchText}
              placeholder="Search"
            />
           
          </SearchHeader>
          {/* <HorezentalLine/> */}
          {/* <TextInput
            value={groupTitle.title}
            placeholder="Group Name"
            setValue={(value) => handleInputChange("title", value)}
          /> */}
            <SubHeading>{'Selected Users'}</SubHeading>
          <MessageMember top={"20px"} maxHeight={"30vh"}>
            <MessageMemberInner>
              {selectedUser.map((member) => {
                return (
                  <ChatMemberContainer>
                    <MemberInformation>
                      <Container
                        onMouseEnter={() =>
                          setSeletedUserHovered(member?.user?.id)
                        }
                        onMouseLeave={() => setSeletedUserHovered(false)}
                      >
                        <MemberImage
                          src={
                            member?.user?.image
                              ? member?.user?.image
                              : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                          }
                        />
                        {seletedUserHovered === member?.user?.id && (
                          <CloseButton
                            onClick={() => removeUser(member)}
                            fontSize={"16px"}
                          >
                            X
                          </CloseButton>
                        )}
                      </Container>
                      <MemberName color={true}>
                        {member?.user.firstName.length > 9
                          ? member?.user.firstName.slice(0, 8) + ".."
                          : member?.user.firstName}
                      </MemberName>
                    </MemberInformation>
                  </ChatMemberContainer>
                );
              })}
            </MessageMemberInner>
          </MessageMember>
          <HorezentalLine />
          <SubHeading>{'Select Users'}</SubHeading>
          <Checkbox
          isBorder={true}
              label="Select All"
              value={allSelected}
              setValue={(newValue) => {
                setAllSelected(!allSelected);
              }}
            />
          {teamMembers.length > 0 ? (
            <Content>
              <MessageMember height={"30vh"}>
                <MessageMemberInner>
                  {filteredUser.filter(member => member?.user?.id !== currentUser).map((member) => { 
                  const isSelected = selectedUser.includes(member);
                    return (
                      <ChatMemberContainer>
                        <MemberInformation onClick={() => selectedUsers(member)}>
                          <Container
                            onMouseEnter={() => setHovered(member?.user?.id)}
                            onMouseLeave={() => setHovered(false)}
                          >
                            <MemberImage
                                disabled={isSelected}

                              src={
                                member?.user?.image
                                  ? member?.user?.image
                                  : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                              }
                            />
                            {hovered === member?.user?.id && (
                              <CloseButton
                                onClick={() => console.log("click")}
                                fontSize={"26px"}
                                disabled={isSelected}

                              >
                                +
                              </CloseButton>
                            )}
                          </Container>
                          <MemberName color={true}>
                            {member?.user.firstName.length > 9
                              ? member?.user.firstName.slice(0, 8) + ".."
                              : member?.user.firstName}
                          </MemberName>
                        </MemberInformation>
                      </ChatMemberContainer>
                    );
                  })}
                </MessageMemberInner>
              </MessageMember>
              <ActionButton isGroup={true}>
                <Button
                  text={"Create Chat"}
                  disabled={!selectedUser.length}
                  variant="primary"
                  onClick={() => createGroup("all_members_and_managers")}
                />
              </ActionButton>
            </Content>
          ) : (
            "Current team do not have any member"
          )}
        </Modal>
      )}
    </>
  );
};

export default MyTeams;
