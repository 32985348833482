import React, { useEffect, useRef, useState } from "react";
import { ImageUploadContainer, ImagePreview, UploadButton } from "./styles";
import TextInput from "../TextInput";
import Button from "../Button";
import Icon from "../Icon";
import { ButtonSpacer } from "../Button/styles";
import colors from "../../config/colors";
import callAPI from "../../api/apiCaller";
import { Context } from "../../Store";
import { uploadImageToS3 } from "../utils";

function ImageUploadButtonLess({
  item = "Image",
  size = 150,
  value = null,
  setValue,
  setIsValid = () => {},
  teamVariant,
}) {
  const handleClickImage = () => {
    fileInputRef.current.click();
  };
  const [uploadState, setUploadState] = React.useState("not-uploaded");
  const [selectedFile, setSelectedFile] = useState(value);
  const { state, dispatch } = React.useContext(Context);
  const fileInputRef = useRef(null);

  return (
    <ImageUploadContainer>
      <ImagePreview
        size={'120'}
        src={
          selectedFile
            ? selectedFile
            : value
            ? value
            : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
        }
        onClick={handleClickImage}
      ></ImagePreview>
      <>
        <label
          htmlFor="fileInputs"
          style={{
            position: "fixed",
            width: "15%",
            height: "10%",
            cursor: "pointer",
          }}
        ></label>

        <input
          ref={fileInputRef}
        id="fileInputs"
          type="file"
          style={{ display: "none" }}
          onChange={(event) => {
            if (event.target.files && event.target.files[0]) {
              let img = event.target.files[0];
              var reader = new FileReader();
              reader.readAsDataURL(img);
              reader.onload = () => {
                setUploadState("uploading");
                setSelectedFile(reader.result.toString());
              };
              callAPI(dispatch, "signedUrl", {
                fileType: event.target.files[0].type.split("/")[1],
                fileSize: Math.ceil(event.target.files[0].size / (1024 * 1024)),
              })
                .then((signedUrlResponse) => {
                  setValue(signedUrlResponse.data.key);
                  return uploadImageToS3(dispatch, "uploadFile", {
                    url: signedUrlResponse.data.url,
                    file: event.target.files[0],
                  });
                })
                .then((respone) => {
                  if (respone.ok) {
                    setIsValid(true);
                    setUploadState("upload");
                    return;
                  }
                });
            }
          }}
        />
      </>
    </ImageUploadContainer>
  );
}
export default ImageUploadButtonLess;
