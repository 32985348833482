import React from "react";
import {
  FooterOuterContainer,
  SocialMedia,
  FooterTop,
  FooterLogo,
  Link,
  FooterBottom,
  Text,
  ContactUs,
  Heading
} from "./styles";
import LogoImage from "./mause.svg";

function Footer() {
  return (
    <FooterOuterContainer>
      <FooterTop>
        <FooterLogo
          src={LogoImage}
          onClick={() => {
            document.getElementById("home").scrollIntoView({
              behavior: "smooth",
            });
          }}
        />
        <SocialMedia>
          <Heading>Social Media</Heading>
          <Link href="https://www.linkedin.com/company/onemauve">LinkedIn</Link>
          <Link isLast={true} href="https://www.instagram.com/myonemauve">Instagram</Link>
        </SocialMedia>
        <ContactUs>
          <Heading>Contact Us</Heading>
          <Link>info@onemauve.com</Link>
          <Text>Los Angeles, CA 90066</Text>
        </ContactUs>
      </FooterTop>
      <FooterBottom>
        <Text>© 2024 Copyright Onemauve</Text>
        <Link isLast={true} href="app/terms-and-services">Terms and Services</Link>
      </FooterBottom>
    </FooterOuterContainer>
  );
}
export default Footer;
