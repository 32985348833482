import React from "react";
import { EditProgramContainer } from "./styles";
import ProgramDetails from "../../forms/ProgramDetails";
import {
  createInitialValid,
  updateValidTogether,
  validateIsValid,
  validateText,
} from "../../../../../common/utils";
import callAPI from "../../../../../api/apiCaller";
import { Context } from "../../../../../Store";
import Modal from "../../../../../common/Modal";
import Loading from "../../../../../common/Loading";

function EditProgram({ programId, setShowAddEditProgram }) {
  const [programForm, setProgramForm] = React.useState(null);
  const [isSavingProgram, setIsSavingProgram] = React.useState(false);
  const [isValid, setIsValid] = React.useState(
    createInitialValid(["title", "organization", "description"])
  );
  const { dispatch } = React.useContext(Context);
  React.useEffect(() => {
    programId &&
      callAPI(dispatch, "getProgramInformation", { id: programId }).then(
        (retrievedProgram) => {
          setProgramForm(retrievedProgram.data);
          updateValidTogether(isValid, setIsValid, retrievedProgram.data, {
            title: validateText,
            organization: validateText,
            description: validateText,
          });
        }
      );
  }, []);

  const isImageUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };
  function saveProgram() {
    
    if (isImageUrl(programForm.image)){
      delete programForm.image
    }
    setIsSavingProgram(true);
    callAPI(dispatch, "saveProgram", {
      id: programId,
      ...{
        organization: programForm.organization,
        title: programForm.title,
        description: programForm.description,
        image: programForm.image,
        isMentorSelectionEnabled: programForm.isMentorSelectionEnabled,
      },
    }).then((res) => {
      setIsSavingProgram(false);
      setShowAddEditProgram(true);
    });
  }
  if (programForm === null) {
    return (
      <Modal
        title={"Edit Program"}
        onClose={() => {
          setShowAddEditProgram(false);
        }}
      >
        <EditProgramContainer>
          <Loading item="Program Information" />
        </EditProgramContainer>
      </Modal>
    );
  }
  return (
    <Modal
      title="Edit Program"
      onClose={() => {
        setShowAddEditProgram(false);
      }}
      isHeight={true}
      actions={[
        {
          text: "Cancel",
          variant: "secondary",
          icon: "cross",
          onClick: () => {
            setShowAddEditProgram(false);
          },
        },
        {
          text: "Save",
          icon: isSavingProgram ? "loading" : "tick",
          variant: "primary",
          disabled: !validateIsValid(isValid),
          onClick: saveProgram,
        },
      ]}
    >
      <EditProgramContainer isEditModal={true}>
        <ProgramDetails
          programForm={programForm}
          setProgramForm={setProgramForm}
          isValid={isValid}
          setIsValid={setIsValid}
          isEditProgram={true}
        />
      </EditProgramContainer>
    </Modal>
  );
}
export default EditProgram;
