import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import callAPI from "../../../../../../../../api/apiCaller";
import Accordian from "../../../../../../../../common/Accordian";
import Button from "../../../../../../../../common/Button";
import Loading from "../../../../../../../../common/Loading";
import { DisplayDate, formatDate, formatDateForTask, formatOnlyDate } from "../../../../../../../../common/utils";
import { TasksContainer, TaskTitle, TaskName, TaskDate, TaskContent, TaskDescription, TaskActions, TaskStatus, CreateTaskDate } from "./styles";
import { Context } from "../../../../../../../../Store";
import { ContentActions, ContentCard } from "../../../../../../../../common/Accordian/styles";
import { ButtonSpacer } from "../../../../../../../../common/Button/styles";

function Tasks({ isTeam}) {
  const params = useParams();
  const [tasks, setTasks] = React.useState(null);
  const [markingAsComplete, setMarkingAsComplete] = React.useState(false);
  const { state, dispatch } = React.useContext(Context);
  const [searchParams] = useSearchParams();
  const role = searchParams.get("role");
  const recipientId=params.connectionId
  const tasksWithDueDateInUnixTimeStamp = (tasks) => {
    const tasksWithUnixTimeStamp=[]
    tasks.forEach((task)=>{
      const dateObject = new Date(task.dueDate);
      const unixTimestamp = Math.floor(dateObject.getTime() / 1000); // Convert milliseconds to seconds
      task['dueDate']=unixTimestamp
      tasksWithUnixTimeStamp.push(task) 

    })
 return tasks
    
  };

  // console.log(role,"USer");

  function getTasks(){
    !isTeam ? callAPI(dispatch, "menteeTasks", {  rid:role === "mentee" ? +state.user.id : +recipientId ,id: params.selectedProgram,}).then((retrievedTasks) => {
      if(retrievedTasks.isResponseOk)
       setTasks(retrievedTasks.data)
    })
    :
    callAPI(dispatch, "teamTasks", {id : params.connectionId }).then((retrievedTasks) => {
      if(retrievedTasks.isResponseOk)
       setTasks(retrievedTasks.data)
    });
  }
  React.useEffect(() => {
    getTasks()
  }, []);
  function markTaskAsComplete(task) {
    setMarkingAsComplete(task.id);
   ! isTeam ? callAPI(dispatch, 'markTaskAsCompleteForCurrentUser', { id: params.selectedProgram, tid: task.id, rid:role === "mentee" ? +state.user.id : +recipientId }).then((updatedTasks) => {
      setMarkingAsComplete(null);
      getTasks()
    }):
    callAPI(dispatch, 'markTeamTaskAsComplete', { id: task.id, status:'completed' }).then((updatedTasks) => {
      setMarkingAsComplete(null);
      getTasks()
    })

  }

  if (tasks === null) {
    return <Loading loadingItem="Tasks" />
  }
  if (tasks.length === 0) {
    return <TasksContainer>
      No Tasks Assigned Yet.
    </TasksContainer>
  }
  return <TasksContainer>
   { !isTeam ? <Accordian sections={tasks.map(task => {
      return {
        title: <TaskTitle>
          <TaskName>{task.title}</TaskName>
          <CreateTaskDate>Task Created at: {DisplayDate(task.createdAt)}</CreateTaskDate>
          <TaskDate isDue={new Date().getTime() > new Date(task.dueDate+"t00:00:00") && task.status === "pending"}>Due on {formatOnlyDate(task.dueDate)}</TaskDate>
        </TaskTitle>,
        content: <TaskContent>

          <ContentCard>
            {task.description}
            <ButtonSpacer/>
            <TaskStatus isCompleted={task.status === "completed"}>Status: <b>{task.status && task.status }</b></TaskStatus>
          </ContentCard>
          <ContentActions>

            {
              task.status !== "completed" && <><ButtonSpacer /><Button
                text="Mark as Completed"
                icon={markingAsComplete === task.id ? "loading" : "tick"}
                variant="dark"
                onClick={() => {
                  markTaskAsComplete(task)
                }} /></>
            }
          </ContentActions>
        </TaskContent>
      }
    })} />
    :
    <Accordian sections={tasks.map(teamTask => {
      return {
        title: <TaskTitle>
          <TaskName>{teamTask.task.title}</TaskName>
          <CreateTaskDate >Task Created at: {DisplayDate(teamTask.task.createdAt)}</CreateTaskDate>
          <TaskDate isDue={new Date().getTime() > new Date(teamTask.task.dueDate+"t00:00:00") && teamTask.task.status === "pending"}>Due on {formatOnlyDate(teamTask.task.dueDate)}</TaskDate>
        </TaskTitle>,
        content: <TaskContent>

          <ContentCard>
            {teamTask.task.description}
            <ButtonSpacer/>
            <TaskStatus isCompleted={teamTask.status === "completed"}>Status: <b>{teamTask.status && teamTask.status }</b></TaskStatus>
          </ContentCard>
          <ContentActions>

            {console.log(teamTask.task,"TaskTest")}
            {
              teamTask?.status !== "completed" && <><ButtonSpacer /><Button
                text="Mark as Completed"
                icon={markingAsComplete === teamTask.id ? "loading" : "tick"}
                variant="dark"
                onClick={() => {
                  markTaskAsComplete(teamTask)
                }} /></>
            }
          </ContentActions>
        </TaskContent>
      }
    })} />

   }
  </TasksContainer>;
}
export default Tasks;