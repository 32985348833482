import React, { useEffect, useState } from "react";
import {
  BoardContainer,
  BoardSidebar,
  BoardTitle,
  BoardBody,
  BodyTitle,
  BodyContainer,
  StyledIframe,
  BoardSideBarHeader,
  BoardsInfo,
  BoardHeading,
  BoardSubHeading,
  UserInfoContainer,
  IframHeader,
  BoardNotification,
  BoardDetail,
  ActionContainer,
  BoardAction,
  Minimize,
} from "./style";
import Button from "../../../../../../../../common/Button";
import callAPI from "../../../../../../../../api/apiCaller";
import Modal from "../../../../../../../../common/Modal";
import { Context } from "../../../../../../../../Store";
import { useSearchParams } from "react-router-dom";
import Labelize from "../../../../../../../../common/Labelize";
import TextInput from "../../../../../../../../common/TextInput";
import Loading from "../../../../../../../../common/Loading";
import { DisplayDate } from "../../../../../../../../common/utils";
import Icon from "../../../../../../../../common/Icon";
import colors from "../../../../../../../../config/colors";
import { HorezentalLine } from "../../../../MyTeams/styles";
const Boards = ({isTeam}) => {
  const [heading, setHeading] = useState();
  const [boardId, setboardId] = useState(null);
  const [active, setActive] = useState(false);
  const [isDeleteId, setIsDeleteId] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [isAddBoard, setIsAddBoadr] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showBoardView, setShowBoardView] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedBoardId, setSelectedBoardId] = useState(null);
  const [getBoardInformation, setGetBoardInformation] = useState([]);
  const [searchParams] = useSearchParams();
  const role = searchParams.get("role");
  const { state, dispatch } = React.useContext(Context);
  const url = window.location.href;
  const parts = url.split("/"); // Split the URL by '/'
  const id = parts[parts.length - 2]; // Get the second-to-last part of the URL
  const programId = parts[parts.indexOf("programs") + 1];
  const [boardInfo, setBoardInfo] = useState({
    type: "kanban",
    name: "",
    description: "oneMauve",
    programId: +programId,
    userId: role === "mentee" ? +state.user.id : +id,
    teamId:isTeam ? id : ''
  });

  function AddBoard() {
    setIsLoading(true);
    callAPI(dispatch, "createKanban", { ...boardInfo }).then((res) => {
      setIsLoading(false);
      setShowPopup(false);
      // setIsAddBoadr(true);
      getBoard();
      setBoardInfo({
        type: "kanban",
        name: "",
        description: "",
        programId: +programId,
        userId: role === "mentee" ? +state.user.id : +id,
        teamId:isTeam ? id : ''
      })
    });
  }

  function UpdateBoard() {
    setIsLoading(true);

    callAPI(dispatch, "updateBoard", {
      id: selectedBoardId,
      ...boardInfo,
    })
      .then((res) => {
        setIsLoading(false);
        setShowPopup(false);
        setIsUpdate(false);
        getBoard();
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error updating board:", error);
      });
  }

  function getBoard() {
    isTeam ? 
    callAPI(dispatch, "getTeamBoards", {
      rid: id,
      id: programId,
    }).then((res) => {
      setGetBoardInformation(res.data);
      setLoading(false);
    })
    :
    callAPI(dispatch, "getKanbanBoards", {
      rid: role === "mentee" ? state.user.id : id,
      id: programId,
    }).then((res) => {
      setGetBoardInformation(res.data);
      setLoading(false);
    });
  }

  const DeleteBoard = (id) => {
    setIsDeleteId(id)
    setIsLoading(true);
    callAPI(dispatch, "DeleteBoard", {
      id: id,
    }).then((res) => {
      getBoard();
      setIsLoading(false);
    });
  };

  const EditBoardInformation = (item) => {
    setIsUpdate(true);
    setBoardInfo({
      type: item.type,
      name: item.name,
      description: item.description,
      programId: item.programId,
      userId: item.userId,
    });
    setSelectedBoardId(item.id);
    setShowPopup(true);
  };

  const handleInputChange = (key, value) => {
    setBoardInfo({
      ...boardInfo,
      [key]: value,
    });
  };
  const BoardSelection = (item) => {
    setHeading(item.name);
    setboardId(item.id);
    setActive(item.id);
  };


  
 function IconPackMaximize(props) {
    return (
      <svg width="512" height="512" viewBox="0 0 512 512" style="color:currentColor" xmlns="http://www.w3.org/2000/svg" class="h-full w-full"><rect width="512" height="512" x="0" y="0" rx="30" fill="transparent" stroke="transparent" stroke-width="0" stroke-opacity="100%" paint-order="stroke"></rect><svg width="256px" height="256px" viewBox="0 0 32 32" fill="currentColor" x="128" y="128" role="img" style="display:inline-block;vertical-align:middle" xmlns="http://www.w3.org/2000/svg"><g fill="currentColor"><path fill="currentColor" d="M20 2v2h6.586L18 12.582L19.414 14L28 5.414V12h2V2H20zm-6 17.416L12.592 18L4 26.586V20H2v10h10v-2H5.414L14 19.416z"/></g></svg></svg>
    )
  }

  useEffect(() => {
    getBoard();
  }, []);

  return (
    <BoardContainer>
      <BoardSidebar>
        <BoardSideBarHeader>
          <BoardTitle>Your Boards</BoardTitle>
          <Button
            icon="add"
            disabled={isLoading === true ? true : false}
            onClick={() => {
              setShowPopup(true);
            }}
          />
        </BoardSideBarHeader>
        {loading ? (
          <Loading loadingItem="Miro Board" />
        ) : (
          getBoardInformation?.map((item) => {
            return (
              <BoardsInfo
                onClick={() => BoardSelection(item)}
                active={active === item.id}
              >
                <BoardDetail>
                  <BoardHeading>{item?.name}</BoardHeading>
                  <ActionContainer>

                    <Button
                      icon="edit"
                      onClick={() => {
                        EditBoardInformation(item);
                      }}
                    />

                    <Button      
                    icon= {isDeleteId===item.id && isLoading === true ? "loading" : "trash"}
                    onClick={() => {
                        DeleteBoard(item.id);
                      }}
                    />

                  </ActionContainer>
                </BoardDetail>
                <BoardSubHeading>
                  {DisplayDate(item?.createdAt)}
                </BoardSubHeading>
              </BoardsInfo>
            );
          })
        )}
      </BoardSidebar>
      <BoardBody isWidth={true}>
        <BodyContainer >
          <IframHeader>
            <BodyTitle>{heading}</BodyTitle>
            <BoardAction>
              {" "}
              <Button
                icon="user"
                onClick={() => {
                  window.open("https://miro.com/login", "_blank");
                }}
              />
            {boardId &&  <Button
                icon="maximize"
                onClick={() =>setShowBoardView(true)}
              />}

            </BoardAction>
          </IframHeader>
          {boardId ? (
            <StyledIframe
              src={`https://miro.com/app/board/${boardId}/`}
              title="miro"
            />
          ) : (
            <BoardNotification top={'20%'}>
              {" "}
              {loading ? (
                <Loading loadingItem="Miro Board" />
              ) : getBoardInformation?.length > 0 ? (
                "Please select the board"
              ) : (
                "You don't have any board"
              )}
            </BoardNotification>
          )}
        </BodyContainer>
      </BoardBody>

      {showBoardView && (
        <Modal
        width='97%'
          title={heading}
          onClose={() => {
            setShowBoardView(false);
          }}
        >
          <BoardBody boardView={true}>
            <Minimize>
            <Button icon={'minimize'} onClick={()=>setShowBoardView(false)} />
            </Minimize>
        <BodyContainer  boardView={true}>
          {boardId ? (
            <StyledIframe
              src={`https://miro.com/app/board/${boardId}/`}
              title="miro"
            />
          ) : (
            <BoardNotification top={'20%'}>
              {" "}
              {loading ? (
                <Loading loadingItem="Miro Board" />
              ) : getBoardInformation?.length > 0 ? (
                "Please select the board"
              ) : (
                "You don't have any board"
              )}
            </BoardNotification>
          )}
        </BodyContainer>
         </BoardBody>
        </Modal>
      )}
      {showPopup && (
        <Modal
          title={"Miro Board Information"}
          onClose={() => {
            setShowPopup(false);
          }}
          actions={[
            {
              text: "Cancel",
              icon: "cross",
              onClick: () => {
                setShowPopup(false);
                setIsLoading(false);
              },
            },
            {
              text: isUpdate ? "Update Board" : "Add Board",
              icon: isLoading === true ? "loading" : "tick",
              onClick: () => {
                isUpdate ? UpdateBoard() : AddBoard();
              },
            },
          ]}
        >
          <UserInfoContainer>
            <Labelize label="Miro Board Name">
              <TextInput
                value={boardInfo.name}
                placeholder="Board Name"
                setValue={(value) => handleInputChange("name", value)}
              />
            </Labelize>
          </UserInfoContainer>
        </Modal>
      )}
       {showBoardView && (
        <Modal
          title={heading}
          onClose={() => {
            setShowBoardView(false);
          }}
          boardView={true}
        >
          <BoardBody boardView={true}>
            <Minimize>
            <Button icon={'minimize'} onClick={()=>setShowBoardView(false)} />
            </Minimize>
        <BodyContainer  boardView={true}>
          {boardId ? (
            <StyledIframe
              src={`https://miro.com/app/board/${boardId}/`}
              title="miro"
            />
          ) : (
            <BoardNotification top={'20%'}>
              {" "}
              {loading ? (
                <Loading loadingItem="Miro Board" />
              ) : getBoardInformation?.length > 0 ? (
                "Please select the board"
              ) : (
                "You don't have any board"
              )}
            </BoardNotification>
          )}
        </BodyContainer>
         </BoardBody>
        </Modal>
      )}
    </BoardContainer>
  );
};

export default Boards;
