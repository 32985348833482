import React from "react";
import colors from "../../config/colors";
import Icon from "../Icon";
import { ButtonContainer, ButtonSpacer } from "./styles";

function Button({
  className,
  text,
  onClick = () => { },
  icon,
  variant = "secondary",
  iconLast = false,
  disabled = false,
  isNotRound,
   isTH,
   width,
   simple,
   borderLeft,
   borderRight
}) {
  let buttonColors = {
    "primary": {
      backgroundColor: colors.primary,
      color: colors.secondary
    },
    "secondary": {
      backgroundColor: colors.secondary,
      color: colors.primary
    },
    "danger": {
      backgroundColor: colors.failure,
      color: colors.background
    },
    "disabled": {
      backgroundColor: colors.darkBackground,
      color: colors.background
    },
    "Thdisabled": {
      backgroundColor: colors.primary,
      color: colors.background
    },
    "dark":{
      backgroundColor: colors.background,
      color: colors.primary
    }
  }
  let { color, backgroundColor } = buttonColors[disabled ? isTH ? 'Thdisabled': "disabled" : variant];
  return <ButtonContainer
  width={width}
    className={className}
    disabled={disabled}
    isNotRound={isNotRound}
    color={color}
    backgroundColor={backgroundColor}
    simple={simple}
    borderLeft={borderLeft}
    borderRight={borderRight}
    onClick={() => {
      !disabled && onClick();
    }}
    onlyIcon={icon && !text}
  >
    {icon && !iconLast && <Icon type={icon} color={color} />}
    {
      icon && !iconLast && text && <ButtonSpacer></ButtonSpacer>
    }
    {text}
    {
      icon && iconLast && text && <ButtonSpacer></ButtonSpacer>
    }
    {icon && iconLast && <Icon type={icon} color={color} />}
  </ButtonContainer>;
}
export default Button;