import styled from "styled-components";
import colors from "../../../config/colors";

export const FooterContainer = styled.div`
    font-size:12px;
    text-align:center;
    padding:5px;
    background-color:${colors.text};
    color:${colors.background};
`;

export const FooterOuterContainer = styled.div`
    background-color:${colors.text};
    padding: 70px 48px;
    color:${colors.backgroundBlack};
    @media screen and (max-width: 992px) {
        padding: 50px 20px;
    }
    @media screen and (max-width: 767px) {
        flex-direction: column;
        padding: 28px 17px 47px;
    }
`;

export const FooterTop = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 143px;
    @media screen and (max-width: 767px) {
        flex-direction: column;
        gap: 40px;
    }
`;

export const FooterBottom = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 90px;
    margin-top:72px;
    @media screen and (max-width: 767px) {
        flex-direction: column;
        gap: 12px;
        margin-top: 40px;
    }
`;

export const ContactUs = styled.div`
`;

export const FooterLogo = styled.img`
`;

export const SocialMedia = styled.div`
`;

export const Link = styled.a`
    color: rgba(255, 255, 255, 0.70);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: block;
    margin-bottom: ${props=>props.isLast ? 0 : 13}px;
    text-decoration: none;
`;

export const Text = styled.p`
    color: rgba(255, 255, 255, 0.70);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
`;

export const Heading = styled.h2`
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0 0 20px;
`;

