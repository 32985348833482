import styled from "styled-components";
import { panelSpacing } from "../../../../common/styles";


export const ParticipantTeams = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap:25px 20px;
  @media (max-width:640px) {
    flex-direction:column;
  }

`;

export const TeamContainer = styled.div`
box-shadow: 0px 2px 4px 0px #0004;
    position: block;
    padding: 10px;
    flex-wrap:wrap;
    text-align:center;
    background-color: #fff;
    border-radius: 15px;
     box-sizing: border-box; 
     width:300px;
    max-height:${props => props.height ? props.height :'fit-content'};
    min-height:${props => props.height ? props.height :'fit-content'};
    @media (max-width:1400px) {
        width:280px;
    }
`;
export const Heading = styled.h2`
margin-top:-5px;


color:#b7b3b3;
text-align:${props => props.size && 'right'};
 font-size:${props => props.size && props.size };


`;

export const ImageBox = styled.div`
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:${panelSpacing}px;
//  margin-top:${props => props.height && props.height  };
 height:${props => props.height ? '170px' :'150px'  };


`;
export const TeamInfo = styled.h3`
 text-align:center;
 display:flex;
 justify-content:space-between;
 align-items: center;


`;
export const EditTeam = styled.span`
    margin-left: -38px;
    z-index: 9;
   

`;
export const NameHeading = styled.span`
    width: 250px; 
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis; 


`;

export const Image = styled.img`
    width: 150px;
    height: 150px;
    object-fit:cover;
    border-radius:${panelSpacing}px;
`;

export const CardContainer = styled.div`
background-color:#EFEFEF ;
height:100%;
padding: 10px;
box-sizing: border-box;
width:100%;
overflow:hidden;



`;
export const MemberSpan = styled.span`
display:block;
color:blue;
text-align:right;
margin-right:30px;
`;
export const ImageShow = styled.image`
width:100%;
height:auto;
object-fit:cover;

`;
export const Actions = styled.div`
display:flex;
justify-content:center;
width:100%;
align-items: center;

`;
export const TeamNameSpan = styled.div`
width:60%;


`;

