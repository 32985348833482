import createReducer from "../common/createReducer";

var reducers = {
  NEW_TOAST: (toasts, {message,toastType}) => {
    return [
      ...toasts,
      {
        message,
        type:toastType,
        expiry: new Date().getTime() + 5000,
      }
    ]
  },
  TRACKED_TOAST:(toasts,{id,message,toastType})=>{
    let toast = {
      id,
      message,
      type:toastType,
      expiry:new Date().getTime() + 4000,
    }
    if(toast.type === "inProgress"){
      toast.expiry = Infinity;
      return [
        ...toasts,
        toast
      ]
    }
    else{
      return toasts.map(currentToast=>{
        return currentToast.id === toast.id ? toast : currentToast
      })
    }
  },

  REFRESH: (toasts) => {
    let now = new Date().getTime();
    return toasts.filter(toast => toast.expiry > now)
  },

  DELETE: (toasts,{id}) => {
    return toasts.filter(toast => toast.id!==id)
  },
};

const initialState = [];

export default createReducer(initialState, reducers);