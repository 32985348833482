import React, { useState } from 'react'
import { Actions, EditTeam, Heading, ImageBox, NameHeading, TeamContainer, TeamInfo, TeamNameSpan } from './style'
import { ImagePreview } from '../../../../common/ImageUpload/styles'
import TextInput from '../../../../common/TextInput'
import Button from '../../../../common/Button'
import ImageUpload from '../../../../common/ImageUpload'
import ImageUploadButtonLess from '../../../../common/ImageUpload/image-upload-without-button'
import { useNavigate, useParams } from 'react-router-dom'

const TeamInfoCard = ({teamInfo, member ,isEdit,loading,setIsEdit,handleEditTeam,updateField,UpdateTeam,setSpecificProgram}) => {

  const params= useParams()
  const navigate=useNavigate()

  return (
    <TeamContainer  height={'340px'}>
    <Heading size={'16px'}>{member.teamMembers?.length && member.teamMembers?.length!==1 ? member.teamMembers?.length  + "\tMembers": member.teamMembers?.length === 1 ? member.teamMembers?.length  + "\tMember" :'No Member'}</Heading>
    <ImageBox height={'50px'}>
    {isEdit=== member.id ? (
          <ImageUploadButtonLess
            value={teamInfo.media ? teamInfo.media : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'}
            setValue={(value) => updateField('media')(value)}
            size={80}
            isEdit={true}
          />
        ) : (
          <ImagePreview src={member?.media ? member.media : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'} size={120} />
        )}
    </ImageBox>
    <TeamInfo>
      {isEdit=== member?.id ? <TextInput
        value={teamInfo?.name}
        setValue={(value) => updateField('name')(value)}
      />:
      <NameHeading>{member.name?.length >10 ? member.name.slice(0,10)+'...' :member.name }</NameHeading>
      }
      <EditTeam>
        <Button icon={isEdit=== member.id ? "add" : 'edit'} 
       onClick={isEdit=== member.id ? ()=>UpdateTeam() :   () => { handleEditTeam() }}
        />
      </EditTeam>
    </TeamInfo>
    <Actions>
      <TeamNameSpan>{member?.mentors?.length > 0 && member?.teamMembers[0]?.user?.firstName.slice(0,6) + '...'  }</TeamNameSpan>
      <Button
        
        variant="primary"
        width={"10px"}
        text={'View'}
        icon={ "view"}
        // onClick={() => navigate(`/programs/${params.selectedProgram}/my-teams/${member.id}/meetings/`)}
        onClick={() =>{
          navigate(
            `/programs/${params.selectedProgram}/${params.tab}/${member.id}/meetings`
          )
           setSpecificProgram(true)
          }}
      />
    </Actions>
  </TeamContainer>
  )
}

export default TeamInfoCard
