import React from "react";
import { Context } from "../../../../../Store";
import callAPI from "../../../../../api/apiCaller";
import Loading from "../../../../../common/Loading";
import Modal from "../../../../../common/Modal";
import { validateIsValid } from "../../../../../common/utils";
import ApplicationEditor from "../../forms/ApplicationEditor";
import { ApplicationEditorModalContainer } from "./styles";

function ApplicationEditorModal({ programId, setShowApplicationEditor }) {
  const [applicationForm, setApplicationForm] = React.useState(null);
  const [isValid, setIsValid] = React.useState({});
  const [isSaving, setIsSaving] = React.useState(false);
  const { dispatch } = React.useContext(Context);
  React.useEffect(() => {
    callAPI(dispatch, "getApplicationForm", { id: programId }).then(
      (response) => {
        setApplicationForm(response.data);
      }
    );
  }, []);
  function saveApplicationForm() {
    setIsSaving(true);
    callAPI(dispatch, "saveApplicationForm", {
      id: programId,
      ...applicationForm,
    }).then(() => {
      setIsSaving(false);
      setShowApplicationEditor(false);
    });
  }

  if (applicationForm === null) {
    return (
      <Modal title={"Edit Application Form"}>
        <ApplicationEditorModalContainer>
          <Loading loadingItem="Application Questions" />
        </ApplicationEditorModalContainer>
      </Modal>
    );
  }

  return (
    <Modal
      title={"Edit Application Form"}
      isHeight={"auto"}
      onClose={() => {
        setShowApplicationEditor(false);
      }}
      actions={[
        {
          text: "Cancel",
          icon: "cross",
          onClick: () => {
            setShowApplicationEditor(false);
          },
        },
        {
          text: "Save",
          icon: isSaving ? "loading" : "tick",
          disabled: !validateIsValid(isValid),
          variant: "primary",
          onClick: () => {
            saveApplicationForm();
          },
        },
      ]}
    >
      <ApplicationEditorModalContainer>
        <ApplicationEditor
          programForm={{ applicationForm }}
          setProgramForm={(newProgram) => {
            setApplicationForm(newProgram.applicationForm);
          }}
          isValid={isValid}
          setIsValid={setIsValid}
        />
      </ApplicationEditorModalContainer>
    </Modal>
  );
}
export default ApplicationEditorModal;
