import styled from "styled-components";
import colors from "../../config/colors";


// position:fixed;
//     top:0px;
//     right:0px;
//     height:100vh;
//     width:300px;
//     pointer-events: none
export const ToastsContainer = styled.div`
    position:fixed;
    top:0px;
    right:0px;
    height:auto;
    width:300px;
    z-index:99999;
`;
export const ToastContainer = styled.div`
  width:100%;
  box-sizing: border-box;
  margin:10px 0px;
  display:flex;
  // justify-content:space-between;
  padding:5px;
  border-radius:10px;
  background-color:${props => props.type === "inProgress" ? colors.darkBackground : colors[props.type] };
  border:solid 1px ${colors.background}44;
  height:${props=>props.escape ? "fit-content" : "fit-content"};
  transition:all 0.2s;
  overflow:hidden;
`;

export const ToastMessage = styled.div`
    color:${colors.background};
    margin-left:10px;
    align-items:center;
    display:flex;
`
export const IconContainer = styled.div`
  height:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  padding:10px;
  background-color:${colors.text}44;
  border-radius:10px;
  box-sizing:border-box;
  cursor:pointer;
`;