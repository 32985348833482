import React, { useState } from "react";
import { LoginSecurityContainer, SecuritySection, SectionHeader, SectionActions,SectionText, SecuritySectionHeader } from "./styles";
import Labelize from "../../../../common/Labelize";
import { checkIfTokenValid, createInitialValid, updateValid, validatePassword, validateText } from "../../../../common/utils";
import TextInput from "../../../../common/TextInput";
import Button from "../../../../common/Button";
import Modal from "../../../../common/Modal";

import { ButtonSpacer } from "../../../../common/Button/styles";
import { useNavigate } from "react-router-dom";
import { validateIsValid } from "../../../../common/utils";
import callAPI from "../../../../api/apiCaller";
import { Context } from "../../../../Store";
import { HamburgerIconContainer, HamburgerIconContainerSpan } from "../../Programs/ProgramContent/styles";


function LoginSecurity(props) {
  const {state,dispatch} = React.useContext(Context);
  const [resetForm, setResetForm] = React.useState({
    currentPassword: "",
    password: "",
    confirmPassword: "",
  })
  const [showPassword, setShowPassword]=useState(false)
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [resetIsValid, setResetIsValid] = React.useState(createInitialValid(["currentPassword", "password", "confirmPassword"]))
  const [deactivationAccount, setDeactivationAccount]=useState(false)

  
  function updateResetField(field) {
    return (newValue) => {
      setResetForm({
        ...resetForm,
        [field]: newValue
      })
    }
  }
  function logOut(){
    localStorage.setItem("accessToken","")
    window.location.href="/app";
  }

  function closeModal(){
    setIsModalOpen(false)
  }
  function changePassword(){
    callAPI(dispatch,"changePassword", resetForm).then((res) => {
    });
    
  }

  function deletUserAccont(){
    setDeactivationAccount(true)
    callAPI(dispatch,"deleteUser", {}).then((res) => {
      if(res.status===401){
        logOut()
      }
    });
  }

  const form=[
    {
      label: "Current Password",
      key: "currentPassword",
      type: showPassword ? "text":"password",
      hideInstruction:true,

    },
    {
      label: "New Password",
      key: "password",
      type: showPassword ? "text":"password",
      validator: (newValue) => {
        return validatePassword(newValue);
      },
    },
    {
      label: "Confirm Password",
      key: "confirmPassword",
      hideInstruction:true,
      type:showPassword ? "text":"password",
      validator: (newValue, form) => {
        return validatePassword(newValue) && form.password === newValue;
      },
    },
  ]

 
  function isResetFormValid(){
    return Object.keys(resetForm).every((field)=>resetForm[field]!="") && resetForm.confirmPassword===resetForm.password
  }
  return <LoginSecurityContainer >
    <SecuritySection>
    <SecuritySectionHeader>
    <HamburgerIconContainer onClick={()=>props.setHamburgerOpen(!props.hamburgerOpen)}>
            <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
            <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
            <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
          </HamburgerIconContainer>
      <SectionHeader>Reset Password</SectionHeader>
      </SecuritySectionHeader>
      {
       form.map((field) => {
          return <>
          {/* label,labelkey ,mode,children,horizontal = false, isValid */}
            <Labelize label={field.label } labelkey={field.key} mode={"programs"} isValid={resetIsValid[field.key]}>
              <TextInput
                placeholder={field.label}
                type={field.type}
                value={resetForm[field.key]}
                setValue={updateResetField(field.key)}
                hideInstruction={field.hideInstruction}
                isValid={resetIsValid[field.key]}
                setIsValid={updateValid(resetIsValid, setResetIsValid, field.key)}
                validator={
                  field.validator ? (newValue)=>field.validator(newValue, resetForm) :validateText
                }
                labelkey={field.key}
              />
            </Labelize>
            <ButtonSpacer />
          </>
        })
      }
      <SectionActions>
        <Button icon="tick" variant="primary" disabled={!isResetFormValid()} onClick={changePassword} text="Reset Password" />
      </SectionActions>
    </SecuritySection>
    <SecuritySection>
      <SectionHeader>Logout</SectionHeader>
      <SectionText>You can log out of your One Mauve account.</SectionText>
      <SectionActions>
        <Button icon="next" iconLast variant="primary" text="Log Out" onClick={logOut}/>
      </SectionActions>
    </SecuritySection>
    <SecuritySection>
      <SectionHeader >Delete Account</SectionHeader>
      <SectionText>You can delete your One Mauve account.</SectionText>
      <SectionActions>
        <Button
        onClick={()=>{
          setIsModalOpen(true)
        }}
        icon="trash" variant="primary" text="Delete Account" />
      </SectionActions>
    </SecuritySection>
    {isModalOpen && <Modal
        title={"Are you sure you want to proceed?"}
        isDelete={true}
        onClose={
          closeModal
          // setShowEditProgramManagers(false)
        }
      >
        <SectionActions>
        <Button icon={ deactivationAccount ? "loading" :"trash"} variant="danger" onClick={deletUserAccont} text="Delete Account" />
        <ButtonSpacer />
        <Button icon="tick" variant="primary" onClick={()=>setIsModalOpen(false)}  text="Cancel" />

      </SectionActions>

        {/* <EditProgramManagersContainer>
        <Loading item="Program Managers" />
        </EditProgramManagersContainer>; */}
      </Modal>
}
  </LoginSecurityContainer>;
}
export default LoginSecurity;