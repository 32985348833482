import { useEffect, useState, useContext } from "react";
import {
    TextInputContainer,
    Input,
    ContactMemberImages,
    ContactName,
    DropdownContainer,
    DropdownItem,
    ContactInfo,
    SearchableChatContainer,
    UserNotFound,
    UserNotFoundText,
    ImageContainer
} from "./styles";
import { useParams } from "react-router-dom";
import Loading from "../Loading";
import { getGroupConversationsList } from "../../Store/GroupConversationList/accessors";
import { Context } from "../../Store";


export const SearchGroupChat = ({
    SearchableItemClick,
    placeholder = "Placeholder",
    disabled = false,
    wordLimit = 0,
    validator = () => true,
    isValid = null,
    isTextArea = false,
    isNotRound=false,
    setIsValid = () => {},
    isHeight=false
    }) => {
    const params = useParams();
    const [isDropdown, setIsDropdown] = useState(false);
    const [value , setValue] = useState('');
    const { state } = useContext(Context);
    const [filterConversations, setFilteredConversation] = useState(getGroupConversationsList(state).messages);
    const [contactNotFound, setContactNotFound] = useState(false);


    useEffect(() => {
        const filtering = () => {
            if(getGroupConversationsList(state).messages){
                const filteredMessages = getGroupConversationsList(state).messages.filter((message) => {
                    if (!message) return false;
                    const { team, title, members } = message;
            
                    const teamNameMatch =
                      team?.name &&
                      team.name.toLowerCase().startsWith(value.toLowerCase());
                    const titleMatch =
                      title && title.toLowerCase().startsWith(value.toLowerCase());
            
                    const nameMatch = members?.some(
                      (member) =>
                        (member?.user?.firstName &&
                          member.user.firstName
                            .toLowerCase()
                            .startsWith(value.toLowerCase())) ||
                        (member?.user?.lastName &&
                          member.user.lastName
                            .toLowerCase()
                            .startsWith(value.toLowerCase()))
                    );
            
                    // Return true if any of the conditions match
                    return teamNameMatch || titleMatch || nameMatch;
                  });
                
                if(filteredMessages.length > 0){
                    setFilteredConversation(filteredMessages)
                    setContactNotFound(false);
                }else{
                    setContactNotFound(true);
                }
            }
        }
        filtering();
    },[value,state])

    return(
        <SearchableChatContainer>
        <TextInputContainer>
            <Input
                isValid={isValid}
                value={value}
                placeholder={isNotRound ? "" : placeholder}
                type={"text"}
                isTextArea={isTextArea}
                isHeight={isHeight}
                disabled={disabled}
                onChange={(e) => {
                if (disabled) {
                    return;
                }
                setValue(e.target.value);
                if(e.target.value <= 0){
                    setIsDropdown(false)
                } else{
                    setIsDropdown(true);
                }
                setIsValid(
                    (wordLimit !== 0 ? e.target.value.length <= wordLimit : true) &&
                    validator(e.target.value)
                );
                }}
                rows={4}
                cols={50}
                min={new Date().toISOString().slice(0, 16)}
            />
        </TextInputContainer> 
                {isDropdown && 
                    <>
                <DropdownContainer>
                        {!filterConversations ? <Loading loadingItem="Contacts" /> : !contactNotFound ? filterConversations && filterConversations.map((item) => {
                            return (
                                <DropdownItem key={item.id} onClick={() => {
                                    SearchableItemClick(item)
                                    setIsDropdown(false);
                                    setValue('');
                                }}>
                                   
                                    <ContactInfo>
                                        <ImageContainer>
                                            {item.members.slice(0, 3).map((member, i) => {
                                                return (
                                                    <ContactMemberImages
                                                        key={i}
                                                        lastImage={i == 2 ? true : false}
                                                        width={item.members?.length < 2}
                                                        src={
                                                            !member.userLeft && member?.user?.image
                                                            ? member?.user?.image
                                                            : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                                        }
                                                    />
                                                );
                                            })}
                                        </ImageContainer>
                                        <div>
                                            <ContactName>
                                                {item.title
                                                ? item?.title?.length > 12
                                                ? item?.title.slice(0, 13) + "..."
                                                : item?.title
                                                : item?.team.name && item.team.name?.length > 12
                                                ? item?.team.name.slice(0, 12) + "..."
                                                : item?.team.name}
                                            </ContactName>
                                        </div>
                                    </ContactInfo>
                                </DropdownItem>
                            )
                        }) : <UserNotFound><UserNotFoundText>Group not found</UserNotFoundText></UserNotFound>}
                </DropdownContainer> 

            </>
        }
        </SearchableChatContainer>
    )
}