import React, { useEffect } from "react";
import Header from "./Header";
import { AppContainer } from "./styles";
import { Route, Routes } from "react-router-dom";
import Programs from "./Programs";
import Login from "./Login";
import { Context } from "../../Store";
import { getUserInfo, isUserLoggedIn } from "../../Store/User/accessors";
import { Navigate, useLocation } from "react-router";
import { checkIfTokenValid } from "../../common/utils";
import { loginUser } from "../../Store/User/actions";
import Loading from "../../common/Loading";
import Messages from "./Messages";
import Profile from "./Profile";
import ProgramBrowser from "./ProgramBrowser";
import UserBrowser from "./UserBrowser";
import ControlPanel from "./ControlPanel";
import { notificationToast, refreshToasts } from "../../Store/Toasts/actions";
import { anyToastExist } from "../../Store/Toasts/accessors";
import { useParams } from "react-router-dom";
import ErrorMessageForCalendar from "./Programs/ProgramContent/CalendarErrorPage";
import ReactGA from 'react-ga4'
import GroupMessages from "./Messages/GroupMessages";
import { socket } from "../../common/chat-socket";
import TermsAndConditions from "../TermsAndConditions";
  
function App() {
  let { state, dispatch } = React.useContext(Context);
  const params=useParams()
  const [isTokenChecked, setIsTokenChecked] = React.useState(false);


  React.useEffect(() => {
    checkIfTokenValid(dispatch).then(res => {
      setIsTokenChecked(true);
      dispatch(loginUser(res))
      // console.log("res ", res)
      socket.emit("store_user", res.data.id);
    }).catch(err => {
      setIsTokenChecked(true);
    })
    setInterval(() => {
      if(anyToastExist(state)){
        dispatch(refreshToasts())
      }
    }, 1000)
    ReactGA.send({ hitType: "pageview", page: "/app", title: "current user" });
  }, []);
  // React.useEffect(() => {
  //   socket.on('privateMessage', (msg) => {
  //     notificationToast(dispatch, {"success":msg})
  //   });

  // Emit an event to the backend
  if (!isTokenChecked) {
    return (
      <AppContainer>
        <Loading loadingItem="app" />
      </AppContainer>
    );
  }
  // }, []);
    if (!isUserLoggedIn(state) && window.location.pathname.includes("app/terms-and-services")){
      return (
      <AppContainer>
      <TermsAndConditions />
        </AppContainer>
      );
    } else if(!isUserLoggedIn(state)) {
    return (
      <AppContainer>
        <Login />
      </AppContainer>
    );
  }




  return (
    <AppContainer>
      <Header />
      <Routes>
        {getUserInfo(state)?.roles[0] === "admin" ? (
          <>
            <Route path="/controlPanel/:panel" element={<ControlPanel />} />
            <Route path="/userbrowser" element={<UserBrowser />} />
            <Route path="/userbrowser/:userId" element={<UserBrowser />} />
            <Route path="/programbrowser" element={<ProgramBrowser />} />
            <Route
              path="/programbrowser/:programId"
              element={<ProgramBrowser />}
            />
          </>
        ) : (
          <>
          {/* <Route path="/programs/Outbound" element={<Programs />} /> */}
            <Route path="/" element={<Programs />} />
            <Route path="/programs" element={<Programs />} />
            <Route path="/programs/:selectedProgram" element={<Programs />} />
            <Route
              path="/programs/:selectedProgram/:tab"
              element={<Programs />}
            />
            <Route
              path="/programs/:selectedProgram/:tab/:connectionId/:connectionTab"
              element={<Programs />}
            />
            <Route
              path="/programs/:selectedProgram/:tab/:connectionId/:connectionTab/:currentNote"
              element={<Programs />}
            />
            <Route
              path="/programs/added-to-calendar"
              element={<ErrorMessageForCalendar />}
            />
            {/* <Route
              path="/programs/my-teams"
              element={<MyTeams />}
            /> */}
            {/* <Route
              path="/programs/:selectedProgram/my-teams/:connectionId/:connectionTab/"
              element={<SpecificTeam />}
            /> */}
          </>
        )}  

        <Route path="/messages/:user" element={<Messages  key={params.user}/>} />
        <Route path="/messages/group/:user" element={<GroupMessages />} />
        <Route path="/messages/group" element={<GroupMessages />} />
        <Route path="/messages" element={<Messages  />} />
        <Route path="/profile/:profileSection" element={<Profile />} />
        <Route
          path="/"
          element={
            <Navigate
              to={
                getUserInfo(state).roles[0] === "admin"
                  ? "/programbrowser"
                  : "/programs"
              }
            />
          }
        />
      </Routes>
    </AppContainer>
  );
}
export default App;
