import styled from "styled-components";
import colors from "../../../../../config/colors";
import { Scrollable } from "../../../../../common/styles";

export const ProgramsListSectionContainer = styled.div`
    border-bottom: 1px solid ${colors.secondary};
   
      
`;

export const FolderName = styled.div`
line-height:40px;
height: 40px;
box-sizing: border-box;
font-size:16px;
padding:0px 20px;
font-weight: bold;
width:350px;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
`;
export const FolderPrograms = styled.div``;
