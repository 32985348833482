import NewRequest from "../NewRequest";
import RequestList from "../RequestList";
import {  RequestContainer, RequestWrapper } from "./style";
import { OpenNetworkProgramTabs } from "./config";
import Tabs from "../../../../../common/Tabs";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

function Requests() {
 
    return <RequestContainer>
   
        <RequestWrapper>
       <RequestList/>
    </RequestWrapper>
    </RequestContainer>

}

export default Requests;