const colorArray = [
  { color: "9px solid #44449B" },
  { color: "9px solid #EFEFEF" },
    { color: "9px solid #34bd1d" },
    { color: "9px solid #44449B" },
    { color: "9px solid #eba41d" },
    { color: "9px solid #f35608" },
    { color: "9px solid #A5D0A8" },
    { color: "9px solid #8CADA7" },
    { color: "9px solid #CCCCCC" },
    { color: "9px solid #00C714" },
  ];

  export default colorArray

  