import styled from "styled-components";
import colors from "../../../../../../../../../config/colors";
import { panelSpacing } from "../../../../../../../../../common/styles";

export const NotesListContainer = styled.div`
    width: 300px;
    border-right: 1px solid ${colors.secondary};
    height:calc(100vh - 340px - ${panelSpacing*2}px);
    box-sizing: border-box;
`;

export const NoteItem = styled.div`
    padding:15px;
    border-bottom:solid 1px ${colors.secondary};
    cursor:pointer;
    transition: all 0.1s ease-in-out;
    user-select: none;
    :active{
        transform: scale(0.95);
        
    }
    ${props=>props.active && `
        border-left: solid 5px ${colors.primary};
    `}
`;
export const NoteTitle = styled.div`
    font-size: 16px;
    margin-bottom:5px;

    ${props=>props.active && `
        font-weight: bold;
    `}
`;
export const NoteTime = styled.div`
    font-size: 12px;
`;
export const NoteAction = styled.div`
    padding:${panelSpacing}px;
    display:flex;
    align-items:center;
    justify-content:center;
`;