import styled from "styled-components";
import { borderRadius } from "../../../common/styles";
import { isMobile } from "../../../common/utils";
import colors from "../../../config/colors";

export const ServicesPanelContainer = styled.div`
    display:flex;
    align-items:center;
    flex-direction:column;
    padding:5%;
    width:100%;
    box-sizing: border-box;
    background-color:${colors.background};

    
`;

export const ServicesTitle = styled.div`
    font-size:48px;
    font-weight:bold;
    margin-bottom:10px;
    color:${colors.primary};
    text-align:center;
@media(max-width:991px) {
    margin-top:40px;
}
`;
export const ServicesSubText = styled.div`
    font-size:18px;
    margin-bottom:60px;
    text-align:center;
`;
export const ServiceCards = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    ${
      isMobile() &&
      `
        flex-direction:column;
    `
    }
`;
export const ServiceCard = styled.div`
    max-width:30%;
    background-color:${colors.secondary}77;
    border-radius:${borderRadius}px;
    padding:40px;
    box-sizing:border-box;
    height:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    margin-bottom:20px;
    ${
      isMobile() &&
      `
        max-width:95%;
    `
    }
`;
export const ServiceImage = styled.svg`
    height:200px;
    width:200px;
    margin-bottom:20px;
`;
export const ServiceCardTitle = styled.div`
    font-size:24px;
    font-weight:bold;
    color:${colors.primary};
    margin-bottom:10px;
`;
export const ServiceCardText = styled.div`
    font-size:18px;
    text-align:center;
`;

//

export const ServicesPanelOuterContainer = styled.div`
    box-sizing: border-box;
    background-color: #FAFAFA;
    padding: 50px 44px 82px;
    @media screen and (max-width: 1920px) {
        padding: 25px 44px 82px;
    }
    @media screen and (max-width: 1600px) {
        padding: 15px 44px 82px;
    }
    @media screen and (max-width: 1366px) {
        padding: 30px 22px 82px;
    }
    @media screen and (max-width: 992px) {
        padding: 40px 22px 62px;
    }
    @media screen and (max-width: 767px) {
        padding: 60px 17px 38px;
    }
`;

export const ServiceTitle = styled.div`
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 56px;
    color:${colors.primary};
    text-align:center;
    @media screen and (max-width: 1366px) {
        font-size: 38px;
        line-height: 54px;
        max-width: 80%;
        margin: 0 auto;
    }
    @media screen and (max-width: 992px) {
        font-size: 30px;
        line-height: 38px;
    }
    @media screen and (max-width: 767px) {
        max-width: 100%;
    }
`;


export const ServiceSubText = styled.div`
    color: #302F2F;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 606px;
    margin: 16px auto 0;
    @media screen and (max-width: 1366px) {
        font-size: 20px;
    }
    @media screen and (max-width: 992px) {
        font-size: 18px;
        line-height: 26px;
        max-width: 80%;
        margin: 20px auto 0;
    }
    @media screen and (max-width: 767px) {
        max-width: 100%;
    }
`;


export const ServicesCards = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin-top: 50px;
    @media screen and (max-width: 992px) {
        flex-direction: column;
    }
    @media screen and (max-width: 767px) {
        margin-top: 30px;
    }
`;

export const ServicesCard = styled.div`
    border-radius: 20px;
    background: rgba(79, 68, 155, 0.10);
    padding: 24px 0 60px;
    max-width: 30%;
    width:100%;
    min-height: 330px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1600px) {
        padding: 24px 15px 60px;
    }
    @media screen and (max-width: 1366px) {
        padding: 15px;
        max-width: 29%;
    }
    @media screen and (max-width: 992px) {
        max-width: 70%;
        margin: 0 auto;
        min-height: 300px;
        margin-bottom: 25px;
        &:last-child{
            margin-bottom: 0px;
        }
    }
    @media screen and (max-width: 767px) {
        max-width: 80%;
        padding: 25px 28px;
    }
`;


export const ServicesImage = styled.img`
`;

export const ServicesCardTitle = styled.h2`
    color: #44449B;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media screen and (max-width: 1600px) {
        font-size: 28px;
    }
    @media screen and (max-width: 1366px) {
        font-size: 24px;
    }
    @media screen and (max-width: 992px) {
        font-size: 22px;
    }
`;

export const ServicesCardText = styled.p`
    color: #000;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    min-height: 100px;
    max-width: 380px;
    margin: 0 auto;
    @media screen and (max-width: 1366px) {
        font-size: 20px;
    }
    @media screen and (max-width: 992px) {
        font-size: 18px;
        min-height: 50px;
    }
`;