import styled from "styled-components";
import { headerSize, panelSpacing } from "../../../common/styles";
import colors from "../../../config/colors";

export const HeaderContainer = styled.div`
    height: ${headerSize}px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: ${colors.primary};
    box-shadow: 0px 2px 4px 0px #0004;
`;
export const HeaderSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;
export const LogoContainer = styled.div`
    background-color: ${colors.primary};
    height:${headerSize}px;
    display: flex;
    padding:0px 20px;
    align-items: center;
    justify-content: center;
    border-right:2px solid ${colors.primary};
    box-shadow: 2px 0px 4px 0px #0004;
    z-index:5;

`;
export const HeaderOption = styled.div`
    font-size: ${15/50 * headerSize}px;
    padding:0px 25px;
    height:${headerSize}px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    color:${props=>props.active? colors.primary : colors.background};
    background-color:${props=>props.active? colors.background : colors.primary};
    box-shadow:${props=>props.active? `0px 5px 5px 1px #00000022` : "none"};
    font-weight: ${props=>props.active?"bold":"normal"};
    cursor:pointer;
    transition: all 0.1s ease-in-out;
    
    :active{
        transform: scale(0.95);
    }

`;
export const IconOption=styled(HeaderOption)`
    padding:0px;
    display:flex;
    align-items: center;
    justify-content: center;
    height:${headerSize}px;
    width:${headerSize}px;
    box-sizing:border-box;
`;
export const Premium=styled(HeaderOption)`
    padding:0px;
    display:flex;
    align-items: center;
    justify-content: center;
    height:${headerSize}px;
    width:${headerSize}px;
    box-sizing:border-box;
`;
export const UserOption = styled(HeaderOption)`
    padding:${10/50 * headerSize}px;
`;
export const UserImage = styled.img`
    height:${30/40 * headerSize}px;
    width:${30/40 * headerSize}px;
    object-fit:cover;
    border-radius:50%;
    border:solid 1px ${colors.primary};
`;


export const ProfileDropdown = styled.div`
    position:absolute;
    top:${headerSize}px;
    right:${props=>props.visible ? 0: -200}px;
    width:fit-content;
    background-color:${colors.text}88;
     backdrop-filter:blur(5px);
    padding:0px;
    transition:all 0.3s;
    width:200px;
    border-radius:0px 0px 0px ${panelSpacing}px ;
    z-index:5;
`;
export const ProfileOption = styled.div`
    background-color:${props=>props.active? colors.primary:colors.background};
    color:${props=>props.active? colors.background:colors.primary};
    padding:${panelSpacing/2}px;
    font-size: ${15/50 * headerSize}px;
    font-weight:normal;
    margin:${panelSpacing/4}px;
    
    border-radius:${panelSpacing}px;
    display:flex;
    align-items:center;
    white-space:nowrap;
    transition:all 0.3s;
    :hover{
        font-weight:bold;
    }
`;
export const ProfileBorder = styled.hr`
    margin:0px;
`;