import styled from "styled-components";


export const Spacer = styled.div`
    height: 30px;
    width:100%;
`

export const ReviewPanelOuterContainer = styled.div`
    padding:  50px 0 67px;
    background-color: #FFFFFF;
    box-sizing: border-box;
    @media screen and (max-width: 767px) {
        padding:  50px 0 50px;
    }
`;

export const ReviewPanelContainer = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
`;

export const ReviewHeading = styled.h3`
    color: #44449B;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 50px; 
    max-width: 660px;
    margin: 0 auto;
    @media screen and (max-width: 1366px) {
        font-size: 38px;
        line-height: 48px;
        max-width: 80%; 
    }
    @media screen and (max-width: 992px) {
        font-size: 30px;
        line-height: 38px; 
    }
    @media screen and (max-width: 767px) {
        max-width: 100%; 
        padding: 0 20px;
    }
`;

export const SliderContainer = styled.div`
    margin-top: 53px;
`;

export const Card = styled.div`
    display: flex !important;
    max-width: 80%;
    width:100%;
    padding: 20px 18px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 20px;
    border: 1px solid #F3F3F3;
    min-height: 170px;
`


export const ReviewText = styled.p`
    color: #5D5D5D;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    min-height: 110px;
`
export const ReviewPerson = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 38px;
`
export const ReviewImage = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
`
export const Image = styled.img`
    height: 100%;
    width: 100%;
    border-Radius: 50%;
    object-fit: cover;
    object-position: left;
`
export const Info = styled.div`
`

export const Name = styled.h3`
    color: #5D5D5D;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
`

export const Role = styled.p`
    color: #5D5D5D;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 0;
`
