import React from "react";
import {
  MeetingPanelContainer,
  MeetingPanelHeading,
  MeetingPanelDescription,
  MeetingPanelImage,
  MeetingLines,
  MeetingImageWrapper
} from "./styles";
import MeetingImage from "./Meeting.png";
import MeetingBackgroundLines from "./MeetingLines.svg";

const MeetingPanel = () => {
  return (
    <MeetingPanelContainer>
      <MeetingPanelHeading>Group and One-to-One Mentoring made easy</MeetingPanelHeading>
      <MeetingPanelDescription>Engage in group, or one-to-one mentoring</MeetingPanelDescription>
      <MeetingImageWrapper>
        <MeetingPanelImage src={MeetingImage} alt="Meeting Image" />
      </MeetingImageWrapper>
      <MeetingLines src={MeetingBackgroundLines} alt="Background Meeting Image"/>
    </MeetingPanelContainer>
  );
};

export default MeetingPanel;
