import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Card,
  ReviewText,
  ReviewPerson,
  ReviewImage,
  Image,
  Info,
  Name,
  Role,
} from "./styles";
import ReviewPhoto from "./ReviewPerson.svg";
import ReviewPhoto2 from "./ReviewPerson2.png";
import ReviewPhoto3 from "./ReviewPerson3.png";
import ReviewPhoto4 from "./ReviewPerson4.png";
import ReviewPhoto5 from "./ReviewPerson5.png";
import ReviewPhoto6 from "./ReviewPerson6.png";
import ReviewPhoto7 from "./ReviewPerson7.png";
import ReviewPhoto8 from "./ReviewPerson8.png";

export default function ReviewSlider({ isBottom }) {
  var settings = {
    dots: false,
    infinite: true,
    loop: true,
    autoplay: true,
    speed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplaySpeed: 0,
    centerMode: true,
    easing: "linear",
    pauseOnFocus: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var settingsBottom = {
    dots: false,
    infinite: true,
    loop: true,
    autoplay: true,
    speed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplaySpeed: 0,
    centerMode: true,
    easing: "linear",
    rtl: true,
    pauseOnFocus: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      {isBottom ? (
        <>
          <Slider {...settingsBottom}>
            <Card>
              <ReviewText>
                “Onemauve’s mentors don’t just offer generic advice—they take
                the time to understand your unique challenges and goals. The
                experience feels truly customized, and the results speak for
                themselves”
              </ReviewText>
              <ReviewPerson>
                <ReviewImage>
                  <Image src={ReviewPhoto4} alt="Review Person" />
                </ReviewImage>
                <Info>
                  <Name>Victor T.</Name>
                  <Role>Mentee</Role>
                </Info>
              </ReviewPerson>
            </Card>
            <Card>
              <ReviewText>
                “One Mauve significantly helped our team retain our mentors in
                the program! So thankful for this platform”
              </ReviewText>
              <ReviewPerson>
                <ReviewImage>
                  <Image src={ReviewPhoto5} alt="Review Person" />
                </ReviewImage>
                <Info>
                  <Name>Debbie J.</Name>
                  <Role>Mentorship Coordinator</Role>
                </Info>
              </ReviewPerson>
            </Card>
            <Card>
              <ReviewText>
                “One mauve stands out because the simple interface makes it
                incredibly easy to manage my mentorship program and retain my
                mentors”
              </ReviewText>
              <ReviewPerson>
                <ReviewImage>
                  <Image src={ReviewPhoto6} alt="Review Person" />
                </ReviewImage>
                <Info>
                  <Name>Roselyn D.</Name>
                  <Role>Mentorship Coordinator</Role>
                </Info>
              </ReviewPerson>
            </Card>
            <Card>
              <ReviewText>
                “Onemauve gave me the tools and the encouragement I needed to
                navigate a career transition. The guidance I received was
                invaluable in helping me land a new role in a different
                industry.”
              </ReviewText>
              <ReviewPerson>
                <ReviewImage>
                  <Image src={ReviewPhoto7} alt="Review Person" />
                </ReviewImage>
                <Info>
                  <Name>Anna A.</Name>
                  <Role>Mentee</Role>
                </Info>
              </ReviewPerson>
            </Card>
          </Slider>
        </>
      ) : (
        <>
          <Slider {...settings}>
            <Card>
              <ReviewText>
                “Before One Mauve, I was overwhelmed with the time required to
                keep our mentorship program going. Now it pretty much runs
                itself!! I’m so pleased with the platform and highly recommend
                it to other coordinators”
              </ReviewText>
              <ReviewPerson>
                <ReviewImage>
                  <Image src={ReviewPhoto8} alt="Review Person" />
                </ReviewImage>
                <Info>
                  <Name>Paulina Q.</Name>
                  <Role>Mentorship Coordinator</Role>
                </Info>
              </ReviewPerson>
            </Card>
            <Card>
              <ReviewText>
                “I’ve never experienced mentorship as impactful as what Onemauve
                offers. The mentors are truly invested in your personal and
                professional success, and it shows.”
              </ReviewText>
              <ReviewPerson>
                <ReviewImage>
                  <Image src={ReviewPhoto2} alt="Review Person" />
                </ReviewImage>
                <Info>
                  <Name>Bjorn C.</Name>
                  <Role>Mentee</Role>
                </Info>
              </ReviewPerson>
            </Card>
            <Card>
              <ReviewText>
                “One Mauve made it super easy for me to schedule coffee chats
                with my mentees! I no longer lose track of emails, meetings, and
                our progress.”
              </ReviewText>
              <ReviewPerson>
                <ReviewImage>
                  <Image src={ReviewPhoto3} alt="Review Person" />
                </ReviewImage>
                <Info>
                  <Name>Jayleen L.</Name>
                  <Role>Mentor</Role>
                </Info>
              </ReviewPerson>
            </Card>
            <Card>
              <ReviewText>
                “Onemauve’s platform is packed with resources and one-on-one
                mentorship opportunities that are unmatched. I went from
                entry-level to a management position in record time!”
              </ReviewText>
              <ReviewPerson>
                <ReviewImage>
                  <Image src={ReviewPhoto} alt="Review Person" />
                </ReviewImage>
                <Info>
                  <Name>Deleon R.</Name>
                  <Role>Mentee</Role>
                </Info>
              </ReviewPerson>
            </Card>
          </Slider>
        </>
      )}
    </>
  );
}
