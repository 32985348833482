import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import OneMauve from './OneMauve';
// import gitInfo from "./gitInfo/gitinfo.json"
import ReactGA from 'react-ga4'

const root = ReactDOM.createRoot(document.getElementById('root'));
ReactGA.initialize('G-TW8HNXKZK9')
root.render(
    <OneMauve />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();