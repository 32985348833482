import styled from "styled-components";
import { Scrollable } from "../../../../../common/styles";

export const AddProgramContainer = styled.div`
width:60vw;
    max-height:70vh;
    ${Scrollable}
    padding-right:20px;
    @media(max-width:640px){
        width:100%;
    }
`;


export const TitleBar = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
`;
export const ProgressContainer = styled.div`
    width:30%;
`;
export const Actions = styled.div`
    display:flex;
    align-items:center;
    justify-content:flex-end;
`;