import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { Context } from "../../../../../Store";
import callAPI from "../../../../../api/apiCaller";
import Button from "../../../../../common/Button";
import ComboBox from "../../../../../common/ComboBox";
import Labelize from "../../../../../common/Labelize";
import TextInput from "../../../../../common/TextInput";
import {
  dateToUnixTimeStamp,
  formatDateToPST,
} from "../../../../../common/utils";
import {
  NewRequestHeader,
  RequestButtonContainer,
  RequestListContainer,
  RequestListContainerWrapper,
} from "./styles";
function NewRequestForm({
  editForm = {},
  isEdit = false,
  setShowForm,
  isTeam = false,
  setNewMeeting,
  currentTeamTab,
}) {
  // console.log(currentTeamTab,"<<currentTeamTab");
  const { state, dispatch } = React.useContext(Context);
  const [vouchers, setVouchers] = useState([{ id: "No", title: "No" }]);
  const [openNetworkPrograms, setOpenNetworkPrograms] = React.useState([]);
  const [resetForm, setResetForm] = React.useState();
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [isValid, setIsValid] = React.useState({
    av1: null,
    av2: null,
    av3: null,
    comment: null,
    program: null,
    location: null,
  });
  const params = useParams();
  function updateResetField(field) {
    return (newValue) => {
      setResetForm({
        ...resetForm,
        [field]: newValue,
      });
    };
  }

  const isDisabled = () => {
    if (isEdit) return false;
    if (isTeam) return false;
    return resetForm?.date &&
      resetForm?.date2 &&
      resetForm?.date3 &&
      resetForm?.location &&
      resetForm?.reasonForMeeting
      ? false
      : true;
  };

  const isDisabledWithMeetingLink = () => {
    if (isEdit) return false;
    if (isTeam) return false;
      return resetForm?.date &&
        resetForm?.date2 &&
        resetForm?.date3 &&
        resetForm?.location &&
        resetForm?.reasonForMeeting && 
        resetForm?.meetingLink
        ? false
        : true;
  };

  const withMeetingLink =  resetForm?.location === "virtual" && openNetworkPrograms[0]?.programType === "OPEN_NETWORK";

  const programs = useCallback(() => {
    callAPI(dispatch, "getOpenNetworkPrograms", {
      id: isEdit ? editForm.programId : params.selectedProgram,
    }).then((res) => {
      // console.log();
      if (res.isResponseOk) {
        if (isEdit) {
          setResetForm({
            ...resetForm,
            ...editForm.meeting,
            date: editForm ? formatDateToPST(editForm.date) : "",
            date2: editForm ? formatDateToPST(editForm.date2) : "",
            date3: editForm.date3 ? formatDateToPST(editForm.date3) : "",
            //  { reasonForMeeting:editForm.meeting.reasonForMeeting ?editForm.meeting.reasonForMeeting : '',
            //   additionalInfo:editForm.meeting.additionalInfo ?editForm.meeting.additionalInfo : '',
            //   programId: editForm.programId ?editForm.programId : '',
            //   location: editForm?.meeting?.location,}
            // perk: editForm.meeting.perk && editForm.meeting.perk==='coffee'?'coffee':'lunch'
          });
          const currentProgram = res.data.find(
            (program) => program.id === editForm.programId
          );
          setOpenNetworkPrograms([currentProgram]);
          if (
            currentProgram?.programBalance?.availableCoffees > 0 &&
            currentProgram.programBalance.availableLunch > 0
          ) {
            setVouchers([
              ...vouchers,
              { title: "Coffee", id: "coffee" },
              { title: "Lunch", id: "lunch" },
            ]);
          } else if (currentProgram?.programBalance?.availableLunch > 0) {
            setVouchers([...vouchers, { title: "Lunch", id: "lunch" }]);
          } else if (currentProgram?.programBalance?.availableCoffees > 0) {
            setVouchers([...vouchers, { title: "Coffee", id: "coffee" }]);
          } else {
            setVouchers([{ title: "Not available", id: "Not_available" }]);
          }
        } else {
          const selectedProgramId = params.selectedProgram;
          const currentProgram = res.data.find(
            (program) => program.id == selectedProgramId
          );
          setOpenNetworkPrograms([currentProgram]);
          // console.log("currentProgram ", currentProgram)
          if (
            currentProgram?.programBalance?.availableCoffees > 0 &&
            currentProgram.programBalance.availableLunch > 0
          ) {
            setVouchers([
              ...vouchers,
              { title: "Coffee", id: "coffee" },
              { title: "Lunch", id: "lunch" },
            ]);
          } else if (currentProgram?.programBalance?.availableLunch > 0) {
            setVouchers([...vouchers, { title: "Lunch", id: "lunch" }]);
          } else if (currentProgram?.programBalance?.availableCoffees > 0) {
            setVouchers([...vouchers, { title: "Coffee", id: "coffee" }]);
          } else {
            setVouchers([{ title: "Not available", id: "Not_available" }]);
          }
          setResetForm({
            ...resetForm,
            programId: currentProgram?.id ? currentProgram?.id : "",
            location: locations[0].id,
          });
        }
      }
    });
  }, []);
  React.useEffect(() => {
    programs();
  }, []);

  const locations = [
    { title: "In person", id: "In person" },
    { title: "virtual", id: "virtual" },
  ];

  const meetingRequests = {
    form: [
      {
        label: "Select Program",
        key: "programId",
        placeholder: "Select a Program",
        options: isEdit
          ? [editForm.program]
          : openNetworkPrograms
          ? openNetworkPrograms
          : "",
        type: "combobox",
      },
      {
        label: "Reason for meeting",
        key: "reasonForMeeting",
        placeholder: "Indicate reason",
        validator: (newValue) => {
          return true;
        },
        type: "text",
      },
      {
        label: "Select a location",
        key: "location",
        placeholder: "Location",
        options: locations,
        type: "combobox",
      },
      {
        label: "Meeting link",
        key: "meetingLink",
        placeholder: "paste link here",
        type: "text",
      },
      {
        label: "Would you like to request coffee or lunch voucher",
        key: "perk",
        placeholder: "Voucher",
        options: vouchers?.length && vouchers,
        type: "combobox",
      },

      {
        label: isTeam ? "Select a Date/Time" : "Availability",
        key: "date",
        value: "2023-09-28T12:27",
        placeholder: "availability",
        validator: (newValue) => {
          return true;
        },
        type: "datetime-local",
      },
      {
        key: "date2",
        placeholder: "availability",
        validator: (newValue) => {
          return true;
        },
        type: "datetime-local",
      },

      {
        key: "date3",
        placeholder: "availability",
        validator: (newValue) => {
          return true;
        },
        type: "datetime-local",
      },

      {
        label: isTeam
          ? "Additional Comments (If virtual, include meeting link here)"
          : "Additional comments",
        key: "additionalInfo",
        placeholder: "additional comments",
        validator: (newValue) => {
          return true;
        },
        type: "TextArea",
      },
    ],
    action: (setIsProcessing, form, dispatch, setMode, navigate, resetForm) => {
      if (isEdit) {
        form.id = editForm.meetingId;
        if (form.perk === "No") {
          delete form.perk;
        }
      }

      form.date3 = dateToUnixTimeStamp(form.date3);
      form.date = dateToUnixTimeStamp(form.date);
      form.date2 = dateToUnixTimeStamp(form.date2);
      form.programId = +form.programId;
      if (openNetworkPrograms[0].programType === "TEAM") {
        delete form.date2;
        delete form.date3;
        form.teamId = params.connectionId;
        form.meetingWith = currentTeamTab;
      }

      let request = {
        ...form,
        receivedby: parseInt(params.connectionId),
        isOpenNetwork: withMeetingLink ? true: false,
      };

      // callAPI(dispatch,isEdit ? "acceptMeetingRequest" : "createMeeting", request)
      callAPI(dispatch, isEdit ? "updateMeeting" : "createMeeting", request)
        .then((response) => {
          setIsProcessing(false);
          setResetForm(null);
          setShowForm(false);
          setNewMeeting(false);
        })
        .catch((err) => {
          console.error(err);
          setIsProcessing(false);
          setNewMeeting(false);
        });
    },
  };

  return (
    <RequestListContainer>
      <RequestListContainerWrapper>
        <NewRequestHeader>
          {isTeam ? "Schedule a Meeting" : "Request Meeting"}
        </NewRequestHeader>
        {meetingRequests.form.map((element) => {
          return (
            <>
              {" "}
              <Labelize
                label={
                  element.key === "meetingLink" ?
                 (element.key === "meetingLink" && resetForm?.location === "virtual" && openNetworkPrograms[0]?.programType === "OPEN_NETWORK" ?  
                  element.label : '') : element.key === "perk" && resetForm?.location === "virtual" ? '' 
                    : element.label
                }
              >
                {element.type === "combobox" ? (
                  element.key === "perk" &&
                  resetForm?.location === "virtual" ? (
                    ''
                  ) : (
                    <ComboBox
                      placeholder={element.placeholder}
                      options={element.options}
                      validator={(newValue) =>
                        element.validator(newValue, meetingRequests.form)
                      }
                      hideInstruction={element.hideInstruction}
                      value={
                        isEdit && editForm?.meeting[element.key]
                          ? editForm.meeting[element.key]
                          : meetingRequests.form[element.key]
                      }
                      type={element.type}
                      setValue={updateResetField(element.key)}
                    />
                  )
                ) : element.key === "meetingLink" ? (
                  element.key === "meetingLink" &&
                  resetForm?.location === "virtual" && openNetworkPrograms[0]?.programType === "OPEN_NETWORK" ? (
                    <TextInput
                        placeholder={element.placeholder}
                        value={
                          element.type === "datetime-local" && isEdit
                            ? resetForm && resetForm[element.key]
                            : isEdit
                            ? resetForm && resetForm[element.key]
                            : meetingRequests.form[element.key]
                        }
                        type={element.type}
                        isTextArea={element.type === "TextArea" ? true : undefined}
                        isHeight={element.type === "TextArea" ? true : false}
                        setValue={updateResetField(element.key)}
                      />
                  ) : (
                    ""
                  )
                ) : isTeam ? (
                  <>
                    {element.key === "reasonForMeeting" && (
                      <TextInput
                        placeholder={element.placeholder}
                        isValid={isValid[element.key]}
                        setIsValid={(newIsValid) => {}}
                        validator={(newValue) =>
                          element.validator(newValue, meetingRequests.form)
                        }
                        hideInstruction={element.hideInstruction}
                        value={
                          element.type === "datetime-local" && isEdit
                            ? resetForm && resetForm[element.key]
                            : isEdit
                            ? resetForm && resetForm[element.key]
                            : meetingRequests.form[element.key]
                        }
                        type={element.type}
                        isTextArea={
                          element.type === "TextArea" ? true : undefined
                        }
                        isHeight={element.type === "TextArea" ? true : false}
                        setValue={updateResetField(element.key)}
                      />
                    )}
                    {element.key === "date" && (
                      <TextInput
                        placeholder={element.placeholder}
                        isValid={isValid[element.key]}
                        setIsValid={(newIsValid) => {}}
                        validator={(newValue) =>
                          element.validator(newValue, meetingRequests.form)
                        }
                        hideInstruction={element.hideInstruction}
                        value={
                          element.type === "datetime-local" && isEdit
                            ? resetForm && resetForm[element.key]
                            : isEdit
                            ? resetForm && resetForm[element.key]
                            : meetingRequests.form[element.key]
                        }
                        type={element.type}
                        isTextArea={
                          element.type === "TextArea" ? true : undefined
                        }
                        isHeight={element.type === "TextArea" ? true : false}
                        setValue={updateResetField(element.key)}
                      />
                    )}
                    {element.key === "additionalInfo" && (
                      <TextInput
                        placeholder={element.placeholder}
                        isValid={isValid[element.key]}
                        setIsValid={(newIsValid) => {}}
                        validator={(newValue) =>
                          element.validator(newValue, meetingRequests.form)
                        }
                        hideInstruction={element.hideInstruction}
                        value={
                          element.type === "datetime-local" && isEdit
                            ? resetForm && resetForm[element.key]
                            : isEdit
                            ? resetForm && resetForm[element.key]
                            : meetingRequests.form[element.key]
                        }
                        type={element.type}
                        isTextArea={
                          element.type === "TextArea" ? true : undefined
                        }
                        isHeight={element.type === "TextArea" ? true : false}
                        setValue={updateResetField(element.key)}
                      />
                    )}
                  </>
                ) : (
                  <TextInput
                    placeholder={element.placeholder}
                    isValid={isValid[element.key]}
                    setIsValid={(newIsValid) => {
                      // setIsValid({ ...isValid, [element.key]: newIsValid });
                    }}
                    validator={(newValue) =>
                      element.validator(newValue, meetingRequests.form)
                    }
                    hideInstruction={element.hideInstruction}
                    value={
                      element.type === "datetime-local" && isEdit
                        ? resetForm && resetForm[element.key]
                        : isEdit
                        ? resetForm && resetForm[element.key]
                        : meetingRequests.form[element.key]
                    }
                    // value={element.type === "datetime-local" && isEdit ? unixTImeStampToIsoDate(editForm.meeting[element.key]) : isEdit ?  editForm.meeting[element.key] : meetingRequests.form[element.key]}
                    type={element.type}
                    isTextArea={element.type === "TextArea" ? true : undefined}
                    isHeight={element.type === "TextArea" ? true : false}
                    setValue={updateResetField(element.key)}
                  />
                )}
              </Labelize>
            </>
          );
        })}
        <RequestButtonContainer>
          <Button
            text={
              isEdit
                ? "Update Request"
                : isTeam
                ? "Schedule Meeting"
                : "Submit Request"
            }
            variant="primary"
            disabled={withMeetingLink ? isDisabledWithMeetingLink() : isDisabled()}
            icon={isProcessing ? "loading" : ""}
            onClick={() => {
              setIsProcessing(true);
              meetingRequests.action(setIsProcessing, resetForm, dispatch);
            }}
          />
        </RequestButtonContainer>
      </RequestListContainerWrapper>
    </RequestListContainer>
  );
}

export default NewRequestForm;
