import React, { useEffect, useState } from "react";
import {
  Actions,
  Card,
  CardContainer,
  ClindalyContainer,
  ImageWrapper,
  InfoWraper,
  StyledIframe,
  Subtitle,
} from "./style";
import { UserImage } from "../../../../../../Messages/ChatWindow/styles";
import { Spacer } from "../../../../ProgramInBounds/styles";
import Button from "../../../../../../../../common/Button";
import callAPI from "../../../../../../../../api/apiCaller";
import { useParams } from "react-router-dom";
import { Context } from "../../../../../../../../Store";
import Loading from "../../../../../../../../common/Loading";
import colorArray from "../../../../../../../../config/colors-array";
import Modal from "../../../../../../../../common/Modal";

const ProgramManager = () => {
  const params = useParams();
  // const [isCalendly, setIsCalendly] = useState(false);
  // const [userCalendlyLink, setUserCalendlyLink] = useState(null);
  const [managers, setManagers] = useState([]);
  const { state, dispatch } = React.useContext(Context);

  const getManagers = () => {
    callAPI(dispatch, "getProgramManagers", { id: params.selectedProgram })
      .then((res) => {
        setManagers(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const openUserCalendly = (userCalenderLink) => {
    window.open(`https://calendly.com/${userCalenderLink}`, "_blank");
  };
  useEffect(() => {
    getManagers();
  }, []);

  if (managers?.length === null) {
    return <Loading loadingItem="Managers" />;
  }

  return (
    <>
      <CardContainer>
        {managers.map((manager, i) => (
          <Card color={colorArray[i].color}>
            <Subtitle font={"20px"}>
              {manager.user?.firstName + " " + manager.user?.lastName}
            </Subtitle>
            <Spacer />
            <ImageWrapper>
              <UserImage
                src={
                  manager.user?.image
                    ? manager.user?.image
                    : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                }
              />
              <InfoWraper>
                <Subtitle>{manager.user?.calendarLink}</Subtitle>
              </InfoWraper>
            </ImageWrapper>
            <Actions>
              <Button
                width={25}
                text={"Book"}
                disabled={!manager.user?.calendarLink}
                onClick={() => openUserCalendly(manager.user?.calendarLink)}
                // onClick={() => {
                //   setIsCalendly(true);
                //   setUserCalendlyLink(manager.user?.calendarLink);
                // }}
              />
            </Actions>
          </Card>
        ))}
        {/* {isCalendly && (
          <Modal
            title={"One Mauve Calendly "}
            onClose={() => setIsCalendly(false)}
            actions={[
              {
                text: "Cancel",
                icon: "cross",
                onClick: () => {
                  setIsCalendly(false);
                },
              },
            ]}
          >
            <ClindalyContainer>
              {userCalendlyLink && (
                <StyledIframe
                  src={`https://calendly.com/${userCalendlyLink}`}
                  title="Calendly"
                />
              )}
            </ClindalyContainer>
          </Modal>
        )} */}
      </CardContainer>
    </>
  );
};

export default ProgramManager;
