import styled from "styled-components";
import { Scrollable, panelSpacing } from "../../../../../common/styles";
import colors from "../../../../../config/colors";

export const RequestContainer = styled.div`
margin-top:0px
    padding-top:1rem
`;

export const RequestWrapper= styled.div`
height:calc(100vh - 95px - ${panelSpacing*2}px);
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
 
`