import styled from "styled-components";

export const RequestListContainer = styled.div`
    width: 100%;
    border-width: 2px;
        ${'' /* height: calc(100vh - 240px); */}
        overflow-y: auto;
`;

export const RequestButtonContainer = styled.div`
display:flex;
justify-content: end;
margin-top:10px;
`;

export const NewRequestHeader = styled.div`

    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
 
`;

export const RequestListContainerWrapper = styled.div`
    padding:10px;

`;
