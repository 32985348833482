import createReducer from "../common/createReducer";

var reducers = {

  Conversation_List:(state, action) => {
    return {
        messages : action.messages
    };
  },
};


const initialState = {
};

export default createReducer(initialState, reducers);