import React from "react";
import { FeedBackHeader, FeedbackContainer } from "./styles";
import { SectionActions, SectionHeader, SecuritySection } from "../LoginSecurity/styles";
import TextInput from "../../../../common/TextInput";
import Button from "../../../../common/Button";
import { ButtonSpacer } from "../../../../common/Button/styles";
import { HamburgerIconContainer, HamburgerIconContainerSpan } from "../../Programs/ProgramContent/styles";

function Feedback(props) {
  const [content, setContent] = React.useState("");
  const [isValid, setIsValid] = React.useState(null);
  return <FeedbackContainer>
    <SecuritySection>
      <FeedBackHeader>
    <HamburgerIconContainer onClick={()=>props.setHamburgerOpen(!props.hamburgerOpen)}>
            <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
            <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
            <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
          </HamburgerIconContainer>
      <SectionHeader>Send Feedback</SectionHeader>
      </FeedBackHeader>
      <TextInput isTextArea value={content} setValue={setContent} isValid={isValid} setIsValid={setIsValid} wordLimit={250} placeholder="Your Feedback" isHeight={false}/>
      <ButtonSpacer />
      <SectionActions>
        <Button icon="send" variant="primary" disabled={!isValid} text="Send Feedback" />
      </SectionActions>
    </SecuritySection>
  </FeedbackContainer>;
}
export default Feedback;