import React from "react";
import { convertToLinks, formatDate, isResponsive } from "../../../../../common/utils";
import { Context } from "../../../../../Store";
import { getUserInfo } from "../../../../../Store/User/accessors";
import { ChatBubbleContainer,SenderImage,Bubble,Message,Time } from "./styles";

function ChatBubble({message,userImage }) {
  
  const {state}= React.useContext(Context);
  if(message.message){
  let loggedInUser = getUserInfo(state)
  const userIsSender =  loggedInUser?.id === message?.user?.id;
  return <ChatBubbleContainer isSender={userIsSender} >
    <SenderImage src={userIsSender ? loggedInUser?.image ?loggedInUser.image: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png':userImage? userImage:'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'} />
    <Bubble isSender={userIsSender}>
      {/* <Message>{message.message}</Message> */}
      <Message dangerouslySetInnerHTML={{ __html: convertToLinks(message.message,userIsSender) }} />
      <Time isSender={userIsSender}>{formatDate(message.createdAt)}</Time>
    </Bubble>
  </ChatBubbleContainer>;
  }
}
export default ChatBubble;