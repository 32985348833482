import styled from "styled-components";
import colors from "../../config/colors";
import { panelSpacing } from "../styles";

export const ButtonContainer = styled.div`
    background-color: ${props => props.backgroundColor};
    border:solid 1px ${props=>props.backgroundColor};
    color: ${props => props.color};
    justify-content:${props=>props.width ? 'center':''};
    padding:${props=> props.onlyIcon ? "10px" : "10px 20px"};
    font-size:14px;
    width:${props=>props.width ? props.width+'%' :'fit-content'};
    height:fit-content;
    border-radius:${props=>props.isNotRound ? '8px':props.simple ?'0px':  '20px'};
    transition: all 0.1s ease-in-out;
    opacity:1;
    cursor:${props=>props.disabled?"not-allowed":"pointer"};
    border-bottom-left-radius:${props => props.borderLeft && '10%'};
    border-top-left-radius:${props => props.borderLeft && '10%'};
    border-bottom-right-radius:${props => props.borderRight && '10%'};
    border-top-right-radius:${props => props.borderRight && '10%'};

    :hover{
        
        opacity:0.95;
        border:solid 1px ${colors.darkBackground};
    }
    :active{
        opacity:0.9;
        transform:scale(1.05);
    }
    display:flex;
    align-items:center;
    user-select:none;
    @media(max-width:1200px) {
        padding: 8px 10px;
    }
`;
export const ButtonSpacer = styled.div`
    display:block;
    min-width:10px;
    min-height:10px;
`;
export const SectionActions = styled.div`
    display:flex;
    align-items: center;
    justify-content: flex-end;
    margin-top:${props=>props.isTerm ? "20px" : "10px"};
    gap:${props=>props.isTerm ? "15px" : ""};
`;