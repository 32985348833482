import React from "react";
import { Context } from "../../../../Store";
import { getProgramListRefreshToggle, getProgramsList, isProgramsListLoaded } from "../../../../Store/ProgramsList/accessors";
import { refreshProgramList, setProgramList, setProgramListAsLoading } from "../../../../Store/ProgramsList/actions";
import callAPI from "../../../../api/apiCaller";
import Button from "../../../../common/Button";
import { ButtonSpacer } from "../../../../common/Button/styles";
import Labelize from "../../../../common/Labelize";
import Loading from "../../../../common/Loading";
import Modal from "../../../../common/Modal";
import TextInput from "../../../../common/TextInput";
import { validateUrl } from "../../../../common/utils";
import { SectionActions } from "../../Profile/LoginSecurity/styles";
import AddProgram from "../popups/AddProgram";
import AddFolder from "./AddFolder";
import ProgramsListSection from "./ProgramsListSection";
import { ButtonWraper, CrossButtonContainer, CrossButtonContainerSpan, HeaderLine, JoinPorgramContainer, ListActions, ProgramSections, ProgramsListContainer, ProgramsListHeader } from "./styles";

function ProgramsList(props) {
  const [searchText, setSearchText] = React.useState("");
  const [showAddFolder, setShowAddFolder] = React.useState(false);
  const { state, dispatch } = React.useContext(Context);
  const [showAddProgram, setShowAddProgram] = React.useState(false);
  const [refreshList, SetRefreshList]= React.useState(false)
  const [showJoinModal, setShowJoinModal]= React.useState(false)
  const [programUrl, setProgramUrl] = React.useState('');
  const [urlValid, setIsUrlValid]= React.useState(false)


  function getPrograms(){
    dispatch(setProgramListAsLoading())
    callAPI(dispatch,"fetchPrograms").then((programs) => {
      dispatch(setProgramList(programs.data))
    })
  }
  React.useEffect(() => {
    dispatch(setProgramListAsLoading())
    getPrograms()
  }, [getProgramListRefreshToggle(state)]);

  function moveProgram(id, folder) {
    dispatch(setProgramListAsLoading())
    callAPI(dispatch,"moveProgram", { programId:+id, folderTitle:folder }).then((response) => {
      getPrograms()
      // dispatch(refreshProgramList())
    })
  }
  function addFolder(name) {
    dispatch(setProgramListAsLoading())
    return callAPI(dispatch,"addFolder", { title:name })
  }

  const programs = getProgramsList(state)
  return <ProgramsListContainer hamburgerOpen={props.hamburgerOpen}>

    <CrossButtonContainer onClick={()=>props.setHamburgerOpen(!props.hamburgerOpen)}>
    <CrossButtonContainerSpan></CrossButtonContainerSpan>
    <CrossButtonContainerSpan></CrossButtonContainerSpan>
    </CrossButtonContainer>
    <ButtonWraper>
    </ButtonWraper>
    <ProgramsListHeader>
      <HeaderLine>
        Programs
        <ListActions>
          <Button icon="add" onClick={() => { setShowAddProgram(true) }} />
          <ButtonSpacer />
          <Button icon="add-folder" onClick={() => setShowAddFolder(true)} />
          <ButtonSpacer />
           <Button text='Join'  variant="secondary"  onClick={() =>  setShowJoinModal(true) }/>
        </ListActions>
      </HeaderLine>
      <TextInput value={searchText} setValue={setSearchText} placeholder="Search" />
    </ProgramsListHeader>
    <ProgramSections>
      {
        isProgramsListLoaded(state) &&
        Object.keys(programs).filter((folder) => {
          return searchText == "" || programs[folder].some(programs => programs.title.toLowerCase().includes(searchText.toLowerCase()))
        }).map(folder => {
          return <ProgramsListSection
            moveProgram={moveProgram}
            key={folder}
            searchText={searchText}
            folder={folder}
            programs={programs[folder]}
            setHamburgerOpen={props.setHamburgerOpen}
            hamburgerOpen={props.hamburgerOpen}
          />
        })
      }
      {
        !isProgramsListLoaded(state) && <Loading loadingItem="Programs" />
      }
    </ProgramSections>
    {
      showAddFolder && <AddFolder addFolder={addFolder} closeAddFolder={() => {
        setShowAddFolder(false);
      }} />
    }

    {
      showAddProgram && 
      <AddProgram closeModal = {(shouldRefresh) => {
        if (shouldRefresh) {
          dispatch(refreshProgramList())
        }
        setShowAddProgram(false);
      }}/>
    }
 {showJoinModal &&  <Modal
        title={"Join a program"}
        isDelete={true}
        onClose={() => setShowJoinModal(false)}
      >
         <JoinPorgramContainer>
          <Labelize label="Paste the link for the program you would like to join space below">
          <TextInput 
            placeholder="Paste your link here"
            value={programUrl} 
            type="text"
            isValid={validateUrl["text"]}
            validator={validateUrl}
            setValue={(newValue) => {
              setIsUrlValid(validateUrl(newValue))
              setProgramUrl(newValue)
          }} />
        </Labelize>
        <SectionActions>
        <Button icon={"cross"} variant="secondary"
         onClick={()=>setShowJoinModal(false)}
           text="Cancel" />
        <ButtonSpacer />
        <Button icon="tick" variant="primary" 
         disabled={urlValid ?false:true} 
          onClick={()=>window.location.href=programUrl}
           text="Save" />

      </SectionActions>
      </JoinPorgramContainer>
        
          </Modal>}
  </ProgramsListContainer>;
 
  
}
export default ProgramsList;