import createReducer from "../common/createReducer";

var reducers = {
  LOGIN: (_, action) => {
    return {
      ...action.user,
      isLoggedIn: true
    };
  },
  LOGOUT:()=>{
    return initialState
  },
  UPDATE_USER:(_, action)=>{
  return {  
    ...action.user,
    isLoggedIn: true,
  };
  }
};

const initialState = {
  id:-1,
  firstName:"",
  lastName:"",
  isLoggedIn:false,
  email:"",
};

export default createReducer(initialState, reducers);