import React from "react";
import callAPI from "../../../../../api/apiCaller";
import { ButtonSpacer } from "../../../../../common/Button/styles";
import Checkbox from "../../../../../common/Checkbox";
import CheckboxGroup from "../../../../../common/CheckboxGroup";
import Labelize from "../../../../../common/Labelize";
import Modal from "../../../../../common/Modal";
import TextInput from "../../../../../common/TextInput";
import {
  createInitialValid,
  updateValid,
  updateValidTogether,
  validateArray,
  validateIsValid,
  validateString,
  validateText,
} from "../../../../../common/utils";
import { SendAnnouncementContainer, StyledReactQuill } from "./styles";
import { Context } from "../../../../../Store";
import Loading from "../../../../../common/Loading";
// ReactQuill css import 
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const defaultFontSize = '14px'
const modules = {
  toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    [{size: ['small', false, 'large', 'huge']}], // Adding font size options
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, 
     {'indent': '-1'}, {'indent': '+1'}],
    ['clean']
  ],
  clipboard: {
    matchVisual: false,
  }
};

const formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
];


function SendAnnouncement({ announcementProgram, closeModal, announcementId }) {
  const [announcement, setAnnouncement] = React.useState({
    programId: +announcementProgram,
    subject: "",
    content: "",
    // announceTo: [],
  });
  const [isValid, setIsValid] = React.useState(
    // createInitialValid(["subject", "content", "announceTo"])
    createInitialValid(["subject"])
  );
  const [isSendingAnnouncement, setIsSendingAnnouncement] =
    React.useState(false);
  const { state, dispatch } = React.useContext(Context);
  React.useEffect(() => {
    if (announcementId) {
      callAPI(dispatch, "getAnnouncement", { id: announcementId }).then(
        (retrievedAnnouncement) => {
          updateValidTogether(isValid, setIsValid, retrievedAnnouncement.data, {
            subject: validateText,
            content: validateText,
            announceTo: validateArray(),
          });
          if (retrievedAnnouncement.isResponseOk) {
            setAnnouncement(retrievedAnnouncement.data);
          } else setAnnouncement({});
        }
      );
    }
  }, []);

  function send() {
    setIsSendingAnnouncement(true);
    if (announcementId) {
      callAPI(dispatch, "saveAnnouncement", {
        id: announcement.id,
        programId: announcement.programId,
        subject: announcement.subject,
        content: announcement.content,
        announceTo: announcement.announceTo,
      }).then(() => {
        setIsSendingAnnouncement(false);
        closeModal(true);
      });
    } else {
      callAPI(dispatch, "sendAnnouncement", { ...announcement }).then(() => {
        setIsSendingAnnouncement(false);
        closeModal(true);
      });
    }
  }

  if (announcementId && announcement.subject === "") {
    return (
      <Modal
        title={announcementId ? "Edit Announcement" : "Send Announcement"}
        isDelete={true}
        onClose={() => {
          closeModal(false);
        }}
        actions={[
          {
            text: "Cancel",
            onClick: () => {
              closeModal(false);
            },
          },
        ]}
      >
        <SendAnnouncementContainer>
          <Loading loadingItem="Announcement" />
        </SendAnnouncementContainer>
      </Modal>
    );
  }
  return (
    <Modal
      title={announcementId ? "Edit Announcement" : "Send Announcement"}
      isDelete={true}
      onClose={() => {
        closeModal(false);
      }}
      actions={[
        {
          text: "Cancel",
          onClick: () => {
            closeModal(false);
          },
        },
        {
          text: "Publish",
          disabled: !validateIsValid(isValid)||!announcement.content?.length,
          variant: "primary",
          icon: isSendingAnnouncement ? "loading" : "send",
          onClick: () => {
            send();
          },
        },
      ]}
    >
      <SendAnnouncementContainer>
        <Labelize label="Subject">
          <TextInput
            value={announcement.subject}
            setValue={(newValue) => {
              setAnnouncement({
                ...announcement,
                subject: newValue,
              });
            }}
            isValid={isValid.subject}
            validator={validateString}
            setIsValid={updateValid(isValid, setIsValid, "subject")}
            placeholder="Announcement Subject"
          />
        </Labelize>
        <ButtonSpacer />
        {/* <Labelize label="Body">
          <TextInput
            value={announcement.content}
            isTextArea
            isHeight={true}
            setValue={(newValue) => {
              setAnnouncement({
                ...announcement,
                content: newValue,
              });
            }}
            placeholder="Announcement Body"
            // wordLimit={250} 
            isValid={isValid.content}
            validator={validateString}
            setIsValid={updateValid(isValid, setIsValid, "content")}
          />
          
        </Labelize> */}
{/* using rtc pkgs*/}
<Labelize label="Body">
  <StyledReactQuill
    value={announcement.content}
    onChange={(newValue) => {
      setAnnouncement({
        ...announcement,
        content: newValue,
      });
    }}
    placeholder="Announcement Body"
    style={{ fontSize: defaultFontSize }}
    modules={modules}
      formats={formats}
  />
  
</Labelize>


        <ButtonSpacer />
        <ButtonSpacer />
        {/* <CheckboxGroup
          label={"Announce To"}
          value={announcement.announceTo}
          setValue={(newValue) => {
            setAnnouncement({
              ...announcement,
              announceTo: newValue,
            });
          }}
          isValid={isValid.announceTo}
          setIsValid={updateValid(isValid, setIsValid, "announceTo")}
          options={[
            {
              label: "Mentors",
              value: "mentor",
            },
            {
              label: "Mentees",
              value: "mentee",
            },
            {
              label: "Program Managers",
              value: "manager",
            },
          ]}
        /> */}
      </SendAnnouncementContainer>
    </Modal>
  );
}
export default SendAnnouncement;
