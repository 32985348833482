import styled from "styled-components";
import colors from "../../../../../config/colors";

export const ChatBubbleContainer = styled.div`
    display: flex;
    flex-direction:${props=>props.isSender? "row-reverse" : "row"};
    align-items: flex-end ;
    margin-bottom: 10px;
`;
export const SenderImage = styled.img`
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 50%;
    margin:10px;
`;

export const Bubble = styled.div`
    background-color: ${props => props.isSender ? colors.primary : colors.background};
    color: ${props => props.isSender? colors.background : colors.text};
    padding:10px;
    border-radius: 10px 10px ${props=>props.isSender ? 0 : 10}px ${props=>props.isSender ? 10 : 0}px;
    display: flex;
    align-items: start;
    height:fit-content;
    max-width:60%;

        flex-direction:column;
    
`;
export const Message = styled.div`
word-break: break-word;

`;
export const Time = styled.div`
    font-size:8px;
    margin-left:10px;
    align-self: end;
`;