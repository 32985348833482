import createReducer from "../common/createReducer";

var reducers = {

  MARK_AS_READ_MESSAGE:(state, action) => {
    // console.log(action,'action in mainnnn')
  return {
    ...state,
    count:action.count,
    isRead:action.isRead
  };
  },
  // MARK_AS_READ_GROUP_MESSAGE:(isRead, action) => {
  //   console.log(action,'action in mainnnn')

  // return {
  //   isRead:action.isRead
  // };
  // },
};


const initialState = {
  count:0,
  isRead:false,
};

export default createReducer(initialState, reducers);