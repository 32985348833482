import styled from "styled-components";
import { panelSpacing } from "../../../../../common/styles";

export const ProgramTasksContainer = styled.div`
padding:${panelSpacing}px;
height:calc(100vh - 250px);

`;
export const TasksActions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom:20px;
`;