import styled from "styled-components";
import colors from "../../../config/colors";
import { Scrollable, panelSpacing } from "../../../common/styles";

export const LoginContainer = styled.div`
    background-color: ${colors.text};
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;
     @media (max-width:767px) {
        margin-top:-20px;
      }
`;

export const LoginForm = styled.div`
    width:30vw;
    background-color: ${colors.background};
    border-radius: 10px;
    padding:${panelSpacing}px;
    box-sizing: border-box;
    margin-bottom:2vw;
    max-height:50vh;
    ${Scrollable}

    @media (max-width:1366px) {
        width:40vw;
      }

      @media (max-width:1024px) {
        width:44vw;
      }
    @media (max-width: 767px) {
        ${'' /* width:47%; */}
        width:80vw;

      }
    @media (max-width: 480px) {
        width:80vw;
        max-height:60vh;
      }
`;
export const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height:40vh;
    @media (max-width: 1366px) {
      height:30vh;
      }
      @media (max-width: 1024px) {
        width:60%;
      }
    @media (max-width: 767px) {
        width: 55%;   
        height: 28vh;     
      }
`;
export const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const LoginPageOption = styled.div`
    color:${colors.secondary};
    cursor:pointer;
    font-size:14px;
    :hover{
        color:${colors.background};
    }
`;
export const Header = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
    margin-bottom:15px;
`;
export const Heading = styled.div`
    font-size:24px;
    font-weight:bold;
    color:${colors.primary};
`;
export const DevString = styled.div`
    position:fixed;
    bottom:0;
    left:0;
    color:${colors.background}22;
    font-size:7px;
`;

export const PhoneNumberInputWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
`;

export const CountryCode = styled.div`
  margin-right: 8px;
`;

export const PhoneNumberInput = styled.input`
  border: none;
  flex: 1;
  outline: none;
`;
