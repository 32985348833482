import styled from "styled-components";
import { Scrollable, borderRadius, panelSpacing } from "../../../../common/styles";
import colors from "../../../../config/colors";

export const ProgramsListContainer = styled.div`
    width:350px;
    min-width:350px;
    background-color: #fff;
    height: 100%;
    border-radius: ${borderRadius}px;
    box-sizing: border-box;
    z-index:10;
    @media(max-width:991px) {
       position: fixed;
       left: ${props => props.hamburgerOpen===true ? '0%' : '-100%'};
       transition: 0.5s;
       border: 2px solid ${colors.secondary};
       border-left: none;
    }
`;
export const ProgramsListHeader = styled.div`
    padding:10px 20px 20px 20px;
    border-bottom: 1px solid ${colors.secondary};
`;
export const HeaderLine = styled.div`
    font-size: 24px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
`;
export const ListActions = styled.div`
    display: flex;
`;
export const ProgramSections = styled.div`
 overflow-y: scroll;
overflow-x: hidden;
height: inherit;
${Scrollable};
height: calc(100vh - 300px);
 ::-webkit-scrollbar {
        background-color:${colors.secondary};
        border:solid 1px ${colors.darkBackground};
        width:5px;
        border-radius:0px 0px 10px 0px;
      }
      ${'' /* @media(min-width:1200px) {
        max-height:63vh;
    }
      @media(min-width:991px) {
        max-height:63vh;
        
    } */}
    @media(max-width:767px) {
        height: calc(100vh - 350px);
    }

`;
export const CrossButtonContainer= styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 10px 14px 7px 0px;
    
`
export const CrossButtonContainerSpan= styled.span`
    width: 20px;
    height: 4px;
    background-color: #44449B;
    display: block;
    transform: rotate(45deg) translate(5px,3px);
    border-radius: 5px;
    &:last-child {
        transform: rotate(-45deg) translate(0px,2px);
    }
    @media(min-width:991px) {
        display:none
    }
`
export const ButtonWraper= styled.div`
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: end;
    gap:10px
   
  
`
export const JoinPorgramContainer= styled.div`
width: 80vh;
@media (max-width:767px){
width: 100%;
}

`;