export const loginUser =  (userDetails) => {
  // console.log("userdetails123", userDetails.data)
  userDetails.data.accessToken && localStorage.setItem('accessToken', userDetails.data.accessToken);
  delete userDetails?.accessToken;
  return {
    type: "LOGIN",
    user: userDetails.data,
    segmentName: "user",
  }
}
export const updateUser = (updatedUserDetails) => {
  // console.log("update user ", updatedUserDetails)
  return {
    type: "UPDATE_USER",
    user: updatedUserDetails,
    segmentName: "user", // Assuming 'user' is the segment where user details are stored
  };
};