import React from "react";
import colors from "../../config/colors";
import Icon from "../Icon";
import { CheckboxContainer,TheBox } from "./styles";
import { Link, useNavigate } from "react-router-dom";

function Checkbox({label,value,setValue,disabled=false,isLinkContent,link, size, isAgree, isModal},isBorder=false) {
  const navigate = useNavigate();
  return <CheckboxContainer isAgree={isAgree} isModal={isModal}>
    <TheBox
    isBorder={isBorder}
    isAgree={isAgree}
     checked={value} 
     disabled={disabled} 
     onClick={()=>{
      !disabled && setValue(!value);
    }}
    >
      <Icon type="tick" size={size ? `${size}px` : '14px'} color={colors.background}/>
    </TheBox>
    {label}{link && <>&nbsp;</>}{link && <Link onClick={() => {
      navigate(link)
      window.location.reload();
    }}>{isLinkContent}</Link>}
  </CheckboxContainer>;
}
export default Checkbox;