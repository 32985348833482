import styled from "styled-components";
import { Scrollable, panelSpacing } from "../../../../../../common/styles";
import colors from "../../../../../../config/colors";

export const InvitesContainer = styled.div`
box-sizing: border-box;
padding:${panelSpacing}px;
overflow-x:hidden;
height:calc(100vh - 140px - ${panelSpacing*4}px);
border-top:solid 1px ${colors.secondary};
${Scrollable}
@media (max-width:767px) {
height:calc(100vh - 450px);
}

`;
export const InviteEmpty = styled.p`
display: flex;
justify-content: center;
align-items: center;
margin: auto;
height: -webkit-fill-available;
 text-transform: capitalize;
`;