import React from "react";
import {
  HamburgerIconContainer,
  HamburgerIconContainerSpan,
} from "../../Programs/ProgramContent/styles";
import {
  TermsSectionHeader,
  SectionHeader,
  TermsContainer,
  TermsSection,
} from "./style";
import TermsAndConditionsContent from "../../../TermsAndConditions/TermsConditionsContent";

const TermsConditions = (props) => {
  return (
    <TermsContainer>
      <TermsSection>
        <TermsSectionHeader>
          <HamburgerIconContainer
            onClick={() => props.setHamburgerOpen(!props.hamburgerOpen)}
          >
            <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
            <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
            <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
          </HamburgerIconContainer>
          <SectionHeader>Terms Of Service</SectionHeader>
        </TermsSectionHeader>
        <TermsAndConditionsContent isProfile={true} />
      </TermsSection>
    </TermsContainer>
  );
};

export default TermsConditions;
