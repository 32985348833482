import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import callAPI from "../../../../../../../../api/apiCaller";
import NoteEditor from "./NoteEditor";
import NotesList from "./NotesList";
import { NotesContainer } from "./styles";
import { Context } from "../../../../../../../../Store";

function Notes() {
  const [notes, setNotes] = React.useState(null);
  let params=useParams();
  let navigate = useNavigate();
  let [isAddingNote, setIsAddingNote] = React.useState(false);
  let [isNoteDeleting, setIsNoteDeleting] = React.useState(false);
  const {state,dispatch} = React.useContext(Context);
  React.useEffect(() => {
    setNotes(null)
    callAPI(dispatch,"getNotes",{programId:params.selectedProgram,connection:params.connectionId}).then((connectionNotes) => {
      setNotes(connectionNotes);
    });
  },[]);

  function addNote(){
    setIsAddingNote(true)
    callAPI(dispatch,"addNewNote",{programId:params.selectedProgram,connection:params.connectionId}).then((response)=>{
      setIsAddingNote(false)
      setNotes(response.notes);
      navigate(`/programs/${params.selectedProgram}/${params.tab}/${params.connectionId}/${params.connectionTab}/${response.newNoteId}`)
    })
  }
  function deleteNote(id){
    setIsNoteDeleting(true)
    callAPI(dispatch,"deleteNote",{id:id,programId:params.selectedProgram,connection:params.connectionId}).then((response)=>{
      setNotes(response);
      setIsNoteDeleting(false)
      navigate(`/programs/${params.selectedProgram}/${params.tab}/${params.connectionId}/${params.connectionTab}`)
    })
  }
  return <NotesContainer>
    <NotesList 
      notes={notes} 
      addNote={addNote} 
      isAddingNote = {isAddingNote} 
    />
    <NoteEditor deleteNote={deleteNote} isNoteDeleting={isNoteDeleting}/>
  </NotesContainer>;
}
export default Notes;