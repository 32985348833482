import React from "react";
import { AddProgramContainer, TitleBar, ProgressContainer, Actions } from "./styles";
import ProgressBar from "../../../../../common/ProgressBar";
import Modal from "../../../../../common/Modal";
import { createSteps } from "./config";
import { createInitialValid, validateIsValid } from "../../../../../common/utils";
import Button from "../../../../../common/Button";
import { ButtonSpacer } from "../../../../../common/Button/styles";
import callAPI from "../../../../../api/apiCaller";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../../../Store";
import { attachPromiseToToast, toastTrackedMessage } from "../../../../../Store/Toasts/actions";

function AddProgram({ closeModal }) {
  const [currentStep, setCurrentStep] = React.useState(0);
  const [isSavingProgram,setIsSavingProgram] = React.useState(false);
  const {state,dispatch} = React.useContext(Context);
  const [newProgramId,setNewProgramId] = React.useState(false);
  const navigate = useNavigate();
  const [programForm, setProgramForm] = React.useState({
    title: "",
    description: "",
    organization: "",
    image: "",
    programType:"1_1_NETWORK",
    programManagers: [],
    applicationForm: {
      "accepts":{
          "mentor":false,
          "mentee":false,
          "member": false,
      },
      "mentor":[],
      "mentee":[],
      "member":[]
  }
  });
  const [stepIsValid, setStepIsValid] = React.useState({});
  let currentStepConfig = createSteps[currentStep];
  React.useEffect(() => {
 
    setStepIsValid(createInitialValid(currentStepConfig.isValidFields))

    
  }, [currentStep]);

  async function saveProgram() {

    setIsSavingProgram(true)
    return callAPI(dispatch,"addNewProgram",  programForm )
  }


  return <Modal 
    title={<TitleBar>
      {currentStep === createSteps.length - 1 ? "Program Created" : "Add New Program"}
      <ProgressContainer>
        <ProgressBar percent={currentStep / (createSteps.length-1)} type={currentStep === createSteps.length-1 ? "done" : "normal"}/>
      </ProgressContainer>
    </TitleBar>}
    onClose={closeModal}
    actions={[
      {
        text: "Back",
        icon: "back",
        variant: "secondary",
        disabled: currentStep === 0 || currentStep === createSteps.length-1,
        onClick: () => {
         
          setCurrentStep(currentStep - 1)
        }
      },
      {
        text: "Cancel",
        variant: "secondary",
        icon: "cross",
        disabled:currentStep === createSteps.length-1,
        onClick:()=>closeModal(false)
      },
      {
        text: currentStep === createSteps.length-1 ? "Done":"Next",
        icon: isSavingProgram ? "loading" : currentStep === createSteps.length-1 ? "tick":"next",
        variant: "primary",
        iconLast: true,
        disabled: !validateIsValid(stepIsValid),
        onClick: () => {
          if(currentStep === createSteps.length - 1){
            closeModal(true);
            navigate(`/programs/${newProgramId}/home`)

          }
          else if(currentStep === createSteps.length - 2){
            saveProgram().then((response)=>{
              setIsSavingProgram(false);

              
              if(!response.isResponseOk){
                closeModal(true);
                toastTrackedMessage(1, response.message, "failure")
                return response.message

              }
              setNewProgramId(response.data.programId)
              setIsSavingProgram(false);
              setCurrentStep(currentStep + 1);

            })
          }
        

          // if(programForm.programType==='TEAM'){
            
          //   if(currentStep===1){
          //     setCurrentStep(currentStep + 2);
          //     saveProgram().then((response)=>{
          //       setIsSavingProgram(false);
  
                
          //       if(!response.isResponseOk){
          //         closeModal(true);
          //         toastTrackedMessage(1, response.message, "failure")
          //         return response.message
  
          //       }
          //       setNewProgramId(response.data.programId)
          //       setIsSavingProgram(false);
                
  
          //     })
          //     return
          //   }
          //   setCurrentStep(currentStep + 1);
            
          // }
          else{
            
            setCurrentStep(currentStep + 1);

          }
        }
      },
    ]}


  > <AddProgramContainer>
      <currentStepConfig.component 
          isValid={stepIsValid} 
          setIsValid={setStepIsValid} 
          programForm={programForm} 
          setProgramForm={setProgramForm} 
          newProgramId={newProgramId}
          />
    </AddProgramContainer>

  </Modal>;
}
export default AddProgram;