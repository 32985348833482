import styled from "styled-components";
import colors from "../../../../../../../../../config/colors";
import { panelSpacing } from "../../../../../../../../../common/styles";

export const NoteEditorContainer = styled.div`
    background-color: ${colors.secondary};
    width: calc(100% - 300px);
    padding:${panelSpacing}px;
    box-sizing: border-box;
    box-shadow:inset 0px 0px 10px 0px #0003;
    border-radius:0px 0px 10px 0px;
`;
export const NoNoteContainer = styled.div`
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    height:100%;
    color:${colors.darkBackground};
    font-size:14px;
`;
export const NoteHeader=styled.div`
    padding:10px 15px;
    border-bottom:solid 1px ${colors.secondary};
    display:flex;
    border-radius:10px;
    background-color: ${colors.background};
    margin-bottom:10px;
`;
export const NoteTitle = styled.input`
    appearance:none;
    border:none;
    width:100%;
    box-sizing: border-box;
    font-size:18px;
    outline:none;
    color:${colors.text}88;
    transition:all 0.1s ease-in-out;
    :focus{
        color:${colors.text};
        opacity:1;
    }
`;
export const NoteActions = styled.div`
    display:flex;
`;

export const NoteContent = styled.div`
    background-color: ${colors.background};
    border-radius:10px;
    .rdw-editor-toolbar{
        border:none;
        border-bottom:solid 1px ${colors.secondary};
        border-radius: 10px 10px 0px 0px;
    }
    .rdw-option-wrapper{
        border:solid 1px ${colors.secondary};
        border-radius: 5px;
        transition:all 0.1s ease-in-out;
    }
    .rdw-option-wrapper:hover{
        border:solid 1px ${colors.darkBackground};
        box-shadow:none;
    }
    .rdw-option-wrapper:active{
       transform:scale(0.9);
    }
    .rdw-option-active{
        border:solid 1px ${colors.primary};
        box-shadow:none;
    }
    .rdw-option-active:hover{
        border:solid 1px ${colors.primary}88;
    }
    .rdw-editor-main{
        padding:0px 10px;
        height:calc(100vh - 500px - ${panelSpacing*4}px);
        box-sizing: border-box;
    }
    .DraftEditor-root{
        height: calc(100% - 20px);
    }
`;
