/**
 *
 * Notification System:
 *
 * All new notifications would be marked "new".
 * A notification would be marked as "read" if it was clicked on. Read notifications are cleared after a day.
 */

export function attachPromiseToToast(dispatch, promise, info) {
  let id = Math.random().toString();
  let error=''
  info?.inProgress && dispatch(toastTrackedMessage(id, info.inProgress, "inProgress"));
  promise
    .then(async (response) => {
      error=response.message?  response.message: info.failure
      if(response.errors?.length){
        error=`${response.errors[0].errors}`
      }
      if(!response.isResponseOk){
        info?.failure && dispatch(toastTrackedMessage(id, error, "failure"));
      }
        else{
          info?.success && dispatch(toastTrackedMessage(id,  response.message?  response.message: info.success, "success"));
          }
    })
    .catch((err) => {
      dispatch(toastTrackedMessage(id, err.message, "failure"));
      dispatch(refreshToasts())
    });
}



export function notificationToast(dispatch, info) {
  let id = Math.random().toString();
  dispatch(toastTrackedMessage(id, "info.inProgress", "inProgress"));
  dispatch(toastTrackedMessage(id,  info.success, "success"));
  dispatch(refreshToasts())
}

export const toastMessage = (message, toastType) => {
  return {
    type: "NEW_TOAST",
    message,
    toastType,
    segmentName: "toast",
  };
};

export const toastTrackedMessage = (id, message, toastType) => {
  return {
    type: "TRACKED_TOAST",
    id,
    message,
    toastType,
    segmentName: "toast",
  };
};
export const refreshToasts = () => {
  return {
    type: "REFRESH",
    segmentName: "toast",
  };
};

export const deleteToast = (id) => {
  return {
    type: "DELETE",
    segmentName: "toast",
    id
  };
};
