import styled from "styled-components";
import colors from "../../../../../../config/colors";

export const ConnectionCardContainer = styled.div`
    box-shadow: 0 0 4px 0px #0004;
    width: 250px;
    border-radius: 10px;
    margin-right:20px;
    margin-bottom:20px;
`;

export const ConnectionImage=styled.div`
    background-image: url(${props => props.image});
    width:250px;
    height:250px;
    background-size:cover;
    background-position:center;
    box-shadow: inset 0 0 100px 10px rgba(0,0,0,0.9);
    border-radius: 10px 10px 0px 0px;
`;
export const ConnectionLine = styled.div`
display:flex;
align-items:center;
width: 250px;
margin-top: -45px;
padding:10px;
box-sizing: border-box;
color: #fff;


`;
export const ConnectionName = styled.div`
    font-size: 20px;
    max-width:200px;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

`;
export const ConnectionActions = styled.div`
    display: flex;
    justify-content:center;
    align-items:center;
    padding:10px;
`;
export const TaskCounter = styled.div`
    background-color: ${colors.primary};
    font-size:12px;
    display:flex;
    align-items:center;
    justify-content:center;
    margin-left:5px;
    border-radius:50%;
    width:20px;
    font-weight:normal;
    height:20px;
`;