import styled from "styled-components";
import { Scrollable, headerSize, panelSpacing } from "../../../../common/styles";
import colors from "../../../../config/colors";

export const ProgramContainer = styled.div`
    
    background-color:${colors.background};
    border-radius:${panelSpacing}px;
    box-sizing:border-box;
    height:calc(100vh - ${headerSize+2*panelSpacing}px);
`;

export const Header = styled.div`
    display:flex;
    padding:${panelSpacing}px;
    align-items:center;
    justify-content:space-between;
`;
export const Section = styled.div`
    display:flex;
    align-items:center;
`;
export const Title = styled.div`
    font-size:24px;
    margin-left:20px;
    font-weight:bold;
`;
export const Body = styled.div`
    border-top:solid 1px ${colors.secondary};
    padding:${panelSpacing}px;
    height:calc(100vh - ${headerSize + 2*panelSpacing + 2*panelSpacing + 40}px);
    ${Scrollable}
    box-sizing:border-box;
`;
export const Image = styled.img`
    width: 250px;
    height: 250px;
    object-fit:cover;
    margin-left:${panelSpacing}px;
    border-radius:${panelSpacing}px;
`;
export const Form = styled.div`
    width:calc(100% - 250px - ${panelSpacing}px);
`;