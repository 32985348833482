import React from "react";
import { useNavigate } from "react-router-dom";
import callAPI from "../../../api/apiCaller";
import Button from "../../../common/Button";
import { ButtonSpacer } from "../../../common/Button/styles";
import Labelize from "../../../common/Labelize";
import Loading from "../../../common/Loading";
import Table from "../../../common/Table";
import TextInput from "../../../common/TextInput";
import { BrowserContainer, BrowserTable, BrowserFilters, FilterTop, FilterActions, FiltersHeading, Actions } from "./styles";
import { Context } from "../../../Store";

export function createInitialFilters(config) {
  let filters = {};
  config.filters.forEach(filter => {
    filters[filter.key] = ""
  })
  return filters
}

function Browser({ config }) {
  const [data, setData] = React.useState(null);
  const [filters, setFilters] = React.useState(createInitialFilters(config));
  const [unsetFilters, setUnsetFilters] = React.useState(createInitialFilters(config));
  const [isFilterDisabled, setIsFilterDisabled] = React.useState(false);
  const navigate = useNavigate();
  const {state,dispatch} = React.useContext(Context);
  React.useEffect(() => {
    setIsFilterDisabled(true);
    setData(null)
    callAPI(dispatch,config.api, { filters }).then(retrievedData => {
      setData(retrievedData)
      setIsFilterDisabled(false);
    });
  }, [filters])
  return <BrowserContainer>
    <BrowserTable>
      {data == null ? <Loading loadingItem={config.item} /> :
        <Table
          columns={[...config.columns,
          {
            key: "",
            title: "Actions",
            sortable: false,
            render: ({ row }) => {
              return <Actions>
                {
                  config.rowActions.map((action,i) => {
                    return <React.Fragment key={i}>
                      <ButtonSpacer />
                      <Button variant={action.variant} disabled={action.isDisabled ? action.isDisabled(row) : false} icon={action.icon} onClick={action.onClick(row)} />
                    </React.Fragment>
                  })
                }
              </Actions>
            }
          }]}
          rows={config.getRows(data)}
        />}
    </BrowserTable>
    <BrowserFilters>
      <FilterTop>
        <FiltersHeading>Filters</FiltersHeading>
        {
          config.filters.map(filter => {
            return <>
              <Labelize label={"Filter By " + filter.label}>
                <TextInput value={unsetFilters[filter.key]} disabled={isFilterDisabled} setValue={(newValue) => setUnsetFilters({ ...unsetFilters, [filter.key]: newValue })} placeholder={filter.label + " keyword"} />
              </Labelize>
              <ButtonSpacer />
            </>
          })
        }
      </FilterTop>
      <FilterActions>
        <Button disabled={isFilterDisabled} onClick={() => {
          setFilters(unsetFilters)
        }} text="Apply" variant="primary" />
        <ButtonSpacer />
        <Button disabled={isFilterDisabled} onClick={() => {
          setFilters(createInitialFilters(config))
          setUnsetFilters(createInitialFilters(config))
        }} text="Clear" />
      </FilterActions>
    </BrowserFilters>
  </BrowserContainer>;
}
export default Browser;