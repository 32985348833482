import React from "react";
import {
  ReviewPanelOuterContainer,
  ReviewPanelContainer,
  ReviewHeading,
  SliderContainer,
  Spacer
} from "./styles";
import ReviewSlider from "./slider";

const ReviewPanel = () => {
  return (
    <ReviewPanelOuterContainer>
      <ReviewPanelContainer>
        <ReviewHeading>
          What previous mentors, mentees, and coordinators have said about us
        </ReviewHeading>
      </ReviewPanelContainer>
      <SliderContainer>
        <ReviewSlider />
        <Spacer />
        <ReviewSlider isBottom={true} />
      </SliderContainer>
    </ReviewPanelOuterContainer>
  );
};

export default ReviewPanel;
