import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ChatGroupWindow from "../ChatWindow/GroupChatWindow";
import MessageList from "../MessageList";
import { MessagesContainer } from "../styles";

function GroupMessages() {
  const [hamburgerOpen, setHamburgerOpen]= React.useState(false)
  const [refresh, setRefresh] = React.useState(false)
  

  return <MessagesContainer>
    <MessageList setHamburgerOpen={setHamburgerOpen} hamburgerOpen={hamburgerOpen}  setRefresh={setRefresh} refresh={refresh} />
    <ChatGroupWindow setHamburgerOpen={setHamburgerOpen} hamburgerOpen={hamburgerOpen}  setRefresh={setRefresh} refresh={refresh} />
    </MessagesContainer>;
}
export default GroupMessages;