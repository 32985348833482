import styled from "styled-components";
import { Scrollable, panelSpacing } from "../../../../../common/styles";

export const ProgramParticipantsContainer = styled.div` @media (max-width:640px) {
    overflow-y:scroll;
        height: calc(100vh - 145px);
    }`;
export const MyTeamContainers = styled.div`
    @media (max-width:640px) {
    overflow-y:scroll;
    }
`;

export const PillContainer = styled.div`
    width:100%;
    display:flex;
    align-items:center;
    box-sizing:border-box;
    padding:${panelSpacing}px;
    justify-content: space-between;
      
    @media (max-width:1366px) {
        flex-wrap:wrap;
        row-gap:10px;
    }
    @media (max-width:991px) {
        flex-direction: column;
        gap: 10px;
    }
`;

export const SearchBoxContainer = styled.div`
    width:300px;
    @media (max-width:1366px) {
        order: 1;
        width:100%;
    }
    @media (max-width:991px) {
        order: 0;
        max-width:400px;
    }
`;
export const ButtonWraper = styled.div`
    display:none;
`;