import React from "react";
import callAPI from "../../../../../api/apiCaller";
import Button from "../../../../../common/Button";
import Loading from "../../../../../common/Loading";
import Modal from "../../../../../common/Modal";
import TextInput from "../../../../../common/TextInput";
import { validateEmail } from "../../../../../common/utils";
import { EditProgramManagersContainer, ProgramManagerLine, ProgramManagerInfo, ManagerName, ManagerImage  } from "./styles";
import { Context } from "../../../../../Store";
import { getUserInfo } from "../../../../../Store/User/accessors";
import { ErrorMessage } from "../../forms/CreateFlowProgramManager/styles";

function EditProgramManagers({ editProgram, setShowEditProgramManagers }) {
  const [programManagers, setProgramManagers] = React.useState(null);
  const [newProgramManagerEmail, setNewProgramManagerEmail] = React.useState("");
  const [isAddingNewProgramManager, setIsAddingNewProgramManager] = React.useState(false);
  const [isRemovingProgramManager, setIsRemovingProgramManager] = React.useState(false);
  const [hasChangedProgramManager, setHasChangedProgramManager] = React.useState(false);
  const {state,dispatch} = React.useContext(Context);
  const [isValid,setIsValid] = React.useState(false);

  function getProgramInformation(){
    callAPI(dispatch,"getProgramInformation", { id: editProgram }).then((retrievedProgram) => {
      setProgramManagers(retrievedProgram.data.managers);
    })
  }
  React.useEffect(() => {
    getProgramInformation()
  },[]);
  function removeProgramManager(programManagerEmail) {
    setIsRemovingProgramManager(programManagerEmail);
    callAPI(dispatch,"removeProgramManager", { programId: +editProgram, email: programManagerEmail }).then((response) => {
      setIsRemovingProgramManager(false)
      setHasChangedProgramManager(true)
      setProgramManagers(response)
      if(response.isResponseOk){
        getProgramInformation()
      }
    });
  }
  function addProgramManager() {
    if(newProgramManagerEmail===getUserInfo(state).email) return
    setIsAddingNewProgramManager(true)
    callAPI(dispatch,"addProgramManager", { programId: +editProgram, email: newProgramManagerEmail }).then((response) => {
      setIsAddingNewProgramManager(false);
      setHasChangedProgramManager(true);
      setNewProgramManagerEmail("")
     if(response.isResponseOk){
      getProgramInformation()
     }
    });
  }
  if (editProgram && programManagers === null) {
    return <Modal
        title={"Edit Program Managers"}
        onClose={() => {
          setShowEditProgramManagers(false)
        }}
      ><EditProgramManagersContainer>
        <Loading item="Program Managers" />
        </EditProgramManagersContainer>
      </Modal>
   
  }
  return  <Modal
  title={"Edit Program Managers"}
  onClose={() => {
    setShowEditProgramManagers(hasChangedProgramManager)
  }}
  // isHeight={true}
  actions={[
    {
      text: "Back",
      variant: "secondary",
      icon: "back",
      onClick: () => {
        setShowEditProgramManagers(hasChangedProgramManager)
      }
    }
  ]}
>
  <EditProgramManagersContainer>
      {
        programManagers.length && programManagers.map((programManager) => {
          return <ProgramManagerLine>
            <ProgramManagerInfo>
              <ManagerImage src={programManager.user.image?programManager.user.image:"https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"}></ManagerImage>
              <ManagerName>{programManager.user?.firstName+" "+programManager.user?.lastName}</ManagerName>
            </ProgramManagerInfo>
            <Button icon={isRemovingProgramManager === programManager.id ? "loading" : "cross"} onClick={() => {
              removeProgramManager(programManager.user.email)
            }} />
          </ProgramManagerLine>
        })
      }
        {(newProgramManagerEmail===getUserInfo(state).email) &&
                <ErrorMessage>
               Cannot add your own email as  Program Manager
             </ErrorMessage> 
             
             }
      <ProgramManagerLine>
    
        <ProgramManagerInfo>
  
  
       
          <TextInput
            value={newProgramManagerEmail}
            setValue={setNewProgramManagerEmail}
            placeholder="Enter New Program Manager Email"
            isValid={isValid}
            validator={validateEmail}
            setIsValid={setIsValid}
          />
        </ProgramManagerInfo>
        <Button 
        icon={isAddingNewProgramManager ? "loading" : "add"} 
        onClick={addProgramManager}
        disabled={!isValid}
         />
      </ProgramManagerLine>
    
  </EditProgramManagersContainer>
</Modal>;
}
export default EditProgramManagers;