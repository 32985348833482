import styled from "styled-components";

export const DemoPanelOuterContainer = styled.div`
    padding: 60px 0 90px;
    position: relative;
    background-color: rgba(77, 77, 159, 0.10);
    @media screen and (max-width: 992px) {
        padding: 60px 0 50px;
    }
    @media screen and (max-width: 767px) {
        padding: 30px 15px 62px;
    }
`;

export const DemoPanelContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 2;
`;

export const DemoDescription = styled.p`
    color: #302F2F;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 375px;
    margin: 24px auto 0;
    @media screen and (max-width: 1366px) {
        font-size: 20px;
    }
    @media screen and (max-width: 992px) {
        font-size: 18px;
    }
`;

export const DemoHeading = styled.h3`
    color: #44449B;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 56px;
    margin: 0;
    @media screen and (max-width: 1366px) {
        font-size: 38px;
        line-height: 48px;
         
    }
    @media screen and (max-width: 992px) {
        font-size: 30px;
        line-height: 38px; 
    }
`;

export const DemoButton = styled.a`
    padding: 5px 12px;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 240px;
    height: 60px;
    border-radius: 5px;
    background: #4F449B;
    margin-top: 50px;
    color: #FFFFFF;
    cursor: pointer;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    font-size: 20px;
    @media screen and (max-width: 1366px) {
        font-size: 18px;
        line-height: 26px;
        padding: 15px 30px;
        width: auto;
        height: auto;
        margin-top: 30px;
    }
    @media screen and (max-width: 992px) {
        font-size: 16px;
        line-height: 24px;
        padding: 12px 20px;
        margin-top: 30px;
        margin-bottom: 20px;
    }
`;

export const DemoRightLines = styled.img`
    position: absolute;
    left: 0;
    bottom: 0;
    @media screen and (max-width: 767px) {
        width: 25%;
    }
`;



export const DemoLeftLines = styled.img`
    position: absolute;
    right: 0;
    top: 0;
    @media screen and (max-width: 767px) {
        width: 25%;
    }
`;