import styled from "styled-components";
import { headerSize, panelSpacing } from "../../../common/styles";
import colors from "../../../config/colors";

export const MessagesContainer = styled.div`
    height: calc(100vh - ${headerSize}px);
    padding:${panelSpacing}px;
    background-color: ${colors.secondary};
    display: flex;
    justify-content: space-between;
`;