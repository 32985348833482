import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProfileSectionsContainer,ProfileSectionHeader,ProfileSectionOption, ControlPanelHeader } from "./styles";
import Icon from "../../../../common/Icon";
import { ButtonSpacer } from "../../../../common/Button/styles";
import colors from "../../../../config/colors";
import { CrossButtonContainer, CrossButtonContainerSpan } from "../../Programs/ProgramsList/styles";

function ProfileSections(props) {
  const options=[
    {
      label:"Your Account",
      value:"account",
      icon:"user"
    },
    {
      label:"Login & Security",
      value:"security",
      icon:"security",
    },
    {
      label:"Terms & Services",
      value:"terms-and-services",
      icon:"terms",
    },
    {
      label:"Send Feedback",
      value:"feedback",
      icon:"chat"
    },
    {
      label:"Billing",
      value:"billing",
      icon:"calendar"
    },
  ]
  const navigate = useNavigate();
  const params=useParams();
  return <ProfileSectionsContainer hamburgerOpen={props.hamburgerOpen} >
    {/* <ControlPanelHeader> */}
    <CrossButtonContainer onClick={()=>props.setHamburgerOpen(!props.hamburgerOpen)}>
    <CrossButtonContainerSpan></CrossButtonContainerSpan>
    <CrossButtonContainerSpan></CrossButtonContainerSpan>
    </CrossButtonContainer>
    <ProfileSectionHeader>
      Control Panel
      
    </ProfileSectionHeader>
    
    {/* </ControlPanelHeader> */}
    {
      options.map((option)=>{
        return <ProfileSectionOption 
        key={option.value}
        disable={option.value==='feedback' ?true : false}
        isActive={option.value===params.profileSection}
        onClick={()=>{
          navigate("/profile/"+option.value)
        }}>
          <Icon type={option.icon} size={18} color={option.value===params.profileSection?colors.background:option.value==='feedback'?colors.secondary:colors.primary}/>
          <ButtonSpacer/>
          {option.label}
          </ProfileSectionOption>
      })
    }
  </ProfileSectionsContainer>;
}
export default ProfileSections;