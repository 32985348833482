import React from "react";
import { useParams } from "react-router-dom";
import callAPI from "../../../../../../api/apiCaller";
import Loading from "../../../../../../common/Loading";
import { convertToLinks, formatDate, formatDescription, formatsDescription } from "../../../../../../common/utils";
import {
  AnnouncementsContainer,
  AnnouncementHeader,
  Announcer,
  AnnouncerImage,
  AnnouncementInfo,
  AnnouncementEdit,
  AnnouncerName,
  AnnouncementTime,
  AnnouncementContent,
  ViewMoreSpan
} from "./styles";
import { Context } from "../../../../../../Store";
import SendAnnouncement from "../../../popups/SendAnnouncement";
import Button from "../../../../../../common/Button";
import Accordian from "../../../../../../common/Accordian";

function Announcements({ editMode, refreshProgram }) {

  let params = useParams();
  const [announcements, setAnnouncements] = React.useState(null);
  const { state, dispatch } = React.useContext(Context);
  const [showFullContent, setShowFullContent] = React.useState(false);
  const [showEditAnnouncement, setShowEditAnnouncement] = React.useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = React.useState(null)

  const formatDescription = (announcement) => {
    
    // console.log("announcement ",announcement.id)
    return showFullContent ? announcement.content : announcement.content.slice(0, 250);
  };

  React.useEffect(() => {
    setAnnouncements(null)
    callAPI(dispatch, "getProgramAnnouncements", { id: params.selectedProgram }).then(info => {
      if(info.isResponseOk)
     { 
      setAnnouncements(info.data)
     }
     else setAnnouncements([])
    })

  }, [params.selectedProgram]);

  if (announcements === null) {
    return <Loading loadingItem="Announcements" />
  }
  return <AnnouncementsContainer>
    <Accordian
    multiOpen
    defaultOpen
    collapsible={false}
      sections={announcements.map(announcement=>{
        return {
          title: <AnnouncementHeader>
            <Announcer>
            <AnnouncerImage src={announcement.user.image ?announcement.user.image:'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'} />
          <AnnouncementInfo>
            <AnnouncerName>{announcement.user.firstName} &nbsp;{announcement.user.lastName}</AnnouncerName>
            <AnnouncementTime>{formatDate(announcement.createdAt)}</AnnouncementTime>
          </AnnouncementInfo>
            </Announcer>
         
          {editMode && <AnnouncementEdit>
            <Button icon="edit" onClick={()=>setShowEditAnnouncement(announcement.id)}/>
          </AnnouncementEdit>}
        </AnnouncementHeader>,
        content:
        //  <AnnouncementContent        >
        // { selectedAnnouncement===announcement.id ? formatDescription(announcement): announcement.content.slice(0,250)} 
        
        // {announcement.content.length >250 &&<ViewMoreSpan icon="" onClick={()=>{
        //   setShowFullContent(!showFullContent)
        //   setSelectedAnnouncement(announcement.id)
        //   }}>{showFullContent && selectedAnnouncement===announcement.id ? "Hide" :"View more"}</ViewMoreSpan>}
        // </AnnouncementContent>

        <AnnouncementContent>
  {/* Render the announcement content with links converted */}
  {/* <div dangerouslySetInnerHTML={{ __html: convertToLinks(selectedAnnouncement === announcement.id ? formatDescription(announcement) : announcement.content.slice(0, 250)) }} /> */}
  <div
  dangerouslySetInnerHTML={{
    __html: `
      <style>
        div.description p{
          margin: 0;
        }
        div.description ol{
          margin: 0;
        }
        div.description ul{
          margin: 0;
        }
       div.description a{
        color: rgb(14 76 222 / 80%) !important;
       }
      
      </style>
      ${convertToLinks(
        selectedAnnouncement === announcement.id
          ? formatDescription(announcement)
          : announcement.content.slice(0, 250))
      }
    `,
  }}
  className="description"
/>
  {/* Conditionally render the "View more" option */}
  {announcement.content.length > 250 && (
    <ViewMoreSpan icon="" onClick={()=>{
      setShowFullContent(!showFullContent);
      setSelectedAnnouncement(announcement.id);
    }}>
      {showFullContent && selectedAnnouncement === announcement.id ? "Hide" : "View more"}
    </ViewMoreSpan>
  )}
</AnnouncementContent>
        }
      })}
    />
    {
      showEditAnnouncement && <SendAnnouncement
      announcementProgram={params.selectedProgram}
        announcementId={showEditAnnouncement}
        closeModal={(shouldRefresh) => {
          if (shouldRefresh) {
            refreshProgram()
          }
          setShowEditAnnouncement(false)
        }} />
    }
  </AnnouncementsContainer>;
}
export default Announcements;