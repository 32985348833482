import styled from "styled-components";
import { Scrollable } from "../../../../../common/styles";
import colors from "../../../../../config/colors";

export const AddParticipantsContainer = styled.div`
    width:100%;
`;
export const AddLine=styled.div`
margin-top:20px;
    border:1px solid ${colors.secondary};
    padding:10px;
    border-radius:10px;
`;
export const NewParticipantLine = styled.div`
    display:flex;
    align-items:center;
        gap:5px;
    justify-content:space-between;
    @media (max-width:640px) {
        flex-direction:${props=>props.isColumn && 'column'};
        gap:5px;
    }
    
`;
export const NewParticipantEmailContainer=styled.div`
    width:50%;
    @media (max-width:640px) {
    width:100%;
        
    }
    
`;
export const NewParticipantActions = styled.div`
    align-items:center;
    justify-content:center;
    display:flex;
`;

export const AddedLines=styled.div`
    ${Scrollable}
    height: 30vh;
    @media (max-width:640px) {
        height: 100vh;
        max-height: calc(100vh - 490px);
    }
`;
export const NewParticipantEmail = styled.div``;
export const NewParticipantRole = styled.div`
    text-transform:capitalize;
    margin-right:30px;
    font-weight:bold;
    color:${colors.primary};
`;
export const NewParticipantRow = styled(NewParticipantLine)`
    padding:10px;
    border-bottom:1px solid ${colors.secondary};
`;