export const setProgramList = (list) => {
    return {
      type: "SET_PROGRAM_LIST",
      list,
      segmentName: "programsList",
    }
  }
  export const setProgramListAsLoading = () => {
    return {
      type:"IS_LOADING_PROGRAM_LIST",
      segmentName: "programsList",
    }
  }
  export const refreshProgramList = () => {
    return {
      type:"REFRESH_LIST",
      segmentName: "programsList",
    }
  }