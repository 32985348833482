import styled from "styled-components";

export const MeetingPanelContainer = styled.div`
    background: #4F449B;
    box-shadow: 0px 1.229px 12.29px 0px rgba(182, 174, 174, 0.25);
    padding: 40px 0 82px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    @media screen and (max-width: 992px) {
        padding: 40px 0 42px;
    }
    @media screen and (max-width: 767px) {
        padding: 30px 17px 40px;
    }
`;

export const MeetingPanelHeading = styled.h2`
    color: #FFF;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 50px;
    max-width:  660px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    @media screen and (max-width: 1366px) {
        font-size: 38px;
        line-height: 48px;
        max-width: 80%;
    }
    @media screen and (max-width: 992px) {
        font-size: 30px;
        line-height: 38px;
    }
    @media screen and (max-width: 767px) {
        max-width: 100%;
    }
`;

export const MeetingPanelDescription = styled.p`
    color: #FFF;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width:  660px;
    margin: 24px auto 67px;
    position: relative;
    z-index: 2;
    @media screen and (max-width: 1366px) {
        font-size: 20px;
        margin: 20px auto 60px;
    }
    @media screen and (max-width: 992px) {
        font-size: 18px;
        line-height: 26px;
        max-width: 80%;
        margin: 20px auto 40px;
    }
    @media screen and (max-width: 767px) {
        max-width: 100%;
    }
`;

export const MeetingPanelImage = styled.img`
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
`;

export const MeetingLines = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;


export const MeetingImageWrapper = styled.div`
    max-width: 75%;
    width: 100%;
    @media screen and (max-width: 767px) {
        max-width: 100%;
    }
`;

