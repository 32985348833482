import React from "react";
import { ButtonSpacer } from "../../../common/Button/styles";
import ProgramContent from "./ProgramContent";
import ProgramsList from "./ProgramsList";
import { ProgramsContainer } from "./styles";
import Modal from "../../../common/Modal";
import Button from "../../../common/Button";
import { SectionActions } from "../../../common/Button/styles";
import Checkbox from "../../../common/Checkbox";
import TermsAndConditionsContent from "../../TermsAndConditions/TermsConditionsContent";
import callAPI from "../../../api/apiCaller";
import {getAgreeTermAndConditions} from '../../../Store/TermAndConditions/accessors'
import { Context } from "../../../Store";
import { getUserInfo } from "../../../Store/User/accessors";
import { checkIfTokenValid } from "../../../common/utils";
import { AgreeTermAndConditions } from "../../../Store/TermAndConditions/actions";

function Programs() {
  let { state, dispatch } = React.useContext(Context);
const [hamburgerOpen, setHamburgerOpen]= React.useState(false)
const [isChecked, setIsChecked] = React.useState(false);
const handleChangeTerms = (newValue) => {
  setIsChecked(newValue);
}

const handleBack = () => {
  localStorage.setItem("accessToken", "")
  callAPI(dispatch, "logout", {})
  window.location.href = "/app";
}

React.useEffect(() => {

},[getAgreeTermAndConditions(state).agreeTermCond])

if(!getAgreeTermAndConditions(state).agreeTermCond){
  return (
    <Modal
      width={'30%'}
    >
      <TermsAndConditionsContent isModal={true} />
      <Checkbox
        label="We have updated the Terms and Services. Please accept to proceed."
        isAgree={true}
        isModal={true}
        size={10}
        value={isChecked}
        setValue={(newValue) => {
          handleChangeTerms(newValue);
        }}
      />
      <SectionActions isTerm={true} >
        <Button
          icon="back"
          variant="secondary"
          onClick={handleBack}
          text="Back"
        />
        <Button
          variant="primary"
          onClick={() => {
            callAPI(dispatch, "UpdateTermsConditions", {
              id: getUserInfo(state).id,
              agreeTermCond: isChecked
            })
              .then((res) => {
                if (res.isResponseOk) {
                  checkIfTokenValid(dispatch).then(res => {
                    dispatch(AgreeTermAndConditions(res.data.agreeTermCond))
                    return res
                  })
                }
              })
          }}
          disabled={!isChecked}
          text="Submit"
        />
      </SectionActions>
    </Modal>
  )
}
return <ProgramsContainer>
    <ProgramsList setHamburgerOpen={setHamburgerOpen} hamburgerOpen={hamburgerOpen}/>
    <ProgramContent setHamburgerOpen={setHamburgerOpen} hamburgerOpen={hamburgerOpen}/>
  </ProgramsContainer>;
}
export default Programs;