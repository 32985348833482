import React, { useState } from "react";
import Button from "../../../../common/Button";
import { ButtonSpacer } from "../../../../common/Button/styles";
import Labelize from "../../../../common/Labelize";
import TextInput from "../../../../common/TextInput";
import { checkIfTokenValid, createInitialValid, getImageKeyFromURL, updateValid, validateString, validateText } from "../../../../common/utils";
import { YourAccountContainer, YourAccountTitle, FormActions, ShortInfoSection, InfoSection, ImageContainer, AccountHeader, Popup } from "./styles";
import ImageUpload from "../../../../common/ImageUpload";
import { Context } from "../../../../Store";
import callAPI from "../../../../api/apiCaller";
import { CrossButtonContainer, CrossButtonContainerSpan } from "../../Programs/ProgramsList/styles";
import { HamburgerIconContainer, HamburgerIconContainerSpan } from "../../Programs/ProgramContent/styles";
import { loginUser, updateUser } from "../../../../Store/User/actions";
import Icon from "../../../../common/Icon";
import Modal from "../../../../common/Modal";
import { HeadingWraper } from "../../Programs/ProgramContent/ProgramOutBounds/style";
import { Link } from "react-router-dom";

function YourAccount(props) {
  const [isCalendly ,setCalendly]=React.useState(false)
  console.log(isCalendly,"<");
  const [profile,setProfile] = React.useState({
    firstName:"",
    lastName:"",
    pronouns:"",
    bio:"",
    // as nikki said Will need to also remove the calendar section in the settings because users no longer need to 
    //link their calendar
    //bug link https://trello.com/c/Ot1ZJRGt/15-1-1-mentoring-change-the-calendar-button-to-scheduler
    calendarLink:"",
  });
  const [isValid,setIsValid] = React.useState(createInitialValid(["firstName","lastName","pronouns","bio"]));
  const {state,dispatch } = React.useContext(Context);
  React.useEffect(()=>{
    for (const key in state.user) {
      if (state.user[key] === null) {
        delete state.user[key];
      }
    }

    setProfile({
      ...profile,
      ...state.user
    })
  }, [])
  function updateField(field) {
    return function (newValue) {
      setProfile({
        ...profile,
        [field]: newValue
      })
    }
  }
  
  function updateUserDetails(){
    const {id,firstName,lastName,pronouns,bio,image, calendarLink}=profile
    
    callAPI(dispatch,"updateUser", {id, calendarLink, firstName,lastName,pronouns,bio,image :image ? getImageKeyFromURL(image): '', email:state.user.email }).then(() => {
      checkIfTokenValid(dispatch).then(res => {
        dispatch(loginUser(res))
      })
    });

  }
  const fields=[
    {
      key: "firstName",
      label: "First Name",
    },
    {
      key: "lastName",
      label: "Last Name",
    },
    {
      key: "pronouns",
      label: "Pronouns",
    },
    {
      key: "bio",
      label: "Bio",
      wordLimit: 500,
    },
     // as nikki said Will need to also remove the calendar section in the settings because users no longer need to 
    //link their calendar
    //bug link https://trello.com/c/Ot1ZJRGt/15-1-1-mentoring-change-the-calendar-button-to-scheduler
    // calendarLink:"",
    {
      key: "calendarLink",
      label: "Calendly Name",
    },
  ]
  return <YourAccountContainer>

<AccountHeader>
  <HamburgerIconContainer onClick={()=>props.setHamburgerOpen(!props.hamburgerOpen)}>
            <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
            <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
            <HamburgerIconContainerSpan></HamburgerIconContainerSpan>
          </HamburgerIconContainer>
    <YourAccountTitle>Your Account</YourAccountTitle>
    </AccountHeader>
    <ShortInfoSection>
      <InfoSection>
        {
          fields.slice(0, 2).map((field) => {
            return <>
              <ButtonSpacer />
              <Labelize label={field.label}>
                {profile[field.key]}
              </Labelize>
            </>
          })
        }
      </InfoSection>
      <ImageContainer>
        <ImageUpload value={profile.image} setValue={updateField("image")} />
      </ImageContainer>
    </ShortInfoSection>
{
  fields.slice(3).map((field) => {
    return <>
    <ButtonSpacer/>
    <Labelize label={field.label}>
      <TextInput 
        placeholder={field.label} 
        value={profile[field.key]}
        setValue={updateField(field.key)}
        isValid={isValid[field.key]}
        isTextArea={field.wordLimit}
        isHeight={true}
        wordLimit={field.wordLimit}
        setIsValid={updateValid(isValid,setIsValid,field.key)}
        validator={field.validator?field.validator:validateString}
        />
    </Labelize>
    </>
  })
}
<Popup>
<Button icon={'info'} onClick={()=>setCalendly(true)}/>
</Popup>
<FormActions>
  <Button icon="save" variant="primary" text={"Save"} onClick={updateUserDetails}/>
</FormActions>
{isCalendly && (<Modal
      title={"Calendly Information"}
onClose={()=>setCalendly(false)}>
<HeadingWraper>{'please visit ' }<Link to= {'https://calendly.com/app/personal/link'}  target="_blank">{'https://calendly.com/app/personal/link'}</Link>{' to get your calenldy name'}</HeadingWraper>
</Modal>)}
  </YourAccountContainer>;
}
export default YourAccount;