import styled from "styled-components";
import { Scrollable } from "../../common/styles";


export const TermsAndConditionOuterContainer = styled.div`
    font-family: 'Nunito Sans', sans-serif;
    z-index:3;
    height:100vh;
    width:100vw;
    ${Scrollable};
`;

export const TermsAndConditionContainer = styled.div`
    margin: 0 auto;
    padding: 0 0 20px;
    max-width: ${props=>props.isModal ? '100%' : props.isProfile ? '100%' : '50%'};
    padding: ${props=>props.isModal ? '0 0 20px' : props.isProfile ? '0 0 0' : '0 0 20px'};
    height: ${props=>props.isModal ? '45vh' : props.isProfile ? '100%' : ''};
    overflow: ${props=>props.isModal && 'auto'};
    ${props=>props.isModal && `${Scrollable}`};
`;

export const Category = styled.div`
    
`;

export const Heading = styled.h2`
    text-align: center;
    color: #44449B;
    font-size: ${props=>props.isModal ? '16px' : '28px'};
    line-height: ${props=>props.isModal ? '22px' : '34px'};
    margin-bottom: ${props=>props.isModal ? '15px' : '30px'};
`;

export const Description = styled.p`
    font-size: ${props=>props.isModal ? '12px' : props.isProfile ? '14px' : '18px'};
    line-height: ${props=>props.isModal ? '18px' : props.isProfile ? '20px' : '24px'};
    padding: 0 40px;
    padding: ${props=>props.isProfile ? '0 0' : '0 40px'};
`;

export const SubHeading = styled.h3`
    color: #44449B;
    font-size: ${props=>props.isModal ? '14px' : props.isProfile ?  '16px' : '20px'};
    line-height: ${props=>props.isModal ? '20px': props.isProfile ?  '22px' : '26px'};
`;

export const SubDescription = styled.div`
    position: relative;
    color: #626262;
`;

export const SubDescriptionPoint = styled.h4`
    position: absolute;
    top: 2px;
    left: 0;
    margin: 0;
    color: #44449B;
    font-size: ${props=>props.isModal ? '14px' : props.isProfile ? '14px' : '20px'};
    line-height: ${props=>props.isModal ? '20px' : props.isProfile ? '20px' : '26px'};
`;

export const SubDescriptionDetail = styled.p`
    padding-left: 50px;
    max-width: 90%;
    font-size: ${props=>props.isModal ? '12px' : props.isProfile ? '14px'  : '18px'};
    line-height: ${props=>props.isModal ? '18px' : props.isProfile ? '20px'  : '24px'};
`;

export const SubDescriptionDetailPoint = styled.span`
    margin-right: 10px;
    color: #44449B;
`;
