import createReducer from "../common/createReducer";

const reducers = {
  MARK_AS_READ_PERSONAL_MESSAGE: (state, action) => {
    return {
      ...state,
      count: action.count,
    };
  },
};

const initialState = {
    count: 0,
};

export default createReducer(initialState, reducers);