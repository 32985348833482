import React from "react";
import { DateLineContainer,DateLineLine,DateLineContent } from "./styles";

function DateLine({dateString}) {
  return <DateLineContainer>
    <DateLineLine />
    <DateLineContent>{dateString}</DateLineContent>
    <DateLineLine />
  </DateLineContainer>;
}
export default DateLine;