import styled from "styled-components";
import {
  Scrollable,
  panelSpacing,
} from "../../../../../../../../common/styles";
import colors from "../../../../../../../../config/colors";

export const BoardContainer = styled.div`
    display:flex;
    width:100%;
    height:100%;
    min-height: 650px;
    box-sizing:border-box;
    @media (max-width:640px) {
        flex-direction: column;
        height: 90vh;
        gap: 20px;
    }


`;
export const BoardSidebar = styled.div`
width: 300px;
min-width: 300px;
background-color: #fff;
height: 100%;

box-sizing: border-box;
padding:10px;
${Scrollable}
@media (max-width:640px) {
     width: 100%;
     max-height: 530px;
    flex-shrink: 0;
    }
   
`;
export const BoardTitle = styled.div`
font-size: 20px;
font-weight: 500;
text-transform: capitalize;

`;
export const BoardBody = styled.div`
background-color: #EFEFEF;
height: ${(props) => props.boardView ? '90vh' : '100%'};
width: ${(props) => props.boardView ? '90vw' : '100%'};
padding: 10px 0 0 10px;
box-sizing: border-box;
flex: 1;
overflow:hidden;
@media (max-width:767px) {
  flex: unset;
  flex-shrink: 0;
  padding: 0px;
  order-radius: 8px;
  width: ${(props) => props.boardView ? "90%" : '100%'};
  background-color: ${(props) => props.isWidth && "transparent"};
  height: ${(props) => props.boardView ? '75vh' : '100%'};
  }
`;

export const Minimize = styled.div`
    position: absolute;
    top: 15px;
    right:10px;


`;

export const BodyContainer = styled.div`
background-color: #FFFFFF;
border-radius: 10px;
width: 100%;
height:${(props) => (props.isTeam ? `calc(100vh - 533px)`  : props.boardView ? "100%" : "100%")};
padding-top: ${(props) => (props.boardView ? "0px" : "10px")};
padding-bottom: 10px;
overflow:hidden;
${(props) => (props.boardView ? "" : Scrollable)}



`;

export const BoardSideBarHeader = styled.div`
  display:flex;
  justify-content:space-between;
  align-items: center;
  z-index: 9;
  padding-bottom: 10px;

`;
export const BodyTitle = styled.div`
    font-size: 20px;
    text-transform: capitalize;
    color: #b1b1b1;
    padding: 10px;

`;
// export const BoardAction = styled.div`
//   display:flex;
//   justify-content:center;
//   gap:10px;
//   margin-right:10px;

// `;
export const StyledIframe = styled.iframe`
  width:100%;
  height:100%;
  border:none;


`;
export const BoardAction = styled.div`
  display:flex;
  justify-content:center;
  gap:10px;
  margin-right:10px;

`;
export const BoardsInfo = styled.div`
    padding: 15px;
    border-bottom: solid 1px #EFEFEF;
    cursor: pointer;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color:${(props) => props.active && "#44449B"};
    color:${(props) => props.active && "#fff"};

&:hover {
    background-color: #44449B;
    color:#fff
  }

  

`;
export const BoardHeading = styled.div`
font-size: 16px;
margin-bottom: 5px;

`;
export const BoardSubHeading = styled.div`
font-size: 12px;


`;

export const UserInfoContainer = styled.div`
    max-height:60vh;
    width:50vw;
`;

export const SubHeading = styled.div`
    font-weight:bold;
    color:${colors.primary};
    margin:10px 0px;
`;
export const MemberContainer = styled.div`
    max-height:50vh;
    overflow-y:scroll;
`;
export const IframHeader = styled.div`
  display:flex;
  width:100%;
  justify-content:space-between;
    border-bottom: 1px solid #b1b1b1;

`;
export const BoardDetail = styled.div`
  display:flex;
  width:100%;
  justify-content:space-between;

`;
export const ActionContainer = styled.div`
  display:flex;
gap:10px;

`;
export const BoardNotification = styled.h3`
text-align:center;
margin-top:${(props) => (props.top ? props.top : "")};

`;
