import React, { useEffect } from 'react';
import styled from 'styled-components';
import { validatePhoneNumber } from '../utils';
import countries from "../../../countries.json"
import colors from '../../config/colors';

const RequestMeetingFormWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #efefef;
  border-radius: 50px;
  padding: 8px;
  background-color: #efefef;
`;

const CountryCode = styled.div`
  margin-right: 8px;
  padding: 2px;
  border: 1px solid #efefef;
  border-radius: 37px;
  background: #efefef;
  color: #757589;
  width:100%
`;

const SelectStyle= styled.select`
border: 1px solid #efefef;
width: 100%;
background: border-box;
& option{
  background:'#efefef';

}
`


const ComboBox = ({options, placeholder, setValue,value,}) => {
  return (
    <RequestMeetingFormWrapper >
      <SelectStyle value={value} placeholder={placeholder}   name='Select a program' onChange={(e)=>{
        setValue(e.target.value)
      }}>
          {options?.map((option) => (
            <option key={option?.id} value={option?.id}>
              {option?.title}
            </option>
          ))}
        </SelectStyle>
    </RequestMeetingFormWrapper>
  );
};

export default ComboBox;
