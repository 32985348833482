import React from "react";
// import BackgroundPanel from "./BackgroundPanel";
import Footer from "./Footer/footer";
import Header from "./Header/Header";
import HomePanel from "./HomePanel/homePanel";
import ServicesPanel from "./ServicesPanel/servicePanel";
import { HomePageContainer } from "./styles";
import MeetingPanel from "./MeetingsPanel";
import MentorView from "./MentorView";
import DemoPanel from "./DemoPanel";
import ReviewPanel from "./ReviewPanel";
function HomePage() {
  return <HomePageContainer>
    {/* <BackgroundPanel /> */}
    <Header />
    <HomePanel />
    <ServicesPanel />
    <MeetingPanel />
    <MentorView />
    <ReviewPanel/>
    <DemoPanel />
    <Footer/>
  </HomePageContainer>;
}
export default HomePage;