import styled from "styled-components";
import colors from "../../../../config/colors";
import { panelSpacing } from "../../../../common/styles";

export const SectionHeader = styled.div`
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 6px;
`;

export const TermsSectionHeader = styled.div`
@media(max-width:991px){
    display: flex;
    gap: 5px;
}
`;

export const TermsContainer = styled.div``;

export const TermsSection = styled.div`
    padding:${panelSpacing}px;
    border-bottom:1px solid ${colors.secondary};
`;