import React, { useEffect, useState } from "react";
import { ImageUploadContainer, ImagePreview, UploadButton } from "./styles";
import TextInput from "../TextInput";
import Button from "../Button";
import Icon from "../Icon";
import { ButtonSpacer } from "../Button/styles";
import colors from "../../config/colors";
import callAPI from "../../api/apiCaller";
import { Context } from "../../Store";
import { uploadImageToS3 } from "../utils";

function ImageUpload({ item = "Image",size=150, value = null, setValue,setIsDisabled=()=>{}, setIsValid = () => { },teamVariant, height ,isEdit=false ,handleEditTeamClick,fileInputRef,newTeam,aspectRatio }) {
  const [uploadState,setUploadState] = React.useState("not-uploaded");
  const [selectedFile, setSelectedFile]=useState(value)
  const {state,dispatch } = React.useContext(Context);
  return <ImageUploadContainer height={height} aspectRatio={aspectRatio}>
    <ImagePreview size={size} src={selectedFile ? selectedFile : value ? value :'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'}/>
   {teamVariant ?
(<>
<label htmlFor="fileInput" style={{ position: 'fixed',  width: isEdit ? '2%':'15%', height:isEdit ?  '3%': '10%', cursor: 'pointer',marginTop: isEdit ? '-28px':'',borderRadius:isEdit ? '150px':''}}  onClick={() => {
              fileInputRef.current.click();
            }}>
</label>

      <input type="file" 
        id="fileInput"
        ref={fileInputRef}
        style={{ visibility: 'hidden', width: 0, height: 0, overflow: 'hidden' }}
          onChange={(event)=>{
            if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            var reader = new FileReader();
            reader.readAsDataURL(img);
            reader.onload=()=>{
              setUploadState("uploading");
              setSelectedFile(reader.result.toString())
            }
            callAPI(dispatch,"signedUrl", {fileType:(event.target.files[0].type).split("/")[1],fileSize:(Math.ceil((event.target.files[0].size)/(1024 * 1024)))})
            .then((signedUrlResponse) => {
              setValue(signedUrlResponse.data.key)
              setIsDisabled(true)
              return uploadImageToS3(dispatch,'uploadFile',{url: signedUrlResponse.data.url,file:event.target.files[0]})
            }).then((respone)=>{
              if(respone.ok){
                setIsValid(true)
                setUploadState("upload");
                setIsDisabled(false)
              
              return
            }
            });
          }
      }} /></>)
   : (<UploadButton>
      <input type="file" style={{ display: "none" }} onChange={(event)=>{
          if (event.target.files && event.target.files[0]) {

            let img = event.target.files[0];
            var reader = new FileReader();
            reader.readAsDataURL(img);
            reader.onload=()=>{
              setUploadState("uploading");
              setSelectedFile(reader.result.toString())
            }
            callAPI(dispatch,"signedUrl", {fileType:(event.target.files[0].type).split("/")[1],fileSize:(Math.ceil((event.target.files[0].size)/(1024 * 1024)))})
            .then((signedUrlResponse) => {
              setValue(signedUrlResponse.data.key)
              setIsDisabled(true)
              return uploadImageToS3(dispatch,'uploadFile',{url: signedUrlResponse.data.url,file:event.target.files[0]})
            }).then((respone)=>{
              if(respone.ok){
                setIsValid(true)
                setUploadState("upload");
                setIsDisabled(false)

              return
            }
            });
          }
      }} />
      <Icon type={uploadState === "not-uploaded" ? "upload" : uploadState === "uploading" ? "loading" : "upload"} color={colors.background} />
      <ButtonSpacer />
      Upload {item}
    </UploadButton>)}
  </ImageUploadContainer>;
}
export default ImageUpload;