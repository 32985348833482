import styled from "styled-components";
import { panelSpacing } from "../../../../../common/styles";

export const ProgramDetailsContainer = styled.div`
`;



export const Basics = styled.div`
    display:flex;
    justify-content:space-between;
    @media (max-width:767px) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }


`;
export const LeftSection = styled.div`
    width: calc(100% - 150px - ${panelSpacing}px);
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    @media (max-width:767px) {
        margin-top:30px;
        width: 100%;
    }
`;
export const RightSection = styled.div`
    width:150px;
`;