import React from "react";
import { NoteEditorContainer,NoteTitle,NoteContent,NoNoteContainer, NoteHeader,NoteActions } from "./styles";
import callAPI from "../../../../../../../../../api/apiCaller";
import Loading from "../../../../../../../../../common/Loading";
import Button from "../../../../../../../../../common/Button";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import { ButtonSpacer } from "../../../../../../../../../common/Button/styles";
import { useParams } from "react-router-dom";
import { Context } from "../../../../../../../../../Store";

function NoteEditor({currentNote,deleteNote,isNoteDeleting}) {

  const [note,setNote] = React.useState(null);
  const [isNoteSaving,setIsNoteSaving] = React.useState(false);
  const {state,dispatch} = React.useContext(Context);
  const params= useParams();

  React.useEffect(() => {
    if(params.currentNote){
      setNote(null);
      callAPI(dispatch,"getNote",{noteId:params.currentNote}).then(res => {
        setNote(res)
      })
    }
  }, [params.currentNote]);

  function saveNote(){
    setIsNoteSaving(true);
    callAPI(dispatch,"saveNote",{note}).then(savedNote=>{
      setIsNoteSaving(false)
      setNote(savedNote)
    })
  }
  if(!params.currentNote){
    return <NoteEditorContainer>
      <NoNoteContainer>
        Select a note to view/edit it
      </NoNoteContainer>
    </NoteEditorContainer>
  }
  else if(!note){
    return <NoteEditorContainer>
      <NoteContent>
        <Loading loadingItem="Note"/>
      </NoteContent>
    </NoteEditorContainer>
  }
  return <NoteEditorContainer>
    <NoteHeader>
      <NoteTitle 
        value={note.title} 
        placeholder="Enter Note Title here"
        onChange={(e)=>{
          setNote({
            ...note,
            title:e.target.value
          });
        }}
      />
        <NoteActions>
        <Button 
            icon={isNoteDeleting?"loading":"trash"}
            variant="secondary" 
            text="Delete"
            onClick={()=>{
              deleteNote();
            }}
          />
        <ButtonSpacer/>
          <Button 
            icon={isNoteSaving?"loading":"save"}
            variant="primary" 
            text="Save"
            onClick={()=>{
              saveNote();
            }}
          />
          
        </NoteActions>
      </NoteHeader>
    <NoteContent>
      <Editor
        initialContentState={note.content}
        onContentStateChange={(newContent)=>{
          setNote({
            ...note,
            content:newContent
          })
        }}
        toolbar={{
          options: ['inline', 'blockType', 'list', 'textAlign', 'history'],
          blockType: {
            inDropdown: false,
            options: ['Normal', 'H1', 'H2', 'H3','H4', 'H5', 'H6','Blockquote'],
          },

        }}
      />
    </NoteContent>
  </NoteEditorContainer>;
}
export default NoteEditor;