import styled from "styled-components";
import colors from "../../config/colors";
import { Scrollable } from "../styles";

export const TextInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  position: relative;
`;
export const WordCount = styled.div`
  font-size: 10px;
  margin-top: -15px;
  margin-right: 15px;
  color: ${(props) => (props.isAboveLimit ? "red" : colors.darkBackground)};
`;
export const PaymentInput = styled.input`
    appearance: none;
    padding:7px;
    background-color: ${colors.secondary};
    font-size: 14px;
    position: relative;
    font-family: sans-serif;
    border:solid 1px ${(props) =>
      props.isValid === null || props.value.length === 0
        ? colors.secondary
        : props.isValid
        ? colors.primary
        : "red"};
    width: 100%;
    box-sizing: border-box;
    transition: all 0.1s ease-in-out;
    outline: ${colors.background};
    ${(props) => props.disabled && "opacity: 0.5;"}
    cursor: ${(props) => (props.disabled ? "not-allowed" : "text")};
    ::placeholder 
        color: ${colors.darkBackground};
    }
    :focus{
        border:solid 1px ${(props) =>
          props.isValid === null || props.value.length === 0
            ? colors.darkBackground
            : props.isValid
            ? colors.primary
            : "red"};
    }
`;

export const Input = styled.input`
    appearance: none;
    padding:10px;
    background-color: ${colors.secondary};
    font-size: 14px;
    border-radius:20px;
    position: relative;
    font-family: sans-serif;
    border:solid 1px ${(props) =>
      props.isValid === null || props.value.length === 0
        ? colors.secondary
        : props.isValid
        ? colors.primary
        : "red"};
    width: 100%;
    box-sizing: border-box;
    transition: all 0.1s ease-in-out;
    outline: ${colors.background};
    ${(props) => props.disabled && "opacity: 0.5;"}
    cursor: ${(props) => (props.disabled ? "not-allowed" : "text")};
    ::placeholder 
        color: ${colors.darkBackground};
    }
    :focus{
        border:solid 1px ${(props) =>
          props.isValid === null || props.value.length === 0
            ? colors.darkBackground
            : props.isValid
            ? colors.primary
            : "red"};
    }
`;

export const ViewPasswordContainer = styled.div`
  position: absolute;
  right: 0.6rem;
  top: 0.6rem;
  cursor:pointer,
  @media (max-width: 768px) {
    svg {
      height: 10px;
    }
  }
`;

// flex-direction:column;
// align-items: flex-start !important;

export const PasswordHintContainer = styled.div`
  display: block;
  color: red;
  margin-top: 5px;
  cursor: pointer;
`;
// padding-right: 8px;

export const DivSpacer = styled.div`
  display: block;
`;

export const InstructionsStar = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

export const InstructionsStarSpan = styled.span`
  display: block;
  color: ${(props) => props.color};
`;

export const PasswordLabel = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
  align-items: center;
  color: ${(props) => props.color};
  ${(props) =>
    props.horizontal &&
    `
  font-size: 14px;
`}
  white-space: nowrap; /* Prevent text from wrapping to multiple lines */
  overflow: hidden; /* Hide any overflowed text */
  text-overflow: ellipsis; /* Show ellipsis (...) for overflowed text */
`;
export const TextArea = styled.textarea`
    height: 100px;
    resize:both;
    font-family: 'Inter',sans-serif;
    appearance: none;
    padding:10px;
    background-color: ${colors.secondary};
    font-size: 14px;
    border-radius:10px;
    border:solid 1px ${(props) =>
      props.isValid === null || props.value.length === 0
        ? colors.secondary
        : props.isValid
        ? colors.primary
        : "red"};
    width: 100%;
    height: ${props=> props.isHeight ?'150px' :'40px'};
    box-sizing: border-box;
    transition: all 0.1s ease-in-out;
    outline: ${colors.background};
    ${(props) => props.disabled && "opacity: 0.5;"}
    cursor: ${(props) => (props.disabled ? "not-allowed" : "text")};
    resize: vertical;
    ::placeholder 
        color: ${colors.darkBackground};
    }
    ${Scrollable}
    :focus{
        border:solid 1px ${(props) =>
          props.isValid === null || props.value.length === 0
            ? colors.darkBackground
            : props.isValid
            ? colors.primary
            : "red"};
    }
`;

export const MessageTime =styled.div`
    font-size: 10px;
    color: ${colors.darkBackground};
`;

export const ContactImage =styled.img`
    height:30px;
    width:30px;
    border-radius:50%;
    object-fit:cover;
  
`;

export const ContactName =styled.div`
    font-size:15px;
    font-weight:bold;
    margin-bottom:5px;
`;

export const MessageSummary =styled.div`
    font-size: 12px;
`;

export const DropdownContainer = styled.div`
    max-height: 200px;
    overflow: auto;
    scrollbar-width: none;
    position: absolute;
    background: #efefef;
    z-index: 1;
    top: 40px;
    box-shadow: 2px 5px 10px rgba(0,0,0, 0.16);
    width: 100%;
    border-radius: 0 0 8px 8px;
`

export const DropdownItem = styled.div`
    display: flex;
    align-items: center;
    min-height: 40px;
    padding: 10px;
    justify-content: space-between;
    gap: 10px;
    border-bottom: solid 1px #ccc;
    cursor: pointer;
`

export const ContactInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
`

export const SearchableChatContainer =styled.div`
    position: relative;
`;

export const UserNotFound = styled.div`
    display: flex;
    align-items: center;
    min-height: 40px;
    padding: 10px;
    justify-content: center;
`

export const UserNotFoundText = styled.span`
  font-size:15px;
  font-weight:bold;
`

export const ContactMemberImages =styled.img`
    height:${props=>props.width ? '45px':'25px'};
    width:${props=>props.width ? '45px':'23px'};
    border-radius:40%;
    object-fit:cover;
    position: ${props=>props.lastImage && 'relative'};
    left:${props=>props.lastImage &&  '50%'}; /* Moves the image to the center horizontally */
    transform: ${props=>props.lastImage && 'translateX(-50%)'};
`;

export const ImageContainer =styled.div`
    height:60px;
    min-width:60px;
    width:60px;
    border-radius:50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Aligns the first two images to the left and right edges */
    align-items: center; 
`;