import user from "./User";
import programsList from "./ProgramsList";
import notifications from "./Notifications";
import messageCount from "./UnreadMessage";
import personalMessageCount from './PersonalMessageCount'
import conversationList from './ConversationList'
import groupConversationList from "./GroupConversationList";
import groupMessageCount from "./GroupMessageCount";
import UpcomingMeetingsCount from "./ProgramMeetingCount";
import CompletedMeetingsCount from "./ProgramMeetingCount";
import UnacceptedMeetingCount from "./ProgramMeetingCount";
import UpcomingTeamMeetingCount from "./ProgramMeetingCount";
import CompletedTeamMeetingCount from "./ProgramMeetingCount";
import RequestedMeetingCount from "./ProgramMeetingCount";
import TermAndConditions from "./TermAndConditions";
import toast from "./Toasts";


const storeSegments = {
  user,
  programsList,
  notifications,
  toast,
  messageCount,
  personalMessageCount,
  conversationList,
  groupConversationList,
  groupMessageCount,
  UpcomingMeetingsCount,
  CompletedMeetingsCount,
  UnacceptedMeetingCount,
  UpcomingTeamMeetingCount,
  CompletedTeamMeetingCount,
  RequestedMeetingCount,
  TermAndConditions,
};

export default storeSegments;